import React from "react";
import { Link } from "@amzn/stencil-react-components/link";
import { useChecklistArb } from "../../hooks/use-arb";
import { ChecklistPantherKey } from "../../utility/types/translations";
import { NHE_EVENT_TYPE } from "../../config/appConfigConstants";
import { useSendAnalyticEvent } from "../../hooks/jobDetailsCardAnalyticEventHelper.hook";

interface LinkWithRedirectWithAdobeMetricsProps {
  link: string;
  messageKey: ChecklistPantherKey;
  messagePlaceholder?: string;
  appointmentType?: NHE_EVENT_TYPE;
  isScheduleAppt?: boolean;
}

const LinkWithRedirectWithAdobeMetrics = ({
  link,
  messageKey,
  messagePlaceholder,
  appointmentType,
  isScheduleAppt,
}: LinkWithRedirectWithAdobeMetricsProps) => {
  const bundle = useChecklistArb();
  const { sendScheduleOrRescheduleClickEvent } = useSendAnalyticEvent();

  const handleRecheduleLinkOnClick = () => {
    if (appointmentType) {
      sendScheduleOrRescheduleClickEvent(appointmentType, isScheduleAppt);
    }
  };

  return (
    <Link
      target="_blank"
      href={link}
      onClick={handleRecheduleLinkOnClick}
      data-testid="general-first-day-header-reschedule-button"
    >
      {bundle.getMessage(messageKey, messagePlaceholder)}
    </Link>
  );
};

export default LinkWithRedirectWithAdobeMetrics;
