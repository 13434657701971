import { ImageProcessingData } from "@amzn/hvh-camera-ui";

export interface State {
  selectedShot?: ImageProcessingData;
  sampleShots?: ImageProcessingData[];
}

export interface Action {
  payload: any;
  type: ActionType;
}

export enum ActionType {
  UpdateSampleShots = "UPDATE_SAMPLE_SHOTS",
  UpdateSelectedShot = "UPDATE_SELECTED_SHOT",
}
