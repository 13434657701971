// Implements formatting mostly according to https://console.spec.whatwg.org/#logger but outputs a string to allow serialization
export function formatMessage(...args: unknown[]): string {
  if (args.length === 0) {
    // We want a more useful message for when no arguments are provided so we can tell this code is working
    return "No message arguments provided";
  } else if (args.length === 1) {
    // You can call `console.error(errorObject)` and we should handle it
    return String(args[0]);
  } else if (typeof args[0] === "string") {
    // The main logic is for `console.error("Some format string %s", someValue)` using substitutions
    /**
     * The algorithm is to split the format string into plain strings and format specifiers, then replace the
     * specifiers such as "%s" or "%d" using the provided additional arguments. Finally, the list is concatenated. For example:
     * Input: "Test %s with number %d", "format", 42
     * pieces: ["Test "]
     * pieces: ["Test ", "format"]
     * pieces: ["Test ", "format", " with number "]
     * pieces: ["Test ", "format", " with number ", "42"]
     * Output: "Test format with number 42"
     *
     * If for some reason you need to add a new specifier you must append it to the regex and add a case
     * to the if/else tree in the replacement loop to handle it
     */
    // This matches all of the message format specifiers except CSS
    const fmtregex = /%%|%s|%d|%i|%f|%o|%O/g;
    // We will split the template string and replace format specifiers with values to build this array
    const pieces: string[] = [];
    let previousEnd = 0; // Used to grab the text leading up to the current specifier
    let argIndex = 1; // Tracks which argument index to use for the next substitution
    let result: RegExpExecArray | null = null;
    while ((result = fmtregex.exec(args[0])) !== null) {
      const fullmatch = result[0]; // Regex always returns the full matched value in index 0, after that are capture groups if present
      pieces.push(args[0].slice(previousEnd, result.index)); // Add everything from after the last match (or the beginning if this is the first match) up to this match as a plain string
      previousEnd = result.index + fullmatch.length;
      if (fullmatch === "%%") {
        pieces.push("%"); // Escape sequence, don't consume an argument
      } else if (argIndex >= args.length) {
        pieces.push(fullmatch); // Don't perform replacements when out of arguments
      } else {
        // This is the important case: we need to consume an argument and append the formatted value to pieces
        const formatArg = args[argIndex++];
        if (fullmatch === "%s") {
          // String
          pieces.push(String(formatArg));
        } else if (fullmatch === "%d" || fullmatch === "%i") {
          // Integer
          pieces.push(parseInt(String(formatArg)).toString());
        } else if (fullmatch === "%f") {
          // Float
          pieces.push(parseFloat(String(formatArg)).toString());
        } else if (fullmatch === "%o" || fullmatch === "%O") {
          // Object, but we will convert to string representation
          pieces.push(typeof formatArg === "object" ? JSON.stringify(formatArg) : String(formatArg));
        }
      }
    }
    pieces.push(args[0].slice(previousEnd)); // Don't forget any trailing text after the last specifier
    return pieces.join("");
  } else {
    // Someone messed up the console.error() invocation
    return JSON.stringify(args);
  }
}
