import { load, save } from "../utility/local-storage";

const KEY = "badge_failed_attempts";

const MAX_NUM_OF_ATTEMPTS = 3;

export const getNumberOfFailedAttempts = (): number => {
  try {
    return +load(KEY);
  } catch (e) {
    return 0;
  }
};

export const incrementFailedAttempts = () => {
  const numberOfFailedAttempts = getNumberOfFailedAttempts();
  save(KEY, numberOfFailedAttempts + 1);
};

export const reachedAttemptsLimit = () => {
  const numberOfFailedAttempts = getNumberOfFailedAttempts();
  return numberOfFailedAttempts >= MAX_NUM_OF_ATTEMPTS;
};
