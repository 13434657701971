import React, { useState, useEffect } from "react";
import "./styles.scss";
import { Text, H1 } from "@amzn/stencil-react-components/text";
import { Row } from "@amzn/stencil-react-components/layout";
import { useChecklistArb } from "../../hooks/use-arb";
import { StatusContainer } from "./status-container";
import { getSettings, Stage } from "../../helpers/get-settings";
import { Button, ButtonSize, ButtonVariant } from "@amzn/stencil-react-components/button";
import { useNavigateContactUrl } from "./useNavigateContactUrl.hook";

export const ScreeningResultErrorHeader = () => {
  const bundle = useChecklistArb();
  const [stage, setStage] = useState<Stage>();
  useEffect(() => {
    getSettings().then((settings) => setStage(settings.stage));
  }, []);
  const formattedSorryMsg = bundle.getMessage("Checklist-AppointmentCard-Sorry-Msg");
  const errorMsg = bundle.getMessage("Checklist-AppointmentCard-Error-Msg");

  const viewresult = bundle.formatMessage("Checklist-AppointmentCard-ButtonText7");
  const contactus = bundle.formatMessage("Checklist-AppointmentCard-ButtonText4");
  const navigateContactUrl = useNavigateContactUrl(stage);

  return (
    <StatusContainer
      data-testid={"page-header-screening-result-error"}
      largeViewPortImage={require("../../assets/svg/error.svg").default}
      smallViewPortImage={require("../../assets/svg/error.svg").default}
    >
      <H1 fontSize={"T400"} fontWeight={"regular"} id="main-content">
        {formattedSorryMsg}
      </H1>

      <Row gridGap={"1rem"}>
        <Text fontSize={"T200"} textAlign="center">
          {errorMsg}
        </Text>
      </Row>

      <Row gridGap={"1rem"}>
        <Button size={ButtonSize.Small} onClick={() => document.getElementById("screeningresult")?.scrollIntoView()}>
          {viewresult}
        </Button>
        <Button
          role="link"
          tabIndex={0}
          variant={ButtonVariant.Primary}
          size={ButtonSize.Small}
          onClick={navigateContactUrl}
        >
          {contactus}
        </Button>
      </Row>
    </StatusContainer>
  );
};
