import React from "react";
import { PageContainer } from "@amzn/stencil-react-components/page";
import { Text } from "@amzn/stencil-react-components/text";
import { Col } from "@amzn/stencil-react-components/layout";
import { HVH_COLORS } from "../../config/palette";
import { useChecklistArb } from "../../hooks/use-arb";

export const NoMatchRoute = () => {
  const bundle = useChecklistArb();
  const message = bundle.getMessage("Checklist-General-PageNotFound");
  return (
    <Col height="100vh">
      <PageContainer color={HVH_COLORS.NEUTRAL_90} height="100%">
        <Col alignItems="center" justifyContent="center" height="100%">
          <Text fontWeight="regular" fontSize="T300">
            404 | {message}
          </Text>
        </Col>
      </PageContainer>
    </Col>
  );
};
