import React from "react";
import {
  Text as StencilText,
  H1 as StencilH1,
  H2 as StencilH2,
  H3 as StencilH3,
  H4 as StencilH4,
  Label as StencilLabel,
} from "@amzn/stencil-react-components/text";
import { HVH_COLORS } from "../../config/palette";

export const H1 = ({ children, ...props }: any) => {
  return (
    <StencilH1 fontWeight="regular" fontSize="T500" {...props}>
      {children}
    </StencilH1>
  );
};

export const H2 = ({ children, ...props }: any) => {
  return (
    <StencilH2 fontWeight="regular" fontSize="T400" {...props}>
      {children}
    </StencilH2>
  );
};

export const H3 = ({ children, ...props }: any) => {
  return (
    <StencilH3 fontWeight="regular" fontSize="T300" {...props}>
      {children}
    </StencilH3>
  );
};

export const H4 = ({ children, ...props }: any) => {
  return (
    <StencilH4 fontWeight="regular" fontSize="T300" {...props}>
      {children}
    </StencilH4>
  );
};

export const Text = ({ children, ...props }: any) => {
  return (
    <StencilText fontWeight="regular" fontSize="T200" {...props}>
      {children}
    </StencilText>
  );
};

export const Label = ({ children, ...props }: any) => {
  return (
    <StencilLabel fontSize="T200" color={HVH_COLORS.NEUTRAL_70} {...props}>
      {children}
    </StencilLabel>
  );
};
