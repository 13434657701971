import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Text, H3 } from "../../../../components/typography";
import { Col, Row, Spacer, Hr } from "@amzn/stencil-react-components/layout";
import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { IconPencil } from "@amzn/stencil-react-components/icons";
import { DocumentUploadButton } from "./document-upload-button";
import { useChecklistArb } from "../../../../hooks/use-arb";
import { AttachmentType, hasWorkAuthorizationAttachment } from "../models";
import { DocumentCompletedUploadFrontBack } from "./document-completed-upload-front-back";
import { DocumentCompletedUploadDropdown } from "./document-completed-upload-dropdown";
import { DocumentUploadComplete } from "./document-upload-complete";
import { Link } from "@amzn/stencil-react-components/link";
import { idInfo } from "../../../../utility/candidate-data";
import { StatusEnum } from "../../../../utility/enums/common";
import { DocumentUploadDropdown } from "./document-upload-dropdown";
import { isSpanishLocale } from "../../../../utility/locale-helper";
import { MX_ACCEPTABLE_DOCUMENTS_URL_ES, MX_ACCEPTABLE_DOCUMENTS_URL_EN } from "../../../../config/urls";
import { apiSlice, useLazyGetOldDocUploadValidatorQuery } from "../../../../reduxStore/api/apiSlice";
import { useAppDispatch } from "../../../../reduxStore/reduxHooks";
import { eventHandler } from "../helpers/eventHandler";
import { DOC_UPLOADS, I_9_DOCUMENT } from "../../../../config/taskConfigConstants";
import { CONTACT_US_LINK } from "../../../../utility/constants";
import LinkWithRedirect from "../../../../components/common/LinkWithRedirect";
import { useGetCandidateData } from "../../../../hooks/apis/useGetCandidateData";

export interface AttachmentMetaData {
  attachmentType: string;
  attachmentSubType: string;
  attachmentExpirationDateTime: string;
  attachmentUpdatedDateTime: string;
  contentType: string;
  fileName: string;
}

interface DocumentUploadTaskProps {
  status: StatusEnum;
  taskName?: string | undefined;
}

export const DocumentUploadTask = ({ status, taskName }: DocumentUploadTaskProps) => {
  const { applicationId, requisitionId } = useParams<{
    applicationId: string;
    requisitionId: string;
  }>();
  const dispatch = useAppDispatch();
  const displayI9DocUpload = taskName === I_9_DOCUMENT || taskName === undefined;
  const displayDocUpload = taskName === DOC_UPLOADS || taskName === undefined;

  const candidateData = useGetCandidateData();
  const [getDocUploadValidator] = useLazyGetOldDocUploadValidatorQuery();

  const bundle = useChecklistArb();
  const curpIdTitleMessage = bundle.getMessage("Checklist-MainPage-CURP-ID-Title");
  const curpIdTextMessage = bundle.getMessage("Checklist-MainPage-CURP-ID-Text");
  const curpIdNumberMessage = bundle.getMessage("Checklist-MainPage-CURP-ID-Number-Title");
  const curpIdTitleMessage2 = bundle.getMessage("Checklist-CURP-ID-Title");
  const editButtonMessage = bundle.getMessage("Checklist-MainPage-Document-Edit-ButtonText");

  const workAuthorizationTitleMessage = bundle.getMessage("Checklist-MainPage-Work-Authorization-Title");
  const workAuthorizationText = bundle.formatMessage("Checklist-WorkAuthorization-Instruction-Without-Deadline", {
    link: <LinkWithRedirect link={CONTACT_US_LINK} messageKey="Checklist-Common-Here" messagePlaceholder="here" />,
  });
  const here = bundle.getMessage("Checklist-MainPage-Link");
  const acceptableDocLink = isSpanishLocale() ? MX_ACCEPTABLE_DOCUMENTS_URL_ES : MX_ACCEPTABLE_DOCUMENTS_URL_EN;
  const link = (
    <Link target="_blank" href={acceptableDocLink}>
      {here}
    </Link>
  );
  const workAuthorizationText3 = bundle.getMessage("Checklist-MainPage-Work-Authorization-Text3");
  const taxIdTitleMessage = bundle.getMessage("Checklist-MainPage-Tax-ID-Title");
  const taxIdTextMessage = bundle.getMessage("Checklist-MainPage-Tax-ID-Text");
  const taxIdTitle2 = bundle.getMessage("Checklist-Tax-ID-Title");
  const taxIdNumberMessage = bundle.getMessage("Checklist-Tax-ID-Number-Title");

  const nssMessage = bundle.getMessage("Checklist-MainPage-NSS-Title");
  const nssTextMessage = bundle.getMessage("Checklist-MainPage-NSS-Text");
  const nssIdMessage = bundle.getMessage("Checklist-NSS-Number-Title");
  const nssButtonTextMessage = bundle.getMessage("Checklist-MainPage-NSS-ButtonText");

  const documentFrontTitle = bundle.getMessage("Checklist-Document-Front-Title");

  const [currentAttachments, setCurrentAttachments] = useState<Map<string, string[]>>(new Map());

  const [documentData, setDocumentData] = useState<Map<string, idInfo>>(new Map());
  const stars = "***************";
  useEffect(() => {
    if (candidateData) {
      getDocUploadValidator({ candidateId: candidateData.candidateId }).then(async ({ data }) => {
        if (data) {
          const transformedAttachmentData = new Map();
          data.forEach((metaData: AttachmentMetaData) => {
            const { attachmentType, attachmentSubType } = metaData;
            const value = transformedAttachmentData.get(attachmentType);
            if (value) {
              value.push(attachmentSubType);
            } else {
              transformedAttachmentData.set(attachmentType, [attachmentSubType]);
            }
          });
          setCurrentAttachments(transformedAttachmentData);
        }
      });
      const { additionalNationalIds, primaryNationalId } = candidateData;
      const mapFromNationalIds = new Map(Object.entries(additionalNationalIds ?? {}));
      if (primaryNationalId != null) {
        mapFromNationalIds.set(primaryNationalId.nationalIdType, primaryNationalId);
      }
      setDocumentData(mapFromNationalIds);
    }
  }, [candidateData]);

  return (
    <Col gridGap={12}>
      {displayDocUpload && (
        <Col gridGap={16}>
          <H3 fontWeight={"bold"}>
            {curpIdTitleMessage}
            {<strong style={{ color: "red" }}>*</strong>}
          </H3>
          <Text fontWeight={"bold"}>{curpIdTextMessage}</Text>
          {!currentAttachments.has(AttachmentType.CURP) ? (
            <DocumentUploadButton buttonMessage={curpIdTitleMessage2} documentType={AttachmentType.CURP} />
          ) : (
            <DocumentCompletedUploadFrontBack
              document={AttachmentType.CURP}
              sides={currentAttachments.get(AttachmentType.CURP) || []}
              status={currentAttachments.has(AttachmentType.CURP) ? StatusEnum.COMPLETED : StatusEnum.ACTION_REQUIRED}
            />
          )}
          <Row display="flex" alignItems="center">
            <Col>
              <Text>{curpIdNumberMessage}</Text>{" "}
              <Text>{stars + (documentData.get(AttachmentType.CURP)?.idNumber.slice(-4) ?? "****")}</Text>
            </Col>
            <Spacer flex="1" height={32} width={32} />
            <Link
              href={`/checklist/tasks/document/${requisitionId}/${applicationId}/${AttachmentType.CURP}/true`}
              textDecoration={"none"}
              onClick={(e) =>
                eventHandler(
                  e,
                  { documentType: AttachmentType.CURP, isEdit: true, buttonDelegationEnabled: true },
                  dispatch
                )
              }
            >
              <Button variant={ButtonVariant.Tertiary}>
                <IconPencil aria-hidden={true} />
                {editButtonMessage}
              </Button>
            </Link>
          </Row>
        </Col>
      )}
      <Hr />
      {displayI9DocUpload && (
        <>
          <Col gridGap={16}>
            <H3 fontWeight={"bold"}>{workAuthorizationTitleMessage}</H3>
            <Text fontWeight={"bold"}>{workAuthorizationText}</Text>
            <Text fontWeight={"bold"}>{workAuthorizationText3}</Text>
            {!hasWorkAuthorizationAttachment(currentAttachments) ? (
              <DocumentUploadDropdown />
            ) : (
              <DocumentCompletedUploadDropdown
                currentAttachments={currentAttachments}
                status={
                  hasWorkAuthorizationAttachment(currentAttachments) ? StatusEnum.COMPLETED : StatusEnum.ACTION_REQUIRED
                }
              />
            )}
          </Col>
          <Hr />
        </>
      )}

      {displayDocUpload && (
        <>
          <Col gridGap={16}>
            <H3 fontWeight={"bold"}>
              {taxIdTitleMessage}
              {<strong style={{ color: "red" }}>*</strong>}
            </H3>
            <Text fontWeight={"bold"}>{taxIdTextMessage}</Text>
            {!currentAttachments.has(AttachmentType.RFC) ? (
              <DocumentUploadButton buttonMessage={taxIdTitle2} documentType={AttachmentType.RFC} />
            ) : (
              <Col>
                <Text>{`${taxIdTitleMessage} - ${documentFrontTitle} `}</Text>
                <DocumentUploadComplete
                  documentType={AttachmentType.RFC}
                  status={
                    currentAttachments.has(AttachmentType.RFC) ? StatusEnum.COMPLETED : StatusEnum.ACTION_REQUIRED
                  }
                />
                <Hr />
              </Col>
            )}
            <Row display="flex" alignItems="center">
              <Col>
                <Text>{taxIdNumberMessage}</Text>{" "}
                <Text>{stars + (documentData.get(AttachmentType.RFC)?.idNumber.slice(-4) ?? "****")}</Text>
              </Col>
              <Spacer flex="1" height={32} width={32} />
              {currentAttachments.has(AttachmentType.RFC) && (
                <Link
                  href={`/checklist/tasks/document/${requisitionId}/${applicationId}/${AttachmentType.RFC}/true`}
                  onClick={(e) =>
                    eventHandler(
                      e,
                      { documentType: AttachmentType.RFC, isEdit: true, buttonDelegationEnabled: true },
                      dispatch
                    )
                  }
                  textDecoration={"none"}
                >
                  <Button variant={ButtonVariant.Tertiary}>
                    <IconPencil aria-hidden={true} />
                    {editButtonMessage}
                  </Button>
                </Link>
              )}
            </Row>
          </Col>
          <Hr />
        </>
      )}

      {displayDocUpload && (
        <>
          <Col gridGap={16}>
            <H3 fontWeight={"bold"}>
              {nssMessage} <strong style={{ color: "red" }}>*</strong>
            </H3>
            <Text fontWeight="bold">{nssTextMessage}</Text>

            {!currentAttachments.has(AttachmentType.NSS) ? (
              <DocumentUploadButton buttonMessage={nssButtonTextMessage} documentType={AttachmentType.NSS} />
            ) : (
              <Col>
                <Text>{`${nssMessage} - ${documentFrontTitle} `}</Text>
                <DocumentUploadComplete
                  documentType={AttachmentType.NSS}
                  status={
                    currentAttachments.has(AttachmentType.NSS) ? StatusEnum.COMPLETED : StatusEnum.ACTION_REQUIRED
                  }
                />
                <Hr />
              </Col>
            )}
            <Row display="flex" alignItems="center">
              <Col>
                <Text>{nssIdMessage}</Text>{" "}
                <Text>{stars + (documentData.get(AttachmentType.NSS)?.idNumber.slice(-4) ?? "****")}</Text>
              </Col>
              <Spacer flex="1" height={32} width={32} />
              {currentAttachments.has(AttachmentType.NSS) && (
                <Link
                  href={`/checklist/tasks/document/${requisitionId}/${applicationId}/${AttachmentType.NSS}/true`}
                  onClick={(e) =>
                    eventHandler(
                      e,
                      { documentType: AttachmentType.NSS, isEdit: true, buttonDelegationEnabled: true },
                      dispatch
                    )
                  }
                  textDecoration={"none"}
                >
                  <Button variant={ButtonVariant.Tertiary}>
                    <IconPencil aria-hidden={true} />
                    {editButtonMessage}
                  </Button>
                </Link>
              )}
            </Row>
          </Col>
          <Hr />
        </>
      )}
    </Col>
  );
};
