import React from "react";
import { Col, Flex } from "@amzn/stencil-react-components/layout";
import "./styles.scss";
import { useBreakpoints } from "@amzn/stencil-react-components/responsive";

interface StatusContainerProps {
  id?: string;
  "data-testid"?: string;
  children: React.ReactNode;
  largeViewPortImage: string;
  smallViewPortImage: string;
}

export const StatusContainer = ({
  id,
  "data-testid": dataTestId,
  children,
  largeViewPortImage,
  smallViewPortImage,
}: StatusContainerProps) => {
  const {
    matches: { m: isMediumViewport, s: isSmallViewport },
  } = useBreakpoints();
  const isLargeViewport = !isSmallViewport && !isMediumViewport;

  return (
    <Col
      id={id}
      data-testid={dataTestId}
      className={"appointment-container"}
      padding={isLargeViewport ? "2rem" : "1rem"}
      alignItems={"center"}
      gridGap={"1rem"}
    >
      <Flex
        flexDirection={isLargeViewport ? "row" : "column"}
        justifyContent={"space-between"}
        alignItems={"center"}
        gridGap={"1rem"}
      >
        <Col alignItems={"center"}>
          <img src={isLargeViewport ? largeViewPortImage : smallViewPortImage} alt={""} className={"calendar-image"} />
        </Col>

        <Col gridGap={"1rem"} alignItems={isLargeViewport ? "left" : "center"}>
          {children}
        </Col>
      </Flex>
    </Col>
  );
};
