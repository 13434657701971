import { useEffect, useState } from "react";
import { JobDetailsModel } from "../utility/application-data";
import { GratitudeComponentApplicationStateCriteriaEnum } from "../utility/constants/application-status";
import { NHEAppointmentStatusEnum } from "../components/appointment-card/card-controlled";
import { GratitudeComponentStatusCriteriaType } from "./useApplicationState.hook";
import { AppointmentInfo } from "@amzn/hvh-simple-hire-checklist-internal-api-common";
import moment from "moment-timezone";
import { UnsoundEither } from "../utility/generic-types";

export const isNheNotExistOr404Response = (
  response: UnsoundEither<AppointmentInfo, { errorStatusCode?: number; errorCode?: string }>
) => {
  return (
    String(response?.errorStatusCode) === "404" || String(response?.errorCode) === "NHE_APPOINTMENT_DOES_NOT_EXIST"
  );
};

export const isNheAlreadyCompleted = (response: UnsoundEither<AppointmentInfo, { errorMessage?: string }>) => {
  return String(response?.errorMessage).includes("COMPLETED");
};

export const isJobSchedule404Response = (response: JobDetailsModel) => {
  return String(response?.error?.errorStatusCode) === "404";
};

export const isError = (obj: any) => {
  return obj && ("error" in obj || "errorCode" in obj);
};

export enum GratitudeStateEnum {
  ShowShiftSelection = "ShowShiftSelection",
  ShowNHEAppointment = "ShowNHEAppointment",
  ShowError = "ShowError",
  HideGratitudeComponent = "HideGratitudeComponent",
}
export type GratitudeStateType = keyof typeof GratitudeStateEnum;

export const useGratitudeComponentState = (
  applicationState: GratitudeComponentStatusCriteriaType | "CONTINGENCIES_PENDING",
  NHEAppointment: UnsoundEither<AppointmentInfo, { errorStatusCode: number }> | undefined,
  jobScheduleDetails: JobDetailsModel | undefined | null
) => {
  const [gratitudeComponentState, setGratitudeComponentState] = useState<GratitudeStateType>(
    GratitudeStateEnum.HideGratitudeComponent
  );

  useEffect(() => {
    if (!applicationState) {
      setGratitudeComponentState(GratitudeStateEnum.HideGratitudeComponent);
      return;
    }
    const isOneOfGratitudeComponentApplicationStateCriteria = Object.keys(
      GratitudeComponentApplicationStateCriteriaEnum
    ).includes(applicationState);

    const defaultCase =
      isOneOfGratitudeComponentApplicationStateCriteria &&
      (applicationState === GratitudeComponentApplicationStateCriteriaEnum.POOLING ||
      applicationState === GratitudeComponentApplicationStateCriteriaEnum.SCHEDULE_SELECTION_PENDING
        ? GratitudeStateEnum.ShowShiftSelection
        : GratitudeStateEnum.ShowNHEAppointment);

    defaultCase && setGratitudeComponentState(defaultCase);

    if (jobScheduleDetails) {
      const showShiftSelection =
        jobScheduleDetails?.isScheduleReleased == true || isJobSchedule404Response(jobScheduleDetails);

      if (applicationState === "CONTINGENCIES_PENDING") {
        setGratitudeComponentState(GratitudeStateEnum.HideGratitudeComponent);
        return;
      }

      if (showShiftSelection) {
        setGratitudeComponentState(GratitudeStateEnum.ShowShiftSelection);
        return;
      }

      if (isOneOfGratitudeComponentApplicationStateCriteria && NHEAppointment) {
        const now = moment();

        const inPast = NHEAppointment?.startTimestamp && moment(NHEAppointment?.startTimestamp).isBefore(now, "day");
        const isNHEValid = !inPast || NHEAppointment?.status === NHEAppointmentStatusEnum.RESERVED;

        let showNHEAppointment = !isNHEValid || isNheNotExistOr404Response(NHEAppointment);
        if (isNheAlreadyCompleted(NHEAppointment)) {
          //disable NHE when it has been completed
          showNHEAppointment = false;
        }

        if (applicationState === GratitudeComponentApplicationStateCriteriaEnum.EVALUATION_PENDING) {
          if (showNHEAppointment) {
            setGratitudeComponentState(GratitudeStateEnum.ShowNHEAppointment);
          } else if (isError(NHEAppointment) || isError(jobScheduleDetails)) {
            setGratitudeComponentState(GratitudeStateEnum.ShowError);
          } else {
            setGratitudeComponentState(GratitudeStateEnum.HideGratitudeComponent);
          }
        }
      }
    }
  }, [applicationState, NHEAppointment, jobScheduleDetails]);

  return gratitudeComponentState as GratitudeStateType;
};
