import React, { useState, useEffect } from "react";
import { Col } from "@amzn/stencil-react-components/layout";
import { Input, InputFooter } from "@amzn/stencil-react-components/form";
import { MessageBanner, MessageBannerType } from "@amzn/stencil-react-components/message-banner";
import { useChecklistArb } from "../../../../hooks/use-arb";
import { H2, Text } from "../../../../components/typography";
import { Button } from "@amzn/stencil-react-components/button";
import { AttachmentType } from "../models";
import { useParams } from "react-router-dom";
import { deleteCachedRequest } from "../../../../utility/candidate-data";
import {
  useGetCandidateDataQuery,
  useUpdateCandidateDocumentIdToCDSMutation,
} from "../../../../reduxStore/api/apiSlice";
import { dispatchOnDev, isDevEnv } from "../../../../utility/dev-env-helpers";
import { useBackToMainPage } from "../helpers/useBackToMainPage.hook";
import { useTemporarySubmitDisabledSolution } from "../helpers/useTemporarySubmitDisabledSolution.hook";
import { TEMPORARY_SUBMIT_BUTTON_DISABLED_SOLUTION_SELECTOR } from "../../../../utility/constants/common";
import { Label } from "@amzn/stencil-react-components/text";

export const NssIdEdit = () => {
  const { requisitionId, applicationId } = useParams<{
    requisitionId: string;
    applicationId: string;
  }>();
  const { data: candidateData, refetch } = useGetCandidateDataQuery({ applicationId });
  const [updateCandidateDocumentID] = useUpdateCandidateDocumentIdToCDSMutation();

  const bundle = useChecklistArb();
  const nssMessage = bundle.getMessage("Checklist-MainPage-NSS-Title");
  const nssMessageError = bundle.getMessage("Checklist-NSS-Number-Error");
  const nssIdMessage = bundle.getMessage("Checklist-NSS-Number-Title");
  const documentImageSubmitMessage = bundle.getMessage("Checklist-Document-Image-Submit-ButtonText");
  const documentIdUploadDependencyServiceErrorMessage = bundle.getMessage(
    "Checklist-Document-ID-General-Error-Message"
  );

  const [nssNumber, setNSSNumber] = useState<string>("");
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const [currentNSSId, setCurrentNSSId] = useState<string>("");
  const [idUploadDependencyServiceErrorMessage, setIdUploadDependencyServiceErrorMessage] = useState<string>();

  const stars = "***************";
  const validateNSS = new RegExp("^[0-9]{11}$");
  const backToMainPage = useBackToMainPage(refetch);
  useTemporarySubmitDisabledSolution(false);

  useEffect(() => {
    if (candidateData) {
      if (candidateData.additionalNationalIds?.[AttachmentType?.NSS]?.idNumber) {
        setCurrentNSSId(candidateData.additionalNationalIds[AttachmentType.NSS].idNumber);
      }
    }
  }, [candidateData]);

  useEffect(() => {
    nssNumber.length > 0 && setIsInvalid(!validateNSS.test(nssNumber));
  }, [nssNumber]);

  const clickEventSubmitDocument = async () => {
    if (!candidateData) {
      dispatchOnDev(() => {
        console.error("CandidateData is not ready yet");
      });
      return;
    }

    if (!validateNSS.test(nssNumber)) {
      if (isDevEnv()) console.error("invalid NSS number");
      setIsInvalid(true);
      const input = document.getElementById(`nss`);
      input?.focus();
      return;
    }

    let returnToMainPage = true;
    try {
      if (isDevEnv()) console.log("submit document id");
      updateCandidateDocumentID({
        candidateId: candidateData?.candidateId,
        idData: {
          nationalIdType: AttachmentType.NSS,
          idNumber: nssNumber,
        },
      });
    } catch (err) {
      if (isDevEnv()) console.error("error submit document id");
      setIdUploadDependencyServiceErrorMessage(documentIdUploadDependencyServiceErrorMessage);
      returnToMainPage = false;
    }
    deleteCachedRequest(applicationId); // delete stale data so that updated Id loads on home page
    if (returnToMainPage) {
      backToMainPage();
    }
  };

  return (
    <Col gridGap={12} width={"100%"} padding={"10px 24px"}>
      <H2 fontWeight={"bold"}>{nssMessage}</H2>
      <Label htmlFor="nss">
        {nssIdMessage}{" "}
        <strong style={{ color: "red" }} aria-hidden="true">
          *
        </strong>{" "}
      </Label>
      <Input
        id="nss"
        aria-required="true"
        placeholder={currentNSSId ? stars + currentNSSId.slice(-4) : nssIdMessage}
        maxLength={13}
        onChange={(e) => setNSSNumber(e.target.value)}
        aria-invalid={isInvalid ? "true" : "false"}
        aria-describedby="nssIdError"
        error={isInvalid}
      ></Input>
      {isInvalid ? (
        <InputFooter id="nssIdError" error>
          {nssMessageError}
        </InputFooter>
      ) : null}
      {idUploadDependencyServiceErrorMessage ? (
        <MessageBanner type={MessageBannerType.Error}>{idUploadDependencyServiceErrorMessage}</MessageBanner>
      ) : null}
      <Button
        data-testid="nss-edit-submit"
        onClick={clickEventSubmitDocument}
        id={TEMPORARY_SUBMIT_BUTTON_DISABLED_SOLUTION_SELECTOR}
        style={{ visibility: "hidden" }}
      >
        {documentImageSubmitMessage}
      </Button>
    </Col>
  );
};
