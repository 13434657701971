/* eslint-disable import/no-unused-modules */
import { useEffect } from "react";
import { useLazyGetBgcStatusQuery } from "../../reduxStore/api/apiSlice";
import { useGetApplicationId } from "./useGetApplicationId";
import { isSalesforceApplication } from "../../helpers/url-params";
import { useAppSelector } from "../../reduxStore/reduxHooks";
import { getConfigData, getFeatureFlag, setConfigData } from "../../reduxStore/slices/configSlice";
import { useDispatch } from "react-redux";
import { CONFIG_DATA_KEY } from "../../utility/enums/common";
import { useGetCandidateData } from "./useGetCandidateData";
import { FeatFlag } from "../../config/appConfigConstants";
import { useGetJobDetails } from "./useGetJobDetails";
import { isTaskEnabledByAppConfig } from "../../utility/app-config-helper";
import { isAirJob } from "../../helpers/utils";
import { useGetAllContigenciesData } from "./useGetAllContigenciesData";

export const useGetBackgroundCheckStatus = () => {
  const { bgcStatus, airBgcStatus } = useAppSelector(getConfigData);

  // If we consuming the flag from localStorage, make sure to check it is fully loaded first, otherwise it will throw it as undefined.
  const { isAshConfigLoaded } = useAppSelector(getFeatureFlag);
  const dispatch = useDispatch();
  const allContigencies = useGetAllContigenciesData();
  const scheduleDetails = useGetJobDetails();
  const applicationId = useGetApplicationId();
  const candidateData = useGetCandidateData();
  const [getBgcStatus] = useLazyGetBgcStatusQuery();

  useEffect(() => {
    const flagEnabledBgc = isTaskEnabledByAppConfig(FeatFlag.ENABLE_ALL_CONTINGENCIES_BGC, applicationId);
    const flagEnabledAirBgc = isTaskEnabledByAppConfig(FeatFlag.ENABLE_ALL_CONTINGENCIES_AIR_BGC, applicationId);

    if (scheduleDetails) {
      if (!isAirJob(scheduleDetails?.businessLine) && candidateData?.candidateId) {
        getBgcStatus(
          {
            candidateId: candidateData?.candidateId,
            applicationId,
            isCustomerService: scheduleDetails?.requisitionCategory === "Customer Service",
          },
          true
        )
          .unwrap()
          .then((data) => {
            if (
              flagEnabledBgc &&
              !isSalesforceApplication(applicationId) &&
              allContigencies &&
              allContigencies !== "SKIPPED"
            ) {
              dispatch(
                setConfigData({
                  key: CONFIG_DATA_KEY.BACKGROUND_CHECK_STATUS,
                  value: allContigencies.BGC,
                })
              );
            } else if ((!flagEnabledBgc || isSalesforceApplication(applicationId)) && data) {
              dispatch(
                setConfigData({
                  key: CONFIG_DATA_KEY.BACKGROUND_CHECK_STATUS,
                  value: data,
                })
              );
            }
          });
      }
    }

    if (isAirJob(scheduleDetails?.businessLine) && candidateData?.candidateId) {
      getBgcStatus(
        {
          candidateId: candidateData?.candidateId,
          applicationId,
          businessLine: scheduleDetails?.businessLine,
          isCustomerService: scheduleDetails?.requisitionCategory === "Customer Service",
        },
        true
      )
        .unwrap()
        .then((data) => {
          if (isAirJob(scheduleDetails?.businessLine)) {
            if (flagEnabledAirBgc && allContigencies !== "SKIPPED" && allContigencies?.AirBGC) {
              dispatch(
                setConfigData({
                  key: CONFIG_DATA_KEY.AIR_BACKGROUND_CHECK_STATUS,
                  value: allContigencies.AirBGC,
                })
              );
            } else if (!flagEnabledAirBgc && data) {
              dispatch(
                setConfigData({
                  key: CONFIG_DATA_KEY.AIR_BACKGROUND_CHECK_STATUS,
                  value: data,
                })
              );
            }
          }
        });
    }
  }, [
    airBgcStatus,
    allContigencies,
    applicationId,
    bgcStatus,
    candidateData?.candidateId,
    dispatch,
    getBgcStatus,
    scheduleDetails,
    isAshConfigLoaded,
  ]);

  return { bgcStatus, airBgcStatus };
};
