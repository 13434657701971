import { IconCheckCircleFill, IconSize } from "@amzn/stencil-react-components/icons";
import { Row } from "@amzn/stencil-react-components/layout";
import { Text } from "@amzn/stencil-react-components/text";
import React from "react";
import ProgressRing from "../../../../assets/svg/progress-ring.svg";
import { CUSTOMER_SERVICE, NON_CUSTOMER_SERVICE } from "../../../../config/appConfigConstants";
import { HVH_COLORS } from "../../../../config/palette";
import { ChecklistBundle } from "../../../../hooks/use-arb";
import { CA_CAAS_STATUS } from "../../i9-data";
import { InstructionMapping } from "../../i9-task-card";

export const getCAChecklistMappingCAAS = ({ bundle }: { bundle: ChecklistBundle }) => {
  const translatedCompleted = bundle.getMessage("checklist-General-TaskStatus-Completed");
  const taskActionRequired = bundle.getMessage("checklist-General-TaskStatus-ActionRequired");
  const progressRing = <img width={"S400"} height={"S400"} src={ProgressRing} alt="" />;

  const completionCheck = <IconCheckCircleFill size={IconSize.Medium} color={HVH_COLORS.GREEN_70} />;

  const caCaasStatusToChecklistMapping: InstructionMapping = {
    [NON_CUSTOMER_SERVICE]: {
      [CA_CAAS_STATUS.Pending]: (
        <Row alignItems={"center"} gridGap={"S200"}>
          {progressRing}
          <Text>{taskActionRequired}</Text>
        </Row>
      ),
      [CA_CAAS_STATUS.Initiated]: (
        <Row alignItems={"center"} gridGap={"S200"}>
          {progressRing}
          <Text>{taskActionRequired}</Text>
        </Row>
      ),
      [CA_CAAS_STATUS.Completed]: (
        <Row alignItems={"center"} gridGap={"S200"}>
          {completionCheck}
          <Text>{translatedCompleted}</Text>
        </Row>
      ),
    },
    [CUSTOMER_SERVICE]: {},
  };

  return caCaasStatusToChecklistMapping;
};
