import { getLocale, getSupportedLanguage } from "../../utility/locale-helper";
import { useChecklistArb } from "../../hooks/use-arb";
import moment from "moment-timezone";
import { dateTimeFormatStringBasedOnLocale } from "./dateTimeFormatStringBasedOnLocale";
import { Link } from "@amzn/stencil-react-components/link";
import { H1, Text } from "@amzn/stencil-react-components/text";
import { IconCalendarFill, IconDesktop, IconExternalLink, IconSize } from "@amzn/stencil-react-components/icons";
import { Flex, Row } from "@amzn/stencil-react-components/layout";
import { MLS_REQUIRED_LANGUAGE } from "../../config/feature-control";
import LinkWithRedirectWithAdobeMetrics from "../common/LinkWithRedirectWithAdobeMetrics";
import { Button, ButtonSize, ButtonVariant } from "@amzn/stencil-react-components/button";
import ICalendarLink from "react-icalendar-link";
import React from "react";
import { AppointmentInfo } from "@amzn/hvh-simple-hire-checklist-internal-api-common";
import { Stage } from "../../helpers/get-settings";
import { getCalendarEventV2Appointment, addToCalendarKeyDown } from "./utils";
import { ChecklistPantherKey } from "../../utility/types/translations";
import { isNheVenueTimeEnabledByAppConfig } from "../../utility/app-config-helper";
import { displayVenueTime } from "../../utility/time-processor";
import { NHE_EVENT_TYPE } from "../../config/appConfigConstants";

interface GenericAppointmentHeaderCardProps {
  appointmentType: NHE_EVENT_TYPE;
  rescheduleAppointmentURL: string;
  countDownMsgKey: ChecklistPantherKey;
  countDownMsgTodayKey: ChecklistPantherKey;
  appointmentTitleKey: ChecklistPantherKey;
  calendarFileNameKey: ChecklistPantherKey;
  isEligibleForReschedule: boolean | undefined;
  sfRequiredLanguage: string | undefined;
  appointmentV2Details: AppointmentInfo;
  stage?: Stage;
  onlyOneAppointment: boolean;
  onViewDetailsClick: () => void;
}

// TODO: replace <AppointmentHeaderCard> with this component once Monitoring is good enough for confidence.
export const GenericAppointmentHeaderCard = ({
  appointmentType,
  rescheduleAppointmentURL,
  countDownMsgKey,
  countDownMsgTodayKey,
  appointmentTitleKey,
  calendarFileNameKey,
  isEligibleForReschedule,
  sfRequiredLanguage,
  appointmentV2Details,
  stage,
  onlyOneAppointment,
  onViewDetailsClick,
}: GenericAppointmentHeaderCardProps) => {
  const locale = getLocale();
  const bundle = useChecklistArb();
  const cannotAttend = bundle.formatMessage("Checklist-PreHireDetails-CannotAttend");
  const { startTime, timezone } = getCalendarEventV2Appointment(appointmentV2Details);

  function appointmentHeaderFormatedStartTime() {
    if (!startTime) return;

    const isNheVenueTimeEnabled = isNheVenueTimeEnabledByAppConfig();
    const displayTimeInVenueTime = displayVenueTime(appointmentV2Details);

    const startTimeMoment = moment.utc(startTime).tz(timezone);

    const currDate = moment.tz(timezone).startOf("day");
    const currentDateTime = moment.tz(currDate, timezone).tz(timezone); // Display in the NHE timezone, ensure the Panther format string includes the timezone!

    const dataTimeFormatString = dateTimeFormatStringBasedOnLocale(locale);
    const dateTimeTranslated = bundle.formatMessage(dataTimeFormatString, {
      dateParam: isNheVenueTimeEnabled ? displayTimeInVenueTime : startTimeMoment,
    });

    const dayDiff = startTimeMoment.diff(currentDateTime, "day");
    const countdownMsg =
      dayDiff > 0
        ? bundle.formatMessage(countDownMsgKey, { days: dayDiff })
        : bundle.formatMessage(countDownMsgTodayKey);

    return { countdownMsg, dateTimeTranslated };
  }
  const isVirtual = appointmentV2Details?.locationType !== "PHYSICAL";
  const displayReadyLocation = appointmentV2Details ? appointmentV2Details.displayReadyLocation : null;

  const physicalLocationGoogleMapLink = (
    <Link
      fontSize={"T200"}
      href={"http://maps.google.com/?q=" + displayReadyLocation}
      target={"_blank"}
      data-testid={`appointment-location-${displayReadyLocation}`}
    >
      <Text>{displayReadyLocation}</Text>
    </Link>
  );

  const virtualDisplayLink = displayReadyLocation
    ? displayReadyLocation
    : (bundle.getMessage("Checklist-Common-Virtual") as string);

  const virtualLinkCompleteMessage = (
    <Link
      icon={<IconExternalLink size={IconSize.ExtraSmall} title={bundle.getMessage("Checklist-external-link")} />}
      tabIndex={0}
      fontSize={"T200"}
      href={virtualDisplayLink}
      target={"_blank"}
      margin={{ left: 5 }}
    >
      {appointmentV2Details?.locationType === "VIRTUAL_CONNECT"
        ? bundle.formatMessage("Checklist-Appointment-JoinHere")
        : virtualDisplayLink}{" "}
    </Link>
  );

  const displayReadyVirtualLocation = bundle.formatMessage("Checklist-PreHireDetails-Location-Virtual-NHE", {
    address: virtualLinkCompleteMessage,
  });

  const genericAddressLink =
    // Don't show virtualNHE if it is a RTWEvent
    isVirtual ? (
      <Flex flexDirection="row" alignItems="center" data-testid={`appointment-location-${displayReadyLocation}`}>
        <IconDesktop margin={{ right: 5 }} /> <Text>{displayReadyVirtualLocation}</Text>
      </Flex>
    ) : (
      physicalLocationGoogleMapLink
    );

  const timeDetails = appointmentHeaderFormatedStartTime();
  const supportedLanguage = sfRequiredLanguage
    ? sfRequiredLanguage
    : getSupportedLanguage(appointmentV2Details?.supportedLanguage, bundle);

  const addToCalendar = bundle.formatMessage("Checklist-AppointmentCard-ButtonText3");
  const formattedAppointmentSupportedLanguageMsg =
    bundle.getMessage("Checklist-AppointmentCard-Language") + ": " + supportedLanguage;
  const reschedule = "Checklist-AppointmentCard-ButtonText2";
  const viewDetails = bundle.formatMessage("Checklist-AppointmentCard-ButtonText1");

  const calendarEvent = {
    startTime: appointmentV2Details?.startTimestamp || "",
    endTime: appointmentV2Details?.endTimestamp || "",
    title: bundle.formatMessage(appointmentTitleKey),
    location: appointmentV2Details?.displayReadyLocation || "",
  };

  return (
    <>
      {onlyOneAppointment ? (
        <H1 fontSize={"T400"} fontWeight={"regular"} id="main-content">
          {timeDetails?.countdownMsg}
        </H1>
      ) : (
        <Row>
          <Text fontSize={"T200"}>{timeDetails?.countdownMsg}</Text>
        </Row>
      )}

      <Row gridGap={"1rem"}>
        <Row gridGap={"0.3rem"} alignItems={"center"}>
          <IconCalendarFill />
          <Text data-testid={`appointment-starttime-${startTime}`} fontSize={"T200"}>
            {timeDetails?.dateTimeTranslated}
          </Text>
        </Row>
      </Row>
      {onlyOneAppointment && (
        <>
          {stage && MLS_REQUIRED_LANGUAGE[stage] && (
            <Text data-testid="supported-language" fontSize={"T200"}>
              {formattedAppointmentSupportedLanguageMsg}
            </Text>
          )}
          {genericAddressLink}
          <Row gridGap={"1rem"} alignItems="center">
            {isEligibleForReschedule && (
              <>
                <Text style={{ fontStyle: "italic" }} fontSize={"T200"}>
                  {cannotAttend}
                </Text>
                <LinkWithRedirectWithAdobeMetrics
                  data-testid="reschedule-button"
                  link={rescheduleAppointmentURL}
                  messageKey={reschedule}
                  messagePlaceholder={"Reschedule"}
                  appointmentType={appointmentType}
                />
              </>
            )}
          </Row>
        </>
      )}
      <Row gridGap={"1rem"}>
        <Button
          as={"Col"}
          size={ButtonSize.Small}
          data-testid={`pre-hire-flyout-add-to-calendar-card`}
          onKeyDown={(event) => addToCalendarKeyDown(event, ".add-to-calendar")}
          tabIndex={-1}
        >
          <ICalendarLink
            data-testid="calendar-button"
            key="calendar-button"
            filename={bundle.formatMessage(calendarFileNameKey)}
            event={calendarEvent}
            className={"add-to-calendar"}
          >
            {addToCalendar}
          </ICalendarLink>
        </Button>
        <Button
          data-testid="action-select-appointment-type"
          aria-label="view-details"
          variant={ButtonVariant.Primary}
          size={ButtonSize.Small}
          onClick={onViewDetailsClick}
        >
          {viewDetails}
        </Button>
      </Row>
    </>
  );
};
