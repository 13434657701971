import React, { useEffect, useState } from "react";
import { useLazyGetCandidateHealthQuery } from "../../reduxStore/api/apiSlice";
import { useAshRum } from "../../hooks/useAshRum.hook";

type AuthOrRedirectProps = {
  children: JSX.Element;
};

/**
 * This code is _not_ authentication and doesn't constitute security!
 * Do not use for this purpose!
 *
 * This component only prevents assets from rendering and sends the user to the signin-page for user experience.
 * All assets are still public and the user can disregard the redirect if they so choose.
 * Verification of the user's auth token is done on the server side before returning any data.
 */

function mapOldDomainToNewDomain(oldRequest: URL) {
  let newHostname: string;
  if (!oldRequest.hostname.startsWith("prod") && oldRequest.searchParams.has("HydraTestRun")) {
    return oldRequest;
  }
  if (oldRequest.hostname.startsWith("beta")) {
    newHostname = "beta-us.devo.jobsatamazon.hvh.a2z.com";
  } else if (oldRequest.hostname.startsWith("gamma")) {
    newHostname = "gamma-us.devo.jobsatamazon.hvh.a2z.com";
  } else {
    newHostname = "hiring.amazon.com";
  }

  const newUrl = new URL(oldRequest.toString());
  newUrl.hostname = newHostname;
  return newUrl;
}

export function AuthOrRedirect(props: AuthOrRedirectProps) {
  const { recordRumEvent } = useAshRum();
  const [checkHealth] = useLazyGetCandidateHealthQuery();
  const [renderChildren, setRenderChildren] = useState(false);
  const [renderRedirect, setRenderRedirect] = useState(false);

  const pageUrl = new URL(window.location.href);
  const devEnvironments = ["beta", "gamma", "preprod"];
  const preventRedirect =
    pageUrl.searchParams.has("HydraTestRun") && devEnvironments.some((stage) => pageUrl.hostname.startsWith(stage));

  useEffect(() => {
    setRenderChildren(false);
    setRenderRedirect(false);

    // NOTE: This if statement will be removed on build by webpack
    if (process.env.NODE_ENV === "development") {
      if (process.env.REACT_APP_USE_MOCK_REQUESTS === "true") {
        console.warn("skipping checkHealth");
        setRenderChildren(true);
        return;
      }
    }

    checkHealth(null)
      .unwrap()
      .then((result) => {
        setRenderChildren(true);
      })
      .catch((err: Error) => {
        setRenderRedirect(true);
      });
  }, [preventRedirect]);

  if (pageUrl.hostname.endsWith("checklist.associate.amazondelivers.jobs") && !preventRedirect) {
    window.location.href = mapOldDomainToNewDomain(pageUrl).toString();
    return <div></div>;
  }

  if (renderChildren) {
    return props.children;
  }

  if (renderRedirect) {
    if (process.env.NODE_ENV == "development") {
      return (
        <h1>
          Auth missing or invalid - not redirecting you because this is local dev. Sign in and put your token in
          `accessToken` in localStorage
        </h1>
      );
    }
    recordRumEvent({
      type: "ash_info_auth_redirect",
      fromUrl: pageUrl.href,
    });
    window.location.href = `/app#/login?redirectUrl=${encodeURIComponent(window.location.href)}`;
  }
  return <div></div>;
}
