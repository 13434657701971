import { useCallback } from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { buildUrl } from "../config/urls";

export const useNavigator = (...urls: Array<string>) => {
  const history = useHistory();
  const { applicationId, requisitionId } = useParams<{
    applicationId: string;
    requisitionId: string;
  }>();

  return [
    ...urls.map((url) => {
      const targetUrl = buildUrl(url, { applicationId, requisitionId });
      const goTo = useCallback(() => {
        history.push(targetUrl);
      }, [targetUrl]);
      return goTo;
    }),
    history.goBack,
  ];
};

export const useNavigatorWithURLReady = (url: string) => {
  const history = useHistory();

  return useCallback(() => {
    url.startsWith("http") ? window.location.assign(url) : history.push(url);
  }, [history, url]);
};

export const useNavigatorWithURLReadyOnOpeningNewTab = (url: string) => {
  const history = useHistory();

  return useCallback(() => {
    url.startsWith("http") ? window.open(url) : history.push(url);
  }, [history, url]);
};
