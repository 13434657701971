import React from "react";
import { Banner, ErrorInfo } from "../../utility/types/common";
import BannerMessage from "./BannerMessage";
import { MessageBannerType } from "@amzn/stencil-react-components/message-banner";
import { useChecklistArb } from "../../hooks/use-arb";

interface ErrorMessageProps {
  errorInfo: ErrorInfo;
}

const getErrorStringFromType = (errorInfo: ErrorInfo) => {
  if (!errorInfo || !errorInfo.errorCode) {
    return null;
  }

  if (errorInfo.errorCode === "SF_SERVICE_FAILURE") {
    // TODO: get the translation strings from Alex
    return "Checklist-CardModal-Error";
  }

  return "Checklist-CardModal-Error";
};

const ErrorBannerMessage = ({ errorInfo }: ErrorMessageProps) => {
  const bundle = useChecklistArb();
  const errorString = getErrorStringFromType(errorInfo);
  if (!errorString) {
    return null;
  }

  const bannerMessage: Banner = {
    text: bundle.getMessage(errorString),
    isDismissible: false,
    type: MessageBannerType.Error,
  };

  return <BannerMessage banner={bannerMessage} />;
};

export default ErrorBannerMessage;
