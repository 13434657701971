import { Col } from "@amzn/stencil-react-components/layout";
import { PageContainer } from "@amzn/stencil-react-components/page";
import { Text } from "@amzn/stencil-react-components/text";
import React from "react";
import { HVH_COLORS } from "../../config/palette";
import * as adobeAnalytics from "../../utility/adobe-analytics";
import { ApplicationManageData, JobDetailsModel } from "../../utility/application-data";
import { CandidateData } from "../../utility/candidate-data";
import { applicationIdWhenErr } from "../../helpers/utils";
import { useGetCandidateData } from "../../hooks/apis/useGetCandidateData";
import { useGetJobDetails } from "../../hooks/apis/useGetJobDetails";
import { useGetApplicationManageData } from "../../hooks/apis/useGetApplicationManageData";
import { dispatchOnDev } from "../../utility/dev-env-helpers";
import { AshRumEvent, useAshRum } from "../../hooks/useAshRum.hook";
import { PAGE_NOT_LOAD_ERRORS } from "../../utility/types/common";

const params = window.location.href.split("/");

interface ErrorBoundaryProps {
  recordRumEvent: (event: AshRumEvent) => void;
  children: React.ReactNode;
  candidateData: CandidateData | undefined | null;
  jcDetails: JobDetailsModel | undefined | null;
  applicationManageData: ApplicationManageData | undefined;
  isNotValidApplicationId: boolean;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: { name: string | undefined; message: string | undefined } | undefined;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: undefined };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidMount() {
    window.addEventListener("unhandledrejection", this.onUnhandledRejection);
  }

  componentWillUnmount() {
    window.removeEventListener("unhandledrejection", this.onUnhandledRejection);
  }

  // Error boundary now will handles uncaught promise errors.
  // https://github.com/facebook/react/issues/14981
  onUnhandledRejection = (event: PromiseRejectionEvent) => {
    event.promise.catch((error) => {
      if (String(error).includes(PAGE_NOT_LOAD_ERRORS.REDUX_UPDATE_PASSING_THRESHOLD_ERROR)) {
        this.props.recordRumEvent({
          type: "ash_error_redux_update_passing_threshold",
          message: error,
        });
      }
      if (error.toString().includes(PAGE_NOT_LOAD_ERRORS.REACT_MEMORY_LEAK)) {
        this.props.recordRumEvent({
          type: "ash_error_react_memory_leak",
          message: error,
        });
      }
    });
  };

  componentDidUpdate(prevProps: Readonly<ErrorBoundaryProps>, prevState: Readonly<ErrorBoundaryState>): void {
    if (prevState.error?.message !== this.state.error?.message) {
      dispatchOnDev(() => {
        console.error("[ERROR BOUNDARY]: ", this.state.error);
      });
      if (
        this.state.hasError &&
        this.state.error &&
        this.props.candidateData &&
        this.props.applicationManageData &&
        this.props.jcDetails
      ) {
        adobeAnalytics.addEventMetric(
          window,
          applicationIdWhenErr(params) as string,
          "Ash page unable to load",
          this.props.candidateData,
          this.props.applicationManageData,
          this.props.jcDetails,
          {
            error: { errorCode: this.state.error.name, errorMessage: this.state.error.message },
          }
        );
      }
    }
  }

  componentDidCatch(error: Error) {
    this.setState({
      error: {
        name: error.name,
        message: error.message,
      },
    });
  }

  render() {
    if (this.state.hasError || this.props.isNotValidApplicationId) {
      return (
        <Col height="100vh">
          <PageContainer color={HVH_COLORS.NEUTRAL_90} height="100%">
            <Col alignItems="center" justifyContent="center" height="100%">
              <Text fontWeight="regular" fontSize="T300" textAlign="center">
                Something went wrong <br />
                We are working to fix it
              </Text>
            </Col>
          </PageContainer>
        </Col>
      );
    }

    return this.props.children;
  }
}

export default withQueryValue(ErrorBoundary as unknown as JSX.Element);

function withQueryValue(Component: JSX.Element) {
  return function WrappedComponent(props: any) {
    const { recordRumEvent } = useAshRum();
    const applicationId = applicationIdWhenErr(params) as string;
    const candidateData = useGetCandidateData();
    const jcDetails = useGetJobDetails();
    const applicationManageData = useGetApplicationManageData();

    if (!applicationId) {
      return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Component {...props} isNotValidApplicationId />
      );
    }

    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <Component
        {...props}
        recordRumEvent={recordRumEvent}
        candidateData={candidateData}
        jcDetails={jcDetails}
        applicationManageData={applicationManageData}
      />
    );
  };
}
