import React, { useCallback, useMemo } from "react";
import Dropzone from "react-dropzone-uploader";
import { debounce } from "lodash";
import "./styles.scss";
import { useChecklistArb } from "../../hooks/use-arb";

interface UploaderProps {
  onUpload: (imageData: string | null) => void;
}

const loadImage = async (imageEl: HTMLImageElement) => {
  return new Promise((resolve) => {
    if (imageEl.width > 0 && imageEl.height > 0) {
      resolve(true);
    }
    imageEl.addEventListener("load", () => {
      resolve(true);
    });
  });
};

export const Uploader = ({ onUpload }: UploaderProps) => {
  const fireUpload = useCallback(
    async (imgEl: HTMLImageElement) => {
      await loadImage(imgEl);
      const canvas = document.createElement("canvas");
      canvas.width = imgEl.naturalWidth;
      canvas.height = imgEl.naturalHeight;
      canvas.getContext("2d")?.drawImage(imgEl as CanvasImageSource, 0, 0);
      onUpload(canvas.toDataURL("image/jpeg"));
    },
    [onUpload]
  );

  const onChangeStatus = useCallback(({ meta }) => {
    if (!meta.previewUrl) {
      onUpload(null);
      return;
    }
    const imgEl = document.createElement("img");
    imgEl.src = meta.previewUrl;
    fireUpload(imgEl);
    return meta;
  }, []);

  const onChangeStatusDebounced = useMemo(() => debounce(onChangeStatus, 100), [onChangeStatus]);
  const bundle = useChecklistArb();

  const dragMessage = bundle.getMessage("Checklist-BPUpload-Drag");
  return <Dropzone onChangeStatus={onChangeStatusDebounced} accept="image/*" maxFiles={1} inputContent={dragMessage} />;
};
