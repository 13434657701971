/* eslint-disable import/no-unused-modules */
import { useEffect } from "react";
import { useLazyGetAppointmentV2Query } from "../../reduxStore/api/apiSlice";
import { useGetApplicationId } from "./useGetApplicationId";
import { useAppSelector } from "../../reduxStore/reduxHooks";
import { getConfigData, setConfigData, setFeatureFlag } from "../../reduxStore/slices/configSlice";
import { CONFIG_DATA_KEY, CONFIG_FEATURE_FLAG } from "../../utility/enums/common";
import { useDispatch } from "react-redux";
import { getLocale } from "../../utility/locale-helper";
import { NHEAppointmentApplicationTypeEnum } from "@amzn/hvh-simple-hire-checklist-internal-api-common";
import { isSalesforceApplication } from "../../helpers/url-params";
import { useGetApplicationManageData } from "./useGetApplicationManageData";
import { isEligibleForRTWAppointmentRescheduling } from "../../helpers/configHelper";

export const useGetRTWAppointment = () => {
  const { rtwAppointmentDetails } = useAppSelector(getConfigData);
  const dispatch = useDispatch();
  const applicationId = useGetApplicationId();
  const applicationManageData = useGetApplicationManageData();
  const [getRTWAppointment] = useLazyGetAppointmentV2Query();

  useEffect(() => {
    if (rtwAppointmentDetails) return;

    if (applicationId && !isSalesforceApplication(applicationId) && applicationManageData) {
      getRTWAppointment(
        { applicationId, locale: getLocale(), applicationType: NHEAppointmentApplicationTypeEnum.RIGHT_TO_WORK },
        true
      )
        .unwrap()
        .then((data) => {
          dispatch(
            setConfigData({
              key: CONFIG_DATA_KEY.RTW_APPOINTMENT_DETAILS,
              value: data,
            })
          );

          dispatch(
            setFeatureFlag({
              key: CONFIG_FEATURE_FLAG.IS_ELIGIBLE_FOR_RTW_APPOINTMENT_RESCHEDULING,
              value: isEligibleForRTWAppointmentRescheduling(
                data,
                applicationManageData,
                isSalesforceApplication(applicationId)
              ),
            })
          );
        });
    }
  }, [applicationId, dispatch, getRTWAppointment, rtwAppointmentDetails, applicationManageData]);

  return rtwAppointmentDetails;
};
