import { useAppSelector } from "../../reduxStore/reduxHooks";
import { getConfigData, setConfigData, setFeatureFlag } from "../../reduxStore/slices/configSlice";
import { useDispatch } from "react-redux";
import { useGetApplicationId } from "./useGetApplicationId";
import { useGetApplicationManageData } from "./useGetApplicationManageData";
import { useLazyGetAppointmentV2Query } from "../../reduxStore/api/apiSlice";
import { useEffect } from "react";
import { isSalesforceApplication } from "../../helpers/url-params";
import { getLocale } from "../../utility/locale-helper";
import { NHEAppointmentApplicationTypeEnum } from "@amzn/hvh-simple-hire-checklist-internal-api-common";
import { CONFIG_DATA_KEY, CONFIG_FEATURE_FLAG } from "../../utility/enums/common";
import { isEligibleForNHEAppointmentRescheduling } from "../../helpers/configHelper";

export const useGetDTAppointment = () => {
  const { dtAppointmentDetails } = useAppSelector(getConfigData);
  const dispatch = useDispatch();
  const applicationId = useGetApplicationId();
  const applicationManageData = useGetApplicationManageData();
  const [getNHEAppointment] = useLazyGetAppointmentV2Query();

  useEffect(() => {
    if (dtAppointmentDetails) return;

    if (applicationId && !isSalesforceApplication(applicationId) && applicationManageData) {
      getNHEAppointment(
        { applicationId, locale: getLocale(), applicationType: NHEAppointmentApplicationTypeEnum.DRUG_TEST },
        true
      )
        .unwrap()
        .then((data) => {
          dispatch(
            setConfigData({
              key: CONFIG_DATA_KEY.DT_APPOINTMENT_DETAILS,
              value: data,
            })
          );

          dispatch(
            setFeatureFlag({
              key: CONFIG_FEATURE_FLAG.IS_ELIGIBLE_FOR_DT_APPOINTMENT_RESCHEDULING,
              value: isEligibleForNHEAppointmentRescheduling(
                data,
                applicationManageData,
                isSalesforceApplication(applicationId)
              ),
            })
          );
        });
    }
  }, [applicationId, dispatch, getNHEAppointment, dtAppointmentDetails, applicationManageData]);

  return dtAppointmentDetails;
};
