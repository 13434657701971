import { StatusEnum } from "@amzn/hvh-simple-hire-checklist-internal-api-common";

export const ADOBE_EVENT_SETUP_ATOZ_ACCOUNT = "Click Setup your AtoZ Account";
export const ADOBE_EVENT_VIEW_ATOZ_ACCOUNT = "View your AtoZ Account";
export const ATOZ_ERROR = "AtoZ Event Error";
export const ATOZ_RETRY = "AtoZ Retry Click";

export interface UrlParams {
  applicationId: string;
}

export interface AtoZTaskParams {
  status: StatusEnum;
}

export enum AtoZStatusButtonState {
  Active = "Active",
  Hidden = "Hidden",
  Disabled = "Disabled",
}
