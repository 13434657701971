import React from "react";
import "./styles.scss";
import { Text, H1 } from "@amzn/stencil-react-components/text";
import { Row } from "@amzn/stencil-react-components/layout";
import { Button, ButtonSize, ButtonVariant } from "@amzn/stencil-react-components/button";
import { StatusContainer } from "./status-container";
import { JobDetailsModel } from "../../utility/application-data";
import { IconCalendarFill } from "@amzn/stencil-react-components/icons";
import { processTime } from "../../utility/time-processor";
import { useChecklistArb } from "../../hooks/use-arb";
import ICalendarLink from "react-icalendar-link";
import moment from "moment-timezone";
import { getProcessedJCAddress, isVirtualJob } from "../../utility/jc-response-helper";
import { useParams } from "react-router-dom";
import { isSalesforceApplication } from "../../helpers/url-params";
import { GoogleMapLink } from "../google-map-link";
import { getLocale } from "../../utility/locale-helper";
import { dateTimeFormatStringBasedOnLocale } from "./dateTimeFormatStringBasedOnLocale";
import { useAppSelector } from "../../reduxStore/reduxHooks";
import { getConfigData, getFeatureFlag } from "../../reduxStore/slices/configSlice";
import AppLoader from "../common/AppLoader";
import { apiSlice } from "../../reduxStore/api/apiSlice";
import LinkWithRedirect from "../common/LinkWithRedirect";
import { useGetJobDetails } from "../../hooks/apis/useGetJobDetails";

export interface StartDateHeaderProps {
  jobDetails: JobDetailsModel;
  onViewDetailsClick: () => void;
  cssURLWithoutScheduleId?: string;
}

function getStartDateDifference(startDateAndStartTime: string, timezone: string, timeFormat: string) {
  const dateTzWithStartTime = moment.tz(startDateAndStartTime, timeFormat, timezone);
  const currDate = new Date();
  const currentDateTime = moment.tz(currDate, timezone);
  const startDateDifference = dateTzWithStartTime.diff(currentDateTime, "day");
  return {
    startDateDifference,
    dateTzWithStartTime,
  };
}

export const FirstDayHeader = ({ jobDetails, onViewDetailsClick, cssURLWithoutScheduleId }: StartDateHeaderProps) => {
  const { isTentativeStartDate, isEligibleForCss } = useAppSelector(getFeatureFlag);
  const { sdaData } = useAppSelector(getConfigData);

  const { applicationId } = useParams<{ applicationId: string }>();

  const jcDetails = useGetJobDetails();

  if (!sdaData) return <AppLoader />;

  const bundle = useChecklistArb();
  const locale = getLocale();

  let plainStartDateAndStartTime;
  let startDateDifference;
  let dateTzWithStartTime;

  if (!isSalesforceApplication(applicationId) && sdaData) {
    //for jobs onboarded to DSP
    const startTime = sdaData.startTime;
    const processedStartTime = processTime(startTime);
    const startDateAndStartTime = jobDetails.firstDayOnSite + " " + processedStartTime;
    const timeFormat = "YYYY-MM-DD hh:mm A";
    plainStartDateAndStartTime = moment(startDateAndStartTime, timeFormat); // create time without time zone to preserve site time
    const dateDiff = getStartDateDifference(startDateAndStartTime, sdaData.timezone, timeFormat);
    startDateDifference = dateDiff.startDateDifference;
    dateTzWithStartTime = dateDiff.dateTzWithStartTime;
  } else {
    //logic for salesforce job. Date returned from SF is in a different format compared to SDA
    plainStartDateAndStartTime = moment(jobDetails.firstDayOnSiteL10N, "YYYY-MM-DD").toDate();
    const timeFormat = "YYYY-MM-DD";
    const localTimeZone = moment.tz.guess();
    const dateDiff = getStartDateDifference(jobDetails.firstDayOnSiteL10N, localTimeZone, timeFormat);
    startDateDifference = dateDiff.startDateDifference;
    dateTzWithStartTime = dateDiff.dateTzWithStartTime;
  }

  const dateTranslated = bundle.formatMessage("Checklist-AppointmentCard-Month-Day-Year", {
    dateParam: plainStartDateAndStartTime,
  });

  const dataTimeFormatString = dateTimeFormatStringBasedOnLocale(locale);

  const dateTimeTranslated = bundle.formatMessage(dataTimeFormatString, {
    dateParam: plainStartDateAndStartTime,
  });

  const cannotAttend = bundle.formatMessage("Checklist-PreHireDetails-CannotAttend");
  const localDateTime = !isSalesforceApplication(applicationId) && sdaData ? dateTimeTranslated : dateTranslated;
  const addToCalendar = bundle.getMessage("Checklist-AppointmentCard-ButtonText3");
  const viewDetails = bundle.getMessage("Checklist-AppointmentCard-ButtonText1");
  const appointmentTitle = bundle.getMessage("Checklist-CardControlled-FirstDayTitle");

  const firstDayCalendarEvent = {
    title: appointmentTitle,
    startTime: dateTzWithStartTime.format(),
    location: jobDetails && getProcessedJCAddress(jobDetails),
  };

  const largeViewImage =
    startDateDifference > 0
      ? require("../../assets/svg/star.svg").default
      : require("../../assets/svg/backpack.svg").default;
  const smallViewPortImage =
    startDateDifference > 0
      ? require("../../assets/svg/star.svg").default
      : require("../../assets/svg/backpack.svg").default;

  let headerText;

  if (startDateDifference < 0) {
    headerText = bundle.getMessage("Checklist-AppointmentCard-First-Day-InPast");
  } else if (startDateDifference > 0) {
    if (isTentativeStartDate) {
      headerText = bundle.formatMessage("Checklist-AppointmentCard-First-Day-Future-Tentative", {
        days: startDateDifference,
      });
    } else {
      headerText = bundle.formatMessage("Checklist-AppointmentCard-First-Day-Future", {
        days: startDateDifference,
      });
    }
  } else {
    if (isTentativeStartDate) {
      headerText = bundle.getMessage("Checklist-AppointmentCard-First-Day-Today-Tentative");
    } else {
      headerText = bundle.getMessage("Checklist-AppointmentCard-First-Day-Today");
    }
  }

  const addToCalendarKeyDown = (event: any) => {
    if (event.key === " " || event.code === "Space") {
      event.preventDefault();
      (document.querySelector(".add-to-calendar") as any).click();
    }
  };

  return (
    <StatusContainer
      data-testid={"page-header-first-day"}
      largeViewPortImage={largeViewImage}
      smallViewPortImage={smallViewPortImage}
    >
      <H1
        fontSize={"T400"}
        fontWeight={"regular"}
        textAlign="center"
        id="main-content"
        className="first-day-header"
        data-testid="general-first-day-header"
        data-iconType={startDateDifference > 0 ? "star-img" : "backpack-img"}
      >
        {headerText}
      </H1>

      <Row gridGap={"1rem"}>
        <Row gridGap={"0.3rem"} alignItems={"center"}>
          <IconCalendarFill />
          <Text
            fontSize={"T200"}
            data-testid={`first-day-date-${jcDetails?.firstDayOnSite}-time-${jcDetails?.startTime}`}
          >
            {localDateTime}
          </Text>
        </Row>
      </Row>

      <GoogleMapLink
        isVirtual={isVirtualJob(jobDetails)}
        address={jobDetails && getProcessedJCAddress(jobDetails)}
        idAddress={jobDetails?.address}
      />
      <Row gridGap={"1rem"} alignItems="center">
        {!isSalesforceApplication(applicationId) && isEligibleForCss && cssURLWithoutScheduleId && (
          <>
            <Text style={{ fontStyle: "italic" }} fontSize={"T200"}>
              {cannotAttend}
            </Text>
            <LinkWithRedirect
              link={cssURLWithoutScheduleId}
              messageKey={"Checklist-AppointmentCard-ButtonText2"}
              messagePlaceholder={"Reschedule"}
            />
          </>
        )}
      </Row>
      <Row gridGap={"1rem"}>
        <Button
          as={"Col"}
          size={ButtonSize.Small}
          onClick={() => (document.querySelector(".add-to-calendar") as any).click()}
          onKeyDown={(event) => addToCalendarKeyDown(event)}
          tabIndex={-1}
        >
          <ICalendarLink
            key="calendar-button"
            filename={appointmentTitle}
            event={firstDayCalendarEvent}
            className={"add-to-calendar"}
          >
            {addToCalendar}
          </ICalendarLink>
        </Button>
        <Button
          data-testid="view-first-day-detail"
          aria-label="view-details"
          variant={ButtonVariant.Primary}
          size={ButtonSize.Small}
          onClick={onViewDetailsClick}
        >
          {viewDetails}
        </Button>
      </Row>
    </StatusContainer>
  );
};
