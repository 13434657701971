import { IconCheckCircleFill, IconSize } from "@amzn/stencil-react-components/icons";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import React from "react";
import { HVH_COLORS } from "../../config/palette";
import { useChecklistArb } from "../../hooks/use-arb";
import { StatusEnum } from "../../utility/enums/common";
import { Text } from "../typography";
import { FlyOutEmploymentPaperworkStatusCard } from "../utilities/FlyOutStatusCard";

export interface EmploymentPaperwork {
  status: StatusEnum;
}

export const EmploymentPaperwork = ({ status }: EmploymentPaperwork) => {
  const bundle = useChecklistArb();

  const completed = bundle.getMessage("checklist-General-TaskStatus-Completed");
  const actionRequired = bundle.getMessage("checklist-General-TaskStatus-ActionRequired");
  const dueSoon = bundle.getMessage("checklist-General-TaskStatus-DueSoon");
  const detailsText1 = bundle.getMessage("Checklist-MainPage-EmploymentPaperwork-Text1");
  const detailsText2 = bundle.getMessage("Checklist-MainPage-EmploymentPaperwork-Text2");

  if (status == StatusEnum.COMPLETED) {
    return (
      <FlyOutEmploymentPaperworkStatusCard
        status={status}
        image={
          <Row alignItems={"center"} gridGap={"S200"}>
            <IconCheckCircleFill size={IconSize.Medium} color={HVH_COLORS.GREEN_70} />
            <Text>{completed}</Text>
          </Row>
        }
        descriptionText={detailsText2}
      />
    );
  }

  return (
    <FlyOutEmploymentPaperworkStatusCard
      status={status}
      image={
        <Col gridGap="S200">
          <Row alignItems={"center"}>
            <Text>{actionRequired}</Text>
          </Row>
          <Row alignItems={"center"}>
            <Text>{dueSoon}</Text>
          </Row>
        </Col>
      }
      descriptionText={detailsText1}
    />
  );
};
