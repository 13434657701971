import { Action, ActionType, State } from "./state";

const Reducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionType.UpdateSampleShots:
      return {
        ...state,
        sampleShots: action.payload,
      };
    case ActionType.UpdateSelectedShot:
      return {
        ...state,
        selectedShot: action.payload,
      };
    default:
      return state;
  }
};

export default Reducer;
