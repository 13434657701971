export enum AttachmentType {
  CURP = "Mexico - Unique Population Registry Key",
  NSS = "Mexico - Social Security Number",
  RFC = "Mexico - Treasure Department",
  INE = "Mexico - National Electoral Institute",
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  VOTER_CARD = "Mexico - National Electoral Institute",
  MEXICAN_PASSPORT = "Mexico - Passport",
  CONSULAR_IDENTIFICATION_CARD = "Mexico - Consular ID Card",
  MILITARY_SERVICE_CARD = "Mexico - Military Service ID",
  MEXICAN_BIRTH_CERTIFICATE = "Mexico - Birth Certificate",
  TEMPORARY_RESIDENCE_CARD = "Foreign National - Temporary Residence Card",
  TEMPORARY_RESIDENCE_STUDENT_CARD = "Foreign National - Temporary Residence Student Card",
  PERMANENT_RESIDENCE_CARD = "Foreign National - Permanent Residence Card",
  NATURALIZATION_CERTIFICATE = "Foreign National - Naturalization Certificate",
  TEMPORARY_CONSULAR_RESIDENCE_VISA = "Temporary Consular Residence Visa",
  INM = "Foreign National - National Immigration Institute Approval",
  FMM = "Foreign National - Multi-purpose Immigration Form",
  INM_WORK_AUTHORIZATION = "Foreign National - National Immigration Institute Student Work Authorization",
  OTHER = "OTHER",
}

export const WorkAuthorizationAttachmentType = new Set<AttachmentType>([
  AttachmentType.VOTER_CARD,
  AttachmentType.MEXICAN_PASSPORT,
  AttachmentType.CONSULAR_IDENTIFICATION_CARD,
  AttachmentType.MILITARY_SERVICE_CARD,
  AttachmentType.MEXICAN_BIRTH_CERTIFICATE,
  AttachmentType.TEMPORARY_RESIDENCE_CARD,
  AttachmentType.TEMPORARY_RESIDENCE_STUDENT_CARD,
  AttachmentType.PERMANENT_RESIDENCE_CARD,
  AttachmentType.NATURALIZATION_CERTIFICATE,
  AttachmentType.TEMPORARY_CONSULAR_RESIDENCE_VISA,
  AttachmentType.INM,
  AttachmentType.FMM,
  AttachmentType.INM_WORK_AUTHORIZATION,
  AttachmentType.OTHER,
]);

// These option use multiple AttachmentType when upload
export const TEMPORARY_RESIDENCE_STUDENT_CARD_INM_WORK_AUTHORIZATION =
  "temporary-residence-student-card-inm-work-authorization";
export const TEMPORARY_CONSULAR_RESIDENCE_VISA_OFFICIAL_APPROVAL_INM_FMM =
  "temporary-consular-residence-visa-official-approval-inm-fmm";

export interface UploadDetails {
  documentType: AttachmentType;
  attachmentSubType: string;
  imageBase64?: string;
  expirationDate?: string;
  fileName: string;
  contentType: string;
  returnPresignedUrl?: boolean;
  candidateId?: string;
}

export enum AttachmentSubType {
  FRONT = "FRONT",
  BACK = "BACK",
}

/**
 * This function returns which document's page is optional to upload
 * Currently it only has two document options. Those are used in general doc upload template
 *
 * @param attachmentType
 * @param attachmentSubType
 */
export function isOptional(attachmentType: AttachmentType, attachmentSubType: AttachmentSubType) {
  if (
    (attachmentType === AttachmentType.TEMPORARY_CONSULAR_RESIDENCE_VISA ||
      attachmentType === AttachmentType.INM ||
      attachmentType === AttachmentType.FMM) &&
    attachmentSubType === AttachmentSubType.BACK
  ) {
    return true;
  }

  if (attachmentType === AttachmentType.INM_WORK_AUTHORIZATION && attachmentSubType === AttachmentSubType.BACK) {
    return true;
  }

  return false;
}

export function hasWorkAuthorizationAttachment(currentAttachments: Map<string, string[]>) {
  let contained = false;
  currentAttachments.forEach((_value: string[], key: string) => {
    if (WorkAuthorizationAttachmentType.has(key as AttachmentType)) {
      contained = true;
    }
  });

  return contained;
}
