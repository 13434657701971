interface ClientDeviceInfo {
  device?: string;
  resolution?: string;
  orientation?: string;
  cameras?: Array<any>;
}

const mediaDevicesPromise = navigator.mediaDevices ? navigator.mediaDevices.enumerateDevices() : Promise.resolve([]);

export const getClientDeviceInfo = (): Promise<ClientDeviceInfo> => {
  return new Promise((resolve) => {
    mediaDevicesPromise.then((devices) => {
      resolve({
        device: navigator.userAgent,
        resolution: `${screen.width}x${screen.height}`,
        orientation: screen?.orientation?.type,
        cameras: devices.filter((device) => device.kind === "videoinput"),
      });
    });
  });
};
