import { AwsRum, AwsRumConfig } from "aws-rum-web";
import { getSettings } from "../helpers/get-settings";
import { getCountryForDomain } from "../utility/locale-helper";

class AwsRumClient {
  private static awsRum?: AwsRum;

  public static async initialize(): Promise<AwsRum | undefined> {
    if (!this.awsRum) {
      try {
        const { checklistRumHostRegion, checklistVersion, checklistGuestRumAppId } = await getSettings();
        const applicationVersion = `${checklistVersion ?? "unknown"}-${getCountryForDomain() ?? "unknown"}`;

        const rumConfig: AwsRumConfig = {
          sessionSampleRate: 1,
          telemetries: ["errors", "performance", "http"],
          allowCookies: false,
          enableXRay: false,
          // Don't rely on the apiSlice logic for RUM, just go relative to the origin like https://jobs.amazon.com
          endpoint: new URL("/checklist/api/rum-proxy", window.location.origin).toString(),
          signing: false,
        };

        if (!checklistGuestRumAppId) {
          console.error("Failed to load checklistGuestRumAppId from settings.json");
          return undefined;
        }

        this.awsRum = new AwsRum(
          checklistGuestRumAppId,
          applicationVersion,
          checklistRumHostRegion ?? "us-west-2",
          rumConfig
        );
      } catch (error) {
        console.log(`Error creating AWS RUM client: ${error}`);

        return undefined;
      }
    }

    return this.awsRum;
  }
}

export const initAwsRumClient = () => AwsRumClient.initialize();
