import { SUPPORTED_COUNTRY } from "../../config/appConfigConstants";
import { ChecklistPantherKey } from "../../utility/types/translations";

export interface IReasonsConfig {
  key: string;
  translation: string;
  locations: Array<SUPPORTED_COUNTRY>; // if empty means that all location accepted
}

export type ReasonsCodeConfig = Array<IReasonsConfig>;

export const getReasonsCodeConfig = (translationFc: (str: ChecklistPantherKey) => string): ReasonsCodeConfig => [
  { key: "Accepted another offer", translation: translationFc("Checklist-CardModal-AnotherOffer"), locations: [] },
  {
    key: "Not interested in job location",
    translation: translationFc("Checklist-CardModal-NonInterestedLocation"),
    locations: [],
  },
  {
    key: "Shift doesn't work for me",
    translation: translationFc("Checklist-CardModal-NotWorkingShift"),
    locations: [],
  },
  {
    key: "Pay rate doesn't meet expectation",
    translation: translationFc("Checklist-CardModal-PayRateExpectation"),
    locations: [],
  },
  {
    key: "Location is not convenient for me",
    translation: translationFc("Checklist-CardModal-LocationNotConvenient"),
    locations: [SUPPORTED_COUNTRY.GB],
  },
  {
    key: "Medical problem",
    translation: translationFc("Checklist-CardModal-MedicalProblem"),
    locations: [SUPPORTED_COUNTRY.GB],
  },
  {
    key: "Returning to school",
    translation: translationFc("Checklist-CardModal-ReturnToSchool"),
    locations: [SUPPORTED_COUNTRY.GB],
  },
  {
    key: "None of the above reasons",
    translation: translationFc("Checklist-CardModal-NoneAbove"),
    locations: [],
  },
];

export const getReasonsCodeConfigBasedOnCountry = (
  config: ReasonsCodeConfig,
  country: SUPPORTED_COUNTRY
): ReasonsCodeConfig => {
  return config.filter((el) => {
    if (el.locations.length === 0) {
      return true;
    }

    return el.locations.includes(country);
  });
};
