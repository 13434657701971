import React from "react";
import { useParams } from "react-router-dom";
import { Col } from "@amzn/stencil-react-components/layout";
import { CurpUploadLayout } from "./curp";
import { TaxIdUploadLayout } from "./taxid";
import { NSSUploadLayout } from "./nss";
import { EditCURPId } from "../edit/curp-id-edit";
import { EditTaxId } from "../edit/tax-id-edit";
import { NssIdEdit } from "../edit/NssIdEdit";
import {
  AttachmentType,
  TEMPORARY_CONSULAR_RESIDENCE_VISA_OFFICIAL_APPROVAL_INM_FMM,
  TEMPORARY_RESIDENCE_STUDENT_CARD_INM_WORK_AUTHORIZATION,
} from "../models";
import { FrontUploadLayout } from "./front";
import { FrontBackUploadLayout } from "./front-back";
import { TemplateUploadLayout } from "./template";
import { useAppSelector } from "../../../../reduxStore/reduxHooks";
import { getOldUiFlyoutContent } from "../../../../reduxStore/slices/docUploadSlice";

export const DocumentUpload = () => {
  const oldUiFlyoutContent = useAppSelector(getOldUiFlyoutContent);
  let { documentType, isEdit } = useParams<{
    documentType: string;
    isEdit: string;
  }>();

  documentType = documentType || oldUiFlyoutContent?.documentType;
  isEdit = isEdit || String(oldUiFlyoutContent?.isEdit);

  return (
    <Col>
      {documentType === AttachmentType.OTHER && isEdit === "false" && <FrontUploadLayout documentType={documentType} />}
      {documentType === AttachmentType.CURP && isEdit === "false" ? <CurpUploadLayout /> : null}
      {documentType === AttachmentType.RFC && isEdit === "false" ? <TaxIdUploadLayout /> : null}
      {documentType === AttachmentType.NSS && isEdit === "false" ? <NSSUploadLayout /> : null}
      {documentType === AttachmentType.CURP && isEdit === "true" ? <EditCURPId /> : null}
      {documentType === AttachmentType.RFC && isEdit === "true" ? <EditTaxId /> : null}
      {documentType === AttachmentType.NSS && isEdit === "true" ? <NssIdEdit /> : null}
      {(documentType === AttachmentType.MEXICAN_PASSPORT ||
        documentType === AttachmentType.MILITARY_SERVICE_CARD ||
        documentType === AttachmentType.MEXICAN_BIRTH_CERTIFICATE ||
        documentType === AttachmentType.NATURALIZATION_CERTIFICATE ||
        documentType === AttachmentType.INM_WORK_AUTHORIZATION ||
        documentType === AttachmentType.TEMPORARY_CONSULAR_RESIDENCE_VISA ||
        documentType === AttachmentType.INM ||
        documentType === AttachmentType.FMM) &&
      isEdit === "false" ? (
        <FrontUploadLayout documentType={documentType} />
      ) : null}
      {(documentType === AttachmentType.CONSULAR_IDENTIFICATION_CARD ||
        documentType === AttachmentType.VOTER_CARD ||
        documentType === AttachmentType.TEMPORARY_RESIDENCE_CARD ||
        documentType === AttachmentType.PERMANENT_RESIDENCE_CARD ||
        documentType === AttachmentType.TEMPORARY_RESIDENCE_STUDENT_CARD) &&
      isEdit === "false" ? (
        <FrontBackUploadLayout documentType={documentType} />
      ) : null}
      {(documentType === TEMPORARY_CONSULAR_RESIDENCE_VISA_OFFICIAL_APPROVAL_INM_FMM ||
        documentType === TEMPORARY_RESIDENCE_STUDENT_CARD_INM_WORK_AUTHORIZATION) &&
      isEdit === "false" ? (
        <TemplateUploadLayout documentType={documentType} />
      ) : null}
    </Col>
  );
};
