/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { MessageBanner, MessageBannerType } from "@amzn/stencil-react-components/message-banner";
import { PageContainer } from "@amzn/stencil-react-components/page";
import React, { useCallback, useContext, useState } from "react";
import { H1 } from "../../../../components/typography";
import { HVH_COLORS } from "../../../../config/palette";
import { TAKE_BADGE_PHOTO_RESULT_URL, TAKE_BADGE_PHOTO_START_URL } from "../../../../config/urls";
import { getNumberOfFailedAttempts } from "../../../../helpers/badge-failed-attempts";
import { useSendAnalyticEvent } from "../../../../hooks/jobDetailsCardAnalyticEventHelper.hook";
import { useChecklistArb } from "../../../../hooks/use-arb";
import { useMetrics } from "../../../../hooks/use-metrics";
import { useNavigator } from "../../../../hooks/use-navigator";
import { Context } from "../../../../reactContextStore/store";
import { useSubmitBadgePhotoMutation } from "../../../../reduxStore/api/apiSlice";
import { useAppSelector } from "../../../../reduxStore/reduxHooks";
import { getConfigData } from "../../../../reduxStore/slices/configSlice";
import { extractMetricData } from "../../../../utility/badge-metric";
import { dispatchOnDev } from "../../../../utility/dev-env-helpers";
import "./styles.scss";

export const BadgeConfirmRoute = () => {
  const { sendBadgePhotoEventAnalyticsEvent } = useSendAnalyticEvent();

  const { candidateData } = useAppSelector(getConfigData);

  const [submitBadgePhoto] = useSubmitBadgePhotoMutation();

  const [goToResults, goToBadgeStart] = useNavigator(TAKE_BADGE_PHOTO_RESULT_URL, TAKE_BADGE_PHOTO_START_URL);
  const { state } = useContext(Context);
  const { selectedShot } = state;
  const [bannerDisplay, setBannerDisplay] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const bundle = useChecklistArb();

  const metrics = useMetrics();
  const networkErrorMessage = bundle.getMessage("Checklist-BPConfirm-NetworkError");
  const submitErrorMessage = bundle.getMessage("Checklist-BPConfirm-GeneralError");

  const submit = useCallback(async () => {
    if (!window.navigator.onLine) {
      setErrorMessage(networkErrorMessage);
      setBannerDisplay(true);
      return;
    }
    setLoading(true);

    try {
      if (!candidateData) {
        dispatchOnDev(() => {
          console.error("candidateData is not ready yet");
        });
        return;
      }

      const payload = {
        captureSource: "CANDIDATE",
        badgePhotoContents: selectedShot?.outputImageData ? selectedShot?.outputImageData.split(",").pop() : "",
      };

      sendBadgePhotoEventAnalyticsEvent("confirm badge");

      submitBadgePhoto({ candidateId: candidateData.candidateId, payload });

      if (metrics?.sendMetric) {
        metrics.sendMetric({
          event: "confirm-badge",
          details: {
            ...extractMetricData(selectedShot!),
            numberOfFailedAttempts: getNumberOfFailedAttempts(),
          },
        });
      }
      goToResults();
    } catch (error) {
      dispatchOnDev(() => {
        console.error(error);
      });
      setErrorMessage(submitErrorMessage);
      setBannerDisplay(true);
    }
    setLoading(false);
  }, [selectedShot, metrics?.sendMetric]);

  const dismissBanner = useCallback(() => {
    setErrorMessage("");
    setBannerDisplay(false);
  }, []);

  if (!selectedShot) {
    goToBadgeStart();
  }

  const confirm = bundle.getMessage("Checklist-BPConfirm-Confirm");
  const submitButton = bundle.getMessage("Checklist-General-SubmitButtonText");

  return (
    <>
      <PageContainer color={HVH_COLORS.NEUTRAL_90}>
        <Col gridGap="2rem" alignItems="stretch">
          {bannerDisplay && (
            <MessageBanner
              data-testid="badge-photo-dismiss-message-banner"
              isDismissible={true}
              type={MessageBannerType.Error}
              onDismissed={dismissBanner}
            >
              {errorMessage}
            </MessageBanner>
          )}
          <Row justifyContent="center">
            <H1>{confirm}</H1>
          </Row>
          <Row justifyContent="center">
            <div className="confirm-image-container">
              <img className="preview-image limited-height" src={selectedShot?.outputImageData} alt="preview" />
            </div>
          </Row>
          <Row justifyContent="space-around" width="100%">
            <Button
              data-testid="badge-photo-submit-button"
              onClick={submit}
              variant={ButtonVariant.Primary}
              disabled={loading}
            >
              {submitButton}
            </Button>
          </Row>
        </Col>
      </PageContainer>
    </>
  );
};
