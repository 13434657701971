import { Button } from "@amzn/stencil-react-components/button";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { PageContainer } from "@amzn/stencil-react-components/page";
import React, { useEffect } from "react";
import { H1, Text } from "../../../../components/typography";
import { HVH_COLORS } from "../../../../config/palette";
import { CHECKLIST_URL } from "../../../../config/urls";
import { useSendAnalyticEvent } from "../../../../hooks/jobDetailsCardAnalyticEventHelper.hook";
import { useChecklistArb } from "../../../../hooks/use-arb";
import { useNavigator } from "../../../../hooks/use-navigator";

export const BadgeResultRoute = () => {
  const [goToChecklist] = useNavigator(CHECKLIST_URL);

  const { sendBadgePhotoPageAnalyticsEvent } = useSendAnalyticEvent();

  useEffect(() => {
    sendBadgePhotoPageAnalyticsEvent("badge/result");
  }, []);

  const bundle = useChecklistArb();

  const completed = bundle.getMessage("checklist-General-TaskStatus-Completed");
  const message = bundle.getMessage("Checklist-BPResult-Success");
  const goTo = bundle.getMessage("Checklist-BPConfirm-Navigator");

  return (
    <>
      <PageContainer color={HVH_COLORS.NEUTRAL_90}>
        <Col gridGap="1rem" alignItems="center">
          <H1>{completed}</H1>
          <Text>{message}</Text>
          <Row>
            <Button data-testid="badge-photo-go-back-to-checklist-button" onClick={goToChecklist}>
              {goTo}
            </Button>
          </Row>
        </Col>
      </PageContainer>
    </>
  );
};
