import { Col } from "@amzn/stencil-react-components/layout";
import { MessageBanner, MessageBannerType } from "@amzn/stencil-react-components/message-banner";
import React, { useEffect, useState } from "react";
import { CREATE_EMPLOYEE_A_TO_Z_ACCOUNT } from "../../config/taskConfigConstants";
import { useChecklistArb } from "../../hooks/use-arb";
import { StatusEnum } from "../../utility/enums/common";
import { useStatusText } from "../task/useStatusText.hook";
import { Text } from "../typography";
import { FlyOutAtoZStatusCard } from "../utilities/FlyOutStatusCard";
import { AtoZTaskParams } from "./atozData";
import { useGetCandidateAtoZWithoutPassword } from "../../hooks/apis/useGetCandidateAtoZWithoutPassword";
import { Spinner, SpinnerSize } from "@amzn/stencil-react-components/spinner";

export const AtoZAccountCreation = ({ status }: AtoZTaskParams) => {
  const candidateAtoZ = useGetCandidateAtoZWithoutPassword();

  const bundle = useChecklistArb();

  const atoZViewAtoZUserNameMessage = bundle.getMessage("Checklist-AtoZ-Show-UserName");

  const atoZSetupAZAccountMessage = bundle.getMessage("Checklist-AtoZ-Setup-MessageDetails");

  const atoZDetailsExtraMessage = bundle.getMessage("Checklist-AtoZ-Setup-MessageDetailsUsername");

  const statusText = useStatusText(status, CREATE_EMPLOYEE_A_TO_Z_ACCOUNT);

  const [atoZEmployeeLogin, setAtoZEmployeeLogin] = useState<string>();

  useEffect(() => {
    if (candidateAtoZ && candidateAtoZ !== "SKIPPED") {
      if (candidateAtoZ && candidateAtoZ.isAccountReady) {
        setAtoZEmployeeLogin(candidateAtoZ.employeeLogin);
      }
    }
  }, [candidateAtoZ]);

  if (status == StatusEnum.ERROR) {
    const here = bundle.getMessage("Checklist-MainPage-Link");

    return (
      <Col gridGap={"S300"}>
        <MessageBanner type={MessageBannerType.Error}>
          <Text>{bundle.formatMessage("Checklist-AtoZ-Account-Creation-Error")}</Text>
        </MessageBanner>
      </Col>
    );
  }

  if (candidateAtoZ === undefined) {
    return (
      <Col padding="1rem" alignItems="center">
        <Spinner size={SpinnerSize.Small} showText={false} />
      </Col>
    );
  }

  if (status == StatusEnum.COMPLETED) {
    return (
      <FlyOutAtoZStatusCard
        status={status}
        atoZBodyText={atoZViewAtoZUserNameMessage}
        atoZEmployeeLogin={atoZEmployeeLogin}
        statusText={statusText}
      />
    );
  }

  return (
    <FlyOutAtoZStatusCard
      status={status}
      atoZBodyText={atoZSetupAZAccountMessage}
      atoZEmployeeLogin={atoZEmployeeLogin}
      atoZDetailsExtra={atoZDetailsExtraMessage}
      statusText={statusText}
    />
  );
};
