/* eslint-disable import/no-unused-modules */
import { Dispatch, useEffect } from "react";
import { useLazyGetCaasInfoQuery, useUpdateCaasI9Mutation } from "../../reduxStore/api/apiSlice";
import { useGetApplicationId } from "./useGetApplicationId";
import { isSalesforceApplication } from "../../helpers/url-params";
import { getCountryForDomain } from "../../utility/locale-helper";
import { useAppSelector } from "../../reduxStore/reduxHooks";
import { getConfigData, getFeatureFlag, setConfigData } from "../../reduxStore/slices/configSlice";
import { useDispatch } from "react-redux";
import { CONFIG_DATA_KEY, StatusEnum } from "../../utility/enums/common";
import { useGetCandidateData } from "./useGetCandidateData";
import { CAAS_DRUGTEST_ALLOWED_COUNTRY, FeatFlag, SUPPORTED_COUNTRY } from "../../config/appConfigConstants";
import { isDevEnv } from "../../utility/dev-env-helpers";
import { useGetAllContigenciesData } from "./useGetAllContigenciesData";
import { isDisableCaasI9GETCallEnabledByAppConfig, isTaskEnabledByAppConfig } from "../../utility/app-config-helper";
import { CAAS_STATUS } from "@amzn/hvh-simple-hire-checklist-internal-api-common";
import { useGetApplicationManageData } from "./useGetApplicationManageData";
import { I9Response } from "../../utility/types/common";
import { getI9CaaSResponse } from "../../helpers/utils";
import { AppDispatch } from "../../reduxStore/store";

export const resolveI9TaskStatus = (status: StatusEnum) => {
  const country = getCountryForDomain();

  if (country === SUPPORTED_COUNTRY.MX && status === StatusEnum.SKIPPED) {
    // https://t.corp.amazon.com/P100507740 - when status is missing, return NOT_STARTED
    return StatusEnum.NOT_STARTED;
  }

  return status || StatusEnum.NOT_AVAILABLE_YET;
};

export const overrideI9Response = (
  applicationId: string,
  caasI9Status: I9Response,
  isEligibleToSkipAppt1: boolean,
  dispatch: AppDispatch
) => {
  let i9Status = resolveI9TaskStatus(caasI9Status.status as StatusEnum);

  // Logic for fast I9 for Alumni: https://quip-amazon.com/d2LnAFkKcV5s#FBO9DA9gwIf
  if (isEligibleToSkipAppt1) {
    if (caasI9Status.status === (CAAS_STATUS.PendingSection3 as string)) i9Status = StatusEnum.NO_ACTION_REQUIRED; // Show task card
    if (caasI9Status.status === (CAAS_STATUS.Section3Complete as string)) i9Status = StatusEnum.SKIPPED; // Skip the task
  }

  dispatch(
    setConfigData({
      key: CONFIG_DATA_KEY.CAAS_I9_STATUS,
      value: { ...caasI9Status, ...getI9CaaSResponse(applicationId, i9Status) },
    })
  );
};

export const useGetCaasI9Status = () => {
  const { caasI9Status } = useAppSelector(getConfigData);
  const { isEligibleToSkipAppt1, isAshConfigLoaded } = useAppSelector(getFeatureFlag);
  const applicationManageData = useGetApplicationManageData();
  const dispatch = useDispatch();
  const allContigencies = useGetAllContigenciesData();
  const applicationId = useGetApplicationId();
  const candidateData = useGetCandidateData();
  const [updateCaasI9] = useUpdateCaasI9Mutation();
  const [getCaasI9Status] = useLazyGetCaasInfoQuery();

  useEffect(() => {
    const country = getCountryForDomain();
    const skipCallForGetCaasI9Status =
      isTaskEnabledByAppConfig(FeatFlag.ENABLE_ALL_CONTINGENCIES_WORK_AUTH, applicationId) ||
      !(country in CAAS_DRUGTEST_ALLOWED_COUNTRY && candidateData);

    // Skip the effect if the necessary data is not ready yet
    if (!candidateData?.candidateId || !isAshConfigLoaded || !applicationManageData) return;

    // If the value is already been set, return that value instead
    if (caasI9Status !== undefined) return;

    if (candidateData?.candidateId && isAshConfigLoaded && applicationManageData) {
      const flagEnabled = isTaskEnabledByAppConfig(FeatFlag.ENABLE_ALL_CONTINGENCIES_WORK_AUTH, applicationId);

      if (caasI9Status === undefined) {
        if (flagEnabled && allContigencies !== "SKIPPED" && allContigencies?.WorkAuthorization) {
          if (
            allContigencies?.WorkAuthorization.status === CAAS_STATUS.WaitingForASHToCreateOrder &&
            !isDisableCaasI9GETCallEnabledByAppConfig()
          ) {
            // This skip condition was added to only called to void another api call
            if (caasI9Status && (caasI9Status as unknown as string) !== "SKIPPED") return;
            updateCaasI9({
              candidateId: candidateData?.candidateId,
              redirectURL: encodeURIComponent(window.location.href),
            })
              .unwrap()
              .then((i9Response) => {
                overrideI9Response(applicationId, i9Response as I9Response, !!isEligibleToSkipAppt1, dispatch);
              });
          } else {
            overrideI9Response(
              applicationId,
              allContigencies.WorkAuthorization as I9Response,
              !!isEligibleToSkipAppt1,
              dispatch
            );
          }
        } else if ((!flagEnabled || isSalesforceApplication(applicationId)) && !skipCallForGetCaasI9Status) {
          // TODO: Check for another country for the flag, if not used, we can remove this block [ALL_CONTINGENCIES]
          getCaasI9Status(
            {
              candidateId: candidateData?.candidateId,
              redirectUrl: encodeURIComponent(window.location.href),
            },
            true
          )
            .unwrap()
            .then((data) => {
              overrideI9Response(applicationId, data, !!isEligibleToSkipAppt1, dispatch);
            });
        } else {
          if (flagEnabled || caasI9Status == "SKIPPED") return;

          if (isDevEnv()) console.warn("Skipping useGetCaasI9Status hook");
          dispatch(
            setConfigData({
              key: CONFIG_DATA_KEY.CAAS_I9_STATUS,
              value: "SKIPPED",
            })
          );
        }
      }
    }
  }, [
    isAshConfigLoaded,
    caasI9Status,
    allContigencies,
    applicationId,
    dispatch,
    getCaasI9Status,
    candidateData?.candidateId,
    candidateData,
    updateCaasI9,
    applicationManageData,
    isEligibleToSkipAppt1,
  ]);

  return caasI9Status;
};
