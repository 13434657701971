/* eslint-disable import/no-unused-modules */
import { useEffect } from "react";
import { useLazyGetCandidateAtoZStatusQuery } from "../../reduxStore/api/apiSlice";
import { useGetApplicationId } from "./useGetApplicationId";
import { isSalesforceApplication } from "../../helpers/url-params";
import { getCountryForDomain, getLocale } from "../../utility/locale-helper";
import { useAppSelector } from "../../reduxStore/reduxHooks";
import { getConfigData, setConfigData } from "../../reduxStore/slices/configSlice";
import { useDispatch } from "react-redux";
import { CONFIG_DATA_KEY } from "../../utility/enums/common";
import { useGetCandidateData } from "./useGetCandidateData";
import { ATOZ_ALLOWED_COUNTRY } from "../../config/appConfigConstants";
import { useGetApplicationManageData } from "./useGetApplicationManageData";
import { APPLICATION_STATE } from "../../utility/constants/application-status";
import { isDevEnv } from "../../utility/dev-env-helpers";

export const useGetCandidateAtoZWithPassword = () => {
  const { candidateAtoZWithPassword } = useAppSelector(getConfigData);
  const dispatch = useDispatch();
  const applicationId = useGetApplicationId();
  const candidateData = useGetCandidateData();
  const applicationManageData = useGetApplicationManageData();
  const [getCandidateDataAtoZWithoutPassword] = useLazyGetCandidateAtoZStatusQuery();

  const skipCallForAtoZ = !(
    getCountryForDomain() in ATOZ_ALLOWED_COUNTRY &&
    applicationManageData?.applicationState === APPLICATION_STATE.HIRE_COMPLETE &&
    !isSalesforceApplication(applicationId) &&
    candidateData &&
    candidateData?.candidateGlobalId
  );

  useEffect(() => {
    if (candidateAtoZWithPassword) return;

    if (applicationManageData && applicationId && candidateData) {
      if (!skipCallForAtoZ) {
        getCandidateDataAtoZWithoutPassword(
          {
            candidateGlobalId: candidateData?.candidateGlobalId,
            applicationId,
            locale: getLocale(),
            returnResetPasswordUrl: "true",
          },
          true
        )
          .unwrap()
          .then((data) => {
            dispatch(
              setConfigData({
                key: CONFIG_DATA_KEY.ATOZ_CANDIDATE_DATA_WITH_PASSWORD,
                value: data,
              })
            );
          });
      } else {
        if (isDevEnv()) console.warn("Skipping useGetCandidateAtoZWithPassword hook");
        dispatch(
          setConfigData({
            key: CONFIG_DATA_KEY.ATOZ_CANDIDATE_DATA_WITH_PASSWORD,
            value: "SKIPPED",
          })
        );
      }
    }
  }, [
    applicationId,
    dispatch,
    getCandidateDataAtoZWithoutPassword,
    candidateAtoZWithPassword,
    skipCallForAtoZ,
    candidateData?.candidateGlobalId,
    applicationManageData,
    candidateData,
  ]);

  return candidateAtoZWithPassword;
};
