import { StatusEnum } from "@amzn/hvh-simple-hire-checklist-internal-api-common";
import { Col } from "@amzn/stencil-react-components/layout";
import { format } from "date-fns";
import React, { BaseSyntheticEvent, useCallback, useEffect, useRef, useState } from "react";
import { useGetApplicationId } from "../../hooks/apis/useGetApplicationId";
import { useGetCandidateData } from "../../hooks/apis/useGetCandidateData";
import { useGetJobDetails } from "../../hooks/apis/useGetJobDetails";
import { useGetJobPreviewVideoDetails } from "../../hooks/apis/useGetJobPreviewVideoData";
import { useSendAnalyticEvent } from "../../hooks/jobDetailsCardAnalyticEventHelper.hook";
import { useChecklistArb } from "../../hooks/use-arb";
import { useUpdateJobPreviewVideoDetailsMutation } from "../../reduxStore/api/apiSlice";
import { getLocale, isEnglishLocale } from "../../utility/locale-helper";
import { H1, Text } from "../typography";

export const JobPreviewVideoFlyout = () => {
  const candidateData = useGetCandidateData();
  const jcDetails = useGetJobDetails();
  const jobPreviewVideoData = useGetJobPreviewVideoDetails();
  const applicationId = useGetApplicationId();
  const [putTaskStatusWithAppId] = useUpdateJobPreviewVideoDetailsMutation();

  const isEnglish: boolean = isEnglishLocale();
  const videoRef = useRef<HTMLVideoElement>(null);
  const { sendNewHireVideoAnalyticsEvent } = useSendAnalyticEvent();
  const bundle = useChecklistArb();

  const jobPreviewVideoDetails = bundle.getMessage("Checklist-NHE-Details");
  const jobPreviewVideoTitle = bundle.getMessage("Checklist-Job-Preview-Video-H1");

  const [watchedTime, setWatchedTime] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [lastPlay, setLastPlay] = useState(0);
  const [linkClickedAt, setLinkClickedAt] = useState("");
  const [videoPlayedAt, setVideoPlayedAt] = useState("");

  const taskType = `${encodeURIComponent(`JOB_PREVIEW_VIDEO#${jcDetails?.siteJobPreviewVideoLink}`)}`;

  const onLoadedmetadata = (e: BaseSyntheticEvent) => {
    const lastCur = !localStorage.getItem("videoCurrentTime")
      ? 0
      : parseFloat(localStorage.getItem("videoCurrentTime") as string);
    e.target.currentTime = lastCur;
    setCurrentTime(lastCur);
    setLastPlay(lastCur);
    setWatchedTime(0);
  };

  const onVideoTimeUpdate = (e: BaseSyntheticEvent) => {
    if (!e.target.seeking) {
      if (e.target.currentTime > watchedTime) {
        setWatchedTime(e.target.currentTime - lastPlay);
      }
      setCurrentTime(e.target.currentTime);
    }
  };

  const onVideoEnd = useCallback(() => {
    sendNewHireVideoAnalyticsEvent("finish job preview video");
  }, []);

  const onVideoPlay = () => {
    setVideoPlayedAt((prevPlayedAt) =>
      prevPlayedAt === "" ? format(new Date(), "yyyy-MM-dd HH:mm:ss") : prevPlayedAt
    );
  };

  const payLoadData = {
    candidateId: candidateData?.candidateId,
    applicationId: applicationId,
    taskStatus: StatusEnum.COMPLETED,
    taskType: taskType,
    requisitionId: jcDetails?.jobId,
    linkClickedAt: linkClickedAt,
    videoPlayedAt: videoPlayedAt,
    totalWatchTime: String(watchedTime),
    language: getLocale(),
    videoUrl: jcDetails?.siteJobPreviewVideoLink,
    shouldUpdateToDataLake: true,
  };

  const sendDataToServer = async () => {
    setLastPlay(currentTime);
    localStorage.setItem("videoCurrentTime", String(currentTime));

    setWatchedTime(0);
    setVideoPlayedAt("");

    putTaskStatusWithAppId({ applicationId, taskType, payload: payLoadData });
  };

  useEffect(() => {
    const handleVisibilitychange = async () => {
      if (document.visibilityState !== "visible") {
        await sendDataToServer();
      }
    };
    document.addEventListener("visibilitychange", handleVisibilitychange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilitychange);
    };
  }, [currentTime, lastPlay, watchedTime]);

  useEffect(() => {
    sendNewHireVideoAnalyticsEvent("job preview video");
    setLinkClickedAt(format(new Date(), "yyyy-MM-dd HH:mm:ss"));

    if (jobPreviewVideoData.jobPreviewVideoDetails?.status != StatusEnum.COMPLETED) {
      putTaskStatusWithAppId({ applicationId, taskType, payload: { ...payLoadData, shouldUpdateToDataLake: false } });
    }
  }, [candidateData]);

  const NheVideoComponent = jcDetails?.siteJobPreviewVideoLink && (
    <Col gridGap="1rem" padding={"1rem"}>
      {<H1>{jobPreviewVideoTitle}</H1>}
      <Text>{jobPreviewVideoDetails}</Text>
      <video
        ref={videoRef}
        id="jobPreviewVideo"
        data-testid="jobPreviewVideo"
        width="100%"
        controls
        onEnded={onVideoEnd}
        onPlay={onVideoPlay}
        onTimeUpdate={onVideoTimeUpdate}
        onLoadedMetadata={onLoadedmetadata}
        crossOrigin="anonymous"
        tabIndex={isEnglish ? 0 : undefined}
        controlsList={isEnglish ? undefined : "nodownload noplaybackrate"}
        disablePictureInPicture={isEnglish ? undefined : true}
      >
        <source src={jcDetails?.siteJobPreviewVideoLink} type="video/mp4" />
      </video>
    </Col>
  );

  return <>{NheVideoComponent}</>;
};
