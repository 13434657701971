import React, { useState, useEffect } from "react";
import "./styles.scss";
import { Text, H1 } from "@amzn/stencil-react-components/text";
import { Row } from "@amzn/stencil-react-components/layout";
import { useChecklistArb } from "../../hooks/use-arb";
import { StatusContainer } from "./status-container";
import { getSettings, Stage } from "../../helpers/get-settings";
import { Button, ButtonSize, ButtonVariant } from "@amzn/stencil-react-components/button";
import { useNavigatorWithURLReady } from "../../hooks/use-navigator";
import { useNavigateContactUrl } from "./useNavigateContactUrl.hook";
interface ShiftUnavailableHeaderProps {
  cssURLWithoutScheduleId?: string;
}
export const ShiftNoAvailableHeader = ({ cssURLWithoutScheduleId }: ShiftUnavailableHeaderProps) => {
  if (!cssURLWithoutScheduleId) {
    return null;
  }

  const [stage, setStage] = useState<Stage>();
  useEffect(() => {
    getSettings().then((settings) => setStage(settings.stage));
  }, []);

  const navigateContactUrl = useNavigateContactUrl(stage);

  const bundle = useChecklistArb();

  const errorMsg = bundle.getMessage("Checklist-AppointmentCard-Error-Schedule-Msg");
  const selectshiftMsg = bundle.getMessage("Checklist-AppointmentCard-Select-Shift-Msg");

  const selectshift = bundle.formatMessage("Checklist-AppointmentCard-ButtonText9");
  const contactus = bundle.formatMessage("Checklist-AppointmentCard-ButtonText4");

  return (
    <StatusContainer
      data-testid={"page-header-shift-unavailable"}
      largeViewPortImage={require("../../assets/svg/cross.svg").default}
      smallViewPortImage={require("../../assets/svg/cross.svg").default}
    >
      <H1 fontSize={"T400"} fontWeight={"regular"} id="main-content">
        {errorMsg}
      </H1>

      <Row gridGap={"1rem"}>
        <Text fontSize={"T200"} textAlign="center">
          {selectshiftMsg}
        </Text>
      </Row>
      <Row gridGap={"1rem"}>
        <Button role="link" tabIndex={0} size={ButtonSize.Small} onClick={navigateContactUrl}>
          {contactus}
        </Button>
        <Button
          role="link"
          tabIndex={0}
          variant={ButtonVariant.Primary}
          size={ButtonSize.Small}
          onClick={useNavigatorWithURLReady(cssURLWithoutScheduleId)}
        >
          {selectshift}
        </Button>
      </Row>
    </StatusContainer>
  );
};
