import React, { useState, useEffect, Fragment } from "react";
import { H2, Text } from "../../../../../components/typography";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { UploadCard } from "../upload-card";
import { ChecklistBundle, useChecklistArb } from "../../../../../hooks/use-arb";
import {
  AttachmentSubType,
  AttachmentType,
  isOptional,
  TEMPORARY_CONSULAR_RESIDENCE_VISA_OFFICIAL_APPROVAL_INM_FMM,
  TEMPORARY_RESIDENCE_STUDENT_CARD_INM_WORK_AUTHORIZATION,
  UploadDetails,
} from "../../models";
import { submitDocument } from "../../requests/requests";
import { useParams } from "react-router-dom";
import { deleteCachedRequest } from "../../../../../utility/candidate-data";
import { MessageBanner, MessageBannerType } from "@amzn/stencil-react-components/message-banner";
import {
  useGetPresignedURLToCDSMutation,
  useSubmitDocToCDSFromPresignedURLMutation,
  useUpdateCDSDocumentUploadMetadataMutation,
} from "../../../../../reduxStore/api/apiSlice";
import { dispatchOnDev } from "../../../../../utility/dev-env-helpers";
import { useBackToMainPage } from "../../helpers/useBackToMainPage.hook";
import { TEMPORARY_SUBMIT_BUTTON_DISABLED_SOLUTION_SELECTOR } from "../../../../../utility/constants/common";
import { useTemporarySubmitDisabledSolution } from "../../helpers/useTemporarySubmitDisabledSolution.hook";
import { useGetCandidateData } from "../../../../../hooks/apis/useGetCandidateData";
import { DebounceButton } from "../../../../../components/common/DebounceButton";

type UploadFileErrorMessage = Record<AttachmentType, AttachmentSubType[]>;

interface TemplateUploadLayoutParams {
  documentType: string;
}

interface HandlerParams {
  documentType: string;
  bundle: ChecklistBundle;
}

interface DocListReturn {
  attachmentType: AttachmentType;
  attachmentSubType: AttachmentSubType[];
  title: string;
  attachmentTypeLabel: string;
}

// document upload object
interface DocUpload {
  attachmentType: AttachmentType;
  attachmentSubType: AttachmentSubType;
  content: UploadDetails | undefined;
  setContent: React.Dispatch<React.SetStateAction<UploadDetails | undefined>>;
  errorMessage: string | undefined;
  setErrorMessage: React.Dispatch<React.SetStateAction<string | undefined>>;
  updateDocumentFn: any;
}
/**
 * This function is used for upload following document
 *
 * Temporary Consular Residence Visa + Official Approval by INM + FMM (Multi-Purpose Immigration Form) (front + back optional of each)
 * Temporary Resident Student Card + INM Work Authorization. (front and back for Temporary Resident Student Card, front and back optional for INM Work Authorization)
 */
export function TemplateUploadLayout({ documentType }: TemplateUploadLayoutParams) {
  const { requisitionId, applicationId } = useParams<{
    requisitionId: string;
    applicationId: string;
  }>();
  const candidateData = useGetCandidateData();
  const [getPresignedURL] = useGetPresignedURLToCDSMutation();
  const [submitDocToCDS] = useSubmitDocToCDSFromPresignedURLMutation();
  const [updateDocMetadataToCDS] = useUpdateCDSDocumentUploadMetadataMutation();

  const bundle = useChecklistArb();
  const titleMessage = titleMessageHandler({ documentType, bundle });
  const docList: Array<DocListReturn> = docListHandler({ documentType, bundle });

  const submitMessage = bundle.getMessage("Checklist-Document-Image-Submit-ButtonText");
  const documentAcceptableFileMessage = bundle.getMessage("Checklist-Document-Acceptable-File-Text");
  const maxSizeMessage = bundle.getMessage("Checklist-Document-Maximum-File-Size-Text");
  const p0LimitFixMessage = bundle.getMessage("p0LimitFixMessage");
  const documentUploadDependencyServiceErrorMessage = bundle.getMessage("Checklist-Document-General-Error-Message");
  const frontErrorMessageBanner = bundle.getMessage("Checklist-Document-General-Error-Front-Message");
  const backErrorMessageBanner = bundle.getMessage("Checklist-Document-General-Error-Back-Message");

  const docUploadList: DocUpload[] = [];
  const docUploadMap = new Map<AttachmentType, Map<AttachmentSubType, DocUpload>>(); // map for fast retrieval
  for (const doc of docList) {
    const docMap = new Map();
    for (const attachmentSubType of doc.attachmentSubType) {
      const [document, setDocument] = useState<UploadDetails>();
      const [errorMessage, setErrorMessage] = useState<string>();

      const updateDocumentFn = async (
        image: string,
        fileName: string,
        contentType: string,
        side: string,
        applicationId?: string
      ) => {
        if (candidateData) {
          const documentData = {
            documentType: doc.attachmentType,
            attachmentSubType: side,
            imageBase64: image,
            fileName: fileName,
            contentType: contentType,
            candidateId: candidateData?.candidateId,
          };
          setDocument(documentData);
        } else {
          dispatchOnDev(() => {
            console.error("candidateData is not ready yet");
          });
        }
      };
      const upload = {
        attachmentType: doc.attachmentType,
        attachmentSubType: attachmentSubType,
        content: document,
        setContent: setDocument,
        updateDocumentFn: updateDocumentFn,
        errorMessage: errorMessage,
        setErrorMessage: setErrorMessage,
      };
      docUploadList.push(upload);
      docMap.set(attachmentSubType, upload);
    }
    docUploadMap.set(doc.attachmentType, docMap);
  }

  const [dependencyServiceErrorMessage, setDependencyServiceErrorMessage] = useState<string>();
  const [blockSubmitButton, setBlockSubmitButton] = useState<boolean>(true);
  const [errorMessages, setErrorMessage] = useState<UploadFileErrorMessage>({} as UploadFileErrorMessage);

  const backToMainPage = useBackToMainPage();

  // Set to false to override the submit behavior
  useTemporarySubmitDisabledSolution(false);

  useEffect(() => {
    // Back side may be optional but should disable when back is choosen and error message present
    let blocked = false;
    for (const i in docUploadList) {
      if (
        (!isOptional(docUploadList[i].attachmentType, docUploadList[i].attachmentSubType) &&
          !docUploadList[i].content?.imageBase64) ||
        (isOptional(docUploadList[i].attachmentType, docUploadList[i].attachmentSubType) &&
          !!docUploadList[i].errorMessage)
      ) {
        blocked = true;
      }
    }
    setBlockSubmitButton(blocked);
  }, [docUploadList]);

  const clickEventSubmitDocument = async () => {
    const docs: UploadDetails[] = [];
    let errorMessages = {} as UploadFileErrorMessage;
    let hasError = false;

    docUploadList.forEach((docUpload, index) => {
      if (docUpload.content && docUpload.content.imageBase64) {
        const newDoc = {
          ...docUpload.content,
        };
        docs.push(newDoc);
      } else {
        const isBackAttachmentOptional =
          docUpload.attachmentSubType === AttachmentSubType.BACK &&
          isOptional(docUpload.attachmentType, AttachmentSubType.BACK);

        if (!isBackAttachmentOptional) {
          errorMessages = {
            ...errorMessages,
            [docUpload.attachmentType]: errorMessages[docUpload.attachmentType]
              ? [...errorMessages[docUpload.attachmentType], docUpload.attachmentSubType]
              : [docUpload.attachmentSubType],
          };
        }

        // blur the submit button if there is any error and refocusing to the error file upload
        if (!hasError) {
          document.getElementById("doc-upload-proxy-button")?.blur();
          const input = document.getElementById(
            `upload-${
              docUpload.attachmentSubType === AttachmentSubType.FRONT ? "front" : "back"
            }-${docUpload.attachmentType.replace(/\s/g, "")}`
          );

          input?.focus();

          hasError = true;
        }
      }
    });

    // Set the error if there is any empty field and disable the submit.
    if (Object.keys(errorMessages).length) {
      setErrorMessage(errorMessages);
      return;
    }

    let returnToMainPage = true;

    try {
      await submitDocument(
        getPresignedURL,
        submitDocToCDS,
        updateDocMetadataToCDS,
        applicationId,
        docs,
        setBlockSubmitButton
      ); // replace bbid
    } catch (err) {
      setBlockSubmitButton(false);
      console.log(err);
      setDependencyServiceErrorMessage(documentUploadDependencyServiceErrorMessage);
      returnToMainPage = false;
    }
    deleteCachedRequest(applicationId); // delete stale data so that updated Id loads on home page
    if (returnToMainPage) backToMainPage();
  };
  return (
    <Col display={"flex"} justifyContent={"center"} gridGap={6} width={"100%"}>
      <H2>{titleMessage}</H2>
      <Text>{documentAcceptableFileMessage}</Text>
      <Text>{maxSizeMessage}</Text>
      {docList.map((doc, index) => {
        const hasErrorFront =
          errorMessages[doc.attachmentType] && errorMessages[doc.attachmentType].includes(AttachmentSubType.FRONT);
        const hasErrorBack =
          errorMessages[doc.attachmentType] && errorMessages[doc.attachmentType].includes(AttachmentSubType.BACK);

        if (doc.attachmentSubType.length == 2) {
          return (
            <Fragment key={`upload-files-${index}`}>
              <Text>{doc.title}</Text>
              <Row padding={{ top: "10px", bottom: "10px" }} gridGap="S400">
                {/* first upload is front, required */}
                <UploadCard
                  side={doc.attachmentSubType[0]}
                  documentDetailsFn={
                    docUploadMap.get(doc.attachmentType)?.get(doc.attachmentSubType[0])?.updateDocumentFn
                  }
                  setErrorMessage={docUploadMap.get(doc.attachmentType)?.get(doc.attachmentSubType[0])?.setErrorMessage}
                  applicationId={applicationId}
                  setDependencyErrorMessage={setDependencyServiceErrorMessage}
                  attachmentTypeName={titleMessage}
                  customId={`upload-front-${doc.attachmentType.replace(/\s/g, "")}`}
                  hasError={hasErrorFront}
                />
                {/* second upload is back, requried or optional */}
                <UploadCard
                  side={doc.attachmentSubType[1]}
                  optional={isOptional(doc.attachmentType, doc.attachmentSubType[1])}
                  documentDetailsFn={
                    docUploadMap.get(doc.attachmentType)?.get(doc.attachmentSubType[1])?.updateDocumentFn
                  }
                  setErrorMessage={docUploadMap.get(doc.attachmentType)?.get(doc.attachmentSubType[1])?.setErrorMessage}
                  applicationId={applicationId}
                  setDependencyErrorMessage={setDependencyServiceErrorMessage}
                  attachmentTypeName={titleMessage}
                  customId={`upload-back-${doc.attachmentType.replace(/\s/g, "")}`}
                  hasError={hasErrorBack}
                />
              </Row>

              {hasErrorFront && (
                <MessageBanner type={MessageBannerType.Error}>
                  <Text
                    id={`upload-front-${index}-description`}
                  >{`${frontErrorMessageBanner}: ${doc.attachmentTypeLabel}`}</Text>
                </MessageBanner>
              )}
              {hasErrorBack && (
                <MessageBanner type={MessageBannerType.Error}>
                  <Text
                    id={`upload-back-${index}-description`}
                  >{`${backErrorMessageBanner}: ${doc.attachmentTypeLabel}`}</Text>
                </MessageBanner>
              )}

              {docUploadMap.get(doc.attachmentType)?.get(doc.attachmentSubType[0])?.errorMessage ||
              docUploadMap.get(doc.attachmentType)?.get(doc.attachmentSubType[1])?.errorMessage ||
              dependencyServiceErrorMessage ? (
                <MessageBanner type={MessageBannerType.Error}>
                  {dependencyServiceErrorMessage ??
                    docUploadMap.get(doc.attachmentType)?.get(doc.attachmentSubType[0])?.errorMessage ??
                    docUploadMap.get(doc.attachmentType)?.get(doc.attachmentSubType[1])?.errorMessage}
                </MessageBanner>
              ) : null}
            </Fragment>
          );
        } else if (doc.attachmentSubType.length == 1) {
          return (
            <>
              <Text>{doc.title}</Text>
              <Row alignSelf={"center"} padding={{ top: "10px", bottom: "10px" }} gridGap="S400">
                <UploadCard
                  side={doc.attachmentSubType[0]}
                  documentDetailsFn={
                    docUploadMap.get(doc.attachmentType)?.get(doc.attachmentSubType[0])?.updateDocumentFn
                  }
                  setErrorMessage={docUploadMap.get(doc.attachmentType)?.get(doc.attachmentSubType[0])?.setErrorMessage}
                  applicationId={applicationId}
                  setDependencyErrorMessage={setDependencyServiceErrorMessage}
                  attachmentTypeName={titleMessage}
                  customId={`upload-front-${doc.attachmentType.replace(/\s/g, "")}`}
                />
              </Row>
              {docUploadMap.get(doc.attachmentType)?.get(doc.attachmentSubType[0])?.errorMessage ?? (
                <MessageBanner type={MessageBannerType.Error}>
                  <Text id={`upload-front-${doc.attachmentType.replace(/\s/g, "")}-description`}>
                    {docUploadMap.get(doc.attachmentType)?.get(doc.attachmentSubType[0])?.errorMessage}
                  </Text>
                </MessageBanner>
              )}
              {dependencyServiceErrorMessage ? (
                <MessageBanner type={MessageBannerType.Error}>
                  {docUploadMap.get(doc.attachmentType)?.get(doc.attachmentSubType[0])?.errorMessage}
                </MessageBanner>
              ) : null}
            </>
          );
        }
      })}

      <Row padding={{ top: "10px", bottom: "10px" }}>
        <MessageBanner type={MessageBannerType.Informational}>{p0LimitFixMessage}</MessageBanner>
      </Row>
      <DebounceButton
        onClick={clickEventSubmitDocument}
        id={TEMPORARY_SUBMIT_BUTTON_DISABLED_SOLUTION_SELECTOR}
        style={{ visibility: "hidden" }}
      >
        {submitMessage}
      </DebounceButton>
    </Col>
  );
}

const titleMessageHandler = ({ documentType, bundle }: HandlerParams) => {
  if (documentType === TEMPORARY_CONSULAR_RESIDENCE_VISA_OFFICIAL_APPROVAL_INM_FMM) {
    return bundle.getMessage("Checklist-Document-Temporary-Consular-Residence-Visa-INM-FMM-Title");
  } else if (documentType === TEMPORARY_RESIDENCE_STUDENT_CARD_INM_WORK_AUTHORIZATION) {
    return bundle.getMessage("Checklist-Document-Temporary-Resident-Student-Card-INM-Work-Authorization-Title");
  }

  return "";
};

const docListHandler = ({ documentType, bundle }: HandlerParams): Array<DocListReturn> => {
  if (documentType === TEMPORARY_CONSULAR_RESIDENCE_VISA_OFFICIAL_APPROVAL_INM_FMM) {
    return [
      {
        attachmentType: AttachmentType.TEMPORARY_CONSULAR_RESIDENCE_VISA,
        title: bundle.getMessage("Checklist-Document-Temporary-Consular-Residence-Visa-INM-FMM-Subtitle1"),
        attachmentSubType: [AttachmentSubType.FRONT, AttachmentSubType.BACK],
        attachmentTypeLabel: bundle.getMessage("Checklist-Document-Temporary-Consular-Residence-Visa"),
      },
      {
        attachmentType: AttachmentType.INM,
        title: bundle.getMessage("Checklist-Document-Temporary-Consular-Residence-Visa-INM-FMM-Subtitle2"),
        attachmentSubType: [AttachmentSubType.FRONT, AttachmentSubType.BACK],
        attachmentTypeLabel: bundle.getMessage("Checklist-Document-INM-Title"),
      },
      {
        attachmentType: AttachmentType.FMM,
        title: bundle.getMessage("Checklist-Document-Temporary-Consular-Residence-Visa-INM-FMM-Subtitle3"),
        attachmentSubType: [AttachmentSubType.FRONT, AttachmentSubType.BACK],
        attachmentTypeLabel: bundle.getMessage("Checklist-Document-FMM"),
      },
    ];
  } else if (documentType === TEMPORARY_RESIDENCE_STUDENT_CARD_INM_WORK_AUTHORIZATION) {
    return [
      {
        attachmentType: AttachmentType.TEMPORARY_RESIDENCE_STUDENT_CARD,
        title: bundle.getMessage("Checklist-Document-Temporary-Resident-Student-Card-INM-Work-Authorization-Subtitle1"),
        attachmentSubType: [AttachmentSubType.FRONT, AttachmentSubType.BACK],
        attachmentTypeLabel: bundle.getMessage("Checklist-Document-Temporary-Resident-Student-Card"),
      },
      {
        attachmentType: AttachmentType.INM_WORK_AUTHORIZATION,
        title: bundle.getMessage("Checklist-Document-Temporary-Resident-Student-Card-INM-Work-Authorization-Subtitle2"),
        attachmentSubType: [AttachmentSubType.FRONT, AttachmentSubType.BACK],
        attachmentTypeLabel: bundle.getMessage("Checklist-Document-INM-Work-Authorization"),
      },
    ];
  }

  return [];
};
