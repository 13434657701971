/* eslint-disable import/no-unused-modules */
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  EDM_URL_UPDATE,
  HIRED_SF_STEP_SUBSTEP,
  ONBASE_APPLICATION_STATUS_TO_CHECK,
  ONBASE_APPLICATION_STATUS_TO_CHECK_FOR_CA,
  SUPPORTED_COUNTRY,
} from "../../config/appConfigConstants";
import { isSalesforceApplication } from "../../helpers/url-params";
import { useLazyGetDocumentStatusInfoQuery } from "../../reduxStore/api/apiSlice";
import { useAppSelector } from "../../reduxStore/reduxHooks";
import { getConfigData, getFeatureFlag, setConfigData, setFeatureFlag } from "../../reduxStore/slices/configSlice";
import { isTaskEnabledByAppConfig } from "../../utility/app-config-helper";
import { APPLICATION_STATE, SF_APP_STATE } from "../../utility/constants/application-status";
import { isDevEnv } from "../../utility/dev-env-helpers";
import { CONFIG_DATA_KEY, CONFIG_FEATURE_FLAG } from "../../utility/enums/common";
import { useGetApplicationId } from "./useGetApplicationId";
import { useGetApplicationManageData } from "./useGetApplicationManageData";
import { useGetCandidateData } from "./useGetCandidateData";
import { getCountryForDomain, getLocale } from "../../utility/locale-helper";

export const useGetOnbaseDocumentStatus = () => {
  const { onbaseData } = useAppSelector(getConfigData);
  const { isEdmLoginEnabled } = useAppSelector(getFeatureFlag);
  const dispatch = useDispatch();
  const applicationId = useGetApplicationId();
  const isEdmLoginEnabledByAppConfig = isTaskEnabledByAppConfig(EDM_URL_UPDATE, applicationId);

  const candidateData = useGetCandidateData();
  const applicationManageData = useGetApplicationManageData();
  const [getOnbaseDocumentStatus] = useLazyGetDocumentStatusInfoQuery();
  const country = getCountryForDomain();
  const locale = getLocale();

  const onbaseApplicationStatesForCanada =
    ONBASE_APPLICATION_STATUS_TO_CHECK_FOR_CA.includes(applicationManageData?.applicationState as APPLICATION_STATE) &&
    candidateData;

  const onbaseApplicationStatesForAllOtherCountries =
    ONBASE_APPLICATION_STATUS_TO_CHECK.includes(applicationManageData?.applicationState as APPLICATION_STATE) &&
    candidateData;

  const skipCallForOnbase = isSalesforceApplication(applicationId)
    ? !(HIRED_SF_STEP_SUBSTEP.includes(applicationManageData?.applicationStepSubStep as SF_APP_STATE) && candidateData)
    : !(country == SUPPORTED_COUNTRY.CA
        ? onbaseApplicationStatesForCanada
        : onbaseApplicationStatesForAllOtherCountries);

  useEffect(() => {
    if (onbaseData) return;

    if (applicationId && candidateData) {
      if (!skipCallForOnbase) {
        getOnbaseDocumentStatus(
          {
            candidateGlobalId: candidateData?.candidateGlobalId,
            applicationId,
            locale: locale,
            edmLoginEnabled: isEdmLoginEnabledByAppConfig,
          },
          true
        )
          .unwrap()
          .then((data) => {
            dispatch(
              setConfigData({
                key: CONFIG_DATA_KEY.ONBASE_DATA,
                value: data,
              })
            );
          });
      } else {
        if (isDevEnv()) console.warn("Skipping useGetOnbaseDocumentStatus hook");
      }

      if (isEdmLoginEnabledByAppConfig) {
        dispatch(
          setFeatureFlag({
            key: CONFIG_FEATURE_FLAG.IS_EDM_LOGIN_ENABLED,
            value: isEdmLoginEnabledByAppConfig,
          })
        );
      }
    }
  }, [
    isEdmLoginEnabledByAppConfig,
    locale,
    applicationId,
    dispatch,
    getOnbaseDocumentStatus,
    onbaseData,
    skipCallForOnbase,
    candidateData?.candidateGlobalId,
    candidateData,
  ]);

  return { onbaseData, isEdmLoginEnabled };
};
