export interface idInfo {
  nationalIdType: string;
  idNumber: string;
}

interface employmentInfo {
  employeeId?: string;
}
interface additionalNationalIdsInfo {
  [keys: string]: idInfo;
}

export interface CandidateData {
  candidateId: string;
  sfCandidateId: string;
  candidateGlobalId: string;
  additionalNationalIds: additionalNationalIdsInfo;
  primaryNationalId: idInfo;
  employmentInfo?: employmentInfo;
}

const cachedRequests: Record<string, Promise<CandidateData>> = {};

export const deleteCachedRequest = (applicationId: string) => {
  delete cachedRequests[applicationId];
};
