import { useEffect } from "react";
import { useLazyGetCaasRTWStatusQuery } from "../../reduxStore/api/apiSlice";
import { useGetCandidateData } from "./useGetCandidateData";
import { RightToWorkResponse } from "../../utility/application-data";
import { getCountryForDomain } from "../../utility/locale-helper";
import { isCustomerService, isTaskEnabledByAppConfig } from "../../utility/app-config-helper";
import { useGetApplicationId } from "./useGetApplicationId";
import { FeatFlag, SUPPORTED_COUNTRY } from "../../config/appConfigConstants";
import { isDevEnv } from "../../utility/dev-env-helpers";
import { useAppDispatch, useAppSelector } from "../../reduxStore/reduxHooks";
import { getConfigData, getFeatureFlag, setConfigData } from "../../reduxStore/slices/configSlice";
import { getRtwAppointmentInfo } from "../../helpers/configHelper";
import { CONFIG_DATA_KEY } from "../../utility/enums/common";
import { useGetAllContigenciesData } from "./useGetAllContigenciesData";

export const useGetCaasRTWStatus = () => {
  const dispatch = useAppDispatch();
  const allContigencies = useGetAllContigenciesData();
  const { isAshConfigLoaded } = useAppSelector(getFeatureFlag);
  const { caasRTWStatus } = useAppSelector(getConfigData);
  const applicationId = useGetApplicationId();
  const candidateData = useGetCandidateData();
  const [getCaasRTWStatus] = useLazyGetCaasRTWStatusQuery();

  useEffect(() => {
    if (candidateData && applicationId && isAshConfigLoaded) {
      const isSkipped = isCustomerService(applicationId) || getCountryForDomain() !== SUPPORTED_COUNTRY.GB;
      const country = getCountryForDomain();
      if (
        isTaskEnabledByAppConfig(FeatFlag.ENABLE_ALL_CONTINGENCIES_RTW, applicationId) &&
        !(isCustomerService(applicationId) || country !== SUPPORTED_COUNTRY.GB)
      ) {
        if (allContigencies && allContigencies !== "SKIPPED" && allContigencies.RightToWork) {
          dispatch(
            setConfigData({
              key: CONFIG_DATA_KEY.RTW_APPOINTMENT_INFO,
              value: getRtwAppointmentInfo(country, allContigencies.RightToWork as RightToWorkResponse),
            })
          );
        }
      }
      if (!isSkipped) {
        getCaasRTWStatus(candidateData.candidateId, true)
          .unwrap()
          .then((data) => {
            const flagEnabled = isTaskEnabledByAppConfig(FeatFlag.ENABLE_ALL_CONTINGENCIES_RTW, applicationId);
            if (
              isTaskEnabledByAppConfig(FeatFlag.ENABLE_ALL_CONTINGENCIES_RTW, applicationId) &&
              allContigencies !== "SKIPPED" &&
              allContigencies?.RightToWork
            ) {
              dispatch(
                setConfigData({
                  key: CONFIG_DATA_KEY.CAAS_RTW_STATUS,
                  value: allContigencies.RightToWork,
                })
              );
            } else if (!flagEnabled && data) {
              dispatch(
                setConfigData({
                  key: CONFIG_DATA_KEY.RTW_APPOINTMENT_INFO,
                  value: getRtwAppointmentInfo(country, data),
                })
              );
              dispatch(
                setConfigData({
                  key: CONFIG_DATA_KEY.CAAS_RTW_STATUS,
                  value: data,
                })
              );
            }
          });
      } else {
        if (isDevEnv()) console.warn("Skipping useGetCaasRTWStatus hook");
        dispatch(
          setConfigData({
            key: CONFIG_DATA_KEY.CAAS_RTW_STATUS,
            value: "SKIPPED",
          })
        );
      }
    }
  }, [allContigencies, applicationId, caasRTWStatus, candidateData, dispatch, getCaasRTWStatus, isAshConfigLoaded]);

  return caasRTWStatus;
};
