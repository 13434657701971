import { StatusEnum } from "./enums/common";
import { CAAS_STATUS } from "../components/i9-task/i9-data";
import { ErrorInfo } from "./types/common";

enum RightToWorkField {
  CANDIDTE_ID = "candidateId",
  ORDER_ID = "orderId",
  DGS_CANDIDATE_ID = "dgsCandidateId",
  CONTINGENCY_TYPE = "contingencyType",
  EXPIRATION_DATE = "expirationDate",
  STATUS_DATE = "statusDate",
  STATUS = "status",
  STATUS_REASON = "statusReason",
  INITIATION_TIME_STAMP = "initiationTimeStamp",
  INITIATION_APPLICATION_ID = "initiatedApplicationId",
  RTW_AUDIT_STATUS = "rtwAuditStatus",
  SECURE_URL = "secureUrl",
  ORDER_RESULT_DATE_TIME = "orderResultDateTime",
}

type RightToWork = {
  [key: string]: any;
} & {
  [RightToWorkField.STATUS]: string;
};

export interface RightToWorkResponse {
  status: StatusEnum | CAAS_STATUS;
  rightToWork: RightToWork;
}

export interface SdaInfo {
  errorCode?: number;
  startDate: string;
  startTime: string;
  publicText: string;
  address: string;
  startDateStatus: string;
  timezone: string;
}

export interface JobDetailsModel {
  error?: ErrorInfo;
  scheduleId?: string;
  geoClusterId?: string;
  externalJobTitle: string;
  scheduleBannerText?: string;
  scheduleText?: string;
  firstDayOnSite?: string;
  startTime?: string;
  briefJobDescription: string;
  basePay?: number;
  address: string;
  city: string;
  state: string;
  postalCode: string;
  image: string;
  shiftDifferential?: number;
  signOnBonus?: number;
  surgePay?: number;
  hoursPerWeek?: number;
  businessLine?: string;
  totalPayRate?: number;
  hoursPerWeekL10N: string;
  firstDayOnSiteL10N: string;
  startTimeL10N: string;
  basePayL10N?: string;
  signOnBonusL10N?: string;
  surgePayL10N?: string;
  totalPayRateL10N: string;
  requiredLanguage: Array<string>;
  jobDescriptionRecordLanguages: Array<string>;
  requiredLanguageFromSalesForce?: string;
  employmentType: string;
  employmentTypeL10N?: string;
  parsedTrainingDate?: string;
  monthlyBasePay?: number;
  monthlyBasePayL10N?: string;
  biweeklyBasePayL10N?: string;
  yearlyBasePayL10N?: string;
  monthlyLanguagePay?: number;
  monthlyLanguagePayL10N?: string;
  monthlyPayRate?: number;
  monthlyPayRateL10N?: string;
  currencyCode?: string;
  siteId?: string;
  countryGroup: string;
  countryCode?: string;
  jobId: string;
  requisitionCategory?: string;
  isScheduleReleased?: boolean;
  startDateStatus?: string;
  publicText?: string;
  timezone?: string;
  fullAddress?: string;
  jobContainerJobMeta?: string;
  isShiftReleased?: boolean;
  siteJobPreviewVideoLink?: string;
}
export interface ApplicationManageData {
  error?: ErrorInfo;
  enableCSS: boolean;
  hideWithdrawApplication: boolean;
  applicationState: string | null;
  applicationStepSubStep?: string;
  scheduleId?: string;
  isAlumniApplication: boolean;
  skipAppointmentOne: SkipAppointmentOneEligibility;
}

export interface VideoData {
  uri: string;
}

// https://code.amazon.com/packages/HVHApplicationDomainInterface/blobs/a78843aec95d779e23c7f44a63b42801346456e1/--/src/com/amazon/hvhapplicationdomain/model/common/Application.java#L283
export enum SkipAppointmentOneEligibility {
  ATTEND_BY_ELIGIBILITY_CHECK = "ATTEND_BY_ELIGIBILITY_CHECK",
  ATTEND_BY_USER_SELECTION = "ATTEND_BY_USER_SELECTION",
  SKIP_BY_ELIGIBILITY_CHECK = "SKIP_BY_ELIGIBILITY_CHECK",
  SKIP_BY_USER_SELECTION = "SKIP_BY_USER_SELECTION",
}

export interface DocumentStatus {
  results: [
    {
      error: string;
      candidateId: string;
      documentsAvailable: boolean;
      documentCompletionStatus: string;
      onbaseLoginUrl: string;
    }
  ];
  errorCode: string;
  errorMessage: string;
}

export interface AtoZStatus {
  aToZLoginUrl: string;
  employeeLogin: string;
  employeeId: string;
  personId: string;
  isPasswordSet: boolean;
  isAccountReady: boolean;
  errorCode: number;
  errorMessage: string;
  siteName: string;
  day1Date: string;
}
