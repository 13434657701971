import React, { useEffect } from "react";
import Dropzone, { ILayoutProps } from "react-dropzone-uploader";
import { useChecklistArb } from "../../../../hooks/use-arb";
import { Card } from "@amzn/stencil-react-components/card";
import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { AttachmentSubType, AttachmentType } from "../models";
import { IconCheckCircleFill } from "@amzn/stencil-react-components/icons";
import { Text } from "../../../../components/typography";
import { Col } from "@amzn/stencil-react-components/layout";
import { dispatchOnDev } from "../../../../utility/dev-env-helpers";
import { HVH_COLORS } from "../../../../config/palette";

const ALLOWED_FILE_TYPES = ["application/pdf", "image/png", "image/jpeg", "image/jpg"];
interface Props {
  side: string;
  // Old CURP ids may not have a back side, so it is optional
  optional?: boolean;
  documentDetailsFn: any;
  setErrorMessage: any;
  setDependencyErrorMessage?: any;
  applicationId: string;
  attachmentTypeName?: string;
  customId?: string;
  hasError?: boolean;
}

const Layout = ({ input, previews, dropzoneProps, files, extra: { maxFiles } }: ILayoutProps) => {
  const bundle = useChecklistArb();
  const replaceButtonText = bundle.getMessage("Checklist-Document-Image-Replace-ButtonText");
  const uploadCompleteText = bundle.getMessage("Checklist-MainPage-Document-Upload-Complete-Text");
  const removeFile = () => {
    files[0].remove();
  };
  return (
    <div
      style={{
        height: files.length ? "160px" : "160px",
        width: "100%",
      }}
    >
      {files.length ? (
        <div
          style={{
            height: "160px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Col
            style={{
              height: "160px",
              width: "100%",
              overflow: "hidden",
              justifyContent: "center",
            }}
          >
            {files[0].meta.type == "application/pdf" ? (
              <Col style={{ alignItems: "center" }}>
                <IconCheckCircleFill color="#23756B" style={{ paddingTop: "30px" }} />
                <Text fontWeight="bold">{uploadCompleteText}</Text>
              </Col>
            ) : (
              previews
            )}
          </Col>
          <Button variant={ButtonVariant.Tertiary} onClick={removeFile} style={{ alignSelf: "bottom", height: "10px" }}>
            {replaceButtonText}
          </Button>
        </div>
      ) : (
        <div {...dropzoneProps}>{files.length < maxFiles && input}</div>
      )}
    </div>
  );
};

export const UploadCard = ({
  side,
  optional,
  documentDetailsFn,
  setErrorMessage,
  setDependencyErrorMessage,
  applicationId,
  attachmentTypeName,
  customId,
  hasError,
}: Props) => {
  const bundle = useChecklistArb();
  const documentFrontTitleMessage = bundle.getMessage("Checklist-Document-Front-Title");
  const documentBackOptionTitleMessage = bundle.getMessage("Checklist-Document-Back-Optional-Title");
  const documentBackTitleMessage = bundle.getMessage("Checklist-Document-Back-Title");
  const documentUploadErrorText = bundle.getMessage("Checklist-Document-Upload-Error-Text");

  const dropZoneAttributes = {
    backgroundColor: hasError ? HVH_COLORS.RED_10 : "rgba(23, 104, 201, .3)",
    borderColor: hasError ? HVH_COLORS.RED_70 : "rgba(23, 104, 201, 1)",
    borderWidth: "2px",
    fontSize: "11px",
    borderRadius: "0px",
    minHeight: "0px",
    padding: "5px",
  };

  const inputAttributes = {
    color: "#000",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const previewAttributes = {
    backgroundColor: "",
    innerHeight: "50px !important",
    outerHeight: "50px !important",
    outerWidth: "30px",
    innerWidth: "30px",
  };

  const toDataURL = (src: any, fileType: string, height: number, width: number, callback: any) => {
    const image = new Image();
    image.onload = function () {
      const canvas = document.createElement("canvas");
      const context: any = canvas.getContext("2d");
      canvas.height = height;
      canvas.width = width;
      context.drawImage(this, 0, 0);
      const dataURL = canvas.toDataURL(fileType);
      callback(dataURL);
    };
    image.src = src;
  };

  const processPDF = (files: any, side: string, setState: any, applicationId: string) => {
    const pdf = files.file;
    const reader = new FileReader();
    reader.readAsDataURL(pdf);
    reader.onload = function (e) {
      const image = e.target?.result as string;
      if (image) {
        setState(
          image.replace(/^data:application\/[a-z]+;base64,/, ""),
          files.meta.name,
          files.meta.type,
          side,
          applicationId
        );
      }
    };
  };

  const processImage = (files: any, side: string, setState: any, applicationId: string) => {
    setTimeout(() => {
      toDataURL(files.meta.previewUrl, files.meta.type, files.meta.height, files.meta.width, (base64Image: string) =>
        setState(
          base64Image.replace(/^data:image\/[a-z]+;base64,/, ""),
          files.meta.name,
          files.meta.type,
          side,
          applicationId
        )
      );
    }, 100);
  };

  const handleUpdate = (files: any, side: string, setState: any, applicationId: string) => {
    if (files.meta.size / (1024 * 1024) < 7 && ALLOWED_FILE_TYPES.includes(files.meta.type)) {
      setErrorMessage(null);
      if (files.meta.type === "application/pdf") {
        processPDF(files, side, setState, applicationId);
      } else {
        processImage(files, side, setState, applicationId);
      }
    } else {
      setErrorMessage(documentUploadErrorText);
    }
  };

  if (optional && side === AttachmentSubType.FRONT) {
    dispatchOnDev(() => {
      console.error("Document front cannot be optional, overriding to required");
    });
    optional = false;
  }

  const handleFileRemoval = (files: any, side: string, setState: any) => {
    setErrorMessage(null);
    setDependencyErrorMessage(null);
    setState(null, null, null, side);
  };

  const handleStatusChange = (files: any, side: string, setState: any, status: any, applicationId: string) => {
    if (status == "done") {
      handleUpdate(files, side, setState, applicationId);
    } else if (status == "removed") {
      handleFileRemoval(files, side, setState);
    } else if (status == "rejected_file_type") {
      setErrorMessage(documentUploadErrorText);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.getElementById(`${customId}-id`)!.ariaInvalid = "true";
    }
  };

  const inputContentHandler = () => {
    const sideName =
      side === AttachmentSubType.FRONT
        ? documentFrontTitleMessage + " *"
        : !optional
        ? documentBackTitleMessage + " *"
        : documentBackOptionTitleMessage;

    return (
      <>
        {attachmentTypeName && <span className="screen-reader">{`${attachmentTypeName}, `}</span>}
        {sideName}
      </>
    );
  };

  const fileUploadKeyHandler = (event: any) => {
    if (event.key === " " || event.code === "Space" || event.code === "Enter") {
      event.preventDefault();
      // use custom class as selector then append id for selector
      document.getElementById(`${customId}-id`)?.click();
    }
  };

  // Setting the custom ID for error handling
  useEffect(() => {
    document.querySelectorAll(`#${customId} .dzu-input`)[0].id = `${customId}-id`;
    document.getElementById(`${customId}-id`)!.setAttribute("aria-describedby", `${customId}-description`);
    document.getElementById(`${customId}-id`)!.setAttribute("data-testid", `input-file-${customId}`);
  }, []);

  useEffect(() => {
    const fileInputWrapper = document.querySelectorAll(`#${customId} .dzu-input`)[0];

    // When the component re rendered with the placeholder file, it wont have .dzu-input element, therefore skip the checking since it is marked as true
    if (fileInputWrapper) {
      document.querySelectorAll(`#${customId} .dzu-input`)[0].id = `${customId}-id`;
      const fileInput = document.getElementById(`${customId}-id`);

      fileInput!.setAttribute("aria-invalid", hasError ? "true" : "false");

      if (!optional) fileInput!.setAttribute("aria-required", "true");
    }
  }, [hasError, optional]);

  return (
    <Card
      data-testid={`card-input-${customId}`}
      padding={"5px"}
      height={"auto"}
      width="100%"
      tabIndex={0}
      onKeyDown={(e) => fileUploadKeyHandler(e)}
      id={customId}
      className="upload-card"
    >
      <Dropzone
        accept="image/*,application/pdf"
        onChangeStatus={(files, status) => handleStatusChange(files, side, documentDetailsFn, status, applicationId)}
        maxFiles={1}
        inputContent={inputContentHandler()}
        styles={{
          inputLabel: inputAttributes,
          dropzone: dropZoneAttributes,
          preview: previewAttributes,
        }}
        LayoutComponent={Layout}
      />
    </Card>
  );
};
