import { Chip, ChipBackgroundColor } from "@amzn/stencil-react-components/chip";
import { ExpanderContent } from "@amzn/stencil-react-components/expander";
import {
  IconCalendarFill,
  IconChevronDown,
  IconChevronUp,
  IconClockFill,
  IconGlobe,
  IconHourGlass,
  IconLocationFill,
  IconPayment,
  IconPaymentFill,
  IconQuestionCircle,
  IconSize,
} from "@amzn/stencil-react-components/icons";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { Link } from "@amzn/stencil-react-components/link";
import { Text } from "@amzn/stencil-react-components/text";
import { withTooltip } from "@amzn/stencil-react-components/tooltip";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import IconDuration from "../../assets/svg/duration.svg";
import { EMPLOYMENT_TYPE } from "../../config/appConfigConstants";
import { HVH_COLORS } from "../../config/palette";
import {
  isEligibleToShowLanguageSupportedOnJobDetails,
  showEmploymentType,
  showStartDateTooltip,
} from "../../helpers/utils";
import { useChecklistArb } from "../../hooks/use-arb";
import { useAppSelector } from "../../reduxStore/reduxHooks";
import { getFeatureFlag } from "../../reduxStore/slices/configSlice";
import { isCustomerService } from "../../utility/app-config-helper";
import { getCountryForDomain, getLocale } from "../../utility/locale-helper";
import { GoogleMapLink } from "../google-map-link";
import "../sda/styles.scss";
import { ManageApplicationCardModal } from "./jobDetailsCardModal";
import { JobDetailsPay } from "./jobDetailsPay";
import "./styles.scss";
import { dateTimeTextFromLocale } from "../appointment-card/dateTimeFormatStringBasedOnLocale";

export interface CardDetail {
  errorCode?: number;
  jobTitle: string;
  hours: string;
  startDateUntranslated: string;
  startTimeUntranslated: string;
  startDate: string;
  startTime: string;
  description: string;
  address: string;
  image: string;
  isTentativeStartDate: boolean;
  signOnBonus?: number;
  signOnBonusL10N?: string;
  hourlyBasePayL10N: string;
  biweeklyBasePayL10N: string;
  monthlyBasePayL10N: string;
  yearlyBasePayL10N: string;
  languagePremiumL10N?: string;
  monthlyPayRateL10N?: string;
  scheduleBannerText: string;
  hoursPerWeek: string;
  employmentType: string;
  employmentTypeL10N?: string;
  parsedTrainingDate: string;
  currencyCode: string;
  supportedLanguage?: string;
  isVirtual?: boolean;
  showCurrencyCode: boolean;
  scheduleText?: string;
}
interface ManageApplicationCardHeaderProps {
  jobDetails: CardDetail;
  collapsed: boolean;
}

export const ManageApplicationCardHeader = ({ collapsed, jobDetails }: ManageApplicationCardHeaderProps) => {
  const { isEligibleForCss, isEligibleForWithdraw } = useAppSelector(getFeatureFlag);

  const { applicationId } = useParams<{ applicationId: string }>();

  const {
    startDateUntranslated,
    startTimeUntranslated,
    isTentativeStartDate,
    jobTitle,
    hours,
    startDate,
    description,
    address,
    showCurrencyCode,
    hourlyBasePayL10N,
    biweeklyBasePayL10N,
    monthlyBasePayL10N,
    yearlyBasePayL10N,
    monthlyPayRateL10N,
    languagePremiumL10N,
    signOnBonus,
    signOnBonusL10N,
    scheduleBannerText,
    hoursPerWeek,
    image,
    employmentType,
    employmentTypeL10N,
    isVirtual,
    supportedLanguage,
    parsedTrainingDate,
    currencyCode,
  } = jobDetails;

  const country = getCountryForDomain();

  const hourText = hours ? hours.split("\n") : [];
  const bundle = useChecklistArb();

  const hoursTranslated = bundle.getMessage("Checklist-JobCard-Hours");
  const weekTranslated = bundle.getMessage("Checklist-JobCard-Week");
  const shiftTranslated = bundle.getMessage("Checklist-JobCard-Shift");
  const durationTranslated = bundle.getMessage("Checklist-JobCard-Duration");
  const possibleStartDateTranslated = bundle.getMessage("Checklist-JobCard-PossibleStartDate");
  const startDateTranslated = bundle.getMessage("Checklist-JobCard-StartDate");
  const addressTranslated = bundle.getMessage("Checklist-JobCard-WorkAddress");
  const bonusTranslated = bundle.getMessage("Checklist-JobCard-Bonus");
  const employmentTypeTranslated = showEmploymentType({
    bundle,
    country,
    employmentType: employmentType as EMPLOYMENT_TYPE,
    employmentTypeL10N: employmentTypeL10N as string,
  });
  const languageSupported = bundle.getMessage("Checklist-JobCard-Language");
  const [isExpanded, setIsExpanded] = useState(!collapsed);

  const IconWithTooltip = withTooltip()(IconQuestionCircle);
  const tooltipText = showStartDateTooltip(bundle, country);
  const translatedDateTime = dateTimeTextFromLocale(getLocale(), startDateUntranslated, startTimeUntranslated, bundle);

  const startDateText = (
    <Row alignItems="start">
      <Text fontSize="T200" data-testid={`job-card-start-date-tentative-${isTentativeStartDate}-date-${startDate}`}>
        <strong>{isTentativeStartDate ? possibleStartDateTranslated : startDateTranslated}</strong>
        :&nbsp;{translatedDateTime}
      </Text>
      {isTentativeStartDate && (
        <IconWithTooltip title="tooltip" tooltipText={tooltipText} color={HVH_COLORS.PRIMARY_80} />
      )}
    </Row>
  );

  const requiredTrainingDateText = bundle.getMessage("Checklist-JobCard-RequiredTrainingDates");
  const trainingDates = parsedTrainingDate ? parsedTrainingDate.split("\n") : [];

  const expandableContent = (
    <ExpanderContent isExpanded={isExpanded}>
      <Col gridGap="1rem" padding="0 1rem 1rem 1rem">
        <ul style={{ lineHeight: "1rem" }}>
          {startDate && (
            <li>
              <Row gridGap="0.5rem" alignItems="start">
                <IconCalendarFill size={IconSize.Small} aria-hidden="true" />
                {startDateText}
              </Row>
            </li>
          )}

          {hours && (
            <li>
              <Row gridGap="0.5rem">
                <IconClockFill size={IconSize.Small} aria-hidden="true" />
                <Col gridGap="0.5rem">
                  <Row>
                    <Text fontSize="T200" data-testid="job-card-shift">
                      <strong>{shiftTranslated}</strong>:&nbsp;
                    </Text>
                    <Text fontSize="T200" data-testid={`job-card-shift-details-${jobDetails?.scheduleText}`}>
                      {hourText.map((text: string, index) => (
                        <Row key={text + index}>{text}</Row>
                      ))}
                    </Text>
                  </Row>

                  {parsedTrainingDate && (
                    <Row>
                      <Col>
                        <Text fontSize="T200">
                          <em>{requiredTrainingDateText}</em>
                        </Text>
                        <Text fontSize="T200">
                          {trainingDates.map((date: string, index) => (
                            <Row key={date + index}>{date}</Row>
                          ))}
                        </Text>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </li>
          )}
          <li>
            <Row gridGap="0.5rem">
              <Col>
                <IconPaymentFill size={IconSize.Small} aria-hidden="true" />
              </Col>
              <Col gridGap="0rem 0rem 0rem 0.5rem">
                <Row>
                  <JobDetailsPay
                    country={country}
                    currencyCode={currencyCode}
                    hourlyBasePayL10N={hourlyBasePayL10N}
                    biweeklyBasePayL10N={biweeklyBasePayL10N}
                    monthlyBasePayL10N={monthlyBasePayL10N}
                    yearlyBasePayL10N={yearlyBasePayL10N}
                    languagePremiumL10N={languagePremiumL10N}
                    monthlyPayRateL10N={monthlyPayRateL10N}
                  />
                </Row>
                {scheduleBannerText && (
                  <Row>
                    <Text fontSize="T200" id="schedule-card-hours" data-testid={`job-card-hours-${scheduleBannerText}`}>
                      <em>{scheduleBannerText}</em>
                    </Text>
                  </Row>
                )}
                {!!signOnBonus && (
                  <Row>
                    <Row
                      padding={scheduleBannerText ? "0.3rem 0.3rem 0.3rem 0.5rem" : "0rem 0.3rem 0rem 0.5rem"}
                      backgroundColor={HVH_COLORS.PRIMARY_05}
                      alignItems="center"
                    >
                      <Chip backgroundColor={ChipBackgroundColor.Blue} icon={<IconPayment aria-hidden={true} />}>
                        <Text fontSize="T200" data-testid={`job-card-bonus-${signOnBonus}`}>
                          {bonusTranslated}&nbsp;{showCurrencyCode ? currencyCode : ""}
                          {signOnBonusL10N}
                        </Text>
                      </Chip>
                    </Row>
                  </Row>
                )}
              </Col>
            </Row>
          </li>

          <li>
            <Row gridGap="0.5rem" alignItems="center">
              <IconHourGlass size={IconSize.Small} aria-hidden="true" />
              <Col>
                <Text fontSize="T200" data-testid={`job-card-hours-${hoursPerWeek}`}>
                  <strong>{hoursTranslated}</strong>:&nbsp;{hoursPerWeek}&nbsp;
                  {hoursTranslated.toLowerCase()}/{weekTranslated}
                </Text>
              </Col>
            </Row>
          </li>

          {employmentTypeL10N && (
            <li>
              <Row gridGap="0.5rem" alignItems="center">
                <img src={IconDuration} />
                <Col>
                  <Text fontSize="T200" data-testid={`job-card-employement-type-${employmentTypeL10N}`}>
                    <strong>{durationTranslated}</strong>:&nbsp;
                    {employmentTypeTranslated}
                  </Text>
                </Col>
              </Row>
            </li>
          )}

          <li>
            <Row gridGap="0.5rem" alignItems="start">
              <IconLocationFill size={IconSize.Small} aria-hidden="true" />
              <Col>
                <Text fontSize="T200" data-testid={`job-card-location-${jobDetails?.address}`}>
                  <strong>{addressTranslated}</strong>:&nbsp;
                  <GoogleMapLink isVirtual={isVirtual} address={address} />
                </Text>
              </Col>
            </Row>
          </li>

          {isEligibleToShowLanguageSupportedOnJobDetails({
            supportedLanguage,
            country,
            isCustomerService: isCustomerService(applicationId),
          }) && (
            <li>
              <Row gridGap="0.5rem" alignItems="start">
                <IconGlobe size={IconSize.Small} aria-hidden="true" />
                <Col>
                  <Text fontSize="T200" data-testid={`job-card-supported-language-${jobDetails?.supportedLanguage}`}>
                    <strong>{languageSupported}</strong>:&nbsp;
                    {supportedLanguage}
                  </Text>
                </Col>
              </Row>
            </li>
          )}
        </ul>
      </Col>
    </ExpanderContent>
  );

  const content = (
    <Col backgroundColor={HVH_COLORS.NEUTRAL_0} padding="0rem">
      {
        <Col width="100%" padding="0rem" alignItems={collapsed ? "center" : "start"} data-testid="job-card">
          <Row alignItems="center" gridGap={"2rem"}>
            <Row alignItems="center">
              {!collapsed && (
                <Col padding="1rem 0 1rem 1rem" alignItems="center">
                  <img alt="" src={image} width="70px" data-testid="job-card-img" />
                </Col>
              )}
              <Col padding="1rem 1rem 1rem 1rem" gridGap="0.2rem">
                <Text fontSize="T200" fontWeight="bold" id="schedule-card-jobTitle" data-testid="job-card-title">
                  {jobTitle}
                  {collapsed && (
                    <Link className="trigger-link" onClick={() => setIsExpanded(!isExpanded)}>
                      <Row alignItems="center">
                        {bundle.getMessage("Checklist-JobCard-ViewJobDetails")}
                        {isExpanded ? <IconChevronUp /> : <IconChevronDown />}
                      </Row>
                    </Link>
                  )}
                </Text>
                {!collapsed && (
                  <Text fontSize="T200" id="schedule-card-hours" data-testid="job-card-description">
                    {description}
                  </Text>
                )}
              </Col>
            </Row>
            {(!!isEligibleForCss || !!isEligibleForWithdraw) && (
              <ManageApplicationCardModal
                eligibleForCSS={!!isEligibleForCss}
                eligibleForWithdraw={!!isEligibleForWithdraw}
              />
            )}
          </Row>
          {expandableContent}
        </Col>
      }
    </Col>
  );

  return <>{content}</>;
};
