import React, { useCallback } from "react";
import { buildUrl, CSS_PAGE_URL } from "../../config/urls";
import { useParams } from "react-router-dom";
import { isDragonstone } from "../../helpers/url-params";
import { AppointmentCardUncontrolled } from "./card-uncontrolled";
import { Stage } from "../../helpers/get-settings";
import * as adobeAnalytics from "../../utility/adobe-analytics";
import { isCustomerService } from "../../utility/app-config-helper";
import { useGetCandidateData } from "../../hooks/apis/useGetCandidateData";
import { useGetJobDetails } from "../../hooks/apis/useGetJobDetails";
import { useGetApplicationManageData } from "../../hooks/apis/useGetApplicationManageData";
import { isDevEnv } from "../../utility/dev-env-helpers";

interface AppointmentCardProps {
  stage?: Stage;
}

export const AppointmentCard = ({ stage }: AppointmentCardProps) => {
  const { requisitionId, applicationId } = useParams<{
    requisitionId: string;
    applicationId: string;
  }>();

  const scheduleDetails = useGetJobDetails();

  const candidateData = useGetCandidateData();
  const applicationManageData = useGetApplicationManageData();

  const sendAnalyticsEvent = useCallback(
    async (eventName: string) => {
      //no schedule for customer service job
      if (!isCustomerService(applicationId)) {
        await adobeAnalytics.addEventMetric(
          window,
          applicationId,
          eventName,
          candidateData,
          applicationManageData,
          scheduleDetails,
          {
            dragonstoneSchedule: {
              scheduleID: scheduleDetails?.scheduleId,
              jobID: requisitionId,
            },
            geocluster: {
              ID: scheduleDetails?.geoClusterId,
            },
          }
        );
      }
    },
    [applicationId, candidateData, applicationManageData, scheduleDetails, requisitionId]
  );

  const trackStartDateDetailsClick = useCallback(
    () => sendAnalyticsEvent("first day what to expect click"),
    [sendAnalyticsEvent]
  );

  const trackNHEDetailsClick = useCallback(
    () => sendAnalyticsEvent("ash view appointment details"),
    [sendAnalyticsEvent]
  );

  if (!isDragonstone(requisitionId) && !isCustomerService(applicationId)) {
    if (isDevEnv()) console.warn("skipping showing appointment card");
    return null;
  }

  const cssURLWithoutScheduleId = buildUrl(CSS_PAGE_URL, {
    hostName: window.location.origin,
    requisitionId,
    applicationId,
  });

  return (
    <AppointmentCardUncontrolled
      trackStartDateDetailsClick={trackStartDateDetailsClick}
      trackNHEDetailsClick={trackNHEDetailsClick}
      cssURLWithoutScheduleId={cssURLWithoutScheduleId}
      stage={stage}
    />
  );
};
