import React from "react";
import { MessageBanner, MessageBannerType } from "@amzn/stencil-react-components/message-banner";
import { useChecklistArb } from "../../hooks/use-arb";

export const AlumniMessageBanner = () => {
  const bundle = useChecklistArb();

  return (
    <MessageBanner
      data-testid="alumniMessageBanner"
      dismissButtonAltText="Dismiss this message"
      icon={<></>} // There's a bug in stencil, passing "undefined" still render the default icon. Passing the fragment to override the icon.
      isDismissible={true}
      type={MessageBannerType.Informational}
    >
      {bundle.getMessage(
        "Checklist-Alumni-MessageBanner",
        "Some activities were omitted as we have your required information from previous applications."
      )}
    </MessageBanner>
  );
};
