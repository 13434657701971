import { Col } from "@amzn/stencil-react-components/layout";
import { Link } from "@amzn/stencil-react-components/link";
import { Text } from "@amzn/stencil-react-components/text";
import React from "react";
import { CUSTOMER_SERVICE, NON_CUSTOMER_SERVICE } from "../../../config/appConfigConstants";
import { ChecklistBundle } from "../../../hooks/use-arb";
import { CONTACT_US_LINK, FR_CONTACT_US_LINK } from "../../../utility/constants";
import { CA_CAAS_STATUS } from "../i9-data";
import { InstructionMapping } from "../i9-task-card";
import { isFrenchLocale } from "../../../utility/locale-helper";

export const getCAInstructionMapping = ({ bundle }: { bundle: ChecklistBundle }) => {
  const actionsRequired = bundle.getMessage("Checklist-WorkAuthorization-instruction-ActionsRequired");
  const here = bundle.getMessage("Checklist-MainPage-Link");
  const overview = bundle.getMessage("Checklist-WorkAuthorization-Instruction-Overview");
  const statusInfo = bundle.getMessage("Checklist-WorkAuthorization-Instruction-StatusInfo");
  const submitAcceptable = bundle.getMessage("Checklist-WorkAuthorization-Instruction-SubmitAcceptable");

  const acceptableDocsLink = (
    <Link
      target="_blank"
      href={"https://walpub.s3.us-west-2.amazonaws.com/EmployeeGuides/AMER-LATAM/Canada/Employee_Guide_Canada.pdf"}
    >
      {here}
    </Link>
  );

  const supportLink = (
    <Link target="_blank" href={isFrenchLocale() ? FR_CONTACT_US_LINK : CONTACT_US_LINK}>
      {here}
    </Link>
  );

  const reviewAcceptableDocs = bundle.formatMessage("Checklist-WorkAuthorization-Instruction-ReviewAcceptableDocs", {
    acceptableDocsLink,
  });
  const supportInfo = bundle.formatMessage("Checklist-WorkAuthorization-Instruction-SupportInfo", {
    supportLink,
  });

  const MessageThanksForCompletingWorkAuthorization = bundle.getMessage(
    "Checklist-WorkAuthorization-Instruction-ThankYouMessage"
  );

  const inProgressInstructions: () => JSX.Element = () => (
    <>
      <Col key="maintext" gridGap={"S300"}>
        <Text fontSize="T200">{overview}</Text>
        <Text fontSize="T200">
          {actionsRequired}
          <ol style={{ listStyleType: "decimal", paddingLeft: "2em" }}>
            <li>{reviewAcceptableDocs}</li>
            <li>{submitAcceptable}</li>
            <li>{statusInfo}</li>
          </ol>
          {supportInfo}
        </Text>
      </Col>
    </>
  );

  const caDefaultInstructionMapping: InstructionMapping = {
    [NON_CUSTOMER_SERVICE]: {
      [CA_CAAS_STATUS.Completed]: <Text>{MessageThanksForCompletingWorkAuthorization}</Text>,
      [CA_CAAS_STATUS.Initiated]: inProgressInstructions(),
      [CA_CAAS_STATUS.Pending]: inProgressInstructions(),
    },
    [CUSTOMER_SERVICE]: {},
  };

  return caDefaultInstructionMapping;
};
