import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { Card } from "@amzn/stencil-react-components/card";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { Link } from "@amzn/stencil-react-components/link";
import { useBreakpoints } from "@amzn/stencil-react-components/responsive";
import React from "react";
import heroImg from "../../assets/images/hero.png";
import { Stage } from "../../helpers/get-settings";
import { GratitudeStateEnum, GratitudeStateType } from "../../hooks/useGratitudeComponentState.hook";
import { useUrlBuilder } from "../../hooks/useUrlBuilder.hook";
import { Text } from "../typography";
import { useGratitudeComponentText } from "./hooks/useGratitudeComponentText.hook";

interface GratitudeRouteProps {
  gratitudeComponentState: GratitudeStateType;
  stage: Stage | undefined;
}

export const Gratitude = ({ gratitudeComponentState, stage }: GratitudeRouteProps) => {
  const text = useGratitudeComponentText();

  const {
    matches: { s: isSmallViewport },
  } = useBreakpoints();

  const { cssURLSchedule, appointmentURLService, dashboardURL } = useUrlBuilder();

  const handleClick = () => {
    if (gratitudeComponentState === GratitudeStateEnum.ShowShiftSelection) {
      window.location.href = cssURLSchedule;
    } else if (gratitudeComponentState === GratitudeStateEnum.ShowNHEAppointment) {
      window.location.href = appointmentURLService;
    }
  };

  return (
    <>
      <Col backgroundColor="primary05" alignItems="center">
        <Col alignItems="center" maxWidth={"840px"}>
          <Text fontSize="T500" color="neutral100" margin="32px 0 0 0">
            {text.heroTitle}
          </Text>

          <Text fontSize="T300" color="neutral100" margin="24px 0 16px 0">
            {text.heroText}
          </Text>

          <img src={heroImg} alt="" />
        </Col>
      </Col>

      <Col margin="64px 16px 0 16px" alignItems="center">
        <Card flexDirection="column">
          <Text fontSize="T400" color="black">
            {gratitudeComponentState !== GratitudeStateEnum.ShowError ? text.cardTitle : text.errorText}
          </Text>

          <Text fontSize="T200" color="black" margin="24px 0 16px 0">
            {gratitudeComponentState === GratitudeStateEnum.ShowShiftSelection && text.selectShiftText}
            {gratitudeComponentState === GratitudeStateEnum.ShowNHEAppointment && text.selectAppointmentText}
          </Text>

          <Row alignItems="center" gridGap={32} flexWrap="wrap-reverse">
            <Col width={isSmallViewport ? "100%" : ""} justifyContent="center" alignItems="center" padding={12}>
              <Link className="button" href={dashboardURL}>
                {text.dashboardButtonText}
              </Link>
            </Col>

            {gratitudeComponentState !== GratitudeStateEnum.ShowError && (
              <Col width={isSmallViewport ? "100%" : ""}>
                <Button
                  data-testid="gratitude-handle-click-button"
                  className="button"
                  icon={null}
                  variant={ButtonVariant.Primary}
                  onClick={handleClick}
                >
                  {gratitudeComponentState === GratitudeStateEnum.ShowShiftSelection && text.selectShiftButtonText}
                  {gratitudeComponentState === GratitudeStateEnum.ShowNHEAppointment && text.scheduleNHEButtonText}
                </Button>
              </Col>
            )}
          </Row>
        </Card>
      </Col>
    </>
  );
};
