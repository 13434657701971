import React from "react";
import "./styles.scss";
import { Text, H1 } from "@amzn/stencil-react-components/text";
import { Row } from "@amzn/stencil-react-components/layout";
import { useChecklistArb } from "../../hooks/use-arb";
import { StatusContainer } from "./status-container";

export const ScreeningProcessHeader = () => {
  const bundle = useChecklistArb();

  const formattedScreeningMsg = bundle.getMessage("Checklist-AppointmentCard-Screen-Result");
  const screeningwaittMsg = bundle.getMessage("Checklist-AppointmentCard-Screen-Wait-Text");

  return (
    <StatusContainer
      data-testid={"page-header-screening-result-process"}
      largeViewPortImage={require("../../assets/svg/sandwatch.svg").default}
      smallViewPortImage={require("../../assets/svg/sandwatch.svg").default}
    >
      <H1 fontSize={"T400"} fontWeight={"regular"} textAlign="center" id="main-content">
        {formattedScreeningMsg}
      </H1>

      <Row gridGap={"1rem"}>
        <Text fontSize={"T200"} textAlign="center">
          {screeningwaittMsg}
        </Text>
      </Row>
    </StatusContainer>
  );
};
