import { Col, Container } from "@amzn/stencil-react-components/layout";
import { Spinner, SpinnerSize } from "@amzn/stencil-react-components/spinner";
import React from "react";

const AppLoader = () => {
  return (
    <Container height="100%">
      <Col height="300px" width="100%" alignItems="center" justifyContent="center">
        <Spinner size={SpinnerSize.Medium} data-testid="app-loader" />
      </Col>
    </Container>
  );
};

export default AppLoader;
