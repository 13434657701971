import { useChecklistArb } from "../../../hooks/use-arb";

export const useGratitudeComponentText = () => {
  const bundle = useChecklistArb();

  return {
    heroTitle: bundle.getMessage("Gratitude-Thank-You-Title"),
    heroText: bundle.getMessage("Gratitude-Next-Steps-Text"),

    cardTitle: bundle.getMessage("Gratitude-Start-PreHire-Text"),
    selectShiftText: bundle.getMessage("Gratitude-Select-Shift-Text"),
    selectAppointmentText: bundle.getMessage("Gratitude-Select-Appointment-Text"),

    selectShiftButtonText: bundle.getMessage("Gratitude-Select-Shift-Btn"),
    scheduleNHEButtonText: bundle.getMessage("Gratitude-Schedule-NHE-Btn"),
    dashboardButtonText: bundle.getMessage("Gratitude-Candidate-Dashboard-Btn"),
    errorText: bundle.getMessage("Gratitude-Error"),
  };
};
