import { ScreenResult } from "../../utility/screening-results-helper";

import { JobDetailsModel } from "../../utility/application-data";
import { StatusEnum } from "../../utility/enums/common";
import { I9Response } from "../../utility/types/common";
import { US_CAAS_STATUS } from "../i9-task/i9-data";
import { ContingencyResponse } from "./screeningResults";
import { IconCheckCircleFill, IconCloseCircleFill } from "@amzn/stencil-react-components/icons";
import React, { ReactNode } from "react";
import { HVH_COLORS } from "../../config/palette";
import ProgressRing from "../../assets/svg/progress-ring.svg";

export interface ScreeningResultsControlledInput {
  jobDetails?: JobDetailsModel;
}

export function getScreeningResultStatusFunc(
  response: ContingencyResponse | undefined,
  getStatus: (status: string) => ScreenResult
): ScreenResult {
  if (!response || response.status === StatusEnum.HIDDEN) return ScreenResult.UNDEFINED;

  if (!response.status) return ScreenResult.ERROR;

  return getStatus(response.status);
}

export function i9GetScreeningResultStatusFunc(
  response: I9Response | undefined,
  getStatus: (response: I9Response) => ScreenResult,
  I9_CAAS_STATUS: string | undefined,
  IS_ELIGIBLE_TO_SKIP_APPT_1: boolean
): ScreenResult {
  if (!response) return ScreenResult.UNDEFINED;

  // Logic for fast I9 screening results for Alumni: https://quip-amazon.com/d2LnAFkKcV5s#FBO9DA9gwIf
  // We do the override Section3Complete to be SKIPPED for Alumni.
  if (I9_CAAS_STATUS === (US_CAAS_STATUS.Section3Complete as unknown as string) || I9_CAAS_STATUS === "SKIPPED") {
    return ScreenResult.COMPLETE;
  }

  if (IS_ELIGIBLE_TO_SKIP_APPT_1 && I9_CAAS_STATUS === StatusEnum.NO_ACTION_REQUIRED) {
    return ScreenResult.NO_ACTION_REQUIRED;
  }

  return getStatus(response);
}

export const getStatusIcon = (status: string): ReactNode => {
  switch (status) {
    case ScreenResult.COMPLETE:
    case ScreenResult.NO_ACTION_REQUIRED:
      return <IconCheckCircleFill color={HVH_COLORS.GREEN_70} />;
    case ScreenResult.IN_PROGRESS:
    case ScreenResult.PENDING:
    case ScreenResult.NOT_STARTED:
    case ScreenResult.INITIATED:
      return <img src={ProgressRing} alt="" />;
    default:
      return <IconCloseCircleFill color={HVH_COLORS.RED_70} />;
  }
};
