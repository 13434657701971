import { StatusEnum } from "@amzn/hvh-simple-hire-checklist-internal-api-common";
import { Button, ButtonSize, ButtonVariant } from "@amzn/stencil-react-components/button";
import { IconExternalLink, IconSize } from "@amzn/stencil-react-components/icons";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import React, { useCallback } from "react";
import { useParams } from "react-router-dom";
import { useChecklistArb } from "../../hooks/use-arb";
import { addEventMetricWithBBId } from "../../utility/adobe-analytics";
import { EmploymentPaperwork } from "./EmploymentPaperwork";
import { useAshRum } from "../../hooks/useAshRum.hook";
import { useGetCandidateData } from "../../hooks/apis/useGetCandidateData";
import { useGetOnbaseDocumentStatus } from "../../hooks/apis/useGetOnbaseDocumentStatus";

const ADOBE_EVENT_START_EMPLOYMENT_PAPERWORK = "Start Employment Paperwork";

export const EmploymentPaperworkButton = ({ status }: EmploymentPaperwork) => {
  const bundle = useChecklistArb();

  const { applicationId } = useParams<{ applicationId: string }>();
  const candidateData = useGetCandidateData();
  const { isEdmLoginEnabled, onbaseData } = useGetOnbaseDocumentStatus();

  const onbaseLoginUrl = onbaseData && onbaseData?.results[0].onbaseLoginUrl;

  const { recordRumEvent } = useAshRum();

  const startEmploymentPaperworkButton = bundle.getMessage("Checklist-Complete-Employment-Paperwork");

  const sendAnalyticsEvent = useCallback(
    async (eventName: string) => {
      await addEventMetricWithBBId(candidateData, window, applicationId, eventName);
    },
    [applicationId, candidateData]
  );

  const redirect = useCallback(() => {
    if (!onbaseLoginUrl) {
      return;
    }

    sendAnalyticsEvent(ADOBE_EVENT_START_EMPLOYMENT_PAPERWORK);

    if (isEdmLoginEnabled) {
      recordRumEvent({
        type: "ash_info_edm_portal_redirect_event",
        edmLoginUrl: onbaseLoginUrl,
        from: "employeedocs",
      });
    }

    window.open(onbaseLoginUrl, "_blank");
  }, [onbaseLoginUrl, sendAnalyticsEvent, isEdmLoginEnabled, recordRumEvent]);

  if (status == StatusEnum.COMPLETED || status == StatusEnum.ERROR) {
    return null;
  }

  return (
    <Button
      disabled={status != StatusEnum.ACTION_REQUIRED}
      size={ButtonSize.Small}
      variant={ButtonVariant.Primary}
      onClick={redirect}
      data-testid="complete-employment-paperwork-button"
    >
      <Row gridGap="S200">
        <Col>{startEmploymentPaperworkButton}</Col>
        <Col>
          <IconExternalLink size={IconSize.ExtraSmall} />
        </Col>
      </Row>
    </Button>
  );
};
