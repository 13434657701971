import { ReferrerMode } from "../components/new-hire-video/NewHireVideo";

export interface URLParams {
  requisitionId: string;
  applicationId: string;
}

export const getURLParams = (urlParams: URLParams, getQueryParam: (key: string) => string | null) => {
  const { requisitionId, applicationId } = urlParams;
  let candidateId: string | undefined = undefined;
  let referrerMode = ReferrerMode.Ash;

  if (getQueryParam("na") === "true") {
    candidateId = applicationId;
    referrerMode = ReferrerMode.NonAsh;
  }
  return {
    referrerMode,
    requisitionId,
    applicationId,
    candidateId,
  };
};
