/**
 * I9 Data Mapping
 * Based on https://quip-amazon.com/6QQmAzHGXBe2/CaaS-Real-Time-Status
 */
import { StatusEnum } from "../../utility/enums/common";
import { isCustomerService } from "../../utility/app-config-helper";
import { getCountryForDomain } from "../../utility/locale-helper";
import { SUPPORTED_COUNTRY } from "../../config/appConfigConstants";

export enum I9ButtonState {
  Active = "Active",
  Hidden = "Hidden",
  Disabled = "Disabled",
}

export enum I9Type {
  Normal = "Normal",
  Remote = "Remote",
}

//translate this part to get from bundle
const AshStatusPendingSection1Required = "Checklist-MainPage-I9-PendingSection1";
const AshStatusPendingSection1 = "Checklist-MainPage-I9-PendingSection1Short";
const AshStatusPendingSection2 = "Checklist-MainPage-I9-PendingSection2";
const AshStatusPendingSection2Required = "Checklist-MainPage-I9-PendingSection2Action";
const AshStatusComplete = "checklist-General-TaskStatus-Completed";
const AshPendingSection3 = "Checklist-MainPage-I9-PendingSection3Short";
const AshStatusEmpty = "";

const MessageYouMustCompleteSection1 = "Checklist-MainPage-I9-YouMustCompleteSection1Message";
const MessageThanksForCompletingSection1 = "Checklist-MainPage-I9-CompletedSection1Message";

const MessageThanksForCompletingI9 = "Checklist-MainPage-I9-ThankYouMessage";

const MessageAlreadyCompleted = "Checklist-MainPage-I9-AlreadyCompletedDetailedMessage";

const MessageThanksForCompletingSection1Remote = "Checklist-MainPage-I9-ThanksForCompletingSection1RemoteMessage";

const CAAS_STATUS_TO_MESSAGE = {
  Initiated: MessageYouMustCompleteSection1,
  WaitingForASHToCreateOrder: MessageYouMustCompleteSection1,
  PendingSection1: MessageYouMustCompleteSection1,
  Section1Complete: MessageThanksForCompletingSection1,
  Section2Complete: MessageThanksForCompletingI9,
  PendingSection3: MessageAlreadyCompleted,
  Section3Complete: MessageThanksForCompletingI9,
  "Remote+Section1Complete": MessageThanksForCompletingSection1Remote,
  Archived: MessageAlreadyCompleted,
};

const CAAS_STATUS_TO_BUTTON_STATE = {
  Initiated: I9ButtonState.Active,
  WaitingForASHToCreateOrder: I9ButtonState.Active,
  PendingSection1: I9ButtonState.Active,
  Section1Complete: I9ButtonState.Disabled,
  Section2Complete: I9ButtonState.Disabled,
  PendingSection3: I9ButtonState.Hidden,
  Section3Complete: I9ButtonState.Disabled,
  Archived: I9ButtonState.Hidden,
};

export const CAAS_STATUS_TO_ACTIVITY_STATUS_US_CUSTOMER_SERVICE: Record<string, StatusEnum> = {
  Initiated: StatusEnum.NOT_AVAILABLE_YET,
  WaitingForASHToCreateOrder: StatusEnum.NOT_AVAILABLE_YET,
  PendingSection1: StatusEnum.ACTION_REQUIRED,
  Section1Complete: StatusEnum.ACTION_REQUIRED,
  Section2Complete: StatusEnum.COMPLETED,
  PendingSection3: StatusEnum.COMPLETED,
  Section3Complete: StatusEnum.COMPLETED,
  Archived: StatusEnum.COMPLETED,
  Error: StatusEnum.ERROR,
  Pending: StatusEnum.PENDING,
  AwaitingCandidateInfo: StatusEnum.ACTION_REQUIRED,
  "Awaiting Candidate Info": StatusEnum.ACTION_REQUIRED,
  Completed: StatusEnum.COMPLETED,
  PendingRejectionReview: StatusEnum.PENDING_REVIEW,
  Rejected: StatusEnum.REJECTED,
};

export const CAAS_STATUS_TO_ACTIVITY_STATUS_US_NON_CUSTOMER_SERVICE: Record<string, StatusEnum> = {
  Initiated: StatusEnum.NOT_AVAILABLE_YET,
  WaitingForASHToCreateOrder: StatusEnum.NOT_AVAILABLE_YET,
  PendingSection1: StatusEnum.ACTION_REQUIRED,
  Section1Complete: StatusEnum.COMPLETED,
  Section2Complete: StatusEnum.COMPLETED,
  PendingSection3: StatusEnum.COMPLETED,
  Section3Complete: StatusEnum.COMPLETED,
  Archived: StatusEnum.COMPLETED,
  Error: StatusEnum.ERROR,
  Pending: StatusEnum.PENDING,
  AwaitingCandidateInfo: StatusEnum.ACTION_REQUIRED,
  "Awaiting Candidate Info": StatusEnum.ACTION_REQUIRED,
  Completed: StatusEnum.COMPLETED,
  PendingRejectionReview: StatusEnum.PENDING_REVIEW,
  Rejected: StatusEnum.REJECTED,
};

export const CAAS_STATUS_TO_ACTIVITY_STATUS_CA_NON_CUSTOMER_SERVICE: Record<string, StatusEnum> = {
  Initiated: StatusEnum.ACTION_REQUIRED,
  Pending: StatusEnum.ACTION_REQUIRED,
  Completed: StatusEnum.COMPLETED,
  Cancelled: StatusEnum.NOT_AVAILABLE_YET,
  Created: StatusEnum.NOT_AVAILABLE_YET,
  Expired: StatusEnum.NOT_AVAILABLE_YET,
  Archived: StatusEnum.NOT_AVAILABLE_YET,
  Error: StatusEnum.NOT_AVAILABLE_YET,
};

export enum CAAS_STATUS {
  Initiated = "Initiated",
  WaitingForASHToCreateOrder = "WaitingForASHToCreateOrder",
  PendingSection1 = "PendingSection1",
  Section1Complete = "Section1Complete",
  Section2Complete = "Section2Complete",
  PendingSection3NoAppointmentScheduled = "PendingSection3NoAppointmentScheduled", // TODO: map to real endpoint from BE
  PendingSection3NoActionRequired = "PendingSection3NoActionRequired", // TODO: map to real endpoint from BE
  PendingSection3 = "PendingSection3",
  Section3Complete = "Section3Complete",
  Archived = "Archived",
  Error = "Error",
  Pending = "Pending",
  AwaitingCandidateInfo = "Awaiting Candidate Info",
  Completed = "Completed",
  PendingRejectionReview = "PendingRejectionReview",
  OrderPlacedAtVendor = "OrderPlacedAtVendor",
  PendingManualCheck = "PendingManualCheck",
  PendingF2FCheck = "PendingF2FCheck",
  UkRtwNoCaasIntegration = "UkRtwNoCaasIntegration", // ASH internal use only, default application CAAS status when ASH not integrated with CAAS yet.
  InProgress = "InProgress",
  Rejected = "Rejected",
  Cancelled = "Cancelled",
  Created = "Created",
  Expired = "Expired",
}

export enum CA_CAAS_STATUS {
  Initiated = CAAS_STATUS.Initiated,
  Pending = CAAS_STATUS.Pending,
  Completed = CAAS_STATUS.Completed,
  Cancelled = CAAS_STATUS.Cancelled,
  Created = CAAS_STATUS.Created,
  Expired = CAAS_STATUS.Expired,
  Archived = CAAS_STATUS.Archived,
  Error = CAAS_STATUS.Error,
}

export enum US_CAAS_STATUS {
  Initiated = CAAS_STATUS.Initiated,
  Completed = CAAS_STATUS.Completed,
  Error = CAAS_STATUS.Error,
  Pending = CAAS_STATUS.Pending,
  Archived = CAAS_STATUS.Archived,
  WaitingForASHToCreateOrder = CAAS_STATUS.WaitingForASHToCreateOrder,
  PendingSection1 = CAAS_STATUS.PendingSection1,
  Section1Complete = CAAS_STATUS.Section1Complete,
  Section2Complete = CAAS_STATUS.Section2Complete,
  PendingSection3 = CAAS_STATUS.PendingSection3,
  PendingSection3NoAppointmentScheduled = CAAS_STATUS.PendingSection3NoAppointmentScheduled,
  PendingSection3NoActionRequired = CAAS_STATUS.PendingSection3NoActionRequired,
  Section3Complete = CAAS_STATUS.Section3Complete,
}

export enum GB_CAAS_STATUS {
  Initiated = CAAS_STATUS.Initiated,
  Completed = CAAS_STATUS.Completed,
  Error = CAAS_STATUS.Error,
  Pending = CAAS_STATUS.Pending,
  Archived = CAAS_STATUS.Archived,
  OrderPlacedAtVendor = CAAS_STATUS.OrderPlacedAtVendor,
  AwaitingCandidateInfo = CAAS_STATUS.AwaitingCandidateInfo,
  PendingManualCheck = CAAS_STATUS.PendingManualCheck,
  PendingF2FCheck = CAAS_STATUS.PendingF2FCheck,
  PendingRejectionReview = CAAS_STATUS.PendingRejectionReview,
  UkRtwNoCaasIntegration = CAAS_STATUS.UkRtwNoCaasIntegration,
  InProgress = CAAS_STATUS.InProgress,
}

// TODO: Change to enum to correct status
export enum ZA_CAAS_STATUS {
  Initiated = CAAS_STATUS.Initiated,
  Completed = CAAS_STATUS.Completed,
  Error = CAAS_STATUS.Error,
  Pending = CAAS_STATUS.Pending,
  Archived = CAAS_STATUS.Archived,
}

export enum PH_CAAS_STATUS {
  Initiated = CAAS_STATUS.Initiated,
  Completed = CAAS_STATUS.Completed,
  Error = CAAS_STATUS.Error,
  Pending = CAAS_STATUS.Pending,
  Archived = CAAS_STATUS.Archived,
}

export enum IE_CAAS_STATUS {
  Initiated = CAAS_STATUS.Initiated,
  Completed = CAAS_STATUS.Completed,
  Error = CAAS_STATUS.Error,
  Pending = CAAS_STATUS.Pending,
  Archived = CAAS_STATUS.Archived,
}

export enum IN_CAAS_STATUS {
  Initiated = CAAS_STATUS.Initiated,
  Completed = CAAS_STATUS.Completed,
  Error = CAAS_STATUS.Error,
  Pending = CAAS_STATUS.Pending,
  Archived = CAAS_STATUS.Archived,
}

const CAAS_STATUS_TO_ASH_STATUS: Record<string, string> = {
  Initiated: AshStatusPendingSection1Required,
  WaitingForASHToCreateOrder: AshStatusPendingSection1Required,
  PendingSection1: AshStatusPendingSection1Required,
  Section1Complete: AshStatusPendingSection2,
  Section2Complete: AshStatusComplete,
  PendingSection3: AshPendingSection3,
  Section3Complete: AshStatusComplete,
  "Remote+PendingSection1": AshStatusPendingSection1,
  "Remote+Section1Complete": AshStatusPendingSection2Required,
  "Remote+PendingSection3": AshStatusComplete,
  Archived: AshStatusEmpty,
};

const getAshEntity = (i9Type: I9Type, caasStatus: string, dictionary: any): string => {
  const specificKey = `${i9Type}+${caasStatus}`;
  const commonKey = caasStatus;
  return dictionary[specificKey] || dictionary[commonKey];
};

export const getAshStatus = (i9Type: I9Type, caasStatus: string): string => {
  return getAshEntity(i9Type, caasStatus, CAAS_STATUS_TO_ASH_STATUS);
};

export const getAshButtonState = (i9Type: I9Type, caasStatus: string): I9ButtonState => {
  return getAshEntity(i9Type, caasStatus, CAAS_STATUS_TO_BUTTON_STATE) as I9ButtonState;
};

export const getI9ActivityStatus = (applicationId: string): Record<string, StatusEnum> => {
  const country = getCountryForDomain();
  if (isCustomerService(applicationId)) {
    return CAAS_STATUS_TO_ACTIVITY_STATUS_US_CUSTOMER_SERVICE;
  } else if (country === SUPPORTED_COUNTRY.CA) {
    return CAAS_STATUS_TO_ACTIVITY_STATUS_CA_NON_CUSTOMER_SERVICE;
  }
  return CAAS_STATUS_TO_ACTIVITY_STATUS_US_NON_CUSTOMER_SERVICE;
};
