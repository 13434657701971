import React, { ComponentType, useState, useEffect } from "react";
import { getSettings, Stage } from "../helpers/get-settings";
import { useChecklistArb } from "../hooks/use-arb";
type ComponentWithStage = ComponentType<{ stage: Stage }>;

export const withStage = (Component: ComponentWithStage) => {
  return function ComponentWithStage(props: any) {
    const [stage, setStage] = useState<Stage>();

    useEffect(() => {
      getSettings().then((settings) => setStage(settings.stage));
    });
    const bundle = useChecklistArb();

    if (!stage) {
      return null;
    }

    // browser tab title
    const title = document.getElementById("browserTabTitle");
    if (title !== null) {
      title.innerHTML = bundle.getMessage("checklist-General-PageTabText");
    }

    return <Component {...props} stage={stage} />;
  };
};
