import React from "react";
import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { IconCloseCircleFill, IconQuestionCircleFill } from "@amzn/stencil-react-components/icons";
import { Link } from "@amzn/stencil-react-components/link";
import { Row } from "@amzn/stencil-react-components/layout";
import { Text } from "../../../../components/typography";
import { StatusEnum } from "../../../../utility/enums/common";
import { useParams } from "react-router-dom";
import { useChecklistArb } from "../../../../hooks/use-arb";
import { eventHandler } from "../helpers/eventHandler";
import { AttachmentType } from "../models";
import { useAppDispatch } from "../../../../reduxStore/reduxHooks";

interface UploadNeededParams {
  documentType: string;
  documentMessage: string;
  optional?: boolean;
  status: StatusEnum;
}

export const DocumentUploadNeeded = ({ documentType, documentMessage, optional, status }: UploadNeededParams) => {
  const { requisitionId, applicationId } = useParams<{
    requisitionId: string;
    applicationId: string;
  }>();
  const dispatch = useAppDispatch();

  const bundle = useChecklistArb();
  const uploadText = bundle.getMessage("checklist-General-Upload-Button");
  return (
    <Row display="flex" justifyContent="space-between" alignItems="center">
      <Row width={"80%"} display="flex" gridGap="1em" justifyContent="left">
        {optional ? <IconQuestionCircleFill color="#5c7274" /> : <IconCloseCircleFill color="pink70" />}
        <Text fontWeight="bold">{documentMessage}</Text>
      </Row>
      {status !== StatusEnum.COMPLETED && (
        <Link
          href={`/checklist/tasks/document/${requisitionId}/${applicationId}/${documentType}/false`}
          onClick={(e) =>
            eventHandler(
              e,
              {
                documentType: documentType as AttachmentType,
                isEdit: false,
                buttonDelegationEnabled: true,
              },
              dispatch
            )
          }
          textDecoration={"none"}
        >
          <Button variant={ButtonVariant.Tertiary}>{uploadText}</Button>
        </Link>
      )}
    </Row>
  );
};
