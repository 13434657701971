import React, { useState, useEffect } from "react";
import { H2, Text } from "../../../../../components/typography";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { UploadCard } from "../upload-card";
import { useChecklistArb } from "../../../../../hooks/use-arb";
import { AttachmentSubType, AttachmentType, UploadDetails } from "../../models";
import { submitDocument } from "../../requests/requests";
import { useParams } from "react-router-dom";
import { deleteCachedRequest } from "../../../../../utility/candidate-data";
import { MessageBanner, MessageBannerType } from "@amzn/stencil-react-components/message-banner";
import {
  useGetPresignedURLToCDSMutation,
  useSubmitDocToCDSFromPresignedURLMutation,
  useUpdateCDSDocumentUploadMetadataMutation,
} from "../../../../../reduxStore/api/apiSlice";
import { dispatchOnDev, isDevEnv } from "../../../../../utility/dev-env-helpers";
import { useBackToMainPage } from "../../helpers/useBackToMainPage.hook";
import { TEMPORARY_SUBMIT_BUTTON_DISABLED_SOLUTION_SELECTOR } from "../../../../../utility/constants/common";
import { useTemporarySubmitDisabledSolution } from "../../helpers/useTemporarySubmitDisabledSolution.hook";
import { useGetCandidateData } from "../../../../../hooks/apis/useGetCandidateData";
import { DebounceButton } from "../../../../../components/common/DebounceButton";

interface FrontBackUploadLayoutParams {
  documentType: string;
}
/**
 * This function is used for upload following document
 *
 * Consular Identification Card (front and back)
 * Voter Card (front and back)
 * Temporary Residence Card (front and back)
 * Permanent Residence Card (front and back)
 */
export function FrontBackUploadLayout({ documentType }: FrontBackUploadLayoutParams) {
  const { requisitionId, applicationId } = useParams<{
    requisitionId: string;
    applicationId: string;
  }>();
  const candidateData = useGetCandidateData();
  const [getPresignedURL] = useGetPresignedURLToCDSMutation();
  const [submitDocToCDS] = useSubmitDocToCDSFromPresignedURLMutation();
  const [updateDocMetadataToCDS] = useUpdateCDSDocumentUploadMetadataMutation();

  const bundle = useChecklistArb();
  let titleMessage;
  if (documentType === AttachmentType.CONSULAR_IDENTIFICATION_CARD) {
    titleMessage = bundle.getMessage("Checklist-Document-Consular-Identification-Card-Title");
  } else if (documentType === AttachmentType.VOTER_CARD) {
    titleMessage = bundle.getMessage("Checklist-Document-Voter-Card-Title");
  } else if (documentType === AttachmentType.TEMPORARY_RESIDENCE_CARD) {
    titleMessage = bundle.getMessage("Checklist-Document-Temporary-Residence-Card-Title");
  } else if (documentType === AttachmentType.PERMANENT_RESIDENCE_CARD) {
    titleMessage = bundle.getMessage("Checklist-Document-Permanent-Residence-Card-Title");
  } else if (documentType === AttachmentType.TEMPORARY_RESIDENCE_STUDENT_CARD) {
    titleMessage = bundle.getMessage("Checklist-Document-Temporary-Resident-Student-Card-Title");
  }

  const submitMessage = bundle.getMessage("Checklist-Document-Image-Submit-ButtonText");
  const documentAcceptableFileMessage = bundle.getMessage("Checklist-Document-Acceptable-File-Text");
  const maxSizeMessage = bundle.getMessage("Checklist-Document-Maximum-File-Size-Text");
  const p0LimitFixMessage = bundle.getMessage("p0LimitFixMessage");

  const frontErrorMessageBanner = bundle.getMessage("Checklist-Document-General-Error-Front-Message");
  const backErrorMessageBanner = bundle.getMessage("Checklist-Document-General-Error-Back-Message");
  const documentUploadDependencyServiceErrorMessage = bundle.getMessage("Checklist-Document-General-Error-Message");
  const [frontDocument, setFrontDocument] = useState<UploadDetails>();
  const [backDocument, setBackDocumnet] = useState<UploadDetails>();
  const [dependencyServiceErrorMessage, setDependencyServiceErrorMessage] = useState<string>();
  const [blockSubmitButton, setBlockSubmitButton] = useState<boolean>(true);

  const [frontErrorMessage, setFrontErrorMessage] = useState<string>();
  const [backErrorMessage, setBackErrorMessage] = useState<string>();

  const backToMainPage = useBackToMainPage();
  useTemporarySubmitDisabledSolution(false);

  useEffect(() => {
    // Back side is required
    setBlockSubmitButton(!frontDocument?.imageBase64 || !backDocument?.imageBase64);
  }, [frontDocument, backDocument]);

  const updateDocument = async (
    image: string,
    fileName: string,
    contentType: string,
    side: string,
    applicationId?: string
  ) => {
    if (candidateData) {
      const documentData = {
        documentType: documentType as AttachmentType,
        attachmentSubType: side,
        imageBase64: image,
        fileName: fileName,
        contentType: contentType,
        candidateId: candidateData?.candidateId,
      };
      if (side === AttachmentSubType.FRONT) {
        setFrontDocument(documentData);
      } else {
        setBackDocumnet(documentData);
      }
    } else {
      dispatchOnDev(() => {
        console.error("candidateData is not ready yet");
      });
    }
  };

  const clickEventSubmitDocument = async () => {
    const inputFront = document.getElementById("front-upload-doc");
    const inputBack = document.getElementById("back-upload-doc");

    if (isDevEnv()) console.log("start submit document");

    if (frontErrorMessage || backErrorMessage) {
      if (backErrorMessage) {
        inputBack?.focus();
      }
      if (frontErrorMessage) {
        inputFront?.focus();
      }

      return;
    }

    let returnToMainPage = true;
    if (frontDocument && backDocument) {
      const newFrontDoc = {
        ...frontDocument,
      };
      const newBackDoc = {
        ...backDocument,
      };
      const docs = [newFrontDoc];
      // Back is required
      docs.push(newBackDoc);
      try {
        await submitDocument(
          getPresignedURL,
          submitDocToCDS,
          updateDocMetadataToCDS,
          applicationId,
          docs,
          setBlockSubmitButton
        ); // replace bbid
      } catch (err) {
        setBlockSubmitButton(false);
        console.log(err);
        setDependencyServiceErrorMessage(documentUploadDependencyServiceErrorMessage);
        returnToMainPage = false;
      }
      deleteCachedRequest(applicationId); // delete stale data so that updated Id loads on home page
      if (returnToMainPage) backToMainPage();
    }

    if (!backDocument?.imageBase64) {
      inputBack?.focus();
      setBackErrorMessage(backErrorMessageBanner);
    }
    if (!frontDocument?.imageBase64) {
      inputFront?.focus();
      setFrontErrorMessage(frontErrorMessageBanner);
    }
  };

  const frontErrorMessageText =
    frontErrorMessage === frontErrorMessageBanner ? frontErrorMessageBanner : frontErrorMessage;
  const backErrorMessageText = backErrorMessage === backErrorMessageBanner ? backErrorMessageBanner : backErrorMessage;

  return (
    <Col display={"flex"} justifyContent={"center"} gridGap={6} width={"100%"}>
      <H2>{titleMessage}</H2>
      <Text>{documentAcceptableFileMessage}</Text>
      <Text>{maxSizeMessage}</Text>
      <Row padding={{ top: "10px", bottom: "10px" }} gridGap="S400">
        <UploadCard
          side={AttachmentSubType.FRONT}
          documentDetailsFn={updateDocument}
          setErrorMessage={setFrontErrorMessage}
          applicationId={applicationId}
          setDependencyErrorMessage={setDependencyServiceErrorMessage}
          attachmentTypeName={titleMessage}
          customId={`front-upload-doc`}
          hasError={!!frontErrorMessage}
        />
        <UploadCard
          side={AttachmentSubType.BACK}
          documentDetailsFn={updateDocument}
          setErrorMessage={setBackErrorMessage}
          applicationId={applicationId}
          setDependencyErrorMessage={setDependencyServiceErrorMessage}
          attachmentTypeName={titleMessage}
          customId={`back-upload-doc`}
          hasError={!!backErrorMessage}
        />
      </Row>
      {dependencyServiceErrorMessage && (
        <MessageBanner type={MessageBannerType.Error}>{dependencyServiceErrorMessage}</MessageBanner>
      )}
      {frontErrorMessage && (
        <MessageBanner type={MessageBannerType.Error}>
          <Text id={`front-upload-doc-description`}>{frontErrorMessageText}</Text>
        </MessageBanner>
      )}
      {backErrorMessage && (
        <MessageBanner type={MessageBannerType.Error}>
          <Text id={`back-upload-doc-description`}>{backErrorMessageText}</Text>
        </MessageBanner>
      )}
      <Row padding={{ top: "10px", bottom: "10px" }}>
        <MessageBanner type={MessageBannerType.Informational}>{p0LimitFixMessage}</MessageBanner>
      </Row>
      <DebounceButton
        onClick={clickEventSubmitDocument}
        id={TEMPORARY_SUBMIT_BUTTON_DISABLED_SOLUTION_SELECTOR}
        style={{ visibility: "hidden" }}
      >
        {submitMessage}
      </DebounceButton>
    </Col>
  );
}
