import { Col, Flex, Row } from "@amzn/stencil-react-components/layout";
import { DocumentUploadTask } from "../entry";
import { DocumentUpload } from "../upload";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../reduxStore/reduxHooks";
import { getOldUiFlyoutContent, openOldUiFlyoutContentUpload } from "../../../../reduxStore/slices/docUploadSlice";
import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { TEMPORARY_SUBMIT_BUTTON_DISABLED_SOLUTION_SELECTOR } from "../../../../utility/constants/common";
import { StatusEnum } from "../../../../utility/enums/common";
import { useChecklistArb } from "../../../../hooks/use-arb";
import { useSendAnalyticEvent } from "../../../../hooks/jobDetailsCardAnalyticEventHelper.hook";

export const FlyoutAdapterForOldDocUpload = ({
  status,
  taskName,
}: {
  status: StatusEnum;
  taskName?: string | undefined;
}) => {
  const oldUiFlyoutContent = useAppSelector(getOldUiFlyoutContent);

  return (
    <Flex flexDirection="column" justifyContent="space-between" padding="16px">
      {oldUiFlyoutContent?.documentType === "FORM" ? (
        <DocumentUploadTask status={status} taskName={taskName} />
      ) : (
        <DocumentUpload />
      )}
    </Flex>
  );
};

export const FlyoutAdapterBackBtnForOldDocUpload = () => {
  const bundle = useChecklistArb();
  const dispatch = useAppDispatch();
  const oldUiFlyoutContent = useAppSelector(getOldUiFlyoutContent);
  const flyoutBackText = "Regresar";
  const flyoutSubmitText = bundle.getMessage("Checklist-General-SubmitButtonText");

  const { sendDocumentsAnalyticsEvent } = useSendAnalyticEvent();

  if (oldUiFlyoutContent?.documentType !== "FORM") {
    return (
      <Row justifyContent="space-between">
        <Col width="100%" margin={{ right: "S200" }}>
          <Button
            data-testid="flyout-adapter-back-button"
            onClick={() => {
              sendDocumentsAnalyticsEvent("cancel button click", oldUiFlyoutContent?.documentType);
              dispatch(
                openOldUiFlyoutContentUpload({
                  oldUiFlyoutContent: {
                    documentType: "FORM",
                    isEdit: false,
                    buttonDelegationEnabled: true,
                  },
                })
              );
            }}
          >
            {flyoutBackText}
          </Button>
        </Col>

        <Col width="100%" margin={{ left: "S200" }}>
          <Button
            data-testid="flyout-adapter-submit-button"
            id="doc-upload-proxy-button"
            variant={ButtonVariant.Primary}
            aria-disabled={!!oldUiFlyoutContent.buttonDelegationEnabled}
            onClick={() => {
              const temporarySubmitButton = document.querySelector(
                `#${TEMPORARY_SUBMIT_BUTTON_DISABLED_SOLUTION_SELECTOR}`
              ) as HTMLElement;
              temporarySubmitButton?.click();
              sendDocumentsAnalyticsEvent("submit button click", oldUiFlyoutContent?.documentType);
            }}
          >
            {flyoutSubmitText}
          </Button>
        </Col>
      </Row>
    );
  }

  return null;
};
