/*
  Const used for Task Definition and mapping used for showing the Task Card 
  TODO: We really should move this definitions to common package.
*/
export const ORDER_YOUR_SAFETY_SHOE = "Order your safety shoes";
export const I_9_DOCUMENT = "Pack I-9 documents for your appointment";
export const BADGE_PHOTO = "Take your badge photo";
export const NEW_HIRE_VIDEO = "Watch the new hire video";
export const EMPLOYMENT_PAPERWORK = "Complete employment paperwork";
export const CREATE_EMPLOYEE_A_TO_Z_ACCOUNT = "Set up A to Z Account";
export const BACKGROUNDCHECK_INFORMATION = "Provide background check information";
export const COMPLETE_RIGHT_TO_WORK = "Complete your Right to Work Documentation";
export const SCHEDULE_RIGHT_TO_WORK_APPOINTMENT = "Schedule a Right to Work appointment";
export const DOC_UPLOADS = "Upload documents";
export const JOB_PREVIEW_VIDEO = "Watch the job preview video";

/* Const used for TaskGroup */
export const PRE_HIRE_APPOINTMENT_GROUP = "Pre-hire appointment group";
export const FIRST_DAY_GROUP = "First day group";
