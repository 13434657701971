import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  COUNTRIES_WITHOUT_I9,
  COUNTRIES_WITH_WORK_AUTHORIZATION_TASK_TITLE,
  SUPPORTED_COUNTRY,
} from "../../config/appConfigConstants";
import { I9_ADVANTAGE_URL } from "../../config/urls";
import { isDragonstone } from "../../helpers/url-params";
import { useGetApplicationManageData } from "../../hooks/apis/useGetApplicationManageData";
import { useGetCandidateData } from "../../hooks/apis/useGetCandidateData";
import { useGetJobDetails } from "../../hooks/apis/useGetJobDetails";
import { useChecklistArb } from "../../hooks/use-arb";
import { useLazyGetCaasRTWStatusQuery } from "../../reduxStore/api/apiSlice";
import { useAppSelector } from "../../reduxStore/reduxHooks";
import { getConfigData } from "../../reduxStore/slices/configSlice";
import { addPageLoadMetric } from "../../utility/adobe-analytics";
import { isCustomerService } from "../../utility/app-config-helper";
import { CandidateData } from "../../utility/candidate-data";
import { dispatchOnDev } from "../../utility/dev-env-helpers";
import { StatusEnum } from "../../utility/enums/common";
import { getCountryForDomain, getDefaultLocaleAndCountryForCurrentDomain } from "../../utility/locale-helper";
import { I9Response, I9TaskCardProps } from "../../utility/types/common";
import { US_CAAS_STATUS } from "./i9-data";

export const I9TaskCardButton = ({ status, shouldOverrideStatus }: I9TaskCardProps) => {
  const { applicationId, requisitionId } = useParams<{
    applicationId: string;
    requisitionId: string;
  }>();

  const candidateData = useGetCandidateData();
  const jcDetails = useGetJobDetails();
  const applicationManageData = useGetApplicationManageData();
  const { caasI9Status } = useAppSelector(getConfigData);
  const [getRtwInfo] = useLazyGetCaasRTWStatusQuery();

  const country = getCountryForDomain();

  const overrideStatus = shouldOverrideStatus && status ? shouldOverrideStatus[status] : status;

  const [isI9ErrorCode, setIsI9ErrorCode] = useState<boolean>(false);
  const [i9Response, setI9Response] = useState<I9Response>({});

  const bundle = useChecklistArb();
  const translatedI9Button = bundle.getMessage("Checklist-MainPage-I9Legacy-ButtonText");
  const translatedRtwButton = bundle.getMessage("Checklist-MainPage-RightToWork-ButtonText");
  const translatedWorkAuthorizationButton = bundle.getMessage("Checklist-WorkAuthorization-ButtonText");
  const translatedButtonPH = bundle.getMessage("Checklist-RightToWork-Button-PH");

  const resolveButtonText = (): string => {
    if (country === SUPPORTED_COUNTRY.PH) {
      return translatedButtonPH;
    }

    if (country in COUNTRIES_WITHOUT_I9) {
      return country == SUPPORTED_COUNTRY.CA ? translatedWorkAuthorizationButton : translatedRtwButton;
    }

    return translatedI9Button;
  };

  const trackI9LinkClick = useCallback(() => {
    if (!candidateData || !applicationManageData || !jcDetails) {
      dispatchOnDev(() => {
        if (!candidateData) console.error("candidateData is not available");
        if (!applicationManageData) console.error("applicationState is not available");
        if (!jcDetails) console.error("jcDetails is not available");
      });
      return;
    }
    addPageLoadMetric(
      candidateData,
      applicationManageData,
      jcDetails,
      window,
      applicationId,
      country == SUPPORTED_COUNTRY.US ? "i9advantage" : "rightToWork",
      {
        jobId: requisitionId,
        isDragonstone: isDragonstone(requisitionId),
      }
    );
  }, [applicationId, requisitionId, candidateData, applicationManageData, jcDetails]);

  const requestI9Link = useCallback(
    async (candidateData: CandidateData) => {
      try {
        let response;
        if (getDefaultLocaleAndCountryForCurrentDomain().country == "GB") {
          response = (await getRtwInfo(candidateData?.candidateId)).data;
          setI9Response(response as I9Response);
        } else {
          response = caasI9Status;
          if (!response || (response !== "SKIPPED" && response?.errorCode)) {
            setIsI9ErrorCode(true);
          } else {
            setIsI9ErrorCode(false);
          }
          if (response && response !== "SKIPPED") {
            setI9Response(response);
            return response?.secureURL;
          }
          return I9_ADVANTAGE_URL;
        }
      } catch (e) {
        console.log(e);
      }
    },
    [caasI9Status, getRtwInfo]
  );

  useEffect(() => {
    candidateData && requestI9Link(candidateData);
  }, [candidateData, requestI9Link]);

  const redirect = useCallback(() => {
    if (!i9Response?.secureURL) {
      trackI9LinkClick();
      window.open(I9_ADVANTAGE_URL, "_blank");
      return;
    }
    trackI9LinkClick();

    window.open(i9Response?.secureURL, "_blank");
  }, [i9Response, trackI9LinkClick]);

  // https://i.amazon.com/ASH-3077
  const shouldEnableButtonCSJob = (): boolean => {
    return (
      i9Response?.status?.toString() !== US_CAAS_STATUS.PendingSection1.toString() && isCustomerService(applicationId)
    );
  };

  if (!status || country === SUPPORTED_COUNTRY.IN) {
    return null;
  }
  // remove the action button if it is on IN, IE, ZA and PH
  if (country in COUNTRIES_WITH_WORK_AUTHORIZATION_TASK_TITLE) return null;

  const shouldDisableButton =
    overrideStatus !== StatusEnum.ACTION_REQUIRED || shouldEnableButtonCSJob() || isI9ErrorCode || !i9Response.secureURL
      ? true
      : false;

  return (
    <Button
      variant={ButtonVariant.Primary}
      onClick={redirect}
      disabled={shouldDisableButton}
      data-testid="I9TaskCardButton"
    >
      {resolveButtonText()}
    </Button>
  );
};
