import React, { useState, useEffect } from "react";
import { H2, Text } from "../../../../../components/typography";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { UploadCard } from "../upload-card";
import { useChecklistArb } from "../../../../../hooks/use-arb";
import { AttachmentType, UploadDetails, AttachmentSubType } from "../../models";
import { submitDocument } from "../../requests/requests";
import { useParams } from "react-router-dom";
import { deleteCachedRequest } from "../../../../../utility/candidate-data";
import { MessageBanner, MessageBannerType } from "@amzn/stencil-react-components/message-banner";
import {
  useGetPresignedURLToCDSMutation,
  useSubmitDocToCDSFromPresignedURLMutation,
  useUpdateCDSDocumentUploadMetadataMutation,
} from "../../../../../reduxStore/api/apiSlice";
import { dispatchOnDev, isDevEnv } from "../../../../../utility/dev-env-helpers";
import { useBackToMainPage } from "../../helpers/useBackToMainPage.hook";
import { TEMPORARY_SUBMIT_BUTTON_DISABLED_SOLUTION_SELECTOR } from "../../../../../utility/constants/common";
import { useTemporarySubmitDisabledSolution } from "../../helpers/useTemporarySubmitDisabledSolution.hook";
import { useGetCandidateData } from "../../../../../hooks/apis/useGetCandidateData";
import { DebounceButton } from "../../../../../components/common/DebounceButton";

interface FrontUploadLayoutParams {
  documentType: string;
}
/**
 * This function is used for upload following document
 *
 * Mexican Passport (front, back optional)
 * Military Service Card (front, back optional)
 * Mexican Birth Certificate (front, back optional)
 * Naturalization Certificate (front, back optional)
 */
export function FrontUploadLayout({ documentType }: FrontUploadLayoutParams) {
  const { requisitionId, applicationId } = useParams<{
    requisitionId: string;
    applicationId: string;
  }>();
  const candidateData = useGetCandidateData();
  const [getPresignedURL] = useGetPresignedURLToCDSMutation();
  const [submitDocToCDS] = useSubmitDocToCDSFromPresignedURLMutation();
  const [updateDocMetadataToCDS] = useUpdateCDSDocumentUploadMetadataMutation();

  const bundle = useChecklistArb();

  let titleMessage;
  let textMessage;
  if (documentType === AttachmentType.MEXICAN_PASSPORT) {
    titleMessage = bundle.getMessage("Checklist-Document-Mexican-Passport-Title");
    textMessage = bundle.getMessage("Checklist-Document-Mexican-Passport-Text");
  } else if (documentType === AttachmentType.MILITARY_SERVICE_CARD) {
    titleMessage = bundle.getMessage("Checklist-Document-Military-Service-Card-Title");
    textMessage = bundle.getMessage("Checklist-Document-Military-Service-Card-Text");
  } else if (documentType === AttachmentType.MEXICAN_BIRTH_CERTIFICATE) {
    titleMessage = bundle.getMessage("Checklist-Document-Mexican-Birth-Certificate-Title");
    textMessage = bundle.getMessage("Checklist-Document-Mexican-Birth-Certificate-Text");
  } else if (documentType === AttachmentType.NATURALIZATION_CERTIFICATE) {
    titleMessage = bundle.getMessage("Checklist-Document-Naturalization-Certificate-Title");
    textMessage = bundle.getMessage("Checklist-Document-Naturalization-Certificate-Text");
  } else if (documentType === AttachmentType.INM_WORK_AUTHORIZATION) {
    titleMessage = bundle.getMessage("Checklist-Document-INM-Work-Authorization-Title");
    textMessage = bundle.getMessage(
      "Checklist-Document-Temporary-Resident-Student-Card-INM-Work-Authorization-Subtitle2"
    );
  } else if (documentType === AttachmentType.TEMPORARY_CONSULAR_RESIDENCE_VISA) {
    titleMessage = bundle.getMessage("Checklist-Document-Temporary-Consular-Residence-Visa-Title");
    textMessage = bundle.getMessage("Checklist-Document-Temporary-Consular-Residence-Visa-INM-FMM-Subtitle1");
  } else if (documentType === AttachmentType.INM) {
    titleMessage = bundle.getMessage("Checklist-Document-INM-Title");
    textMessage = bundle.getMessage("Checklist-Document-Temporary-Consular-Residence-Visa-INM-FMM-Subtitle2");
  } else if (documentType === AttachmentType.FMM) {
    titleMessage = bundle.getMessage("Checklist-Document-FMM-Title");
    textMessage = bundle.getMessage("Checklist-Document-Temporary-Consular-Residence-Visa-INM-FMM-Subtitle3");
  } else if (documentType === AttachmentType.OTHER) {
    titleMessage = bundle.getMessage("Checklist-Document-Other-Title");
    textMessage = bundle.getMessage("Checklist-Document-Other-Text");
  }
  const imageSubmitMessage = bundle.getMessage("Checklist-Document-Image-Submit-ButtonText");
  const maximumFileSizeMessage = bundle.getMessage("Checklist-Document-Maximum-File-Size-Text");
  const acceptableFileText = bundle.getMessage("Checklist-Document-Acceptable-File-Text");
  const documentUploadDependencyServiceErrorMessage = bundle.getMessage("Checklist-Document-General-Error-Message");
  const p0LimitFixMessage = bundle.getMessage("p0LimitFixMessage");
  const otherMerge = bundle.getMessage("Checklist-Document-Other-Merge");

  const frontErrorMessageBanner = bundle.getMessage("Checklist-Document-General-Error-Front-Message");
  const backErrorMessageBanner = bundle.getMessage("Checklist-Document-General-Error-Back-Message");

  const [frontDocument, setFrontDocument] = useState<UploadDetails>();
  const [backDocument, setBackDocumnet] = useState<UploadDetails>();
  const [blockSubmitButton, setBlockSubmitButton] = useState<boolean>(true);
  const [dependencyServiceErrorMessage, setDependencyServiceErrorMessage] = useState<string>();
  const [frontErrorMessage, setFrontErrorMessage] = useState<string>();
  const [backErrorMessage, setBackErrorMessage] = useState<string>();

  const backToMainPage = useBackToMainPage();
  useTemporarySubmitDisabledSolution(false);

  useEffect(() => {
    // Back side is optional but should disable when back error message present
    setBlockSubmitButton(!frontDocument?.imageBase64 || !!backErrorMessage);
  }, [frontDocument, backErrorMessage]);

  const updateDocument = async (
    image: string,
    fileName: string,
    contentType: string,
    side: string,
    applicationId?: string
  ) => {
    if (candidateData) {
      const documentData = {
        documentType: documentType as AttachmentType,
        attachmentSubType: side,
        imageBase64: image,
        fileName: fileName,
        contentType: contentType,
        candidateId: candidateData?.candidateId,
      };

      if (side === AttachmentSubType.FRONT) {
        setFrontDocument(documentData);
      } else {
        setBackDocumnet(documentData);
      }
    } else {
      dispatchOnDev(() => {
        console.error("candidateData is not ready yet");
      });
    }
  };

  const clickEventSubmitDocument = async () => {
    const inputFront = document.getElementById("front-index");
    const inputBack = document.getElementById("front-back");

    if (isDevEnv()) console.log("start submit document");

    if (frontErrorMessage || backErrorMessage) {
      if (backErrorMessage) {
        inputBack?.focus();
      }
      if (frontErrorMessage) {
        inputFront?.focus();
      }

      return;
    }

    if (!frontDocument?.imageBase64) {
      inputFront?.focus();
      setFrontErrorMessage(frontErrorMessageBanner);
      return;
    }

    let returnToMainPage = true;
    if (frontDocument) {
      try {
        const docs = [frontDocument];
        // Back is optional
        if (backDocument?.imageBase64) {
          docs.push(backDocument);
        }
        await submitDocument(
          getPresignedURL,
          submitDocToCDS,
          updateDocMetadataToCDS,
          applicationId,
          docs,
          setBlockSubmitButton
        );
      } catch (err) {
        console.log(err);
        setBlockSubmitButton(false);
        setDependencyServiceErrorMessage(documentUploadDependencyServiceErrorMessage);
        returnToMainPage = false;
      }
    }

    deleteCachedRequest(applicationId); // delete stale data so that updated Id loads on home page
    if (returnToMainPage) {
      backToMainPage();
    }
  };

  const frontErrorMessageText =
    frontErrorMessage === frontErrorMessageBanner ? frontErrorMessageBanner : frontErrorMessage;
  const backErrorMessageText = backErrorMessage === backErrorMessageBanner ? backErrorMessageBanner : backErrorMessage;

  return (
    <Col display={"flex"} justifyContent={"center"} gridGap={6} width={"100%"}>
      <H2>{titleMessage}</H2>
      <Text>
        {textMessage} {textMessage && <strong style={{ color: "red" }}>*</strong>}{" "}
      </Text>
      <Text>{acceptableFileText}</Text>
      <Text>{maximumFileSizeMessage}</Text>
      <Row padding={{ top: "10px", bottom: "10px" }} gridGap="S400">
        <UploadCard
          side={AttachmentSubType.FRONT}
          documentDetailsFn={updateDocument}
          setErrorMessage={setFrontErrorMessage}
          setDependencyErrorMessage={setDependencyServiceErrorMessage}
          applicationId={applicationId}
          attachmentTypeName={titleMessage}
          customId={`front-index`}
          hasError={!!frontErrorMessage}
        />
        <UploadCard
          side={AttachmentSubType.BACK}
          optional={true}
          documentDetailsFn={updateDocument}
          setErrorMessage={setBackErrorMessage}
          setDependencyErrorMessage={setDependencyServiceErrorMessage}
          applicationId={applicationId}
          attachmentTypeName={titleMessage}
          customId={`front-back`}
          hasError={!!backErrorMessage}
        />
      </Row>
      {dependencyServiceErrorMessage && (
        <MessageBanner type={MessageBannerType.Error}>{dependencyServiceErrorMessage}</MessageBanner>
      )}
      {frontErrorMessage && (
        <MessageBanner type={MessageBannerType.Error}>
          <Text id={`front-index-description`}>{frontErrorMessageText}</Text>
        </MessageBanner>
      )}
      {backErrorMessage && (
        <MessageBanner type={MessageBannerType.Error}>
          <Text id={`front-back-description`}>{backErrorMessageText}</Text>
        </MessageBanner>
      )}
      {documentType === AttachmentType.OTHER && (
        <Row padding={{ top: "10px", bottom: "10px" }}>
          <MessageBanner type={MessageBannerType.Informational}>{otherMerge}</MessageBanner>
        </Row>
      )}
      <Row padding={{ top: "10px", bottom: "10px" }}>
        <MessageBanner type={MessageBannerType.Informational}>{p0LimitFixMessage}</MessageBanner>
      </Row>
      <DebounceButton
        onClick={clickEventSubmitDocument}
        id={TEMPORARY_SUBMIT_BUTTON_DISABLED_SOLUTION_SELECTOR}
        style={{ visibility: "hidden" }}
      >
        {imageSubmitMessage}
      </DebounceButton>
    </Col>
  );
}
