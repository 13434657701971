import React from "react";
import { Text } from "@amzn/stencil-react-components/text";
import "./styles.css";
import { useChecklistArb } from "../../../../../hooks/use-arb";

export const IndicatorPhotoTooSmall = () => {
  const bundle = useChecklistArb();
  const error = bundle.getMessage("Checklist-BPFaceDetection-TooSmall");
  return (
    <div className="indicator">
      <Text className="message">
        <p>{error}</p>
      </Text>
    </div>
  );
};

export const IndicatorBrightness = () => {
  const bundle = useChecklistArb();
  const error = bundle.getMessage("Checklist-BPFaceDetection-Brightness");
  return (
    <div className="indicator">
      <Text className="message">
        <p className="emoji">💡</p>
        <p>{error}</p>
      </Text>
    </div>
  );
};

export const IndicatorPosition = () => {
  const bundle = useChecklistArb();
  const error = bundle.getMessage("Checklist-BPFaceDetection-Position");
  return (
    <div className="indicator">
      <Text className="message">
        <p>{error}</p>
      </Text>
    </div>
  );
};

export const IndicatorNoFace = () => {
  const bundle = useChecklistArb();
  const error = bundle.getMessage("Checklist-BPFaceDetection-NoFace");
  return (
    <div className="indicator">
      <Text className="message">
        <p>{error}</p>
      </Text>
    </div>
  );
};

export const IndicatorManyFaces = () => {
  const bundle = useChecklistArb();
  const error = bundle.getMessage("Checklist-BPFaceDetection-ManyFaces");
  return (
    <div className="indicator">
      <Text className="message">
        <p>{error}</p>
      </Text>
    </div>
  );
};
