import React, { useMemo } from "react";
import { Col, Row, View } from "@amzn/stencil-react-components/layout";
import { IconCalendarFill, IconCheckCircleFill, IconLocationFill } from "@amzn/stencil-react-components/icons";
import { Expander, ExpanderChevronPosition } from "@amzn/stencil-react-components/expander";
import moment from "moment";
import DOMPurify from "dompurify";
import { Text } from "../typography";
import { useChecklistArb } from "../../hooks/use-arb";
import { SdaInfo } from "../../utility/application-data";
import { processTime } from "../../utility/time-processor";
import "./style.scss";
import "../sda/styles.scss";
import { GoogleMapLink } from "../google-map-link";
import { isSalesforceApplication } from "../../helpers/url-params";
import { useParams } from "react-router-dom";
import { Button, ButtonSize } from "@amzn/stencil-react-components/button";
import { useNavigatorWithURLReady } from "../../hooks/use-navigator";
import { useAppSelector } from "../../reduxStore/reduxHooks";
import { getFeatureFlag } from "../../reduxStore/slices/configSlice";

export interface FirstDayDetailsProps {
  startDate?: string;
  address?: string;
  sdaDetails?: SdaInfo;
  isVirtual?: boolean;
  cssURLWithoutScheduleId?: string;
}

export const FirstDayDetails = ({
  startDate,
  address,
  sdaDetails,
  isVirtual,
  cssURLWithoutScheduleId,
}: FirstDayDetailsProps) => {
  const { applicationId } = useParams<{ applicationId: string }>();

  const { isEligibleForCss } = useAppSelector(getFeatureFlag);

  const bundle = useChecklistArb();

  const whenAndWhere = bundle.getMessage("Checklist-PreHireDetails-WhenAndWhere");
  const googleMapLink = <GoogleMapLink isVirtual={isVirtual} address={address} idAddress={address} />;

  const cannotAttend = bundle.formatMessage("Checklist-PreHireDetails-CannotAttend");
  const reschedule = bundle.getMessage("Checklist-AppointmentCard-ButtonText2");

  let plainStartDateAndStartTime;
  if (!isSalesforceApplication(applicationId) && sdaDetails) {
    const startTime = sdaDetails.startTime;
    const processedStartTime = processTime(startTime);
    const startDateAndStartTime = startDate + " " + processedStartTime;
    plainStartDateAndStartTime = moment(startDateAndStartTime, "YYYY-MM-DD hh:mm A"); // Display site time without providing timezone to keep site time
  } else {
    plainStartDateAndStartTime = moment(startDate, "YYYY-MM-DD").toDate();
  }

  const date = bundle.formatMessage("Checklist-PreHireDetails-Date", {
    date: plainStartDateAndStartTime,
  });

  const time = bundle.formatMessage("Checklist-PreHireDetails-Time", {
    time: plainStartDateAndStartTime,
  });

  const locationDetails = bundle.formatMessage("Checklist-PreHireDetails-Location", {
    address: googleMapLink,
  });
  const purifiedHtml = useMemo(() => DOMPurify.sanitize(sdaDetails?.publicText as string), [sdaDetails?.publicText]);

  const CustomRow = (children: any) => <Row gridGap="0.5rem">{children}</Row>;

  return (
    <Col className="flyout-content" gridGap="1rem" width="100%" data-testid="first-day-details-flyout-content">
      <Row>
        <View width="100%">
          <Expander
            titleText={whenAndWhere}
            shouldExpandOnMount={true}
            chevronPosition={ExpanderChevronPosition.Trailing}
          >
            <Col gridGap="1rem">
              <CustomRow
                {...(
                  <>
                    <IconCalendarFill />
                    <Text data-testid={`first-day-flyout-date-${startDate}`}>{date}</Text>
                  </>
                )}
              ></CustomRow>
              {!isSalesforceApplication(applicationId) && sdaDetails && (
                <CustomRow
                  {...(
                    <>
                      <IconCheckCircleFill />
                      <Text data-testid={`first-day-flyout-time-${sdaDetails.startTime}`}>{time}</Text>
                    </>
                  )}
                ></CustomRow>
              )}
              <CustomRow
                {...(
                  <>
                    <IconLocationFill />
                    <Text>{locationDetails}</Text>
                  </>
                )}
              ></CustomRow>
              {!isSalesforceApplication(applicationId) && isEligibleForCss && cssURLWithoutScheduleId && (
                <Row justifyContent="space-between" alignItems="center">
                  <Text fontSize="T100">
                    <strong>{cannotAttend}</strong>
                  </Text>
                  <Button as={"a"} size={ButtonSize.Small} onClick={useNavigatorWithURLReady(cssURLWithoutScheduleId)}>
                    {reschedule}
                  </Button>
                </Row>
              )}
            </Col>
          </Expander>
        </View>
      </Row>
      <Row>
        {sdaDetails && (
          <Text data-testid="first-day-flyout-public-text">
            <div className="sf-html-container" dangerouslySetInnerHTML={{ __html: purifiedHtml }}></div>
          </Text>
        )}
      </Row>
    </Col>
  );
};
