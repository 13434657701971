import { useEffect } from "react";
import { useLazyGetCandidateDataQuery } from "../../reduxStore/api/apiSlice";
import { useGetApplicationId } from "./useGetApplicationId";
import { useAppSelector } from "../../reduxStore/reduxHooks";
import { getConfigData, setConfigData } from "../../reduxStore/slices/configSlice";
import { CONFIG_DATA_KEY } from "../../utility/enums/common";
import { useDispatch } from "react-redux";

export const useGetCandidateData = () => {
  const { candidateData } = useAppSelector(getConfigData);
  const dispatch = useDispatch();
  const applicationId = useGetApplicationId();
  const [getCandidateData] = useLazyGetCandidateDataQuery();

  useEffect(() => {
    if (candidateData) return;

    if (applicationId) {
      getCandidateData({ applicationId }, true)
        .unwrap()
        .then((data) => {
          dispatch(
            setConfigData({
              key: CONFIG_DATA_KEY.CANDIDATE_DATA,
              value: data,
            })
          );
        });
    }
  }, [applicationId, getCandidateData, candidateData]);

  return candidateData;
};
