import { UploadDetails, AttachmentType } from "../models";
import { MutationTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  MutationDefinition,
} from "@reduxjs/toolkit/dist/query";

export interface PresignedURLResponse {
  presignedUrl: string;
}

export const submitDocument = async (
  getPresignedURL: MutationTrigger<
    MutationDefinition<
      {
        candidateId: string;
        applicationId: string;
        uploadDetails: UploadDetails;
      },
      BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, any, FetchBaseQueryMeta>,
      string,
      PresignedURLResponse,
      "api"
    >
  >,
  submitDocToCDS: MutationTrigger<
    MutationDefinition<
      {
        presignedUrl: string;
        headers: Headers;
        body: "" | Buffer | undefined;
      },
      BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, any, FetchBaseQueryMeta>,
      string,
      any,
      "api"
    >
  >,
  updateDocMetadataToCDS: MutationTrigger<
    MutationDefinition<
      {
        candidateId: string;
        applicationId: string;
        uploadDetails: UploadDetails;
      },
      BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, any, FetchBaseQueryMeta>,
      string,
      any,
      "api"
    >
  >,
  applicationId: string,
  uploadDetailsList: UploadDetails[],
  setBlockSubmitButton: any
) => {
  setBlockSubmitButton(true);
  if (process.env.NODE_ENV === "development" && process.env.REACT_APP_USE_MOCK_REQUESTS === "true") {
    return []; // Mock empty result for now since we don't inspect response body
  }

  for (const uploadDetails of uploadDetailsList) {
    const updatedUploadDetails = { ...uploadDetails };
    delete updatedUploadDetails.imageBase64;

    if (!uploadDetails.candidateId) {
      throw new Error("candidate id not found for document submission!");
    }
    await getPresignedURL({
      applicationId,
      uploadDetails: updatedUploadDetails,
      candidateId: uploadDetails.candidateId,
    })
      .unwrap()
      .then(async ({ presignedUrl }) => {
        const header = new Headers({
          "Content-Type": uploadDetails.contentType,
        });

        const buffer = uploadDetails.imageBase64 && Buffer.from(uploadDetails.imageBase64, "base64");
        submitDocToCDS({
          presignedUrl,
          body: buffer,
          headers: header,
        })
          .unwrap()
          .catch((err) => {
            throw new Error("Upload failure due to dependent service error.");
          });
      });
  }

  //wait for all the documents are submitted, and then proceed to update metadata to CDS
  for (const uploadDetails of uploadDetailsList) {
    const updatedUploadDetails = { ...uploadDetails };
    delete updatedUploadDetails.imageBase64;
    delete updatedUploadDetails.expirationDate;
    await updateDocMetadataToCDS({
      applicationId,
      candidateId: uploadDetails.candidateId as string,
      uploadDetails: updatedUploadDetails,
    });
  }
  setBlockSubmitButton(false);
};

export interface IdDataParams {
  nationalIdType: AttachmentType;
  idNumber?: string;
  expirationDate?: string;
}
