import React from "react";
import { Text } from "@amzn/stencil-react-components/text";
import { ChecklistBundle } from "../../../../hooks/use-arb";

export const getUSChecklistMappingDefault = ({ bundle }: { bundle: ChecklistBundle }) => {
  const translatedSectionOne = bundle.getMessage("Checklist-MainPage-I9-Section1");
  const translatedSectionTwo = bundle.getMessage("Checklist-MainPage-I9-Section2");
  const translatedCompletedAtAppointment = bundle.getMessage("Checklist-MainPage-I9-CompleteAtAppointment");
  const translatedActionRequiredBeforeAppointment = bundle.getMessage(
    "Checklist-MainPage-I9-ActionRequiredBeforeAppointment"
  );

  const usDefaultChecklistSection = (
    <>
      <Text>
        {translatedSectionOne} &#183; {translatedActionRequiredBeforeAppointment}
      </Text>
      <Text>
        {translatedSectionTwo} &#183; {translatedCompletedAtAppointment}
      </Text>
    </>
  );

  return usDefaultChecklistSection;
};
