import { Row } from "@amzn/stencil-react-components/layout";
import { Text } from "@amzn/stencil-react-components/text";
import React from "react";
import { HVH_COLORS } from "../../../../config/palette";
import { useChecklistArb } from "../../../../hooks/use-arb";
import { RIGHT_TO_WORK_TASK_DEFINITION_ID } from "../../../../config/appConfigConstants";
import { getTaskDefinitionsFromConfig } from "../../../../utility/app-config-helper";
import LinkWithRedirect from "../../../common/LinkWithRedirect";

interface GbNonCompleteInstructionProps {
  applicationId: string;
}

export const GbNonCompleteInstruction = ({ applicationId }: GbNonCompleteInstructionProps) => {
  const bundle = useChecklistArb();

  const rightToWorkGeneralMessage = bundle.formatMessage("Checklist-RightToWork-Detail-GeneralMessage");

  const taskDefinitions = getTaskDefinitionsFromConfig(RIGHT_TO_WORK_TASK_DEFINITION_ID, applicationId);

  const requiredDocUrl = taskDefinitions?.requiredDocumentURL ? taskDefinitions.requiredDocumentURL : "";

  const rightToWorkMessage1 = bundle.formatMessage("Checklist-RightToWork-Detail-Message1", {
    requiredDoc: (
      <LinkWithRedirect
        link={requiredDocUrl}
        messageKey="Checklist-RTW-Required-Doc-Link"
        messagePlaceholder="See a list of acceptable documents prior to accessing IDVT."
      />
    ),
  });

  const rightToWorkMessage2 = bundle.getMessage("Checklist-RightToWork-Detail-Message2");
  const rightToWorkMessage3 = bundle.getMessage("Checklist-RightToWork-Detail-Message3");
  const rightToWorkMessage4 = bundle.getMessage("Checklist-RightToWork-Detail-Message4");

  return (
    <>
      <Row>
        <Text fontSize={"T200"}>{rightToWorkGeneralMessage}</Text>
      </Row>
      <Row>
        <Text fontSize={"T200"}>1.{rightToWorkMessage1}</Text>
      </Row>
      <Row>
        <Text fontSize={"T200"}>2.{rightToWorkMessage2}</Text>
      </Row>
      <Row>
        <Text fontSize={"T200"}>3.{rightToWorkMessage3}</Text>
      </Row>
      <Row>
        <Text fontSize={"T200"} color={HVH_COLORS.NEUTRAL_70}>
          {rightToWorkMessage4}
        </Text>
      </Row>
    </>
  );
};
