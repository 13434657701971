import React, { useCallback, useEffect, useState } from "react";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { H3, Text } from "@amzn/stencil-react-components/text";
import { Card } from "@amzn/stencil-react-components/card";
import { renderBGColor } from "../task";
import { getCountryForDomain } from "../../utility/locale-helper";
import { useChecklistArb } from "../../hooks/use-arb";
import { getJobType, getTaskDefinitionsFromConfig, isCustomerService } from "../../utility/app-config-helper";
import {
  CUSTOMER_SERVICE,
  JobType,
  SUPPORTED_COUNTRY,
  RIGHT_TO_WORK_TASK_DEFINITION_ID,
} from "../../config/appConfigConstants";
import "./style.scss";
import { I9TaskCardProps } from "../../utility/types/common";
import { GbNonCompleteInstruction } from "./InstructionsMapping/NotComplete/GbNonCompleteInstruction";
import { getUSChecklistMappingCAAS } from "./ChecklistMapping/CAAS/US";
import { getGBChecklistMappingCAAS } from "./ChecklistMapping/CAAS/GB";
import { getUSChecklistMappingDefault } from "./ChecklistMapping/Default/US";
import { getGBChecklistMappingDefault } from "./ChecklistMapping/Default/GB";
import { getUSInstructionMapping } from "./InstructionsMapping/US";
import { getGBInstructionMapping } from "./InstructionsMapping/GB";
import { ErrorCode } from "./i9-error-message";
import { getIEInstructionMapping } from "./InstructionsMapping/IE";
import { getZAInstructionMapping } from "./InstructionsMapping/ZA";
import { getINInstructionMapping } from "./InstructionsMapping/IN";
import { getPHInstructionMapping } from "./InstructionsMapping/PH";
import { getJPInstructionMapping } from "./InstructionsMapping/JP";
import { getCAInstructionMapping } from "./InstructionsMapping/CA";
import { getZAChecklistMappingDefault } from "./ChecklistMapping/Default/ZA";
import { getIEChecklistMappingDefault } from "./ChecklistMapping/Default/IE";
import { getINChecklistMappingDefault } from "./ChecklistMapping/Default/IN";
import { getPHChecklistMappingDefault } from "./ChecklistMapping/Default/PH";
import { getJPChecklistMappingDefault } from "./ChecklistMapping/Default/JP";
import { getCAChecklistMappingDefault } from "./ChecklistMapping/Default/CA";
import { getZAChecklistMappingCAAS } from "./ChecklistMapping/CAAS/ZA";
import { getPHChecklistMappingCAAS } from "./ChecklistMapping/CAAS/PH";
import { getIEChecklistMappingCAAS } from "./ChecklistMapping/CAAS/IE";
import { getINChecklistMappingCAAS } from "./ChecklistMapping/CAAS/IN";
import { getJPChecklistMappingCAAS } from "./ChecklistMapping/CAAS/JP";
import { getCAChecklistMappingCAAS } from "./ChecklistMapping/CAAS/CA";
import { nonCompleteInstructionConfigUpdated } from "./InstructionsMapping/NotComplete/nonCompleteInstruction.config";
import { TextBuilder, TextBuilderConfigArray } from "../TextBuilder";
import { useAppSelector } from "../../reduxStore/reduxHooks";
import { getConfigData, getFeatureFlag } from "../../reduxStore/slices/configSlice";
import { useGetApplicationId } from "../../hooks/apis/useGetApplicationId";

export type InstructionMapping = Record<JobType, Record<string, JSX.Element | null>>;

interface GetIncompleteInstructionProps {
  applicationId: string;
  country: SUPPORTED_COUNTRY;
  blurbConfig: Record<SUPPORTED_COUNTRY, TextBuilderConfigArray>;
}

export const getIncompleteInstruction = ({ applicationId, country, blurbConfig }: GetIncompleteInstructionProps) => {
  switch (country) {
    case SUPPORTED_COUNTRY.GB:
      return <GbNonCompleteInstruction applicationId={applicationId} />;
    case SUPPORTED_COUNTRY.CA: // TODO: confirm we use default blurb setup for CA
    case SUPPORTED_COUNTRY.PH:
    case SUPPORTED_COUNTRY.IN:
    case SUPPORTED_COUNTRY.IE:
    case SUPPORTED_COUNTRY.ZA:
    case SUPPORTED_COUNTRY.JP:
    case SUPPORTED_COUNTRY.BR:
    case SUPPORTED_COUNTRY.CO:
    case SUPPORTED_COUNTRY.CR:
    case SUPPORTED_COUNTRY.MX:
    case SUPPORTED_COUNTRY.JO:
    case SUPPORTED_COUNTRY.EG:
      return <TextBuilder config={blurbConfig} country={country} />;
    default:
      return null;
  }
};

export const I9TaskCard = ({ status, shouldOverrideStatus }: I9TaskCardProps) => {
  const bundle = useChecklistArb();
  const country = getCountryForDomain();

  const applicationId = useGetApplicationId();

  const { isEligibleToSkipAppt1, isAlumni } = useAppSelector(getFeatureFlag);
  const { caasI9Status } = useAppSelector(getConfigData);

  const taskDefinitions = getTaskDefinitionsFromConfig(RIGHT_TO_WORK_TASK_DEFINITION_ID, applicationId);

  const [i9ErrorCode, setI9ErrorCode] = useState<ErrorCode | undefined>();

  const requestI9Link = useCallback(async () => {
    try {
      if (caasI9Status !== "SKIPPED" && caasI9Status?.errorCode) {
        setI9ErrorCode(caasI9Status.errorCode.toString() as ErrorCode);
      } else {
        setI9ErrorCode(undefined);
      }
    } catch (e) {
      setI9ErrorCode(ErrorCode.Internal);
    }
  }, [caasI9Status]);

  useEffect(() => {
    const taskDefinitions = getTaskDefinitionsFromConfig(RIGHT_TO_WORK_TASK_DEFINITION_ID, applicationId);

    if (!taskDefinitions || taskDefinitions.isIntegratedWithCaaS) {
      requestI9Link();
    }
  }, [requestI9Link, applicationId]);

  const translatedStatus = bundle.getMessage("checklist-General-Status");
  const translatedDetails = bundle.getMessage("Checklist-CardControlled-DetailsTitle");

  const resolveInstructions = () => {
    const jobType = getJobType(applicationId);

    const instructionMapping: Partial<Record<SUPPORTED_COUNTRY, InstructionMapping>> = {
      [SUPPORTED_COUNTRY.US]: getUSInstructionMapping({
        bundle,
        i9ErrorCode,
        isAlumni: !!isAlumni,
      }),
      [SUPPORTED_COUNTRY.GB]: getGBInstructionMapping(),
      [SUPPORTED_COUNTRY.IE]: getIEInstructionMapping(),
      [SUPPORTED_COUNTRY.ZA]: getZAInstructionMapping(),
      [SUPPORTED_COUNTRY.IN]: getINInstructionMapping(),
      [SUPPORTED_COUNTRY.PH]: getPHInstructionMapping(),
      [SUPPORTED_COUNTRY.JP]: getJPInstructionMapping(),
      [SUPPORTED_COUNTRY.CA]: getCAInstructionMapping({ bundle }),
    };

    const instruction = instructionMapping?.[country]?.[jobType]?.[status!];

    const blurbConfig = nonCompleteInstructionConfigUpdated(bundle);

    if (isEligibleToSkipAppt1) {
      return (
        <Text>
          {bundle.getMessage(
            "Checklist-MainPage-Alumni-SkipNHE",
            "Since you have previously completed a Form I-9 with Amazon, you do not need to take any action at this time. If anything else is needed from you for this step, Amazon will reach out to you by phone or email."
          )}
        </Text>
      );
    }

    if (!instruction) {
      return getIncompleteInstruction({ applicationId, country, blurbConfig });
    }

    return instruction;
  };

  const resolveDefaultChecklist = (country: SUPPORTED_COUNTRY, jobType: JobType) => {
    const defaultChecklistMapping: { [key in SUPPORTED_COUNTRY]?: any } = {
      [SUPPORTED_COUNTRY.US]: getUSChecklistMappingDefault({ bundle }),
      [SUPPORTED_COUNTRY.GB]: getGBChecklistMappingDefault({ bundle }),
      [SUPPORTED_COUNTRY.ZA]: getZAChecklistMappingDefault({ bundle }),
      [SUPPORTED_COUNTRY.IE]: getIEChecklistMappingDefault({ bundle }),
      [SUPPORTED_COUNTRY.IN]: getINChecklistMappingDefault({ bundle }),
      [SUPPORTED_COUNTRY.PH]: getPHChecklistMappingDefault({ bundle }),
      [SUPPORTED_COUNTRY.JP]: getJPChecklistMappingDefault({ bundle }),
      [SUPPORTED_COUNTRY.CA]: getCAChecklistMappingDefault({ bundle }),
    };

    const defaultChecklist = defaultChecklistMapping[country];
    const taskDefinitions = getTaskDefinitionsFromConfig(RIGHT_TO_WORK_TASK_DEFINITION_ID, applicationId);

    if (
      country === SUPPORTED_COUNTRY.GB &&
      jobType === CUSTOMER_SERVICE &&
      taskDefinitions &&
      !taskDefinitions.isIntegratedWithCaaS
    ) {
      return null;
    }

    return defaultChecklist;
  };

  const resolveChecklist = () => {
    const jobType = getJobType(applicationId);

    const checklistMapping: { [key in SUPPORTED_COUNTRY]?: any } = {
      [SUPPORTED_COUNTRY.US]: getUSChecklistMappingCAAS({ bundle }),
      [SUPPORTED_COUNTRY.GB]: getGBChecklistMappingCAAS({ bundle }),
      [SUPPORTED_COUNTRY.ZA]: getZAChecklistMappingCAAS({ bundle }),
      [SUPPORTED_COUNTRY.PH]: getPHChecklistMappingCAAS({ bundle }),
      [SUPPORTED_COUNTRY.IE]: getIEChecklistMappingCAAS({ bundle }),
      [SUPPORTED_COUNTRY.IN]: getINChecklistMappingCAAS({ bundle }),
      [SUPPORTED_COUNTRY.JP]: getJPChecklistMappingCAAS({ bundle }),
      [SUPPORTED_COUNTRY.CA]: getCAChecklistMappingCAAS({ bundle }),
    };

    const checklist = checklistMapping?.[country]?.[jobType]?.[status!];

    if (checklist) {
      return checklist;
    }

    return resolveDefaultChecklist(country, jobType);
  };

  const instructions = resolveInstructions();
  const checklist = resolveChecklist();

  const notEligibleForRenderingCountryList = [
    SUPPORTED_COUNTRY.PH,
    SUPPORTED_COUNTRY.IN,
    SUPPORTED_COUNTRY.IE,
    SUPPORTED_COUNTRY.JP,
  ];

  const isNotEligibleForRenderingChecklist = () => {
    if (notEligibleForRenderingCountryList.includes(country)) {
      return true;
    }

    if (isEligibleToSkipAppt1) {
      return true;
    }

    return (
      country !== SUPPORTED_COUNTRY.US && // TODO: should be removed once integration with CaaS is done
      isCustomerService(applicationId) &&
      taskDefinitions &&
      !taskDefinitions.isIntegratedWithCaaS
    );
  };

  return (
    <Col padding={"S300"} gridGap={"S300"}>
      {isNotEligibleForRenderingChecklist() ? null : (
        <Card padding={"S100"}>
          <Row>
            <Col backgroundColor={renderBGColor(status, shouldOverrideStatus)} width={"S200"} />
            {checklist && (
              <Col gridGap={"S300"} padding={"S300"}>
                <H3 fontSize={"T400"}>{translatedStatus}</H3>
                {checklist}
              </Col>
            )}
          </Row>
        </Card>
      )}
      {instructions && (
        <Card padding={"S100"}>
          <Col gridGap={"S300"} padding={"S300"}>
            <H3 fontSize={"T400"}>{translatedDetails}</H3>
            {instructions}
          </Col>
        </Card>
      )}
    </Col>
  );
};
