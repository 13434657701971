import { useBundle } from "@amzn/react-arb-tools";
import { useCallback } from "react";
import { ChecklistPantherKey } from "../utility/types/translations";

interface ArbBundle {
  getMessage: (value: string, defaultString?: string) => string;
  formatMessage: (value: string, ...args: any) => string;
}

/**
 * Restricted bundle with type check on keys to prevent bugs due to invalid key usage
 */
export interface ChecklistBundle {
  getMessage: (value: ChecklistPantherKey, defaultString?: string) => string;
  formatMessage: (value: ChecklistPantherKey, ...args: unknown[]) => string;
}

export function useChecklistArb(): ChecklistBundle {
  const { getMessage, formatMessage } = useArb("HVHChecklistPantherResource");

  // Just rewrap since string is superset of the PantherKey union type
  return {
    getMessage,
    formatMessage,
  };
}

/**
 * A wrapper around the useBundle hook from react-arb-tools.
 *
 * Handles unloaded behavior by returning empty string rather than the message.
 * Logs errors in getting translations to console, then returns the key to
 * prevent the entire application from failing if a key isn't found.
 *
 * @param bundleName Name of the the ARB Bundle to be used.
 * @returns object with getMessage and formatMessage functions, using the same
 * 	signatures as useBundle.
 */
function useArb(bundleName: string): ChecklistBundle {
  /// DO NOT EXPORT THIS FUNCTION. WE NEED TYPE CHECKS TO AVOID BUGS DUE TO INVALID MESSAGE KEYS
  const [bundle, isLoading] = useBundle(bundleName);

  const getMessage = useCallback(
    (value: string, defaultString = "N/A") => {
      if (isLoading) {
        return "";
      } else {
        try {
          return bundle.getMessage(value);
        } catch (e) {
          if (process.env.NODE_ENV !== "development") console.error(e);
          return defaultString;
        }
      }
    },
    [bundle, isLoading]
  );

  const formatMessage = useCallback(
    (value: string, ...args: any) => {
      if (isLoading) {
        return "";
      } else {
        try {
          return bundle.formatMessage(value, ...args);
        } catch (e) {
          console.error(e);
          return "N/A";
        }
      }
    },
    [bundle, isLoading]
  );

  return {
    getMessage,
    formatMessage,
  };
}
