import React from "react";
import { StatusContainer } from "./status-container";
import { H1 } from "@amzn/stencil-react-components/text";
import { Button, ButtonSize, ButtonVariant } from "@amzn/stencil-react-components/button";
import { useChecklistArb } from "../../hooks/use-arb";
import { CONTACT_US_LINK } from "../../utility/constants";
export const DTSchedulingHeader = () => {
  const bundle = useChecklistArb();

  return (
    <StatusContainer
      data-testid="page-header-drug-test-scheduling"
      largeViewPortImage={require("../../assets/images/calendar-large-viewport.png").default}
      smallViewPortImage={require("../../assets/images/calendar-small-viewport.png").default}
    >
      <H1 fontSize={"T400"} fontWeight={"regular"}>
        {bundle.formatMessage("Checklist-DT-Schedule-AppointmentCard-Title")}
      </H1>
      <Button
        data-testid="action-contact-us-to-schedule-drug-test"
        aria-label="contact-us-to-schedule-drug-test"
        variant={ButtonVariant.Primary}
        size={ButtonSize.Small}
        onClick={() => window.open(CONTACT_US_LINK, "_blank")}
      >
        {bundle.formatMessage("Checklist-MainPage-Contact-US")}
      </Button>
    </StatusContainer>
  );
};
