import omit from "lodash/omit";
import { APPLICATION_STATE, SF_APP_STATE } from "../utility/constants/application-status";

export const IS_GLOBAL_ID_ENABLED = "isGlobalIdEnabled";
export const IS_DRUG_TEST_APPOINTMENT_ENABLED = "isDrugTestAppointmentEnabled";
export const IS_DRUG_TEST_RETAKE_APPOINTMENT_ENABLED = "isDrugTestRetakeAppointmentEnabled";
export const IS_NHE_VENUE_TIME_ENABLED = "isNheVenueTimeEnabled";
export const REQUISITION_CATEGORY = "REQUISITION_CATEGORY";
export const TASK_FEATURE_FLAG_CONFIG = "task_feature_flag_config";
export const TASK_DEFINITION_CONFIG = "task_definition_config";
export const IS_DISABLE_I9_GET_CALL = "disableCaasI9GETCall";

/* Config used for defining the taskId for the task definition */
export const BADGE_PHOTO_TASK_ID = "BADGE_PHOTO_TASK";
export const I9_TASK_ID = "I9_TASK";
export const RIGHT_TO_WORK_TASK_DEFINITION_ID = "RIGHT_TO_WORK_TASK_DEFINITION";
export const NHE_VIDEO_TASK_ID = "NHE_VIDEO_TASK";
export const JOB_PREVIEW_VIDEO_TASK_ID = "JOB_PREVIEW_VIDEO_TASK";
export const MYSP_TASK_ID = "MYSP_TASK";
export const EMPLOYMENT_PAPERWORK_TASK_ID = "EMPLOYMENT_PAPERWORK_TASK";
export const ATOZ_TASK_ID = "ATOZ_TASK";
export const DOCUMENT_TASK_ID = "DOCUMENT_TASK";
export const BACKGROUND_CHECK_INFORMATION_ID = "BACKGROUND_CHECK_INFORMATION_TASK";
export const SCHEDULE_RIGHT_TO_WORK_APPOINTMENT_ID = "SCHEDULE_RIGHT_TO_WORK_APPOINTMENT_TASK";
export const EDM_URL_UPDATE = "EDM_URL_UPDATE";

export const CUSTOMER_SERVICE = "Customer Service";
export const NON_CUSTOMER_SERVICE = "Non Customer Service";
export type JobType = typeof CUSTOMER_SERVICE | typeof NON_CUSTOMER_SERVICE;
export const RIGHT_TO_WORK_TASK_TITLE = "RightToWork";

export const SDA_DATE_FORMAT = "ddd MMM DD";
export const JOB_DETAIL_DATE_FORMAT = "YYYY-MM-DD";
export const JOB_DETAIL_TIME_FORMAT = "HH:mm";
export const SDA_TIME_FORMAT = "hh:mmA";

export const FeatFlag = {
  ENABLE_ALL_CONTINGENCIES_BGC: "CAAS_REFACTOR_BGC_TASK",
  ENABLE_ALL_CONTINGENCIES_AIR_BGC: "CAAS_REFACTOR_AIR_BGC_TASK",
  ENABLE_ALL_CONTINGENCIES_MEDICAL_CHECK: "CAAS_REFACTOR_MEDICAL_CHECK_TASK",
  ENABLE_ALL_CONTINGENCIES_DT: "CAAS_REFACTOR_DT_TASK",
  ENABLE_ALL_CONTINGENCIES_AIR_DT: "CAAS_REFACTOR_AIR_DT_TASK",
  ENABLE_ALL_CONTINGENCIES_RTW: "CAAS_REFACTOR_RIGHT_TO_WORK_TASK",
  ENABLE_ALL_CONTINGENCIES_WORK_AUTH: "CAAS_REFACTOR_WORK_AUTH_TASK",
  ENABLE_ZAPPOS_MAGIC_LINK: "ZAPPOS_MAGIC_LINK_TASK",
} as const;

export type FeatureFlag = (typeof FeatFlag)[keyof typeof FeatFlag];

type CountryList = readonly SUPPORTED_COUNTRY[];

// list of ISO-3166 country codes https://github.com/lukes/ISO-3166-Countries-with-Regional-Codes/blob/master/all/all.csv
export enum SUPPORTED_COUNTRY {
  US = "US", // United States
  GB = "GB", // United Kingdom
  MX = "MX", // Mexico
  PH = "PH", // Philippines
  ZA = "ZA", // South Africa
  IE = "IE", // Ireland
  IN = "IN", // India
  CA = "CA", // Canada
  JP = "JP", // Japan
  BR = "BR", // Brazil
  CO = "CO", // Columbia
  CR = "CR", // Costa Rica
  JO = "JO", // Jordan
  EG = "EG", // Egypt
}

export enum CS_SUPPORTED_COUNTRY {
  US = "US", // United States
  GB = "GB", // United Kingdom
  MX = "MX", // Mexico
  PH = "PH", // Philippines
  ZA = "ZA", // South Africa
  IE = "IE", // Ireland
  IN = "IN", // India
  CA = "CA", // Canada
  JP = "JP", // Japan
  BR = "BR", // Brazil
  CO = "CO", // Columbia
  CR = "CR", // Costa Rica
  JO = "JO", // Jordan
  EG = "EG", // Egypt
}

export enum NON_CS_SUPPORTED_COUNTRY {
  US = "US", // United States
  GB = "GB", // United Kingdom
  MX = "MX", // Mexico
  CA = "CA", // Canada
  JP = "JP", // Japan
}

export const ADD_BASE_BEFORE_SALARY_AMOUNT_COUNTRY: CountryList = [SUPPORTED_COUNTRY.MX] as const;

export enum DRUGTEST_ALLOWED_COUNTRY {
  US = SUPPORTED_COUNTRY.US,
  MX = SUPPORTED_COUNTRY.MX,
}

export enum CAAS_DRUGTEST_ALLOWED_COUNTRY {
  US = SUPPORTED_COUNTRY.US,
}

export enum SHOW_BGC_TASK_DETAIL_ALLOWED_COUNTRY {
  GB = SUPPORTED_COUNTRY.GB,
}

export enum MEDICAL_CHECK_ALLOWED_COUNTRY {
  MX = SUPPORTED_COUNTRY.MX,
}

export enum ATOZ_ALLOWED_COUNTRY {
  US = SUPPORTED_COUNTRY.US,
}

export enum COUNTRIES_WITHOUT_I9 {
  GB = SUPPORTED_COUNTRY.GB,
  ZA = SUPPORTED_COUNTRY.ZA,
  PH = SUPPORTED_COUNTRY.PH,
  IE = SUPPORTED_COUNTRY.IE,
  IN = SUPPORTED_COUNTRY.IN,
  MX = SUPPORTED_COUNTRY.MX,
  JP = SUPPORTED_COUNTRY.JP, // https://quip-amazon.com/jaIBAaHvBezr/Japan-Full-PRD-Amazon-Hiring-Solution No work auth right now
  CO = SUPPORTED_COUNTRY.CO,
  CR = SUPPORTED_COUNTRY.CR,
  BR = SUPPORTED_COUNTRY.BR,
  JO = SUPPORTED_COUNTRY.JO,
  EG = SUPPORTED_COUNTRY.EG,
  CA = SUPPORTED_COUNTRY.CA,
}

export enum COUNTRIES_WITHOUT_TASK_STATUS_INDICATOR {
  ZA = SUPPORTED_COUNTRY.ZA,
  PH = SUPPORTED_COUNTRY.PH,
  IE = SUPPORTED_COUNTRY.IE,
  IN = SUPPORTED_COUNTRY.IN,
  GB = SUPPORTED_COUNTRY.GB,
  CO = SUPPORTED_COUNTRY.CO,
  CR = SUPPORTED_COUNTRY.CR,
  BR = SUPPORTED_COUNTRY.BR,
  MX = SUPPORTED_COUNTRY.MX,
  JO = SUPPORTED_COUNTRY.JO,
  EG = SUPPORTED_COUNTRY.EG,
  JP = SUPPORTED_COUNTRY.JP,
}

export const COUNTRIES_WITH_WORK_AUTHORIZATION_TASK_TITLE = omit(
  {
    ...COUNTRIES_WITHOUT_I9,
  },
  [SUPPORTED_COUNTRY.GB, SUPPORTED_COUNTRY.CA] // GB task title will be "Right to Work" instead of "Work Authorization"
);

// If the applicationState is READY_TO_HIRE should not display as a tentative days. https://issues.amazon.com/issues/Kondo_QA_Issue-552
// If the applicationState is HIRE_COMPLETE should not display as a tentative days. https://sim.amazon.com/issues/Kondo_QA_Issue-688
// If the applicationState is APPT_1_PENDING should not display as a tentative days. https://sim.amazon.com/issues/Japan_QA_Issue-459
export const NOT_TENTATIVE_START_APPLICATION_STATES = [
  APPLICATION_STATE.READY_TO_HIRE,
  APPLICATION_STATE.HIRE_COMPLETE,
  APPLICATION_STATE.APPT_1_PENDING,
];

// `HIRED_SF_STEP_SUBSTEP` is used for checking to show the screening and non tentative start date
export const HIRED_SF_STEP_SUBSTEP = [
  SF_APP_STATE.HIRED_DIRECT,
  SF_APP_STATE.HIRED_CONTINGENT,
  SF_APP_STATE.HIRED_ADMIN_TERM,
  SF_APP_STATE.PRE_HIRE_PEOPLE_SOFT,
  SF_APP_STATE.PRE_HIRE_INITIATE_ONBOARDING,
  SF_APP_STATE.PRE_HIRE_ERROR_INITIATE_ONBOARDING,
  SF_APP_STATE.PRE_HIRE_ERROR_NO_SHOW_REHIRE,
];

export const ONBASE_APPLICATION_STATUS_TO_CHECK = [APPLICATION_STATE.READY_TO_HIRE, APPLICATION_STATE.HIRE_COMPLETE];
export const ONBASE_APPLICATION_STATUS_TO_CHECK_FOR_CA = [
  APPLICATION_STATE.READY_TO_HIRE,
  APPLICATION_STATE.HIRE_COMPLETE,
  APPLICATION_STATE.START_DATE_CONFIRMATION_PENDING,
];

export const BADGE_PHOTO_APPLICATION_STATES_FOR_JAPAN = [
  APPLICATION_STATE.CONTINGENCIES_PENDING,
  APPLICATION_STATE.READY_TO_HIRE,
  APPLICATION_STATE.HIRE_COMPLETE,
];

export enum EMPLOYMENT_TYPE {
  REGULAR = "Regular",
  SEASONAL = "Seasonal",
  PERMANENT = "Permanent",
  FIXED_TERM = "Fixed_term",
}

// https://issues.amazon.com/issues/Kondo_QA_Issue-269
// Mapping data: https://code.amazon.com/packages/HVHCareersFrontendSearchWebsite/blobs/0f5dc65b995831abf317d2bb79f78aa6eec3e7cc/--/src/utils/filterOptions/index.ts#L33-L37
export const UK_EMPLOYMENT_TYPE_LABEL_OVERRIDE: Partial<Record<EMPLOYMENT_TYPE, EMPLOYMENT_TYPE>> = {
  [EMPLOYMENT_TYPE.REGULAR]: EMPLOYMENT_TYPE.PERMANENT,
  [EMPLOYMENT_TYPE.SEASONAL]: EMPLOYMENT_TYPE.FIXED_TERM,
};

export const SHOW_SCREENING_RESULT_VALID_STATES = [
  APPLICATION_STATE.CONTINGENCIES_PENDING,
  APPLICATION_STATE.START_DATE_CONFIRMATION_PENDING,
  APPLICATION_STATE.READY_TO_HIRE,
  APPLICATION_STATE.HIRE_COMPLETE,
  APPLICATION_STATE.SCHEDULE_SELECTION_PENDING,
];

// https://sim.amazon.com/issues/Kondo_QA_Issue-620
export const SHOW_SCREENING_RESULT_VALID_STATES_UK = [
  APPLICATION_STATE.PENDING_APPT_1_SCHEDULING,
  APPLICATION_STATE.APPT_1_PENDING,
];

// Alumni will have additional valid states for screening because of skip NHE
export const ADDITIONAL_SCREENING_RESULT_VALID_ALUMNI = [
  APPLICATION_STATE.PENDING_APPT_1_SCHEDULING,
  APPLICATION_STATE.APPT_1_PENDING,
  APPLICATION_STATE.EVALUATION_PENDING,
];

// https://sim.amazon.com/issues/Kondo_QA_Issue-620
export const ADDITIONAL_VALID_APP_STATE_TO_DISPLAY_NHE_UK: string[] = [
  APPLICATION_STATE.PENDING_APPT_1_SCHEDULING,
  APPLICATION_STATE.APPT_1_PENDING,
];

export const NHE_INCOMPLETE_APPLICATION_STATE: string[] = [
  APPLICATION_STATE.EVALUATION_PENDING,
  APPLICATION_STATE.APPT_1_PENDING,
];

export enum NHE_EVENT_TYPE {
  NHE = "NHE",
  RTW = "RTW",
  DT = "DT",
  DT_RETAKE = "DT_RETAKE",
}
