import { StatusEnum } from "../enums/common";
import { CAAS_STATUS } from "../../components/i9-task/i9-data";
import { MessageBannerType } from "@amzn/stencil-react-components/message-banner";
import { AshAppConfig } from "@amzn/hvh-simple-hire-checklist-app-config-common";
import { TaskStatusModel } from "@amzn/hvh-simple-hire-checklist-internal-api-common";

export interface I9Response {
  isRemote?: boolean;
  errorCode?: string;
  secureURL?: string;
  allowRetry?: boolean;
  status?: string;
  PreviousrightToWorkExpired?: boolean;
  rightToWork?: any;
}

export interface I9TaskCardProps {
  status?: StatusEnum | CAAS_STATUS;
  shouldOverrideStatus?: Record<string, StatusEnum>;
}

export interface idInfo {
  nationalIdType: string;
  idNumber: string;
}

export interface additionalNationalIdsInfo {
  [keys: string]: idInfo;
}

export interface Banner {
  type: MessageBannerType;
  text: string;
  isDismissible?: boolean;
  dismissTime?: number;
}

export interface ErrorInfo {
  errorCode: string;
  errorMessage: string;
  errorStatusCode: number;
}

export interface AppConfigResponse {
  configurations: AshAppConfig;
}

interface ContingencyStatus {
  contingencyType: string;
  valid: boolean;
}

export interface ValidCandidateContingenciesResponse {
  applicationId: string;
  valid: boolean;
  contingencyStatusList: Array<ContingencyStatus>;
}

export enum PAGE_NOT_LOAD_ERRORS {
  REDUX_UPDATE_PASSING_THRESHOLD_ERROR = "Redux Error [Update Passed Threshold]",
  REACT_MEMORY_LEAK = "Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application.",
  SECURITY_TOKEN_EXPIRED = "The security token included in the request is expired",
}
