import { AppointmentInfo } from "@amzn/hvh-simple-hire-checklist-internal-api-common";

export const getCalendarEventV2Appointment = (appointmentInfo: AppointmentInfo | undefined) => {
  if (!appointmentInfo) {
    return {
      startTime: "",
      endTime: "",
      location: "",
      timezone: "UTC",
    };
  }

  // appointmentInfo is guaranteed to be defined here since it is not null or undefined.
  const { startTimestamp, endTimestamp, displayReadyLocation, timezone } = appointmentInfo;
  return {
    startTime: startTimestamp,
    endTime: endTimestamp,
    location: displayReadyLocation,
    timezone: timezone,
  };
};

export const addToCalendarKeyDown = (event: any, className: string) => {
  if (event.key === " " || event.code === "Space") {
    event.preventDefault();
    (document.querySelector(className) as any).click();
  }
};
