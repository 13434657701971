import { AppointmentInfo } from "@amzn/hvh-simple-hire-checklist-internal-api-common";
import moment from "moment-timezone";

export function processTime(time: string | undefined): string | undefined {
  //SDA time in format of 11:00AM. Need to inject an additional space between 11:00 and AM to make it valid for formatting
  if (time && time.length >= 6) {
    const trimmed = time.trim();
    const suffix = trimmed.substr(trimmed.length - 2, 2);
    if (suffix == "AM" || suffix == "PM") {
      return trimmed.substr(0, trimmed.length - 2) + " " + suffix;
    }
  }
  return time;
}

export function rtwAppointmentFormatedStartTime(rtwDetails: AppointmentInfo, isNheVenueTimeEnabled: boolean) {
  let timeStart, timeEnd;
  const startMoment = moment(rtwDetails?.startTimestamp);
  const endMoment = moment(rtwDetails?.endTimestamp);
  const weekday = startMoment.format("ddd");
  const appointmentDate = startMoment.format("DD MMM YYYY");

  if (isNheVenueTimeEnabled) {
    timeStart = displayVenueTime(rtwDetails).format("HH:mm");
    timeEnd = displayVenueTime(rtwDetails, true).format("HH:mm");
  } else {
    timeStart = startMoment.format("HH:mm");
    timeEnd = endMoment.format("HH:mm");
  }

  return `${weekday}, ${appointmentDate}, ${timeStart} - ${timeEnd}`;
}

export function displayVenueTime(appointmentV2Details: AppointmentInfo, isRtwEndTimeStamp?: boolean) {
  const siteTimestamp = moment.tz(
    `${isRtwEndTimeStamp ? appointmentV2Details.endTimestamp : appointmentV2Details.startTimestamp}`,
    appointmentV2Details.timezone
  );

  const siteTimeString = siteTimestamp.format("YYYY-MM-DD hh:mm:ss A");

  const localTime = moment(siteTimeString, "YYYY-MM-DD hh:mm:ss A");

  return localTime;
}
