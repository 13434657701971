import { Col, Row } from "@amzn/stencil-react-components/layout";
import { Link } from "@amzn/stencil-react-components/link";
import { Spinner, SpinnerSize } from "@amzn/stencil-react-components/spinner";
import { Text } from "@amzn/stencil-react-components/text";
import React from "react";
import { RIGHT_TO_WORK_TASK_DEFINITION_ID, SUPPORTED_COUNTRY } from "../../config/appConfigConstants";
import { HVH_COLORS } from "../../config/palette";
import { useGetApplicationId } from "../../hooks/apis/useGetApplicationId";
import { useChecklistArb } from "../../hooks/use-arb";
import { useScreeningInfo } from "../../hooks/useScreeningInfo";
import { getRightToWorkOrI9TaskType } from "../../utility/app-config-helper";
import { CONTACT_US_LINK, FR_CONTACT_US_LINK } from "../../utility/constants";
import { ScreenResult, TRANSLATED_SCREEN_RESULT } from "../../utility/screening-results-helper";
import { H2 } from "../typography";
import { getStatusIcon } from "./screeningResultsHelper";
import { isFrenchLocale } from "../../utility/locale-helper";

export interface ContingencyResponse {
  status?: string;
}

interface Result {
  label: string;
  status: string;
}

interface ScreeningResultsProps {
  country: string;
}

export const ScreeningResults = ({ country }: ScreeningResultsProps) => {
  const { airBgcStatus, bgcStatus, dtStatus, mcStatus, workAuthStatus, loaded } = useScreeningInfo();
  const applicationId = useGetApplicationId();
  const isGenericRightToWorkTask = getRightToWorkOrI9TaskType(RIGHT_TO_WORK_TASK_DEFINITION_ID, applicationId);

  const bundle = useChecklistArb();
  const loadingText = bundle.getMessage("checklist-General-TaskStatus-Loading");
  const title = bundle.getMessage("Checklist-MainPage-Screening-Results");

  const getWorkAuthorizationTitle = (country: string, isRightToWorkTask: boolean) => {
    if (country == SUPPORTED_COUNTRY.CA) {
      return bundle.getMessage("Checklist-MainPage-Work-Authorization");
    }

    if (isRightToWorkTask) {
      return bundle.getMessage("Checklist-RightToWork-Task-ScreeningResultTitle");
    }

    return bundle.getMessage("Checklist-MainPage-I9-Title");
  };

  const results: Array<Result> = [
    {
      label: getWorkAuthorizationTitle(country, isGenericRightToWorkTask),
      status: workAuthStatus,
    },
    {
      label: bundle.getMessage("Checklist-MainPage-BGC-Title"),
      status: bgcStatus,
    },
    {
      label: bundle.getMessage("Checklist-MainPage-DT-Title"),
      status: dtStatus,
    },
    {
      label: bundle.getMessage("Checklist-MainPage-AIR-BGC-Title"),
      status: airBgcStatus,
    },
    {
      label: bundle.getMessage("Checklist-MainPage-MedicalCheck-Title"),
      status: mcStatus,
    },
  ];

  if (!loaded) {
    return (
      <Col>
        <Row padding={"1rem"} alignItems={"center"} justifyContent={"space-between"}>
          <H2>{title}</H2>
        </Row>

        <Col alignItems="center" padding={"2rem"} backgroundColor={HVH_COLORS.NEUTRAL_0}>
          <Spinner size={SpinnerSize.Small} loadingText={loadingText} showText={true} />
        </Col>
      </Col>
    );
  }

  return (
    <div id="screeningresult" data-testid="screening-results">
      <Col>
        <Row padding={"1rem"} alignItems={"center"} justifyContent={"space-between"}>
          <H2>{title}</H2>
          {results.filter((screeningResult) => screeningResult["status"] == ScreenResult.ERROR).length > 0 && (
            <Link fontSize={"T200"} target="_blank" href={isFrenchLocale() ? FR_CONTACT_US_LINK : CONTACT_US_LINK}>
              {bundle.getMessage("Checklist-MainPage-Contact-US")}
            </Link>
          )}
        </Row>

        <Col gridGap={"1rem"} padding={"1rem"} backgroundColor={HVH_COLORS.NEUTRAL_0}>
          {results
            .filter((result) => result.status != ScreenResult.UNDEFINED)
            .sort((a, b) => a.label.localeCompare(b.label))
            .map((result: Result, index: number) => {
              return (
                <Row key={index} gridGap={"0.3rem"} alignItems={"center"}>
                  {getStatusIcon(result.status)}
                  <Text
                    data-testid={`screening-results-${result.label}-${result.status}`}
                    fontSize={"T200"}
                    color={
                      result.status === ScreenResult.PENDING || result.status === ScreenResult.NOT_STARTED
                        ? HVH_COLORS.NEUTRAL_70
                        : HVH_COLORS.NEUTRAL_90
                    }
                  >
                    {result.label}{" "}
                    <strong>&middot; {bundle.getMessage(TRANSLATED_SCREEN_RESULT[result.status])}</strong>
                  </Text>
                </Row>
              );
            })}
        </Col>
      </Col>
    </div>
  );
};
