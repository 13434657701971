import React from "react";
import { Text } from "@amzn/stencil-react-components/text";
import { ChecklistBundle } from "../../../../hooks/use-arb";

export const getGBChecklistMappingDefault = ({ bundle }: { bundle: ChecklistBundle }) => {
  const taskNotStarted = bundle.getMessage("checklist-General-TaskStatus-NotStarted");

  const gbDefaultChecklistSection = (
    <>
      <Text>{taskNotStarted}</Text>
    </>
  );

  return gbDefaultChecklistSection;
};
