import { Flyout, FlyoutContent } from "@amzn/stencil-react-components/flyout";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import React, { ReactNode } from "react";
import { resolveDurationText } from "../../helpers/utils";
import { useChecklistArb } from "../../hooks/use-arb";
import { Text } from "../typography";
import "./style.scss";

interface TaskDetailsProps {
  title?: string;
  detailsTitle: string;
  taskName: string | undefined;
  buttons: ReactNode;
  flyoutContent: ReactNode;
  isTaskDetailsViewOpen: boolean;
  closeTaskDetailsView: () => void;
  duration?: number;
  status?: string;
}
export const TaskDetails = ({
  title,
  detailsTitle,
  taskName,
  buttons,
  flyoutContent,
  isTaskDetailsViewOpen,
  closeTaskDetailsView,
  duration,
  status,
}: TaskDetailsProps) => {
  const { getMessage, formatMessage } = useChecklistArb();

  const details = title || getMessage("Checklist-CardControlled-DetailsTitle");

  const timeComplete = formatMessage("checklist-General-Mins", {
    minutes: duration,
  });

  const TASK_WITHOUT_FLYOUT_BANNER: any[] = [];
  const isEligibleToRenderFlyoutBanner = !TASK_WITHOUT_FLYOUT_BANNER.includes(taskName);

  const renderFlyoutBanner = () => {
    return (
      isEligibleToRenderFlyoutBanner && (
        <Col className={"flyout-banner"} gridGap={"1rem"} width="100%">
          <Row padding={"1rem 1rem 0 1rem"} width={"80%"}>
            <Text fontSize="T400" id={"modal-title"}>
              {detailsTitle}
            </Text>
          </Row>
          <Row padding={"0 1rem 0 1rem"}>
            <Text fontSize="T200">{resolveDurationText(timeComplete, status, duration)}</Text>
          </Row>
        </Col>
      )
    );
  };

  return (
    <Flyout
      isOpen={isTaskDetailsViewOpen}
      close={closeTaskDetailsView}
      aria-labelledby={"modal-title"}
      data-flyout-without-banner={!isEligibleToRenderFlyoutBanner}
    >
      <FlyoutContent
        titleText={details}
        buttons={[
          <Col width={"100%"} key={"details-buttons"}>
            {buttons}
          </Col>,
        ]}
        onCloseButtonClick={closeTaskDetailsView}
      >
        {renderFlyoutBanner()}
        {flyoutContent}
      </FlyoutContent>
    </Flyout>
  );
};
