import { Stage } from "../helpers/get-settings";

//Multi-Language Support feature: display required language
export const MLS_REQUIRED_LANGUAGE: Record<Stage, boolean> = {
  local: true,
  alpha: true,
  beta: true,
  gamma: true,
  preprod: true,
  prod: true,
  "alpha-mx": true,
  "beta-mx": true,
  "gamma-mx": true,
  "gamma-uk": false,
  "gamma-in": false,
  "gamma-fe": false,
  "gamma-za": false,
  "preprod-uk": false,
  "prod-uk": false,
  "prod-in": false,
  "prod-fe": false,
  "prod-za": false,
  "preprod-mx": true,
  "preprod-in": false,
  "preprod-fe": false,
  "preprod-za": false,
  "prod-mx": true,
};
