import React from "react";
import { useChecklistArb } from "../../hooks/use-arb";
import { Link } from "@amzn/stencil-react-components/link";
import { Button, ButtonSize, ButtonVariant } from "@amzn/stencil-react-components/button";

const googlePlaceURL = "http://maps.google.com/?q=";
interface GoogleMapLinkParams {
  isVirtual?: boolean;
  address?: string;
  idAddress?: string;
}
export const GoogleMapLink = ({ isVirtual, address, idAddress }: GoogleMapLinkParams) => {
  const bundle = useChecklistArb();
  const remoteLocation = bundle.getMessage("Checklist-JobCard-RemoteLocation");

  if (isVirtual) {
    return <Link data-testid={`first-day-address-virtual-${idAddress}`}>{remoteLocation}</Link>;
  }
  return (
    <Link
      fontSize={"T200"}
      href={googlePlaceURL + encodeURIComponent(address ? address : "")}
      target={"_blank"}
      className={"link-style"}
      data-testid={`first-day-address-${idAddress}`}
    >
      {address}
    </Link>
  );
};

export const GoogleMapLinkWithDirectionButton = ({ isVirtual, address, idAddress }: GoogleMapLinkParams) => {
  const bundle = useChecklistArb();
  const getDirections = bundle.formatMessage("Checklist-PreHireDetails-GetDirections");
  if (isVirtual) {
    return <Link data-testid={`first-day-directions-button-virtual-link`} />;
  }
  return (
    <Link
      data-testid={`first-day-directions-button-link-${idAddress}`}
      key="close-button"
      href={googlePlaceURL + address}
      target={"_blank"}
    >
      <Button variant={ButtonVariant.Primary} size={ButtonSize.Small}>
        {getDirections}
      </Button>
    </Link>
  );
};
