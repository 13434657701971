import React from "react";
import ErrorBannerMessage from "./ErrorBannerMessage";
import { useAppSelector } from "../../reduxStore/reduxHooks";
import { getConfigData } from "../../reduxStore/slices/configSlice";

const ApplicationErrorBanner = () => {
  const { appError } = useAppSelector(getConfigData);
  if (!appError?.errorCode) {
    return null;
  }

  return <ErrorBannerMessage errorInfo={appError} />;
};

export default ApplicationErrorBanner;
