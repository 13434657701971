import React, { useEffect } from "react";
import { StencilProvider } from "@amzn/stencil-react-components/context";
import { BrowserRouter, Switch, Route, useLocation } from "react-router-dom";
import { ChecklistRoute } from "./routes/checklist";
import { NoMatchRoute } from "./routes/no-match";
import { TestCsrfRoute } from "./routes/test/csrf";
import { withStage } from "./hoc/with-stage";
import ErrorBoundary from "./components/error-boundary";
import {
  CHECKLIST_URL,
  CHECKLIST_URL_CID,
  NEW_HIRE_VIDEO_URL,
  TAKE_BADGE_PHOTO_CAMERA_GALLERY_URL,
  TAKE_BADGE_PHOTO_CAMERA_REQUIREMENTS_URL,
  TAKE_BADGE_PHOTO_CAMERA_URL,
  TAKE_BADGE_PHOTO_CONFIRM_URL,
  TAKE_BADGE_PHOTO_RESULT_URL,
  TAKE_BADGE_PHOTO_START_URL,
  TAKE_BADGE_PHOTO_UPLOAD_REQUIREMENTS_URL,
  TAKE_BADGE_PHOTO_UPLOAD_URL,
  OLD_DOCUMENT_UPLOAD_URL,
} from "./config/urls";
import { BadgeStartRoute } from "./routes/tasks/badge/start";
import { BadgeResultRoute } from "./routes/tasks/badge/result";
import { BadgeCameraRequirementsRoute } from "./routes/tasks/badge/camera/requirements";
import { BadgeUploadRequirementsRoute } from "./routes/tasks/badge/upload/requirements";
import { BadgeCameraRoute } from "./routes/tasks/badge/camera";
import { BadgeConfirmRoute } from "./routes/tasks/badge/confirm";
import { BadgePhotoCameraGalleryRoute } from "./routes/tasks/badge/camera/gallery";
import { BadgeUploadRoute } from "./routes/tasks/badge/upload";
import { DocumentUpload } from "./routes/tasks/document/upload";
import Store from "./reactContextStore/store";
import defaultTheme from "@amzn/stencil-react-theme-default";

export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export const ChecklistRouteWithStage = withStage(ChecklistRoute);

function App() {
  return (
    <StencilProvider theme={defaultTheme}>
      <BrowserRouter>
        <ErrorBoundary>
          <div className="app">
            <main>
              <Store>
                <ScrollToTop />
                <Switch>
                  <Route exact path={CHECKLIST_URL}>
                    <ChecklistRouteWithStage />
                  </Route>
                  <Route exact path={CHECKLIST_URL_CID}>
                    <ChecklistRouteWithStage />
                  </Route>
                  <Route path={NEW_HIRE_VIDEO_URL}>
                    <ChecklistRouteWithStage isNewHireVideoRoute={true} />
                  </Route>
                  <Route path={TAKE_BADGE_PHOTO_START_URL}>
                    <BadgeStartRoute />
                  </Route>
                  <Route path={TAKE_BADGE_PHOTO_CAMERA_REQUIREMENTS_URL}>
                    <BadgeCameraRequirementsRoute />
                  </Route>
                  <Route path={TAKE_BADGE_PHOTO_CAMERA_GALLERY_URL}>
                    <BadgePhotoCameraGalleryRoute />
                  </Route>
                  <Route path={TAKE_BADGE_PHOTO_CAMERA_URL}>
                    <BadgeCameraRoute />
                  </Route>
                  <Route path={TAKE_BADGE_PHOTO_UPLOAD_REQUIREMENTS_URL}>
                    <BadgeUploadRequirementsRoute />
                  </Route>
                  <Route path={TAKE_BADGE_PHOTO_UPLOAD_URL}>
                    <BadgeUploadRoute />
                  </Route>
                  <Route path={TAKE_BADGE_PHOTO_CONFIRM_URL}>
                    <BadgeConfirmRoute />
                  </Route>
                  <Route path={TAKE_BADGE_PHOTO_RESULT_URL}>
                    <BadgeResultRoute />
                  </Route>
                  <Route path={OLD_DOCUMENT_UPLOAD_URL}>
                    <DocumentUpload />
                  </Route>
                  {process.env.NODE_ENV === "development" && (
                    <Route path="/test/csrf/:scenario">
                      <TestCsrfRoute />
                    </Route>
                  )}
                  <Route path="*">
                    <NoMatchRoute />
                  </Route>
                </Switch>
              </Store>
            </main>
          </div>
        </ErrorBoundary>
      </BrowserRouter>
    </StencilProvider>
  );
}

export default App;
