import React from "react";
import { Text } from "../typography";
import {
  ProgressBar as StencilProgressBar,
  Status as ProgressBarStatus,
} from "@amzn/stencil-react-components/progress-bar";
import { View } from "@amzn/stencil-react-components/layout";
import { StatusEnum } from "../../utility/enums/common";
import { NHEAppointmentStatusEnum } from "../appointment-card/card-controlled";

interface ProgressBarProps {
  taskStatusMap: Map<string, StatusEnum | NHEAppointmentStatusEnum>;
  bundle: any;
}

export const countAsCompletedTask = (
  status: StatusEnum | NHEAppointmentStatusEnum | undefined,
  validStatusArray: any[]
) => {
  return validStatusArray.includes(status);
};

export const ProgressBar = ({ taskStatusMap, bundle }: ProgressBarProps) => {
  const complete = bundle.getMessage("Checklist-MainPage-Complete");

  let completedTasks = 0;
  let totalTasks = 0;
  const validStatusArray = [
    StatusEnum.COMPLETED,
    StatusEnum.REJECTED,
    NHEAppointmentStatusEnum.COMPLETED,
    StatusEnum.NO_ACTION_REQUIRED,
  ];
  taskStatusMap.forEach((value: StatusEnum | NHEAppointmentStatusEnum) => {
    if (value == StatusEnum.SKIPPED) return;

    if (countAsCompletedTask(value, validStatusArray)) {
      completedTasks++;
    }
    totalTasks++;
  });

  const renderProgressBar = () => {
    const progressBarRatio = completedTasks / totalTasks;
    return (
      <View>
        <Text textAlign="right" data-testid={`task-progress-bar-completed-${completedTasks}-total-${totalTasks}`}>
          {completedTasks}/{totalTasks} {complete}
        </Text>
        <StencilProgressBar
          aria-hidden="true"
          status={ProgressBarStatus.Positive}
          progress={progressBarRatio}
        ></StencilProgressBar>
      </View>
    );
  };
  return renderProgressBar();
};
