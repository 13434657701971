import { Button } from "@amzn/stencil-react-components/button";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { PageContainer } from "@amzn/stencil-react-components/page";
import React, { useEffect, useState } from "react";
import { SkipBadge } from "../../../../components/skip-badge";
import { H1 } from "../../../../components/typography";
import { HVH_COLORS } from "../../../../config/palette";
import {
  CHECKLIST_URL,
  TAKE_BADGE_PHOTO_CAMERA_REQUIREMENTS_URL,
  TAKE_BADGE_PHOTO_UPLOAD_REQUIREMENTS_URL,
} from "../../../../config/urls";
import { reachedAttemptsLimit } from "../../../../helpers/badge-failed-attempts";
import { useSendAnalyticEvent } from "../../../../hooks/jobDetailsCardAnalyticEventHelper.hook";
import { useChecklistArb } from "../../../../hooks/use-arb";
import { useMetrics } from "../../../../hooks/use-metrics";
import { useNavigator } from "../../../../hooks/use-navigator";
import { getClientDeviceInfo } from "../../../../utility/device-info";

export const BadgeStartRoute = () => {
  const [goToCamera, goToUpload, goToChecklist] = useNavigator(
    TAKE_BADGE_PHOTO_CAMERA_REQUIREMENTS_URL,
    TAKE_BADGE_PHOTO_UPLOAD_REQUIREMENTS_URL,
    CHECKLIST_URL
  );

  const { sendBadgePhotoPageAnalyticsEvent } = useSendAnalyticEvent();

  useEffect(() => {
    sendBadgePhotoPageAnalyticsEvent("badge/start");
  }, []);

  const metrics = useMetrics();

  const [showSkipOption, setShowSkipOption] = useState(false);
  const bundle = useChecklistArb();

  useEffect(() => {
    if (metrics?.sendMetric) {
      getClientDeviceInfo().then((clientDeviceInfo) => {
        metrics.sendMetric({ pageView: "badge/start", details: clientDeviceInfo });
      });
    }
    if (reachedAttemptsLimit()) {
      setShowSkipOption(true);
    }
  }, [metrics?.sendMetric]);

  const title = bundle.getMessage("Checklist-BPStart-H1");
  const viaCamera = bundle.getMessage("Checklist-BPStart-Camera");
  const viaUpload = bundle.getMessage("Checklist-BPStart-Upload");

  return (
    <>
      <PageContainer color={HVH_COLORS.NEUTRAL_90}>
        <Col gridGap="1rem" alignItems="center">
          <H1>{title}</H1>
          <Row>
            <Button onClick={goToCamera}>{viaCamera}</Button>
          </Row>
          <Row>
            <Button onClick={goToUpload}>{viaUpload}</Button>
          </Row>
          {showSkipOption && (
            <Row padding="3rem 0 0 0">
              <SkipBadge goToChecklist={goToChecklist} />
            </Row>
          )}
        </Col>
      </PageContainer>
    </>
  );
};
