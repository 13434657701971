import { Button, ButtonSize, ButtonVariant } from "@amzn/stencil-react-components/button";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { sendAnalyticsEvent } from "../../helpers/atoz-analytic-helper";
import { useChecklistArb } from "../../hooks/use-arb";
import { AtoZStatus } from "../../utility/application-data";
import { StatusEnum } from "../../utility/enums/common";
import {
  ADOBE_EVENT_SETUP_ATOZ_ACCOUNT,
  ADOBE_EVENT_VIEW_ATOZ_ACCOUNT,
  ATOZ_ERROR,
  ATOZ_RETRY,
  AtoZStatusButtonState,
  AtoZTaskParams,
  UrlParams,
} from "./atozData";
import { useGetJobDetails } from "../../hooks/apis/useGetJobDetails";
import { useGetApplicationManageData } from "../../hooks/apis/useGetApplicationManageData";
import { useGetCandidateData } from "../../hooks/apis/useGetCandidateData";
import { useGetCandidateAtoZWithoutPassword } from "../../hooks/apis/useGetCandidateAtoZWithoutPassword";
import { useGetCandidateAtoZWithPassword } from "../../hooks/apis/useGetCandidateAtoZWithPassword";

export const CreateEmployeeAccountButton = ({ status }: AtoZTaskParams) => {
  const { applicationId } = useParams<UrlParams>();

  const jobDetails = useGetJobDetails();
  const applicationManageData = useGetApplicationManageData();
  const candidateData = useGetCandidateData();
  const candidateAtoZ = useGetCandidateAtoZWithoutPassword();
  const candidateAtoZWithPassword = useGetCandidateAtoZWithPassword();

  const bundle = useChecklistArb();

  const atoZViewAtoZAccountButton = bundle.getMessage("Checklist-AtoZ-View-Button");

  const atoZSetupAZAccountButton = bundle.getMessage("Checklist-AtoZ-Setup-Button");

  const retry = bundle.getMessage("Checklist-General-RetryButtonText");

  const [atoZButtonStatus, setAtoZButtonStatus] = useState<AtoZStatusButtonState>(AtoZStatusButtonState.Active);

  const [aToZUrl, setAtoZUrl] = useState<string>("");

  useEffect(() => {
    if (candidateAtoZ && candidateAtoZ !== "SKIPPED" && candidateAtoZ.errorCode) {
      sendAnalyticsEvent({
        eventName: ATOZ_ERROR,
        response: candidateAtoZ,
        applicationId,
        candidateData,
        applicationManageData,
        jobDetails,
      });
    } else if (
      candidateData &&
      candidateData.candidateGlobalId &&
      candidateAtoZWithPassword &&
      candidateAtoZWithPassword !== "SKIPPED"
    ) {
      setAtoZUrl(candidateAtoZWithPassword.aToZLoginUrl);
    }
  }, [candidateAtoZ, candidateData, applicationManageData, jobDetails, candidateAtoZWithPassword]);

  const emitAToZMetrics = (response: AtoZStatus) => {
    if (status == StatusEnum.COMPLETED) {
      sendAnalyticsEvent({
        eventName: ADOBE_EVENT_VIEW_ATOZ_ACCOUNT,
        response: response,
        applicationId,
        candidateData,
        applicationManageData,
        jobDetails,
        errorInButtonComp: !!response.errorCode,
      });
    } else if (status == StatusEnum.ACTION_REQUIRED) {
      sendAnalyticsEvent({
        eventName: ADOBE_EVENT_SETUP_ATOZ_ACCOUNT,
        response: response,
        applicationId,
        candidateData,
        applicationManageData,
        jobDetails,
        errorInButtonComp: !!response.errorCode,
      });
    }
  };

  const onRetry = () => {
    if (candidateAtoZ && candidateAtoZ !== "SKIPPED" && candidateAtoZ.errorCode) {
      sendAnalyticsEvent({
        eventName: ATOZ_RETRY,
        response: candidateAtoZ,
        applicationId,
        candidateData,
        applicationManageData,
        jobDetails,
      });
    }
    setAtoZButtonStatus(AtoZStatusButtonState.Disabled);
    location.reload();
  };

  const redirect = (candidateAtoZWithPassword: AtoZStatus) => {
    emitAToZMetrics(candidateAtoZWithPassword);
    window.open(aToZUrl, "_blank");
  };

  const shouldHideButton = () => {
    return (
      atoZButtonStatus !== AtoZStatusButtonState.Active &&
      status !== StatusEnum.API_ERROR &&
      status !== StatusEnum.COMPLETED
    );
  };

  const shouldDisableButton = ![StatusEnum.ACTION_REQUIRED, StatusEnum.COMPLETED].includes(status);

  const renderButton = () => {
    if (shouldHideButton()) {
      return null;
    }

    if (status == StatusEnum.ERROR) {
      return (
        <Button
          data-testid="atoz-create-employee-account-retry-button"
          size={ButtonSize.Small}
          onClick={onRetry}
          disabled={status != StatusEnum.ERROR}
        >
          {retry}
        </Button>
      );
    }

    return (
      <Button
        data-testid="atoz-create-employee-account-button"
        size={ButtonSize.Default}
        variant={ButtonVariant.Primary}
        onClick={() => redirect(candidateAtoZWithPassword as AtoZStatus)}
        disabled={shouldDisableButton}
      >
        {status == StatusEnum.COMPLETED ? atoZViewAtoZAccountButton : atoZSetupAZAccountButton}
      </Button>
    );
  };

  return <>{renderButton()}</>;
};
