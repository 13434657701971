import React from "react";
import { StatusContainer } from "./status-container";
import { H1 } from "@amzn/stencil-react-components/text";
import { useChecklistArb } from "../../hooks/use-arb";
import LinkWithRedirectWithAdobeMetrics from "../common/LinkWithRedirectWithAdobeMetrics";
import { useUrlBuilder } from "../../hooks/useUrlBuilder.hook";
import { NHE_EVENT_TYPE } from "../../config/appConfigConstants";

export const DTRetakeHeader = () => {
  const bundle = useChecklistArb();
  const { DTRetakeAppointmentServiceURL } = useUrlBuilder();

  return (
    <StatusContainer
      data-testid="page-header-drug-test-retake"
      largeViewPortImage={require("../../assets/images/calendar-large-viewport.png").default}
      smallViewPortImage={require("../../assets/images/calendar-small-viewport.png").default}
    >
      <H1 fontSize={"T400"} fontWeight={"regular"}>
        {bundle.formatMessage("Checklist-DT-Schedule-AppointmentCard-Title")}
      </H1>
      <LinkWithRedirectWithAdobeMetrics
        data-testid="reschedule-button"
        link={DTRetakeAppointmentServiceURL}
        messageKey={"Checklist-DT-Schedule-AppointmentCard-ButtonText"}
        messagePlaceholder={"Find a time"}
        appointmentType={NHE_EVENT_TYPE.DT_RETAKE}
        isScheduleAppt={true}
      />
    </StatusContainer>
  );
};
