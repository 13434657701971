import { useEffect } from "react";
import { updateButtonDelegationEnabled } from "../../../../reduxStore/slices/docUploadSlice";
import { useAppDispatch } from "../../../../reduxStore/reduxHooks";

export const useTemporarySubmitDisabledSolution = (disabled: boolean) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(updateButtonDelegationEnabled(disabled));
  }, [disabled]);
};
