import React from "react";
import { AttachmentType, WorkAuthorizationAttachmentType } from "../models";
import { StatusEnum } from "../../../../utility/enums/common";
import { DocumentCompletedUploadFrontBack } from "./document-completed-upload-front-back";

interface DocumentCompletedUploadDropdownParams {
  currentAttachments: Map<string, string[]>;
  status: StatusEnum;
}

export const DocumentCompletedUploadDropdown = ({
  currentAttachments,
  status,
}: DocumentCompletedUploadDropdownParams) => {
  return (
    <>
      {Array.from(currentAttachments).map(([key, value]) => {
        if (WorkAuthorizationAttachmentType.has(key as AttachmentType)) {
          return (
            <DocumentCompletedUploadFrontBack
              key={key}
              document={key as AttachmentType}
              sides={value || []}
              status={status}
            />
          );
        }
      })}
    </>
  );
};
