import React from "react";
import { useGetApplicationId } from "./useGetApplicationId";
import { load } from "../../utility/local-storage";
import { REQUISITION_CATEGORY } from "../../config/appConfigConstants";

export const useGetRequisitionCategory = () => {
  const applicationId = useGetApplicationId();

  let requisitionCategory = load(REQUISITION_CATEGORY + applicationId);

  if (requisitionCategory) {
    requisitionCategory = requisitionCategory.replace(/\s/g, "");
  }

  return requisitionCategory;
};
