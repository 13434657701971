import React, { useState, useEffect } from "react";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { Input, InputFooter } from "@amzn/stencil-react-components/form";
import { MessageBanner, MessageBannerType } from "@amzn/stencil-react-components/message-banner";
import { useChecklistArb } from "../../../../../hooks/use-arb";
import { H2, Text } from "../../../../../components/typography";
import { AttachmentSubType, AttachmentType, UploadDetails } from "../../models";
import { useParams } from "react-router-dom";
import { deleteCachedRequest } from "../../../../../utility/candidate-data";
import {
  useGetCandidateDataQuery,
  useGetPresignedURLToCDSMutation,
  useSubmitDocToCDSFromPresignedURLMutation,
  useUpdateCDSDocumentUploadMetadataMutation,
  useUpdateCandidateDocumentIdToCDSMutation,
} from "../../../../../reduxStore/api/apiSlice";
import { dispatchOnDev } from "../../../../../utility/dev-env-helpers";
import { useBackToMainPage } from "../../helpers/useBackToMainPage.hook";
import { UploadCard } from "../upload-card";
import { submitDocument } from "../../requests/requests";
import { useTemporarySubmitDisabledSolution } from "../../helpers/useTemporarySubmitDisabledSolution.hook";
import { TEMPORARY_SUBMIT_BUTTON_DISABLED_SOLUTION_SELECTOR } from "../../../../../utility/constants/common";
import { Label } from "@amzn/stencil-react-components/text";
import { DebounceButton } from "../../../../../components/common/DebounceButton";

export function NSSUploadLayout() {
  const { requisitionId, applicationId, isEdit } = useParams<{
    requisitionId: string;
    applicationId: string;
    isEdit: string;
  }>();
  const { data: candidateData, refetch } = useGetCandidateDataQuery({ applicationId });
  const [updateCandidateDocumentID] = useUpdateCandidateDocumentIdToCDSMutation();
  const [updateDocMetadataToCDS] = useUpdateCDSDocumentUploadMetadataMutation();
  const [getPresignedURL] = useGetPresignedURLToCDSMutation();
  const [submitDocToCDS] = useSubmitDocToCDSFromPresignedURLMutation();

  const [nssNumber, setNSSNumber] = useState<string>("");
  const [submitButtonToggle, setBlockSubmitButton] = useState<boolean>(true);
  const [currentNSSId, setCurrentNSSId] = useState<string>("");
  const [frontDocument, setFrontDocument] = useState<UploadDetails>();
  const [idUploadDependencyServiceErrorMessage, setIdUploadDependencyServiceErrorMessage] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [fileUploadDependencyServiceErrorMessage, setFileUploadDependencyServiceErrorMessage] = useState<string>();
  const [isAriaInvalid, setIsAriaInvalid] = useState<boolean>(false);

  useEffect(() => {
    if (nssNumber.length > 0 && !validateNSS.test(nssNumber)) {
      setIsAriaInvalid(true);
    } else {
      setIsAriaInvalid(false);
    }
  }, [nssNumber]);

  const backToMainPage = useBackToMainPage(refetch);
  const validateNSS = new RegExp("^[0-9]{11}$");
  const stars = "***************";

  const bundle = useChecklistArb();
  const documentImageSubmitMessage = bundle.getMessage("Checklist-Document-Image-Submit-ButtonText");
  const documentUploadDependencyServiceErrorMessage = bundle.getMessage("Checklist-Document-General-Error-Message");
  const documentIdUploadDependencyServiceErrorMessage = bundle.getMessage(
    "Checklist-Document-ID-General-Error-Message"
  );

  const frontErrorMessageBanner = bundle.getMessage("Checklist-Document-General-Error-Front-Message");
  const maximumFileSizeMessage = bundle.getMessage("Checklist-Document-Maximum-File-Size-Text");
  const acceptableFileText = bundle.getMessage("Checklist-Document-Acceptable-File-Text");
  const p0LimitFixMessage = bundle.getMessage("p0LimitFixMessage");
  const nssTitleMessage = bundle.getMessage("Checklist-NSS-Title");
  const nssError = bundle.getMessage("Checklist-NSS-Number-Error");
  const nssNumberTitleMessage = bundle.getMessage("Checklist-NSS-Number-Title");
  const NssIdImageTextMessage = bundle.getMessage("Checklist-NSS-ID-Text");
  useTemporarySubmitDisabledSolution(false);

  useEffect(() => {
    if (candidateData?.additionalNationalIds?.[AttachmentType.NSS]?.idNumber) {
      setCurrentNSSId(candidateData.additionalNationalIds[AttachmentType.NSS].idNumber);
    }
  }, [candidateData]);

  useEffect(() => {
    if (frontDocument?.imageBase64 && validateNSS.test(nssNumber)) {
      setBlockSubmitButton(false);
    } else {
      setBlockSubmitButton(true);
    }
  }, [frontDocument, nssNumber]);

  const updateDocument = async (image: string, fileName: string, contentType: string, side: string) => {
    if (candidateData) {
      const documentData = {
        documentType: AttachmentType.NSS,
        attachmentSubType: side,
        imageBase64: image,
        fileName: fileName,
        contentType: contentType,
        candidateId: candidateData?.candidateId,
      };
      setFrontDocument(documentData);
    } else {
      dispatchOnDev(() => {
        console.error("candidateData is not ready yet");
      });
    }
  };

  const clickEventSubmitDocument = async () => {
    const isValidNSS = validateNSS.test(nssNumber);

    if (!frontDocument?.imageBase64) {
      setErrorMessage(frontErrorMessageBanner);
      if (!isValidNSS) setIsAriaInvalid(true);

      document.getElementById("doc-upload-proxy-button")?.blur();
      const input = document.getElementById(`nss-index`);
      input?.focus();

      return;
    } else {
      if (!isValidNSS) {
        if (!isValidNSS) setIsAriaInvalid(true);
        const input = document.getElementById(`nss`);
        input?.focus();

        return;
      }
    }

    if (!candidateData) {
      dispatchOnDev(() => {
        console.error("CandidateData is not ready yet");
      });
      return;
    }

    let returnToMainPage = true;
    if (frontDocument && validateNSS.test(nssNumber)) {
      try {
        updateCandidateDocumentID({
          candidateId: frontDocument.candidateId || candidateData.candidateId,
          idData: {
            nationalIdType: AttachmentType.NSS,
            idNumber: nssNumber,
          },
        });
      } catch (err) {
        setIdUploadDependencyServiceErrorMessage(documentIdUploadDependencyServiceErrorMessage);
        returnToMainPage = false;
      }
      try {
        await submitDocument(
          getPresignedURL,
          submitDocToCDS,
          updateDocMetadataToCDS,
          applicationId,
          [frontDocument],
          setBlockSubmitButton
        );
      } catch (err) {
        setBlockSubmitButton(false);
        setFileUploadDependencyServiceErrorMessage(documentUploadDependencyServiceErrorMessage);
        returnToMainPage = false;
      }
      deleteCachedRequest(applicationId); // delete stale data so that updated Id loads on home page
      if (returnToMainPage) {
        backToMainPage();
      }
    }
  };

  return (
    <Col display={"flex"} justifyContent={"center"} gridGap={6} width={"100%"}>
      <H2>{nssTitleMessage}</H2>
      <Text>
        {NssIdImageTextMessage} <strong style={{ color: "red" }}>*</strong>
      </Text>
      <Text>{acceptableFileText}</Text>
      <Text>{maximumFileSizeMessage}</Text>
      <Row alignSelf={"center"} padding={{ top: "10px", bottom: "10px" }} gridGap="S400" width="100%">
        <UploadCard
          side={AttachmentSubType.FRONT}
          documentDetailsFn={updateDocument}
          setErrorMessage={setErrorMessage}
          applicationId={applicationId}
          setDependencyErrorMessage={setFileUploadDependencyServiceErrorMessage}
          attachmentTypeName={nssTitleMessage}
          customId={`nss-index`}
          hasError={
            !!fileUploadDependencyServiceErrorMessage || !!errorMessage || !!idUploadDependencyServiceErrorMessage
          }
        />
      </Row>
      {fileUploadDependencyServiceErrorMessage && (
        <MessageBanner type={MessageBannerType.Error}>{fileUploadDependencyServiceErrorMessage}</MessageBanner>
      )}
      {errorMessage && (
        <MessageBanner type={MessageBannerType.Error}>
          <Text id={`nss-index-description`}>{errorMessage}</Text>
        </MessageBanner>
      )}
      {idUploadDependencyServiceErrorMessage ? (
        <MessageBanner type={MessageBannerType.Error}>{idUploadDependencyServiceErrorMessage}</MessageBanner>
      ) : null}
      <Col>
        <Label htmlFor="nss">
          {nssNumberTitleMessage}{" "}
          <strong style={{ color: "red" }} aria-hidden="true">
            *
          </strong>
        </Label>{" "}
        <Input
          data-testid="nss-input"
          id="nss"
          aria-required="true"
          placeholder={currentNSSId ? stars + currentNSSId.slice(-4) : nssNumberTitleMessage}
          onChange={(e) => setNSSNumber(e.target.value)}
          maxLength={11}
          aria-invalid={isAriaInvalid ? "true" : "false"}
          aria-describedby="nssError"
          error={isAriaInvalid}
        ></Input>
        {isAriaInvalid ? (
          <InputFooter id="nssError" error>
            {nssError}
          </InputFooter>
        ) : null}
      </Col>
      <Row padding={{ top: "10px", bottom: "10px" }}>
        <MessageBanner type={MessageBannerType.Informational}>{p0LimitFixMessage}</MessageBanner>
      </Row>
      <DebounceButton
        onClick={clickEventSubmitDocument}
        id={TEMPORARY_SUBMIT_BUTTON_DISABLED_SOLUTION_SELECTOR}
        style={{ visibility: "hidden" }}
      >
        {documentImageSubmitMessage}
      </DebounceButton>
    </Col>
  );
}
