import React from "react";
import { TextBuilderConfigType, getDefaultValues } from "../../../TextBuilder";
import { uuid } from "../../../../helpers/utils";
import { CONTACT_US_LINK } from "../../../../utility/constants";
import LinkWithRedirect from "../../../common/LinkWithRedirect";

export const nonCompleteInstructionConfigUpdated: TextBuilderConfigType = (arb) => ({
  ...getDefaultValues([
    {
      text: arb.formatMessage("Checklist-WorkAuthorization-Instruction-Without-Deadline", {
        link: <LinkWithRedirect link={CONTACT_US_LINK} messageKey="Checklist-Common-Here" messagePlaceholder="here" />,
      }),
      key: uuid(),
    },
  ]),

  // Don't show any default for CA, all cases are handled in the CA.tsx file
  CA: [],

  IN: [
    {
      text: `${arb.formatMessage("Checklist-WorkAuthorization-Instruction-Without-Deadline", {
        link: <LinkWithRedirect link={CONTACT_US_LINK} messageKey="Checklist-Common-Here" messagePlaceholder="here" />,
      })} ${arb.getMessage("Checklist-WorkAuthorization-Instruction-Additional-IN")}`,
      key: uuid(),
    },
  ],
});
