import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { Col } from "@amzn/stencil-react-components/layout";
import React, { useCallback } from "react";
import { useChecklistArb } from "../../hooks/use-arb";
import { useUrlBuilder } from "../../hooks/useUrlBuilder.hook";
import { useAppSelector } from "../../reduxStore/reduxHooks";
import { getConfigData } from "../../reduxStore/slices/configSlice";
import { StatusEnum } from "../../utility/enums/common";
import { NHEAppointmentStatusEnum } from "../appointment-card/card-controlled";
import { CAAS_STATUS } from "../i9-task/i9-data";

interface RTWAppointmentProps {
  status: StatusEnum | NHEAppointmentStatusEnum;
  shouldOverrideStatus?: Record<string, StatusEnum>;
}

export const RTWScheduleApointmentButton = ({ status, shouldOverrideStatus }: RTWAppointmentProps) => {
  const bundle = useChecklistArb();
  const scheduleRightToWorkButtonText = bundle.getMessage("Checklist-Flyout-RTW-Appointment-Schedule-Button");

  const { rtwAppointmentSelfServiceURL } = useUrlBuilder();
  const { caasRTWStatus } = useAppSelector(getConfigData);

  const overrideStatus = shouldOverrideStatus ? shouldOverrideStatus[status] : status;

  const redirect = useCallback(() => {
    window.open(rtwAppointmentSelfServiceURL, "_blank");
  }, [rtwAppointmentSelfServiceURL]);

  const disableScheduleButton = overrideStatus === NHEAppointmentStatusEnum.UNKNOWN;

  return (
    <div>
      {disableScheduleButton && (
        <Col>
          <Button
            data-testid="right-to-work-reschedule-button"
            variant={ButtonVariant.Primary}
            onClick={redirect}
            disabled={
              (caasRTWStatus !== "SKIPPED" && caasRTWStatus?.rightToWork?.status) !== CAAS_STATUS.PendingF2FCheck
            }
          >
            {scheduleRightToWorkButtonText}
          </Button>
        </Col>
      )}
    </div>
  );
};
