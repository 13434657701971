import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { CONFIG_DATA_KEY, CONFIG_DATA_TYPES, CONFIG_FEATURE_FLAG } from "../../utility/enums/common";
import { GratitudeStateEnum } from "../../hooks/useGratitudeComponentState.hook";

type featureFlagItem = {
  [key in CONFIG_FEATURE_FLAG]: boolean;
};

interface ConfigState {
  featureFlag: Partial<featureFlagItem>;
  data: Partial<CONFIG_DATA_TYPES>;
}

export const initialState: ConfigState = {
  featureFlag: {
    [CONFIG_FEATURE_FLAG.IS_ASH_CONFIG_LOADED]: false,
  },
  data: {
    [CONFIG_DATA_KEY.GRATITUDE_COMPONENT_STATE]: "HideGratitudeComponent" as GratitudeStateEnum,
  },
} as ConfigState;

const configSlice = createSlice({
  name: "ConfigSlice",
  initialState,
  reducers: {
    _setConfigData: (
      state,
      action: PayloadAction<{ key: keyof CONFIG_DATA_TYPES; value: CONFIG_DATA_TYPES[keyof CONFIG_DATA_TYPES] }>
    ) => {
      const { key, value } = action.payload;
      state.data[key] = value;
    },
    _setFeatureFlag: (state, action: PayloadAction<{ key: CONFIG_FEATURE_FLAG; value: boolean }>) => {
      const { key, value } = action.payload;
      state.featureFlag[key] = value;
    },
  },
});

// Wrap setConfigData to get proper type check
export const setConfigData = <K extends keyof CONFIG_DATA_TYPES>(input: { key: K; value: CONFIG_DATA_TYPES[K] }) =>
  configSlice.actions._setConfigData(input);

// Wrap setFeatureFlag to get proper type check
export const setFeatureFlag = (input: { key: CONFIG_FEATURE_FLAG; value: boolean }) =>
  configSlice.actions._setFeatureFlag(input);

export const getConfigData = (state: RootState) => state.config.data;
export const getFeatureFlag = (state: RootState) => state.config.featureFlag;

export default configSlice.reducer;
