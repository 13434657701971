import { Middleware } from "@reduxjs/toolkit";
import { PAGE_NOT_LOAD_ERRORS } from "../../utility/types/common";

// RTK Query ActionTypes statuses "pending, fulfilled, rejected", reference:
// https://github.com/reduxjs/redux-toolkit/blob/a9362fbcec6cb66bdb3e6dc52bbf3d69ea6f2b22/docs/rtk-query/internal/buildSlice.mdx#extrareducers---matching-mutationthunk-cases
const FAILED_RTK_QUERY_ACTION_TYPES = ["api/executeQuery/rejected", "api/executeMutation/rejected"];

// RTK Query FetchBaseQueryError interface, reference:
// https://github.com/reduxjs/redux-toolkit/blob/a9362fbcec6cb66bdb3e6dc52bbf3d69ea6f2b22/docs/rtk-query/usage-with-typescript.mdx#type-safe-error-handling
const FetchBaseQueryErrorTypes = ["FETCH_ERROR", "PARSING_ERROR"];

const errorMessageBuilder = (errorMessage: string) =>
  console.error(`%c[Error Boundary]: ${errorMessage}`, "color: #f00; font-weight: bold;");

export const logger: Middleware = (store) => (next) => (action) => {
  const actionType = action.type.includes("ConfigSlice") ? action.type + "/" + action.payload.key : action.type;

  const isFailedRTKQueryActions = FAILED_RTK_QUERY_ACTION_TYPES.includes(action.type);

  // In case there was a necessary data in PACE that need to be exists,we could throw an error here and error boundary will caught it.

  if (action.payload?.data?.errorMessage?.includes(PAGE_NOT_LOAD_ERRORS.SECURITY_TOKEN_EXPIRED)) {
    errorMessageBuilder(action.payload.data.errorMessage);
    // throw new Error(action.payload.data.errorMessage);
  }

  // Uncomment code below if we want to render error boundary page if there were FetchBaseQueryErrorTypes happened on UI
  // if (FetchBaseQueryErrorTypes.includes(action.payload?.status)) {
  //   throw new Error(`${action.payload?.status}: ${action.payload.error}`);
  // }

  if (isFailedRTKQueryActions) {
    console.groupCollapsed(`[ReduxUpdate] %c[RTKQueryFailedActions] ${actionType}`, "color: #f00; font-weight: bold;");
  } else {
    console.groupCollapsed(`[ReduxUpdate] %c${actionType}`, "color: #1E90FF; font-weight: bold;");
  }

  console.log("Current State:", store.getState());
  console.log("Action Payload:", action.payload);

  const result = next(action);

  console.log("Next State:", store.getState());
  console.groupEnd();

  return result;
};

/**  Note: Redux state changes was not monitored by React.
 * Code below will detect the state changes and throw an error in case, there was a bug in the app that would triggered infinite state update, reference:
 * https://quip-amazon.com/swMiAixMHTm9/PACE-Frontend-Monitoring-Failed-to-Load#temp:C:QKY70ac6f738c364dc09c91887ba
 * @param threshold Threshold value of total actions dispatched sequentially.
 */
export const throwWhenReduxDispatchSameActionSequentiallyAfterPassingThreshold = (threshold: number): Middleware => {
  let prevActions = "";
  let actionCounts: { [key: string]: number } = {};

  return (store) => (next) => (action) => {
    if (prevActions === action.type || prevActions === "") {
      actionCounts[action.type] = (actionCounts[action.type] || 0) + 1;
    } else {
      prevActions = action.type;
      actionCounts = {};
    }

    if (actionCounts[action.type] > threshold) {
      throw new Error(
        `${PAGE_NOT_LOAD_ERRORS.REDUX_UPDATE_PASSING_THRESHOLD_ERROR}: Action "${action.type}" has been dispatched more than ${threshold} times sequentially.`
      );
    }

    return next(action);
  };
};
