import React from "react";
import { Text } from "@amzn/stencil-react-components/text";
import { useChecklistArb } from "../../hooks/use-arb";
import { buildFormattedSalaryContent } from "./payFormatUtils";
import { SUPPORTED_COUNTRY } from "../../config/appConfigConstants";

interface JobDetailsPayProps {
  country: SUPPORTED_COUNTRY;
  currencyCode: string;
  hourlyBasePayL10N: string;
  biweeklyBasePayL10N: string;
  monthlyBasePayL10N: string;
  yearlyBasePayL10N: string;
  languagePremiumL10N?: string;
  monthlyPayRateL10N?: string;
}

export const JobDetailsPay = ({
  country,
  currencyCode,
  hourlyBasePayL10N,
  biweeklyBasePayL10N,
  monthlyBasePayL10N,
  yearlyBasePayL10N,
  languagePremiumL10N,
  monthlyPayRateL10N,
}: JobDetailsPayProps) => {
  const bundle = useChecklistArb();

  const salaryContent = buildFormattedSalaryContent({
    country,
    bundle,
    currencyCode,
    hourlyBasePayL10N,
    biweeklyBasePayL10N,
    monthlyBasePayL10N,
    yearlyBasePayL10N,
    languagePremiumL10N,
    monthlyPayRateL10N,
  });

  // JP HBA Core uses different pay label
  const useHourlyLabel = country === SUPPORTED_COUNTRY.JP;

  const payKey = useHourlyLabel ? "Checklist-JobCard-PayRate-Hourly" : "Checklist-JobCard-PayRate";
  const payTranslated = bundle.getMessage(payKey);

  return (
    <Text fontSize="T200" data-testid={`job-card-pay`}>
      <strong>{payTranslated}</strong>:&nbsp;{salaryContent}
    </Text>
  );
};
