declare global {
  // eslint-disable-next-line import/no-unused-modules
  export interface Window {
    dataLayerArray?: any[];
    ASH_CHECKLIST_isChecklistReferral?: boolean;
    ASH_CHECKLIST_settingsPromise?: Promise<Settings>;
  }
}

export type Stage =
  | "local"
  | "alpha"
  | "beta"
  | "gamma"
  | "preprod"
  | "prod"
  | "alpha-mx"
  | "beta-mx"
  | "gamma-mx"
  | "gamma-uk"
  | "gamma-in"
  | "gamma-fe"
  | "gamma-za"
  | "preprod-uk"
  | "preprod-in"
  | "preprod-fe"
  | "preprod-za"
  | "prod-uk"
  | "preprod-mx"
  | "prod-mx"
  | "prod-in"
  | "prod-fe"
  | "prod-za";

export interface Settings {
  stage: Stage;
  apiEndpoint?: string;
  checklistVersion: string;
  checklistRumHostRegion: string;
  checklistGuestRumAppId?: string;
}

const DEFAULT_SETTINGS: Settings = {
  stage: "prod",
  checklistVersion: "unknown",
  checklistRumHostRegion: "us-west-2",
};

export const getSettings = async (): Promise<Settings> => {
  try {
    window.ASH_CHECKLIST_settingsPromise =
      window.ASH_CHECKLIST_settingsPromise || fetch("/settings.json").then((result) => result.json());
    return await window.ASH_CHECKLIST_settingsPromise;
  } catch (e) {
    console.error(e);
    return DEFAULT_SETTINGS;
  }
};
