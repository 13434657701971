import { Button, ButtonSize } from "@amzn/stencil-react-components/button";
import { Card } from "@amzn/stencil-react-components/card";
import { IconCheckCircleFill, IconSize } from "@amzn/stencil-react-components/icons";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { H3, Text } from "@amzn/stencil-react-components/text";
import { MessageBanner } from "@amzn/stencil-react-components/message-banner";
import React from "react";
import ProgressRing from "../../assets/svg/progress-ring.svg";
import { HVH_COLORS } from "../../config/palette";
import { useChecklistArb } from "../../hooks/use-arb";
import { useNavigatorWithURLReady } from "../../hooks/use-navigator";
import { StatusEnum } from "../../utility/enums/common";
import { NHEAppointmentStatusEnum } from "../appointment-card/card-controlled";
import { BadgePhotoProps } from "../badge-photo/badgePhotoInTaskcardFlyoutDetails";
import { renderBGColor } from "../task";
import { getCountryForDomain } from "../../utility/locale-helper";
import { SUPPORTED_COUNTRY } from "../../config/appConfigConstants";

interface Props {
  status: StatusEnum;
  descriptionText: string;
  image: JSX.Element;
}

interface RTWAppointmentProps {
  status: StatusEnum | NHEAppointmentStatusEnum;
  descriptionText: string;
  image?: JSX.Element;
  headingText: string;
  messageText: string;
  dateText?: string;
  locationText?: string;
  appointmentSelfServiceURL?: string | undefined;
  addToCalendarButton?: JSX.Element;
}

interface AtoZProps {
  status: StatusEnum;
  atoZBodyText: string;
  statusText: string;
  atoZDetailsExtra?: string;
  atoZEmployeeLogin?: string;
}

type UseStatusTextType = (text: string | undefined) => string;

export const bgcUseStatusText: UseStatusTextType = (text) => {
  const safeString = (text?.toString() as keyof typeof cases) || "";
  const { getMessage } = useChecklistArb();

  const cases = {
    [StatusEnum.AWAITING_CANDIDATE_INFO]: () => getMessage("checklist-General-BGCInformation-Pending"),
    [StatusEnum.ERROR]: () => getMessage("checklist-General-TaskStatus-Error"),
    [StatusEnum.COMPLETED]: () => getMessage("checklist-General-TaskStatus-Completed"),
    [StatusEnum.IN_PROGRESS]: () => getMessage("checklist-General-TaskStatus-InProgress"),
    [StatusEnum.INITIATED]: () => getMessage("checklist-General-TaskStatus-InProgress"),
    [StatusEnum.NOT_REQUIRED]: () => getMessage("checklist-General-TaskStatus-InProgress"),
    [NHEAppointmentStatusEnum.RESERVED]: () => getMessage("checklist-General-TaskStatus-Reserved"),
    [NHEAppointmentStatusEnum.UNKNOWN]: () => getMessage("checklist-General-TaskStatus-ActionRequired"),
    [NHEAppointmentStatusEnum.COMPLETED]: () => getMessage("checklist-General-TaskStatus-Completed"),
  };

  const statusText = typeof cases[safeString] === "function" && cases[safeString]();

  return statusText || safeString;
};

export const FlyOutStatusCard = ({ status, image, descriptionText }: Props) => {
  const bundle = useChecklistArb();

  const translatedStatus = bundle.getMessage("checklist-General-Status");

  return (
    <Col padding="S300" gridGap="S300">
      <Card>
        <Col backgroundColor={renderBGColor(status)} width={"6px"} />
        <Col gridGap="S300" padding="S300" width={"100%"}>
          <H3 fontSize={"T400"}>{translatedStatus}</H3>
          <Row alignItems={"center"} gridGap={"S200"}>
            {image}
            <Text>{descriptionText}</Text>
          </Row>
        </Col>
      </Card>
    </Col>
  );
};

export const FlyOutRTWAppointmentStatusCard = ({
  status,
  image,
  descriptionText,
  headingText,
  messageText,
  dateText,
  locationText,
  appointmentSelfServiceURL,
  addToCalendarButton,
}: RTWAppointmentProps) => {
  const bundle = useChecklistArb();

  const reschedule = bundle.getMessage("Checklist-Flyout-RTW-Appointment-Reschedule-Button");

  const translatedStatus = bundle.getMessage("checklist-General-Status");

  return (
    <Col padding="S300" gridGap="S300">
      <Card>
        <Col backgroundColor={renderBGColor(status)} width={"6px"} />
        <Col gridGap="S300" padding="S300" width={"100%"}>
          <H3 fontSize={"T400"}>{translatedStatus}</H3>
          <Row alignItems={"center"} gridGap={"S200"}>
            {image}
            {descriptionText && <Text>{descriptionText}</Text>}
          </Row>
        </Col>
      </Card>

      <Card>
        <Col>
          <Col backgroundColor={renderBGColor(status)} width={"6px"} />
          <Col gridGap={"S300"} padding={"S300"}>
            <H3 fontSize={"T400"}>{headingText}</H3>
            {dateText && <Text fontSize={"T200"}>{dateText}</Text>}
            {locationText && <Text fontSize={"T200"}>{locationText}</Text>}
            {messageText && <Text fontSize={"T200"}>{messageText}</Text>}
            <Row gridGap={"S300"}>
              {appointmentSelfServiceURL && (
                <Col width={"100%"}>
                  <Button size={ButtonSize.Small} onClick={useNavigatorWithURLReady(appointmentSelfServiceURL)}>
                    {reschedule}
                  </Button>
                </Col>
              )}
              {addToCalendarButton && <Col width={"100%"}>{addToCalendarButton}</Col>}
            </Row>
          </Col>
        </Col>
      </Card>
    </Col>
  );
};

export const FlyOutEmploymentPaperworkStatusCard = ({ status, descriptionText, image }: Props) => {
  const bundle = useChecklistArb();
  const country = getCountryForDomain();

  const statusTitle = bundle.getMessage("checklist-General-Status");
  const detailsTitle = bundle.getMessage("Checklist-CardControlled-DetailsTitle");
  const canadaBlurb = bundle.getMessage("Checklist-MainPage-EmploymentPaperwork-Blurb-CA");

  const extraBlurb =
    country === SUPPORTED_COUNTRY.CA ? (
      <MessageBanner data-testid="flyout-details-banner">{canadaBlurb}</MessageBanner>
    ) : undefined;

  return (
    <Col className="flyout-content">
      <Col gridGap="S300">
        <Card title={statusTitle}>
          <Row>
            <Col
              backgroundColor={renderBGColor(status)}
              width={"S100"}
              data-testid={`flyout-status-text-${status}`}
            ></Col>
            <Col gridGap={"S300"} padding={"S300"}>
              <H3 fontSize={"T400"}>{statusTitle}</H3>
              {image}
            </Col>
          </Row>
        </Card>
        <Card>
          <Col gridGap={"S300"} data-testid="flyout-details-text">
            <H3 fontSize={"T400"}>{detailsTitle}</H3>
            <Text>{descriptionText}</Text>
            {extraBlurb}
          </Col>
        </Card>
      </Col>
    </Col>
  );
};

export const FlyOutAtoZStatusCard = ({
  status,
  atoZDetailsExtra,
  atoZBodyText,
  atoZEmployeeLogin,
  statusText,
}: AtoZProps) => {
  const { H3, H4, Text } = require("../typography");
  const bundle = useChecklistArb();
  const dueSoonMessage = bundle.getMessage("checklist-General-TaskStatus-DueSoon");
  const translatedStatus = bundle.getMessage("checklist-General-Status");
  const translatedDetails = bundle.getMessage("Checklist-CardControlled-DetailsTitle");

  return (
    <Col padding={"S300"}>
      <Card>
        <Col backgroundColor={renderBGColor(status as StatusEnum)} width={"S200"} />
        <Col>
          <Col padding={"S300"}>
            <H3 color={"neutral90"} fontSize={"T400"}>
              {translatedStatus}
            </H3>
            {status != StatusEnum.COMPLETED && (
              <Text fontSize={"T200"} data-testid="flyout-text-Due soon">
                {dueSoonMessage}
              </Text>
            )}
            <Text fontSize={"T200"} data-testid={`flyout-status-text-${status}`}>
              {statusText}
            </Text>
          </Col>
        </Col>
      </Card>
      <br />
      <Card padding={"S300"}>
        <Col>
          <H3 color={"neutral70"} fontSize={"T400"}>
            {translatedDetails}
          </H3>
          <Text color={"neutral70"}> {atoZBodyText} </Text>
        </Col>
      </Card>
      <br />
      <Col>
        <H3 color={"neutral70"}> {atoZDetailsExtra} </H3>
        <Col backgroundColor={"primary05"} padding="S300">
          {atoZEmployeeLogin && (
            <H4 color={"Neutral100"} textAlign="center" fontWeight={"bold"} fontSize="T300">
              {atoZEmployeeLogin}
            </H4>
          )}
        </Col>
      </Col>
    </Col>
  );
};

export const FlyOutBadgePhotoStatusCard = ({ status, shouldOverrideStatus }: BadgePhotoProps) => {
  const bundle = useChecklistArb();

  const translatedStatus = bundle.getMessage("checklist-General-Status");
  const translatedUploadCompleted = bundle.getMessage("Checklist-BPUpload-Completed");
  const translatedApprovalPending = bundle.getMessage("checklist-General-Approval-Pending");
  const translatedApproved = bundle.getMessage("checklist-General-Approved");
  const translatedActionRequired = bundle.getMessage("checklist-General-TaskStatus-ActionRequired");

  const displayInstructionDetailsStatus = [StatusEnum.NOT_STARTED, StatusEnum.API_ERROR];

  const translatedUploadCompletedJsx = (
    <>
      <H3 fontSize={"T400"}>{translatedStatus}</H3>
      <Row alignItems={"center"} gridGap={"S200"}>
        <IconCheckCircleFill size={IconSize.Medium} color={HVH_COLORS.GREEN_70} />
        <Text>{translatedUploadCompleted}</Text>
      </Row>
    </>
  );

  if (displayInstructionDetailsStatus.includes(status)) {
    return (
      <Col>
        <Col backgroundColor={renderBGColor(status, shouldOverrideStatus)} width={"S200"} />
        <Col gridGap={"S300"} padding={"S300"}>
          <H3 fontSize={"T400"}>{translatedStatus}</H3>
          <Text>{translatedActionRequired}</Text>
        </Col>
      </Col>
    );
  }

  if (status == StatusEnum.COMPLETED) {
    return (
      <Col gridGap={"S300"} padding={"S300"} width={"100%"}>
        {translatedUploadCompletedJsx}
        <Row alignItems={"center"} gridGap={"S200"}>
          <IconCheckCircleFill size={IconSize.Medium} color={HVH_COLORS.GREEN_70} />
          <Text>{translatedApproved}</Text>
        </Row>
      </Col>
    );
  }

  return (
    <Col gridGap={"S300"} padding={"S300"} width={"100%"}>
      {translatedUploadCompletedJsx}
      <Row alignItems={"center"} gridGap={"S200"}>
        <img width={"S400"} height={"S400"} src={ProgressRing} alt={""} />
        <Text>{translatedApprovalPending}</Text>
      </Row>
    </Col>
  );
};
