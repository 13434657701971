import { IconCheckCircleFill, IconCloseCircleFill, IconSize } from "@amzn/stencil-react-components/icons";
import { Col } from "@amzn/stencil-react-components/layout";
import { H4, Text } from "@amzn/stencil-react-components/text";
import React from "react";
import ProgressRing from "../../assets/svg/progress-ring.svg";
import { SHOW_BGC_TASK_DETAIL_ALLOWED_COUNTRY, SUPPORTED_COUNTRY } from "../../config/appConfigConstants";
import { HVH_COLORS } from "../../config/palette";
import { useChecklistArb } from "../../hooks/use-arb";
import { StatusEnum } from "../../utility/enums/common";
import { FlyOutStatusCard, bgcUseStatusText } from "../utilities/FlyOutStatusCard";
import { BGCInformationProps } from "./types";

export const getFlyoutStatusCard = (status: StatusEnum) => {
  switch (status) {
    case StatusEnum.AWAITING_CANDIDATE_INFO:
      return (
        <FlyOutStatusCard
          status={status}
          image={<img width={"S400"} height={"S400"} src={ProgressRing} alt="" />}
          descriptionText={bgcUseStatusText(status)}
        />
      );
    case StatusEnum.COMPLETED:
      return (
        <FlyOutStatusCard
          status={status}
          image={<IconCheckCircleFill size={IconSize.Medium} color={HVH_COLORS.GREEN_70} />}
          descriptionText={bgcUseStatusText(status)}
        />
      );
    case StatusEnum.ERROR:
      return (
        <FlyOutStatusCard
          status={status}
          image={<IconCloseCircleFill size={IconSize.Medium} color={HVH_COLORS.RED_70} />}
          descriptionText={bgcUseStatusText(status)}
        />
      );
    default:
      return (
        <FlyOutStatusCard
          status={status}
          image={<img width={"S400"} height={"S400"} src={ProgressRing} alt="" />}
          descriptionText={bgcUseStatusText(status)}
        />
      );
  }
};

export const BGCInformation = ({ status, country }: BGCInformationProps) => {
  const { formatMessage, getMessage } = useChecklistArb();

  const bgcDetails = (country as SUPPORTED_COUNTRY) in SHOW_BGC_TASK_DETAIL_ALLOWED_COUNTRY && (
    <Col gridGap={"S300"} padding={"S300"} data-testid="bgcDetails" className="bgc-flyout-details">
      <H4>{getMessage("Checklist-PreHireDetails-WhatToExpect", "What to expect")}</H4>
      <ol>
        <li>
          <Text>
            {formatMessage("Checklist-BGC-Flyout-Detail-1", {
              highlight: <strong>{getMessage("Checklist-BGC-Flyout-Detail-1-highlight")}</strong>,
            })}
          </Text>
        </li>
        <li>
          <Text>
            {formatMessage("Checklist-BGC-Flyout-Detail-2", {
              highlight: <strong>{getMessage("Checklist-BGC-Flyout-Detail-2-highlight")}</strong>,
            })}
          </Text>
        </li>
        <li>
          <Text>
            {formatMessage("Checklist-BGC-Flyout-Detail-3", {
              highlight: <strong>{getMessage("Checklist-BGC-Flyout-Detail-3-highlight")}</strong>,
            })}
          </Text>
        </li>
        <li>
          <Text>
            {getMessage(
              "Checklist-BGC-Flyout-Detail-4",
              "Please follow these instructions carefully to ensure the smooth and successful completion of your background check. If you have any questions or require further assistance, please reach out to the relevant contact provided."
            )}
          </Text>
        </li>
      </ol>
    </Col>
  );

  return (
    <>
      {getFlyoutStatusCard(status)}
      {bgcDetails}
    </>
  );
};
