import { Button, ButtonSize, ButtonVariant } from "@amzn/stencil-react-components/button";
import { Card } from "@amzn/stencil-react-components/card";
import { IconCheckCircleFill, IconSize } from "@amzn/stencil-react-components/icons";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { Link } from "@amzn/stencil-react-components/link";
import { Spinner, SpinnerSize } from "@amzn/stencil-react-components/spinner";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FeatFlag, SUPPORTED_COUNTRY } from "../../config/appConfigConstants";
import { HVH_COLORS } from "../../config/palette";
import {
  CSA_URL,
  CSA_URL_FR,
  WORK_AUTHORITY_STORE_URL,
  WORK_AUTHORITY_STORE_URL_FR,
  ZAPPOS_FAQ_URL,
} from "../../config/urls";
import { Stage, getSettings } from "../../helpers/get-settings";
import { useGetCandidateData } from "../../hooks/apis/useGetCandidateData";
import { useChecklistArb } from "../../hooks/use-arb";
import { useNavigatorWithURLReadyOnOpeningNewTab } from "../../hooks/use-navigator";
import { useAshRum } from "../../hooks/useAshRum.hook";
import { useGetZapposMagicLinkQuery } from "../../reduxStore/api/apiSlice";
import { addEventMetricWithBBId } from "../../utility/adobe-analytics";
import { isTaskEnabledByAppConfig } from "../../utility/app-config-helper";
import { CONTACT_US_LINK } from "../../utility/constants";
import { dispatchOnDev } from "../../utility/dev-env-helpers";
import { StatusEnum } from "../../utility/enums/common";
import { getCountryForDomain, isFrenchLocale } from "../../utility/locale-helper";
import { renderBGColor } from "../task";
import { H3, H4, Text } from "../typography";

interface ZapposPurchasePromotionProps {
  status: StatusEnum;
  taskId?: string;
}

export const ZapposPurchasePromotion = ({ status, taskId }: ZapposPurchasePromotionProps) => {
  const country = getCountryForDomain();
  const bundle = useChecklistArb();

  const discountInfo = bundle.getMessage("Checklist-MindYourStep-Contigencies-Completion-DiscountInfo");
  const discountInfoDescription = bundle.getMessage(
    "Checklist-MindYourStep-Contigencies-Completion-DiscountInfo-Description"
  );
  const statusTitle = bundle.getMessage("checklist-General-Status");
  const detailsTitle = bundle.getMessage("Checklist-CardControlled-DetailsTitle");
  const magicLinkDetailsText = bundle.getMessage("Checklist-MindYourStep-Details-Text-Magic-Link");
  const detailsText1 = bundle.getMessage("Checklist-MindYourStep-Details-Text1");
  const detailsText2 = bundle.getMessage("Checklist-MindYourStep-Details-Text2");

  const acceptableDocument = bundle.getMessage(
    "Checklist-MindYourStep-Canada-Details-Text2-SupportLink",
    "Canadian Standards Association (CSA) approved (Grade 1)"
  );

  const localStoreLink = bundle.getMessage(
    "Checklist-MindYourStep-Canada-Details-Text3-SupportLink",
    "Local Work Authority store"
  );

  const csaLink = (
    <Link target="_blank" href={isFrenchLocale() ? CSA_URL_FR : CSA_URL}>
      {acceptableDocument}
    </Link>
  );

  const localWorkAuthorityStoreLink = (
    <Link target="_blank" href={isFrenchLocale() ? WORK_AUTHORITY_STORE_URL_FR : WORK_AUTHORITY_STORE_URL}>
      {localStoreLink}
    </Link>
  );

  const canadaMindYourStepDetailsText1 = bundle.getMessage("Checklist-MindYourStep-Canada-Details-Text1");

  const canadaMindYourStepDetailsText2 = bundle.formatMessage("Checklist-MindYourStep-Canada-Details-Text2", {
    csaLink: csaLink,
  });

  const canadaMindYourStepDetailsText3 = bundle.formatMessage("Checklist-MindYourStep-Canada-Details-Text3", {
    localWorkAuthorityStoreLink: localWorkAuthorityStoreLink,
  });

  const completed = bundle.getMessage("checklist-General-TaskStatus-Completed");

  const needHelpInfo = bundle.getMessage("Checklist-MindYourStep-Candidate-Support");
  const candidateSupportLinkText = bundle.getMessage("Checklist-MindYourStep-Candidate-Support-Link");

  const magicLinkZapposSupportFaqText = bundle.getMessage("Checklist-MindYourStep-Zappos-Support-Text-FAQ");
  const magicLinkZapposSupportFaqLink = bundle.getMessage("Checklist-MindYourStep-Zappos-Support-FAQ-Link");
  const magicLinkZapposSupportPhoneText = bundle.getMessage("Checklist-MindYourStep-Zappos-Support-Text-Phone");

  const { applicationId } = useParams<{
    applicationId: string;
  }>();

  const magicLinkEnabled = isTaskEnabledByAppConfig(FeatFlag.ENABLE_ZAPPOS_MAGIC_LINK, applicationId);

  const {
    data: zapposMagicLinkData,
    isError: zapposMagicLinkError,
    isLoading: zapposMagicLinkLoading,
  } = useGetZapposMagicLinkQuery(
    magicLinkEnabled && taskId ? { applicationId: applicationId, taskId: taskId } : skipToken
  );

  const { recordRumEvent } = useAshRum();

  const styles = {
    border: "2px dashed rgba(1, 1, 1, 1)",
    color: "black",
    lineHeight: "2.5",
    background: "white",
    width: "100px",
    fontSize: "20px",
  };

  const boxStyle = {
    background: HVH_COLORS.GREEN_10,
    padding: "10px",
    border: "1px solid green",
    color: HVH_COLORS.GREEN_60,
    height: "110px",
    textAlign: "center" as const,
    borderLeft: "8px solid green",
  };

  const [stage, setStage] = useState<Stage>();

  useEffect(() => {
    getSettings().then((settings) => setStage(settings.stage));
  }, []);

  if (country === SUPPORTED_COUNTRY.CA) {
    return (
      <Col className="flyout-content">
        <Col gridGap="1rem">
          <Card>
            <Col gridGap={"1rem"}>
              <H4 data-testid={"flyout-content-get-your-work-shoes-text"} fontSize={"T400"}>
                {canadaMindYourStepDetailsText1}
              </H4>
              <Text data-testid={"flyout-content-csa-link"} textAlign="justify">
                {canadaMindYourStepDetailsText2}
              </Text>
              <Text data-testid={"flyout-content-store-link"} textAlign="justify">
                {canadaMindYourStepDetailsText3}
              </Text>
            </Col>
          </Card>
        </Col>
      </Col>
    );
  }

  if (zapposMagicLinkLoading) {
    return <Spinner size={SpinnerSize.Large} showText={false}></Spinner>;
  }

  if (status == StatusEnum.COMPLETED) {
    return (
      <Col className="flyout-content">
        <Col gridGap="1rem">
          <Card padding="0rem" title={statusTitle}>
            <Row>
              <Col backgroundColor={renderBGColor(status)} width={"6px"}></Col>
              <Col gridGap={"1rem"} padding={"1rem"}>
                <H3 fontSize={"T400"}>{statusTitle}</H3>
                <Row alignItems={"center"} gridGap={"0.5rem"}>
                  <IconCheckCircleFill size={IconSize.Medium} color={HVH_COLORS.GREEN_70} />
                  <Text>{completed}</Text>
                </Row>
              </Col>
            </Row>
          </Card>
          <Card>
            <Col gridGap={"1rem"}>
              <H3 fontSize={"T400"}>{detailsTitle}</H3>
              <Text>{detailsText2}</Text>
            </Col>
          </Card>
        </Col>
      </Col>
    );
  } else if (magicLinkEnabled && taskId && !zapposMagicLinkError) {
    // Zappos Magic Link Flyout only shows up when above conditions are met. Otherwise, fall back to original Flyout.
    return (
      <Col className="flyout-content">
        <Col gridGap="1rem">
          <Text>
            <div style={boxStyle}>
              <H3>
                <strong>{discountInfo}</strong>
                <br />
                <Text>{discountInfoDescription}</Text>
              </H3>
            </div>
          </Text>
          <Card>
            <Col gridGap={"1rem"}>
              <H3 fontSize={"T400"}>{detailsTitle}</H3>
              <Text>{magicLinkDetailsText}</Text>
            </Col>
          </Card>
          <Text>
            {magicLinkZapposSupportFaqText}{" "}
            <Link target="_blank" href={ZAPPOS_FAQ_URL}>
              {magicLinkZapposSupportFaqLink}
            </Link>
            {". "}
            {magicLinkZapposSupportPhoneText}
          </Text>
        </Col>
      </Col>
    );
  }

  if (!taskId) {
    recordRumEvent({
      type: "ash_error_zappos_code_missing",
      taskStatus: status,
    });
  }

  return (
    <Col className="flyout-content">
      <Col gridGap="1rem">
        <Text>
          <div style={boxStyle}>
            <H3>
              <strong>
                {discountInfo}
                <br />
              </strong>
            </H3>
            <span style={styles}>{taskId}</span>
          </div>
        </Text>
        <Card>
          <Col gridGap={"1rem"}>
            <H3 fontSize={"T400"}>{detailsTitle}</H3>
            <Text>{detailsText1}</Text>
          </Col>
        </Card>
        <Text>
          {needHelpInfo}{" "}
          <Link target="_blank" href={CONTACT_US_LINK}>
            {candidateSupportLinkText}
          </Link>
        </Text>
      </Col>
    </Col>
  );
};

interface ZapposPurchasePromotionButtonProps {
  href: string;
  taskId: string;
}
export const ZapposPurchasePromotionButton = ({ href, taskId }: ZapposPurchasePromotionButtonProps) => {
  const bundle = useChecklistArb();

  let purchaseButtonText = bundle.getMessage("Checklist-MindYourStep-Contigencies-Completion-Purchase-ButtonText");
  const magicLinkButtonText = "Go To Zappos";
  const { recordRumEvent } = useAshRum();
  const { applicationId } = useParams<{
    applicationId: string;
  }>();

  const magicLinkEnabled = isTaskEnabledByAppConfig(FeatFlag.ENABLE_ZAPPOS_MAGIC_LINK, applicationId);
  const candidateData = useGetCandidateData();
  const { data: zapposMagicLinkData } = useGetZapposMagicLinkQuery(
    magicLinkEnabled ? { applicationId: applicationId, taskId: taskId } : skipToken
  );

  let zapposRedirectLink = href;

  if (zapposMagicLinkData) {
    zapposRedirectLink = zapposMagicLinkData.url;
    purchaseButtonText = magicLinkButtonText;
  }

  const sendAnalyticsEvent = useCallback(
    async (eventName: string) => {
      if (!candidateData) {
        dispatchOnDev(() => {
          console.error("Candidate data is not available yet");
        });
        return;
      }

      await addEventMetricWithBBId(candidateData, window, applicationId, eventName);
    },
    [applicationId, candidateData]
  );

  const navigateToUrl = useNavigatorWithURLReadyOnOpeningNewTab(zapposRedirectLink);

  const navigateToZappos = useCallback(async () => {
    sendAnalyticsEvent("Make Shoe Purchase");
    recordRumEvent({
      type: "ash_info_zappos_redirect_event",
      taskId,
    });
    await copyTextToClipboard(taskId);
    navigateToUrl();
  }, [sendAnalyticsEvent, navigateToUrl, taskId, recordRumEvent]);

  return (
    <Button size={ButtonSize.Small} variant={ButtonVariant.Primary} onClick={navigateToZappos}>
      {purchaseButtonText}
    </Button>
  );
};

async function copyTextToClipboard(text: string) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  await navigator.clipboard.writeText(text).then(
    function () {},
    function (err) {
      dispatchOnDev(() => {
        console.error("Unable to async copy to clipboard: ", err);
      });
    }
  );
}

function fallbackCopyTextToClipboard(text: string) {
  const textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand("copy");
  } catch (err) {
    dispatchOnDev(() => {
      console.error("Unable to fallback copy to clipboard: ", err);
    });
  }

  document.body.removeChild(textArea);
}
