import React, { useCallback } from "react";
import { StatusEnum } from "../../utility/enums/common";
import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { useChecklistArb } from "../../hooks/use-arb";
import { useUrlBuilder } from "../../hooks/useUrlBuilder.hook";
import { BGCInformationProps } from "./types";

export const BGCInfoTaskCardButton = ({ status }: BGCInformationProps) => {
  const { bgcInfoCollectionURL } = useUrlBuilder();

  const bundle = useChecklistArb();

  const bgcInformationButtonText = bundle.getMessage("Checklist-MainPage-BGC-ButtonText");

  const redirect = useCallback(() => {
    window.open(bgcInfoCollectionURL, "_blank");
  }, [bgcInfoCollectionURL]);

  return (
    <Button
      data-testid="bgc-information-collection-button"
      variant={ButtonVariant.Primary}
      onClick={redirect}
      disabled={status !== StatusEnum.AWAITING_CANDIDATE_INFO}
    >
      {bgcInformationButtonText}
    </Button>
  );
};
