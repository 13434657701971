import React from "react";
import { Text } from "@amzn/stencil-react-components/text";
import { CUSTOMER_SERVICE, JobType, NON_CUSTOMER_SERVICE } from "../../../../config/appConfigConstants";
import { US_CAAS_STATUS } from "../../i9-data";
import { Row } from "@amzn/stencil-react-components/layout";
import { ChecklistBundle } from "../../../../hooks/use-arb";
import { IconCheckCircleFill, IconCloseCircleFill, IconSize } from "@amzn/stencil-react-components/icons";
import { HVH_COLORS } from "../../../../config/palette";
import ProgressRing from "../../../../assets/svg/progress-ring.svg";

export const getUSChecklistMappingCAAS = ({ bundle }: { bundle: ChecklistBundle }) => {
  const translatedSectionOne = bundle.getMessage("Checklist-MainPage-I9-Section1");
  const translatedSectionTwo = bundle.getMessage("Checklist-MainPage-I9-Section2");
  const translatedCompleted = bundle.getMessage("checklist-General-TaskStatus-Completed");
  const translatedCompletedAtAppointment = bundle.getMessage("Checklist-MainPage-I9-CompleteAtAppointment");
  const translatedActionRequiredBeforeAppointment = bundle.getMessage(
    "Checklist-MainPage-I9-ActionRequiredBeforeAppointment"
  );
  const translatedI9Reverification = bundle.getMessage("Checklist-MainPage-I9-Reverification");
  const translatedI9Error = bundle.getMessage("Checklist-MainPage-I9-Error");

  const taskPendingMessage = bundle.getMessage("checklist-General-TaskStatus-Pending");
  const taskActionRequiredMessage = bundle.getMessage("checklist-General-Action-Required");
  const taskActionCompleted = bundle.getMessage("checklist-General-Completed");
  const taskPendingReviewByAmazon = bundle.getMessage("checklist-General-TaskStatus-PendingReviewByAmazon");

  const completionCheck = <IconCheckCircleFill size={IconSize.Medium} color={HVH_COLORS.GREEN_70} />;

  const errorCheck = <IconCloseCircleFill size={IconSize.Medium} color={HVH_COLORS.RED_70} />;

  const progressRing = <img width={"S400"} height={"S400"} src={ProgressRing} alt="" />;

  const usCaasStatusToChecklistSectionMapping: {
    [key in JobType]: { [key in string]: JSX.Element };
  } = {
    [NON_CUSTOMER_SERVICE]: {
      [US_CAAS_STATUS.Section1Complete]: (
        <>
          <Row alignItems={"center"} gridGap={"S200"} data-testid="i9-section-1-completed">
            {completionCheck}
            <Text>
              {translatedSectionOne} &#183; {translatedCompleted}
            </Text>
          </Row>
          <Row alignItems={"start"} gridGap={"S200"} data-testid="i9-section-2-complete-at-appt">
            {progressRing}
            <Text>
              {translatedSectionTwo} &#183; {translatedCompletedAtAppointment}
            </Text>
          </Row>
        </>
      ),
      [US_CAAS_STATUS.Section2Complete]: (
        <>
          <Row alignItems={"center"} gridGap={"S200"}>
            {completionCheck}
            <Text data-testid="i9-section-1-completed">
              {translatedSectionOne} &#183; {translatedCompleted}
            </Text>
          </Row>
          <Row alignItems={"start"} gridGap={"S200"}>
            {completionCheck}
            <Text data-testid="i9-section-2-completed">
              {translatedSectionTwo} &#183; {translatedCompleted}
            </Text>
          </Row>
        </>
      ),
      [US_CAAS_STATUS.PendingSection3]: (
        <Row alignItems={"center"} gridGap={"S200"}>
          {progressRing}
          <Text data-testid="i9-section-3-reverification-complete-at-appt">
            {translatedI9Reverification} &#183; {translatedCompletedAtAppointment}
          </Text>
        </Row>
      ),
      [US_CAAS_STATUS.Archived]: (
        <Row alignItems={"center"} gridGap={"S200"}>
          {progressRing}
          <Text>
            {translatedI9Reverification} &#183; {translatedCompletedAtAppointment}
          </Text>
        </Row>
      ),
      [US_CAAS_STATUS.Section3Complete]: (
        <Row alignItems={"center"} gridGap={"S200"}>
          {completionCheck}
          <Text data-testid="i9-section-3-reverification-completed">
            {translatedI9Reverification} &#183; {translatedCompleted}
          </Text>
        </Row>
      ),
      [US_CAAS_STATUS.Error]: (
        <Row alignItems={"center"} gridGap={"S200"}>
          {errorCheck}
          <Text>{translatedI9Error}</Text>
        </Row>
      ),
      [US_CAAS_STATUS.Pending]: (
        <>
          <Text>{taskPendingMessage}</Text>
        </>
      ),
      [US_CAAS_STATUS.Completed]: (
        <Row alignItems={"center"} gridGap={"S200"}>
          {completionCheck}
          <Text>{translatedCompleted}</Text>
        </Row>
      ),
      [US_CAAS_STATUS.WaitingForASHToCreateOrder]: (
        <>
          <Text>
            {translatedSectionOne} &#183; {translatedActionRequiredBeforeAppointment}
          </Text>
          <Text>
            {translatedSectionTwo} &#183; {translatedCompletedAtAppointment}
          </Text>
        </>
      ),
      [US_CAAS_STATUS.PendingSection1]: (
        <>
          <Text data-testid="i9-section-1-action-required-before-appt">
            {translatedSectionOne} &#183; {translatedActionRequiredBeforeAppointment}
          </Text>
          <Text data-testid="i9-section-2-complete-at-appt">
            {translatedSectionTwo} &#183; {translatedCompletedAtAppointment}
          </Text>
        </>
      ),
      [US_CAAS_STATUS.Initiated]: (
        <>
          <Text>
            {translatedSectionOne} &#183; {translatedActionRequiredBeforeAppointment}
          </Text>
          <Text>
            {translatedSectionTwo} &#183; {translatedCompletedAtAppointment}
          </Text>
        </>
      ),
    },
    [CUSTOMER_SERVICE]: {
      [US_CAAS_STATUS.Section2Complete]: (
        <>
          <Row alignItems={"center"} gridGap={"S200"}>
            {completionCheck}
            <Text>
              {translatedSectionOne} &#183; {translatedCompleted}
            </Text>
          </Row>
          <Row alignItems={"start"} gridGap={"S200"}>
            {completionCheck}
            <Text>
              {translatedSectionTwo} &#183; {translatedCompleted}
            </Text>
          </Row>
        </>
      ),
      [US_CAAS_STATUS.PendingSection1]: (
        <>
          <Row alignItems={"center"} gridGap={"S200"}>
            {progressRing}
            <Text>
              {translatedSectionOne} &#183; {taskActionRequiredMessage}
            </Text>
          </Row>
          <Row alignItems={"start"} gridGap={"S200"}>
            {progressRing}
            <Text>
              {translatedSectionTwo} &#183; {taskActionRequiredMessage}
            </Text>
          </Row>
        </>
      ),
      [US_CAAS_STATUS.Section1Complete]: (
        <>
          <Row alignItems={"center"} gridGap={"S200"}>
            {completionCheck}
            <Text>
              {translatedSectionOne} &#183; {translatedCompleted}
            </Text>
          </Row>
          <Row alignItems={"start"} gridGap={"S200"}>
            {progressRing}
            <Text>
              {translatedSectionTwo} &#183; {taskActionRequiredMessage}
            </Text>
          </Row>
        </>
      ),
      [US_CAAS_STATUS.Archived]: (
        <Row alignItems={"center"} gridGap={"S200"}>
          {completionCheck}
          <Text>{taskActionCompleted}</Text>
        </Row>
      ),
      [US_CAAS_STATUS.PendingSection3]: (
        <Row alignItems={"center"} gridGap={"S200"}>
          {completionCheck}
          <Text>{taskActionCompleted}</Text>
        </Row>
      ),
      [US_CAAS_STATUS.Section3Complete]: (
        <Row alignItems={"center"} gridGap={"S200"}>
          {completionCheck}
          <Text>{translatedCompleted}</Text>
        </Row>
      ),
      [US_CAAS_STATUS.Error]: (
        <Row alignItems={"center"} gridGap={"S200"}>
          {errorCheck}
          <Text>{translatedI9Error}</Text>
        </Row>
      ),
      [US_CAAS_STATUS.Pending]: (
        <>
          <Text>{taskPendingReviewByAmazon}</Text>
        </>
      ),
      [US_CAAS_STATUS.Completed]: (
        <Row alignItems={"center"} gridGap={"S200"}>
          {completionCheck}
          <Text>{translatedCompleted}</Text>
        </Row>
      ),
      [US_CAAS_STATUS.Initiated]: (
        <>
          <Text>
            {translatedSectionOne} &#183; {translatedActionRequiredBeforeAppointment}
          </Text>
          <Text>
            {translatedSectionTwo} &#183; {translatedCompletedAtAppointment}
          </Text>
        </>
      ),
      [US_CAAS_STATUS.WaitingForASHToCreateOrder]: (
        <>
          <Text>
            {translatedSectionOne} &#183; {translatedActionRequiredBeforeAppointment}
          </Text>
          <Text>
            {translatedSectionTwo} &#183; {translatedCompletedAtAppointment}
          </Text>
        </>
      ),
    },
  };

  return usCaasStatusToChecklistSectionMapping;
};
