import { useEffect, useState } from "react";
import { SUPPORTED_COUNTRY } from "../../config/appConfigConstants";
import { useLazyGetVideoUrlQuery } from "../../reduxStore/api/apiSlice";
import { getCountryForDomain, getLocale } from "../../utility/locale-helper";
import { isSalesforceApplication } from "../../helpers/url-params";
import { useGetApplicationId } from "./useGetApplicationId";
import { useGetRequisitionCategory } from "./useGetRequisitionCategory";
import { VideoData } from "../../utility/application-data";
import { isDevEnv } from "../../utility/dev-env-helpers";

export const useGetVideoData = () => {
  const [video, setVideo] = useState<VideoData | null>(null);
  const applicationId = useGetApplicationId();
  const requisitionCategory = useGetRequisitionCategory();
  const [getVideoURL] = useLazyGetVideoUrlQuery();

  const isSkipped = getCountryForDomain() == SUPPORTED_COUNTRY.MX && !isSalesforceApplication(applicationId);

  useEffect(() => {
    if (!isSkipped) {
      getVideoURL({ applicationId, locale: getLocale(), requisitionCategory }, true)
        .unwrap()
        .then((data) => {
          setVideo(data);
        });
    } else {
      if (isDevEnv()) console.warn("Skipping useGetVideoData hook");
    }
  }, [applicationId, getVideoURL, requisitionCategory, isSkipped]);

  return video;
};
