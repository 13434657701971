import React from "react";
import { OldUiFlyoutContentType, openOldUiFlyoutContentUpload } from "../../../../reduxStore/slices/docUploadSlice";
import { AttachmentType } from "../models";

export const eventHandler = (
  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  oldUiFlyoutContent: OldUiFlyoutContentType,
  handler: (params: {
    payload: { oldUiFlyoutContent: { documentType: AttachmentType | "FORM"; isEdit: boolean } };
    type: string;
  }) => any
) => {
  event.preventDefault();
  handler(openOldUiFlyoutContentUpload({ oldUiFlyoutContent }));
};
