import { MetricsSender } from "./../utility/metrics-sender";
import { METRIC_API_URL } from "../config/urls";
import { apiSlice, useMetricsSenderCreateOnFlushMutation } from "../reduxStore/api/apiSlice";
import { useUrlParams } from "./use-url-params";
import { useEffect, useState } from "react";
import { useGetCandidateData } from "./apis/useGetCandidateData";

let metricsSender = null as unknown as MetricsSender;

export const useMetrics = () => {
  const [_metricSender, _setMetricSender] = useState<MetricsSender | null>(metricsSender);
  const { requisitionId, applicationId } = useUrlParams();
  const candidateData = useGetCandidateData();
  const [initMetric] = useMetricsSenderCreateOnFlushMutation();

  const sendMetric = async (data: Record<string, any>) => {
    if (!candidateData) {
      console.error("CandidateData is not available yet");
      return;
    }
    const { candidateId } = candidateData;
    data.candidateId = candidateId;
    data.requisitionId = requisitionId;
    data.applicationId = applicationId;

    if (_metricSender) {
      _metricSender.push(data);
    }
  };

  useEffect(() => {
    if (metricsSender) {
      _setMetricSender(metricsSender);
    } else {
      metricsSender = new MetricsSender(METRIC_API_URL, initMetric);
      _setMetricSender(metricsSender);
    }
  }, [metricsSender]);

  return {
    sendMetric,
  };
};
