import { Card } from "@amzn/stencil-react-components/card";
import { Col } from "@amzn/stencil-react-components/layout";
import { H3, H4 } from "@amzn/stencil-react-components/text";
import React from "react";
import { useChecklistArb } from "../../hooks/use-arb";
import { useAppSelector } from "../../reduxStore/reduxHooks";
import { getConfigData } from "../../reduxStore/slices/configSlice";
import { StatusEnum } from "../../utility/enums/common";
import { renderBGColor } from "../task";
import { FlyOutBadgePhotoStatusCard } from "../utilities/FlyOutStatusCard";

export interface BadgePhotoProps {
  status: StatusEnum;
  shouldOverrideStatus?: Record<string, StatusEnum>;
}

export const BadgePhoto = ({ status, shouldOverrideStatus }: BadgePhotoProps) => {
  const { badgePhotoUrl } = useAppSelector(getConfigData);

  let badgePhotoStatus, badgePhotoInstructions, displayLastUpload;

  const bundle = useChecklistArb();
  const translatedDetails = bundle.getMessage("Checklist-BPUploadRequirements-H1");
  const translatedInstruction = bundle.getMessage("Checklist-BPUploadRequirements-Use");
  const translatedRule1 = bundle.getMessage("Checklist-BPUploadRequirements-Rule1");
  const translatedRule2 = bundle.getMessage("Checklist-BPUploadRequirements-Rule2");
  const translatedRule3 = bundle.getMessage("Checklist-BPUploadRequirements-Rule3");
  const translatedAvoid = bundle.getMessage("Checklist-BPUploadRequirements-DontUse");
  const translatedDoNotRule1 = bundle.getMessage("Checklist-BPUploadRequirements-DontRule1");
  const translatedDoNotRule2 = bundle.getMessage("Checklist-BPUploadRequirements-DontRule2");
  const translatedDoNotRule3 = bundle.getMessage("Checklist-BPUploadRequirements-DontRule3");

  const instructions = (
    <Col gridGap={"S300"} padding={"S300"}>
      <H3 data-testid={"list-label-do"} fontSize={"T400"}>
        {translatedDetails}
      </H3>

      <H4 data-testid={"list-label-do"}>{translatedInstruction}</H4>
      <ul aria-labelledby="list-label-do">
        <li>&#183; {translatedRule1}</li>
        <li>&#183; {translatedRule2}</li>
        <li>&#183; {translatedRule3}</li>
      </ul>

      <H4 data-testid={"list-label-dont"}>{translatedAvoid}</H4>
      <ul aria-labelledby="list-label-dont">
        <li>&#183; {translatedDoNotRule1}</li>
        <li>&#183; {translatedDoNotRule2}</li>
        <li>&#183; {translatedDoNotRule3}</li>
      </ul>
    </Col>
  );

  switch (status) {
    case StatusEnum.NOT_STARTED:
    case StatusEnum.API_ERROR:
      badgePhotoStatus = <FlyOutBadgePhotoStatusCard status={status} shouldOverrideStatus={shouldOverrideStatus} />;
      badgePhotoInstructions = instructions;
      break;
    case StatusEnum.COMPLETED:
      badgePhotoStatus = <FlyOutBadgePhotoStatusCard status={status} />;
      displayLastUpload = true;
      break;
    default:
      badgePhotoStatus = <FlyOutBadgePhotoStatusCard status={status} />;
      displayLastUpload = true;
      break;
  }

  return (
    <Col padding={"S300"} gridGap={"S300"}>
      <Card padding={"S100"}>
        <Col backgroundColor={renderBGColor(status, shouldOverrideStatus)} width={"S200"} />
        {badgePhotoStatus}
      </Card>

      {badgePhotoInstructions && (
        <Card padding={"S100"}>
          <Col>
            <Col backgroundColor={renderBGColor(status, shouldOverrideStatus)} width={"S200"} />
            {badgePhotoInstructions}
          </Col>
        </Card>
      )}
      {displayLastUpload && badgePhotoUrl && (
        <Col alignItems={"center"} padding={"S100 S300 S300 S300"}>
          <img width={"70%"} src={badgePhotoUrl} alt={"badge-photo"} />
        </Col>
      )}
    </Col>
  );
};
