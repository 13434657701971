import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { PageContainer } from "@amzn/stencil-react-components/page";
import React, { useCallback, useContext, useMemo, useRef } from "react";
import ImageGallery from "react-image-gallery";
import { H1 } from "../../../../components/typography";
import { HVH_COLORS } from "../../../../config/palette";
import { TAKE_BADGE_PHOTO_CONFIRM_URL } from "../../../../config/urls";
import { useSendAnalyticEvent } from "../../../../hooks/jobDetailsCardAnalyticEventHelper.hook";
import { useChecklistArb } from "../../../../hooks/use-arb";
import { useNavigator } from "../../../../hooks/use-navigator";
import { ActionType } from "../../../../reactContextStore/state";
import { Context } from "../../../../reactContextStore/store";
import "./gallery.scss";

export const BadgePhotoCameraGalleryRoute = () => {
  const [goToConfirm, goBack] = useNavigator(TAKE_BADGE_PHOTO_CONFIRM_URL);
  const { state, dispatch } = useContext(Context);
  const imageGalleryRef = useRef<any>();

  const { sendBadgePhotoEventAnalyticsEvent } = useSendAnalyticEvent();

  const { sampleShots = [] } = state;

  const tryAgain = useCallback(() => {
    goBack();
  }, [goBack]);

  const selectImage = useCallback(() => {
    const selectedShot = sampleShots[imageGalleryRef?.current.getCurrentIndex()];
    sendBadgePhotoEventAnalyticsEvent("camera select image");

    dispatch({
      type: ActionType.UpdateSelectedShot,
      payload: selectedShot,
    });
    goToConfirm();
  }, [dispatch, goToConfirm, sampleShots]);

  const images = useMemo(() => {
    return sampleShots.map((sampleShot) => {
      return {
        original: sampleShot.outputImageData,
      };
    });
  }, [sampleShots]);
  const bundle = useChecklistArb();

  const banner = bundle.getMessage("Checklist-BPUpload-Banner");
  const retry = bundle.getMessage("Checklist-BPGallery-Retry");
  const select = bundle.getMessage("Checklist-BPUpload-SelectButtonText");
  return (
    <>
      <PageContainer color={HVH_COLORS.NEUTRAL_90}>
        <Col gridGap="2rem" alignItems="center">
          <H1>{banner}</H1>
          <span className="gallery-wrapper">
            <ImageGallery
              ref={imageGalleryRef}
              showPlayButton={false}
              showThumbnails={false}
              showFullscreenButton={false}
              items={images}
            />
          </span>
          <Row justifyContent="space-around" width="100%">
            <Button data-testid="badge-photo-retry-button" onClick={tryAgain}>
              {retry}
            </Button>
            <Button data-testid="badge-photo-select-image-button" variant={ButtonVariant.Primary} onClick={selectImage}>
              {select}
            </Button>
          </Row>
        </Col>
      </PageContainer>
    </>
  );
};
