import { ADD_BASE_BEFORE_SALARY_AMOUNT_COUNTRY, SUPPORTED_COUNTRY } from "../../config/appConfigConstants";
import { getPayRate } from "../../helpers/utils";
import { ChecklistBundle } from "../../hooks/use-arb";
import { PayInterval } from "../../utility/enums/common";
import { ChecklistPantherKey } from "../../utility/types/translations";

interface PayValueAndPeriod {
  payInterval: PayInterval;
  hourlyBasePayL10N: string;
  biweeklyBasePayL10N: string;
  monthlyBasePayL10N: string;
  yearlyBasePayL10N: string;
  monthlyPayRateL10N?: string;
  country: SUPPORTED_COUNTRY;
}

const payValueAndPeriodKey = ({
  payInterval,
  hourlyBasePayL10N,
  biweeklyBasePayL10N,
  monthlyBasePayL10N,
  yearlyBasePayL10N,
  monthlyPayRateL10N,
  country,
}: PayValueAndPeriod): {
  payValue?: string;
  payPeriodKey: ChecklistPantherKey;
} | null => {
  switch (payInterval) {
    case PayInterval.HOURLY:
      return {
        payValue: hourlyBasePayL10N,
        payPeriodKey: "Checklist-JobCard-PayRate-Hour",
      };
    case PayInterval.BIWEEKLY:
      return {
        payValue: biweeklyBasePayL10N,
        payPeriodKey: "Checklist-JobCard-PayRate-Biweekly",
      };
    case PayInterval.MONTHLY:
      // Only EG and JO do language premium and monthlyPayRateL10N
      return {
        payValue: [SUPPORTED_COUNTRY.JO, SUPPORTED_COUNTRY.EG].includes(country)
          ? monthlyPayRateL10N
          : monthlyBasePayL10N,
        payPeriodKey: "Checklist-AppointmentCard-Month",
      };
    case PayInterval.YEARLY:
      return {
        payValue: yearlyBasePayL10N,
        payPeriodKey: "Checklist-JobCard-PayRate-Year",
      };
    default:
      // Force TS error if missing a case above
      // eslint-disable-next-line no-case-declarations
      const _exhaustiveCase: never = payInterval;
      return null;
  }
};

interface BuildFormattedPayRate {
  country: SUPPORTED_COUNTRY;
  bundle: ChecklistBundle;
  currencyCode: string;
  hourlyBasePayL10N: string;
  biweeklyBasePayL10N: string;
  monthlyBasePayL10N: string;
  yearlyBasePayL10N: string;
  languagePremiumL10N?: string;
  monthlyPayRateL10N?: string;
}

/**
 * Build the final pay value+rate string like "$12.50/hour" or "Base MXN$520.00/month" or "$22/hour + $2/hour Language Premium"
 */
export const buildFormattedSalaryContent = ({
  country,
  bundle,
  currencyCode,
  hourlyBasePayL10N,
  biweeklyBasePayL10N,
  monthlyBasePayL10N,
  yearlyBasePayL10N,
  languagePremiumL10N,
  monthlyPayRateL10N,
}: BuildFormattedPayRate): string => {
  const { payInterval, showCurrencyCode } = getPayRate(country);

  const valueAndPeriod = payValueAndPeriodKey({
    payInterval,
    hourlyBasePayL10N,
    biweeklyBasePayL10N,
    monthlyBasePayL10N,
    yearlyBasePayL10N,
    monthlyPayRateL10N,
    country,
  });

  if (null == valueAndPeriod) {
    return "Error";
  }

  const { payValue, payPeriodKey } = valueAndPeriod;

  // Japan is a special case where we use a different label to identify the frequency
  if (country === SUPPORTED_COUNTRY.JP) {
    return bundle.formatMessage("Checklist-JobCard-Pay-Hourly-JP", { payValue });
  }

  const payPeriod = bundle.getMessage(payPeriodKey);
  const includeBaseBeforeSalary = ADD_BASE_BEFORE_SALARY_AMOUNT_COUNTRY.includes(country);

  // Language premium needs separate format message. We don't support adding currency code yet
  if (languagePremiumL10N) {
    // Only EG and JO do language premium, and neither uses showCurrencyCode
    return bundle.formatMessage("Checklist-JobCard-Pay-WithLanguagePremium", {
      basePayValue: payValue,
      premiumPayValue: languagePremiumL10N,
      payPeriod,
    });
  }

  if (showCurrencyCode && includeBaseBeforeSalary) {
    return bundle.formatMessage("Checklist-JobCard-Pay-BaseWithCurrencyCode", {
      currencyCode,
      payValue,
      payPeriod,
    });
  } else if (showCurrencyCode) {
    return bundle.formatMessage("Checklist-JobCard-Pay-WithCurrencyCode", {
      currencyCode,
      payValue,
      payPeriod,
    });
  } else if (includeBaseBeforeSalary) {
    return bundle.formatMessage("Checklist-JobCard-Pay-Base", {
      payValue,
      payPeriod,
    });
  }
  return bundle.formatMessage("Checklist-JobCard-Pay-WithPeriodOnly", {
    payValue,
    payPeriod,
  });
};
