import React, { useState, useEffect } from "react";
import { Col } from "@amzn/stencil-react-components/layout";
import { Input, InputFooter } from "@amzn/stencil-react-components/form";
import { MessageBanner, MessageBannerType } from "@amzn/stencil-react-components/message-banner";
import { useChecklistArb } from "../../../../hooks/use-arb";
import { H2, Text } from "../../../../components/typography";
import { Button } from "@amzn/stencil-react-components/button";
import { AttachmentType } from "../models";
import { useParams } from "react-router-dom";
import { deleteCachedRequest } from "../../../../utility/candidate-data";
import {
  useGetCandidateDataQuery,
  useUpdateCandidateDocumentIdToCDSMutation,
} from "../../../../reduxStore/api/apiSlice";
import { dispatchOnDev, isDevEnv } from "../../../../utility/dev-env-helpers";
import { useBackToMainPage } from "../helpers/useBackToMainPage.hook";
import { TEMPORARY_SUBMIT_BUTTON_DISABLED_SOLUTION_SELECTOR } from "../../../../utility/constants/common";
import { useTemporarySubmitDisabledSolution } from "../helpers/useTemporarySubmitDisabledSolution.hook";
import { Label } from "@amzn/stencil-react-components/text";

export const EditTaxId = () => {
  const { requisitionId, applicationId } = useParams<{
    requisitionId: string;
    applicationId: string;
  }>();
  const { data: candidateData, refetch } = useGetCandidateDataQuery({ applicationId });
  const [updateCandidateDocumentID] = useUpdateCandidateDocumentIdToCDSMutation();

  const bundle = useChecklistArb();
  const taxIdTitleMessage = bundle.getMessage("Checklist-MainPage-Tax-ID-Title");
  const taxIdNumberMessage = bundle.getMessage("Checklist-Tax-ID-Number-Title");
  const taxIdNumberMessageError = bundle.getMessage("Checklist-Tax-ID-Text-Error");
  const documentImageSubmitMessage = bundle.getMessage("Checklist-Document-Image-Submit-ButtonText");
  const documentIdUploadDependencyServiceErrorMessage = bundle.getMessage(
    "Checklist-Document-ID-General-Error-Message"
  );
  const [taxIdNumber, setTaxIdNumber] = useState<string>("");
  const [blockSubmitButton, setBlockSubmitButton] = useState<boolean>(true);
  const [isInvalid, setIsInvalid] = useState<boolean>(false);

  const [currentTaxId, setCurrentTaxId] = useState<string>("");
  const [idUploadDependencyServiceErrorMessage, setIdUploadDependencyServiceErrorMessage] = useState<string>();

  useTemporarySubmitDisabledSolution(false);

  useEffect(() => {
    if (candidateData) {
      if (candidateData?.additionalNationalIds?.[AttachmentType.RFC]?.idNumber) {
        setCurrentTaxId(candidateData.additionalNationalIds[AttachmentType.RFC].idNumber);
      }
    }
  }, [candidateData]);

  const stars = "***************";

  const backToMainPage = useBackToMainPage(refetch);

  const validateTaxIdNumber = new RegExp("^[a-zA-Z0-9]{13}$");
  useEffect(() => {
    taxIdNumber.length > 0 && setIsInvalid(!validateTaxIdNumber.test(taxIdNumber));
  }, [taxIdNumber]);

  const clickEventSubmitDocument = async () => {
    if (!candidateData) {
      dispatchOnDev(() => {
        console.error("CandidateData is not ready yet");
      });
      return;
    }

    if (!validateTaxIdNumber.test(taxIdNumber)) {
      if (isDevEnv()) console.error("invalid tax id number");
      setIsInvalid(true);
      const input = document.getElementById(`taxIdNumber`);
      input?.focus();
      return;
    }

    let returnToMainPage = true;
    try {
      if (isDevEnv()) console.log("submit document id");
      updateCandidateDocumentID({
        candidateId: candidateData?.candidateId,
        idData: {
          nationalIdType: AttachmentType.RFC,
          idNumber: taxIdNumber,
        },
      });
    } catch (err) {
      if (isDevEnv()) console.error("error submit document id");
      setIdUploadDependencyServiceErrorMessage(documentIdUploadDependencyServiceErrorMessage);
      returnToMainPage = false;
    }
    deleteCachedRequest(applicationId); // delete stale data so that updated Id loads on home page
    if (returnToMainPage) {
      backToMainPage();
    }
  };

  return (
    <Col gridGap={12} width={"100%"} padding={"10px 24px"}>
      <H2 fontWeight={"bold"}>{taxIdTitleMessage}</H2>
      <Label htmlFor="taxIdNumber">
        {taxIdNumberMessage}{" "}
        <strong style={{ color: "red" }} aria-hidden="true">
          *
        </strong>{" "}
      </Label>
      <Input
        id="taxIdNumber"
        aria-required="true"
        placeholder={currentTaxId ? stars + currentTaxId.slice(-4) : taxIdNumberMessage}
        maxLength={13}
        onChange={(e) => setTaxIdNumber(e.target.value)}
        aria-invalid={isInvalid ? "true" : "false"}
        aria-describedby="taxIdError"
        error={isInvalid}
      ></Input>
      {isInvalid ? (
        <InputFooter id="taxIdError" error>
          {taxIdNumberMessageError}
        </InputFooter>
      ) : null}
      {idUploadDependencyServiceErrorMessage ? (
        <MessageBanner type={MessageBannerType.Error}>{idUploadDependencyServiceErrorMessage}</MessageBanner>
      ) : null}
      <Button
        data-testid="tax-id-edit-submit"
        onClick={clickEventSubmitDocument}
        id={TEMPORARY_SUBMIT_BUTTON_DISABLED_SOLUTION_SELECTOR}
        style={{ visibility: "hidden" }}
      >
        {documentImageSubmitMessage}
      </Button>
    </Col>
  );
};
