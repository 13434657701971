import { JobDetailsModel } from "./application-data";

const VIRTUAL_JOB = "Virtual";

export function isVirtualJob(scheduleDetails?: JobDetailsModel | null) {
  return (scheduleDetails && scheduleDetails.jobContainerJobMeta?.includes(VIRTUAL_JOB)) || false;
}
export function getProcessedJCAddress(scheduleDetails: JobDetailsModel) {
  //todo eventually ASH should pull fullAddress from JC for all types of jobs. Atm it's enabled for Customer Service job only
  let address = scheduleDetails.fullAddress ?? "";
  if (scheduleDetails?.address) {
    address = scheduleDetails.address;
    if (scheduleDetails.city) {
      address += ", " + scheduleDetails.city;
    }

    if (scheduleDetails.state && scheduleDetails.postalCode) {
      address += ", " + scheduleDetails.state + " " + scheduleDetails.postalCode;
    }
  }
  return address;
}
