import { Row } from "@amzn/stencil-react-components/layout";
import { Text } from "../typography";
import React from "react";
import { StatusEnum } from "../../utility/enums/common";
import { GB_CAAS_STATUS } from "../i9-task/i9-data";

interface Props {
  actionItem?: StatusEnum | GB_CAAS_STATUS | string;
  taskTitleText?: string;
  imageOrText?: JSX.Element | string | undefined | 0;
}

export const TaskCardActionItemContent = ({ actionItem, taskTitleText, imageOrText }: Props) => {
  return (
    <>
      <Text color="neutral100" fontWeight={500}>
        {taskTitleText}
      </Text>
      <Row gridGap={"S100"}>
        {imageOrText}
        <Text color="neutral70" fontWeight={400}>
          {actionItem}
        </Text>
      </Row>
    </>
  );
};

export const TaskCardActionItemDefaultContent = ({ actionItem, taskTitleText, imageOrText }: Props) => {
  return (
    <>
      <Text color="neutral100" fontWeight={500}>
        {taskTitleText}
      </Text>
      <Text color="neutral70" fontWeight={400}>
        {imageOrText}
      </Text>
      <Text color="neutral70" fontWeight={400}>
        {actionItem}
      </Text>
    </>
  );
};
