import React from "react";
import { Modal, ModalContent } from "@amzn/stencil-react-components/modal";
import { Col } from "@amzn/stencil-react-components/layout";
import { SkipBadge } from ".";
import { useChecklistArb } from "../../hooks/use-arb";

interface SkipBadgeProps {
  isOpen: boolean;
  close: () => void;
  goToChecklist: () => void;
}

export const SkipBadgeModal = ({ isOpen, close, goToChecklist }: SkipBadgeProps) => {
  const bundle = useChecklistArb();

  const skipMessage = bundle.getMessage("Checklist-BPSkipBadgeModal-Title2");
  return (
    <Modal isOpen={isOpen} close={close}>
      <ModalContent titleText={skipMessage}>
        <Col padding="1rem">
          <SkipBadge goToChecklist={goToChecklist} />
        </Col>
      </ModalContent>
    </Modal>
  );
};
