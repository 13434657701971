import { useEffect } from "react";
import { useLazyGetApplicationManageDataQuery } from "../../reduxStore/api/apiSlice";
import { getLocale } from "../../utility/locale-helper";
import { useGetApplicationId } from "./useGetApplicationId";
import { useGetJobDetails } from "./useGetJobDetails";
import { CONFIG_DATA_KEY, CONFIG_FEATURE_FLAG } from "../../utility/enums/common";
import { getConfigData, setConfigData, setFeatureFlag } from "../../reduxStore/slices/configSlice";
import { useAppDispatch, useAppSelector } from "../../reduxStore/reduxHooks";
import {
  isEligibleForCSS,
  isEligibleForWithdraw,
  isTentativeStartDateByApplicationState,
} from "../../helpers/configHelper";
import { hasSkipAppointmentOneEligibility } from "../../helpers/utils";
import { ApplicationManageData } from "../../utility/application-data";

export const useGetApplicationManageData = () => {
  const { applicationManageData } = useAppSelector(getConfigData);
  const dispatch = useAppDispatch();
  const applicationId = useGetApplicationId();
  const scheduleDetails = useGetJobDetails();
  const [getApplicationManageData] = useLazyGetApplicationManageDataQuery();

  useEffect(() => {
    if (applicationManageData) return;

    if (scheduleDetails && applicationId) {
      getApplicationManageData(
        {
          applicationId,
          locale: getLocale(),
          isCustomerService: scheduleDetails?.requisitionCategory === "Customer Service",
        },
        true
      ).then(({ error, data }) => {
        const applicationManageData = error && "data" in error ? (error?.data as ApplicationManageData) : data;
        if (!applicationManageData) {
          throw new Error("Retrieved invalid ApplicationManageData from the API");
        }

        if (applicationManageData?.error) {
          dispatch(
            setConfigData({
              key: CONFIG_DATA_KEY.APP_ERROR,
              value: applicationManageData?.error,
            })
          );
        }

        dispatch(
          setConfigData({
            key: CONFIG_DATA_KEY.APPLICATION_MANAGE_DATA,
            value: applicationManageData,
          })
        );

        dispatch(
          setFeatureFlag({
            key: CONFIG_FEATURE_FLAG.IS_ELIGIBLE_FOR_CSS,
            value: isEligibleForCSS(applicationId, applicationManageData),
          })
        );

        dispatch(
          setFeatureFlag({
            key: CONFIG_FEATURE_FLAG.IS_TENTATIVE_START_DATE,
            value: isTentativeStartDateByApplicationState(scheduleDetails, applicationManageData),
          })
        );

        dispatch(
          setFeatureFlag({
            key: CONFIG_FEATURE_FLAG.IS_ELIGIBLE_TO_SKIP_APPT_1,
            value: hasSkipAppointmentOneEligibility(applicationManageData),
          })
        );

        dispatch(
          setFeatureFlag({
            key: CONFIG_FEATURE_FLAG.IS_ALUMNI,
            value: applicationManageData.isAlumniApplication,
          })
        );

        dispatch(
          setFeatureFlag({
            key: CONFIG_FEATURE_FLAG.IS_ELIGIBLE_FOR_WITHDRAW,
            value: isEligibleForWithdraw(applicationId, applicationManageData),
          })
        );
      });
    }
  }, [applicationId, scheduleDetails, getApplicationManageData, applicationManageData, dispatch]);

  return applicationManageData;
};
