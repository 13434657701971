import React from "react";
import "./styles.scss";
import { Text, H1 } from "@amzn/stencil-react-components/text";
import { Row } from "@amzn/stencil-react-components/layout";
import { useChecklistArb } from "../../hooks/use-arb";
import { StatusContainer } from "./status-container";
import { ScreenResult } from "../../utility/screening-results-helper";
import { ScreeningResultsControlledInput } from "../screening-results/screeningResultsHelper";
import { Button, ButtonSize, ButtonIconPosition } from "@amzn/stencil-react-components/button";
import { ScreeningResultCompletedHeader } from "./screening-result-complete";
import { ScreeningResultErrorHeader } from "./screening-result-error";
import { ScreeningProcessHeader } from "./screening-result-process";
import { ProgressArc } from "@amzn/stencil-react-components/spinner";
import { SUPPORTED_COUNTRY, NON_CS_SUPPORTED_COUNTRY, CS_SUPPORTED_COUNTRY } from "../../config/appConfigConstants";
import { useParams } from "react-router-dom";
import { isCustomerService } from "../../utility/app-config-helper";
import { getCountryForDomain } from "../../utility/locale-helper";
import { isAirJob } from "../../helpers/utils";
import { useScreeningInfo } from "../../hooks/useScreeningInfo";

export const ScreeningResultsProgressControlled = ({ jobDetails }: ScreeningResultsControlledInput) => {
  const { airBgcStatus, bgcStatus, dtStatus, mcStatus, workAuthStatus } = useScreeningInfo();

  const bundle = useChecklistArb();

  const { applicationId } = useParams<{
    applicationId: string;
  }>();

  const country = getCountryForDomain() as SUPPORTED_COUNTRY | NON_CS_SUPPORTED_COUNTRY | CS_SUPPORTED_COUNTRY;

  const screeningProcessResults = [workAuthStatus, bgcStatus, dtStatus, mcStatus];

  const screeningResultsForNonCSJobs: Record<NON_CS_SUPPORTED_COUNTRY, string[]> = {
    US: [workAuthStatus, bgcStatus, dtStatus],
    MX: [bgcStatus, dtStatus, mcStatus],
    GB: [workAuthStatus, bgcStatus],
    JP: [bgcStatus],
    CA: [bgcStatus, workAuthStatus],
  };

  const screeningResultsForCSJobs: Record<CS_SUPPORTED_COUNTRY, string[]> = {
    US: [bgcStatus],
    MX: [bgcStatus],
    GB: [bgcStatus],
    IN: [bgcStatus],
    IE: [bgcStatus],
    PH: [bgcStatus],
    ZA: [bgcStatus],
    JP: [bgcStatus],
    CA: [bgcStatus],
    BR: [bgcStatus],
    CO: [bgcStatus],
    CR: [bgcStatus],
    JO: [bgcStatus],
    EG: [bgcStatus],
  };

  if (isAirJob(jobDetails?.businessLine)) {
    screeningResultsForNonCSJobs.US.push(airBgcStatus);
    screeningProcessResults.push(airBgcStatus);
  }

  if (screeningProcessResults.includes(ScreenResult.ERROR)) {
    return <ScreeningResultErrorHeader />;
  }

  if (screeningProcessResults.includes(ScreenResult.PENDING)) {
    return <ScreeningProcessHeader />;
  }

  if (screeningProcessResults.every((status) => [ScreenResult.COMPLETE, ScreenResult.UNDEFINED].includes(status))) {
    return <ScreeningResultCompletedHeader />;
  }

  const results = isCustomerService(applicationId)
    ? screeningResultsForCSJobs[country as CS_SUPPORTED_COUNTRY]
    : screeningResultsForNonCSJobs[country as NON_CS_SUPPORTED_COUNTRY];

  const count = results.filter((result) => result === ScreenResult.COMPLETE).length.toString();
  const totalCount = results.length.toString();

  const formattedProcessingScreenMsg = bundle.getMessage("Checklist-AppointmentCard-Screen-Result");
  const progressBtnText = bundle.getMessage("Checklist-AppointmentCard-ButtonText6");
  const totalTestCountText = bundle.getMessage("Checklist-AppointmentCard-Screen-Test-Count");

  return (
    <StatusContainer
      data-testid={"page-header-screening-result-progress"}
      largeViewPortImage={require("../../assets/svg/sandwatch.svg").default}
      smallViewPortImage={require("../../assets/svg/sandwatch.svg").default}
    >
      <H1 fontSize={"T400"} fontWeight={"regular"} textAlign="center" id="main-content">
        {formattedProcessingScreenMsg}
      </H1>

      <Row gridGap={"1rem"}>
        <Text data-testid={`page-header-screening-result-count-${count}-total-count-${totalCount}`} fontSize={"T200"}>
          {count + "/" + totalCount + " " + totalTestCountText}
        </Text>
      </Row>
      <Row>
        <Button
          aria-label="view-progress"
          size={ButtonSize.Small}
          icon={<ProgressArc />}
          iconPosition={ButtonIconPosition.Leading}
          onClick={() => document.getElementById("screeningresult")?.scrollIntoView()}
        >
          {progressBtnText}
        </Button>
      </Row>
    </StatusContainer>
  );
};
