// ARBTools doesn't support the local-aware time format placeholder "j" so we have to maintain separate 12 and 24 hour translation strings in order to support showing time in the right format

import moment from "moment-timezone";
import { ChecklistBundle } from "../../hooks/use-arb";

export const dateTimeFormatStringBasedOnLocale = (locale: string) => {
  return locale === "en-GB" || locale === "fr-CA"
    ? "Checklist-AppointmentCard-DateTime-24Hour"
    : "Checklist-AppointmentCard-DateTime";
};

export const dateTimeTextFromLocale = (
  locale: string,
  startDate: string,
  startTime: string,
  bundle: ChecklistBundle
) => {
  const dateTimeFormatString = dateTimeFormatStringBasedOnLocale(locale);
  const plainStartDateAndStartTime = startTime
    ? moment(startDate + startTime, "YYYY-MM-DD HH:mm").toDate()
    : moment(startDate, "YYYY-MM-DD").toDate();

  return bundle.formatMessage(dateTimeFormatString, {
    dateParam: plainStartDateAndStartTime,
  });
};
