/* eslint-disable import/no-unused-modules */
import { useEffect } from "react";
import { useLazyGetAllContingenciesByApplicationIdQuery } from "../../reduxStore/api/apiSlice";
import { useGetApplicationId } from "./useGetApplicationId";
import { isSalesforceApplication } from "../../helpers/url-params";
import { useAppSelector } from "../../reduxStore/reduxHooks";
import { getConfigData, getFeatureFlag, setConfigData } from "../../reduxStore/slices/configSlice";
import { useDispatch } from "react-redux";
import { CONFIG_DATA_KEY } from "../../utility/enums/common";
import { useGetCandidateData } from "./useGetCandidateData";
import { FeatFlag } from "../../config/appConfigConstants";
import { isTaskEnabledByAppConfig } from "../../utility/app-config-helper";
import { useGetApplicationManageData } from "./useGetApplicationManageData";
import { AllContingencies } from "@amzn/hvh-simple-hire-checklist-internal-api-common";
import { getLocale } from "../../utility/locale-helper";

export const useGetAllContigenciesData = () => {
  const { allContingenciesStatus } = useAppSelector(getConfigData);

  const { isAshConfigLoaded } = useAppSelector(getFeatureFlag);
  const dispatch = useDispatch();
  const applicationManageData = useGetApplicationManageData();
  const applicationId = useGetApplicationId();
  const candidateData = useGetCandidateData();
  const [getAllContigencies] = useLazyGetAllContingenciesByApplicationIdQuery();

  useEffect(() => {
    if (allContingenciesStatus) return;

    const isAllContingenciesCallEnabledByAppConfig: boolean =
      isTaskEnabledByAppConfig(FeatFlag.ENABLE_ALL_CONTINGENCIES_AIR_BGC, applicationId) ||
      isTaskEnabledByAppConfig(FeatFlag.ENABLE_ALL_CONTINGENCIES_AIR_DT, applicationId) ||
      isTaskEnabledByAppConfig(FeatFlag.ENABLE_ALL_CONTINGENCIES_BGC, applicationId) ||
      isTaskEnabledByAppConfig(FeatFlag.ENABLE_ALL_CONTINGENCIES_DT, applicationId) ||
      isTaskEnabledByAppConfig(FeatFlag.ENABLE_ALL_CONTINGENCIES_MEDICAL_CHECK, applicationId) ||
      isTaskEnabledByAppConfig(FeatFlag.ENABLE_ALL_CONTINGENCIES_RTW, applicationId) ||
      isTaskEnabledByAppConfig(FeatFlag.ENABLE_ALL_CONTINGENCIES_WORK_AUTH, applicationId);

    // CaaS cannot handle applications without schedule ID
    const validStateForCallingAllContingencies: boolean = Boolean(
      isAllContingenciesCallEnabledByAppConfig && !!applicationManageData?.scheduleId
    );

    const locale = getLocale();

    if (isAshConfigLoaded) {
      if (
        candidateData?.candidateId &&
        validStateForCallingAllContingencies &&
        !isSalesforceApplication(applicationId)
      ) {
        getAllContigencies(
          {
            applicationId,
            candidateId: candidateData?.candidateId,
            redirectUrl: encodeURIComponent(window.location.href),
            locale: locale,
          },
          true
        )
          .unwrap()
          .then((data: AllContingencies) => {
            dispatch(
              setConfigData({
                key: CONFIG_DATA_KEY.ALL_CONTINGENCIES_STATUS,
                value: data,
              })
            );
          });
      } else {
        dispatch(
          setConfigData({
            key: CONFIG_DATA_KEY.ALL_CONTINGENCIES_STATUS,
            value: "SKIPPED",
          })
        );
      }
    }
  }, [
    allContingenciesStatus,
    applicationId,
    applicationManageData?.scheduleId,
    candidateData?.candidateId,
    dispatch,
    getAllContigencies,
    isAshConfigLoaded,
  ]);

  return allContingenciesStatus;
};
