import React from "react";
import { Text } from "@amzn/stencil-react-components/text";
import { CUSTOMER_SERVICE, JobType, NON_CUSTOMER_SERVICE } from "../../../../config/appConfigConstants";
import { IE_CAAS_STATUS } from "../../i9-data";
import { Row } from "@amzn/stencil-react-components/layout";
import { ChecklistBundle } from "../../../../hooks/use-arb";
import { IconCheckCircleFill, IconCloseCircleFill, IconSize } from "@amzn/stencil-react-components/icons";
import { HVH_COLORS } from "../../../../config/palette";

export const getIEChecklistMappingCAAS = ({ bundle }: { bundle: ChecklistBundle }) => {
  const translatedCompleted = bundle.getMessage("checklist-General-TaskStatus-Completed");
  const taskPendingMessage = bundle.getMessage("checklist-General-TaskStatus-Pending");
  const taskInError = bundle.getMessage("checklist-General-TaskStatus-Error");
  const taskInitiated = bundle.getMessage("checklist-General-TaskStatus-Initiated");
  const taskInProgress = bundle.getMessage("checklist-General-TaskStatus-InProgress");

  const completionCheck = <IconCheckCircleFill size={IconSize.Medium} color={HVH_COLORS.GREEN_70} />;

  const errorCheck = <IconCloseCircleFill size={IconSize.Medium} color={HVH_COLORS.RED_70} />;

  const ieCaasStatusToChecklistMapping: {
    [key in JobType]?: { [key in string]: JSX.Element };
  } = {
    [NON_CUSTOMER_SERVICE]: {
      [IE_CAAS_STATUS.Initiated]: (
        <>
          <Text>{taskInitiated}</Text>
        </>
      ),
      [IE_CAAS_STATUS.Completed]: (
        <Row alignItems={"center"} gridGap={"S200"}>
          {completionCheck}
          <Text>{translatedCompleted}</Text>
        </Row>
      ),
      [IE_CAAS_STATUS.Error]: (
        <Row alignItems={"center"} gridGap={"S200"}>
          {errorCheck}
          <Text>{taskInError}</Text>
        </Row>
      ),
      [IE_CAAS_STATUS.Pending]: (
        <>
          <Text>{taskInProgress}</Text>
        </>
      ),
      [IE_CAAS_STATUS.Archived]: (
        <>
          <Text>{taskPendingMessage}</Text>
        </>
      ),
    },
    [CUSTOMER_SERVICE]: {},
  };

  return ieCaasStatusToChecklistMapping;
};
