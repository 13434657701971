import React from "react";
import { Row } from "@amzn/stencil-react-components/layout";
import { IconCheckCircleFill } from "@amzn/stencil-react-components/icons";
import { Text } from "../../../../components/typography";
import { useChecklistArb } from "../../../../hooks/use-arb";
import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { useParams } from "react-router-dom";
import { Link } from "@amzn/stencil-react-components/link";
import { StatusEnum } from "../../../../utility/enums/common";
import { AttachmentType } from "../models";
import { eventHandler } from "../helpers/eventHandler";
import { useAppDispatch } from "../../../../reduxStore/reduxHooks";
interface DocumnetUploadCompleteParams {
  documentType: string;
  status: StatusEnum;
}

export const DocumentUploadComplete = ({ documentType, status }: DocumnetUploadCompleteParams) => {
  const bundle = useChecklistArb();
  const documentUploadCompleteMessage = bundle.getMessage("Checklist-MainPage-Document-Upload-Complete-Text");

  const imageReplace = bundle.getMessage("Checklist-Document-Image-Replace-ButtonText");
  const { requisitionId, applicationId } = useParams<{
    requisitionId: string;
    applicationId: string;
  }>();

  const dispatch = useAppDispatch();

  return (
    <Row display="flex" justifyContent="space-between" alignItems="center">
      <Row width={"158px"} display="flex" gridGap="1em" justifyContent="left">
        <IconCheckCircleFill color="#23756B" />
        <Text fontWeight="bold">{documentUploadCompleteMessage}</Text>
      </Row>

      <Link
        href={`/checklist/tasks/document/${requisitionId}/${applicationId}/${documentType}/false`}
        onClick={(e) =>
          eventHandler(
            e,
            {
              documentType: documentType as AttachmentType,
              isEdit: false,
              buttonDelegationEnabled: true,
            },
            dispatch
          )
        }
        textDecoration={"none"}
      >
        {status === StatusEnum.COMPLETED && <Button variant={ButtonVariant.Tertiary}>{imageReplace}</Button>}
      </Link>
    </Row>
  );
};
