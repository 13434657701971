import React from "react";
import { Link } from "@amzn/stencil-react-components/link";
import { Text } from "../typography";

const supportLinkUrl = window.location.origin + "/contact-us";

export enum ErrorCode {
  Internal = "Internal",
  E400 = "400",
  E404 = "404",
  E406 = "406",
  E409 = "409",
  E422 = "422",
  E503 = "503",
  E500 = "500",
}

export const getErrorMessage = (errorCode?: ErrorCode, bundle?: any): React.ReactNode => {
  const here = bundle.getMessage("Checklist-MainPage-Link");
  const supportLink = (
    <Link target="_blank" href={supportLinkUrl}>
      {here}
    </Link>
  );
  switch (errorCode) {
    case ErrorCode.Internal:
    case ErrorCode.E500:
    case ErrorCode.E400:
      return (
        <Text>
          {bundle.formatMessage("Checklist-MainPage-I9-E400_500", {
            supportLink: supportLink,
          })}
        </Text>
      );

    case ErrorCode.E404:
    case ErrorCode.E406:
      return (
        <Text>
          {bundle.formatMessage("Checklist-MainPage-I9-E404_406", {
            supportLink: supportLink,
          })}
        </Text>
      );
    case ErrorCode.E503:
      return <Text>{bundle.getMessage("Checklist-MainPage-I9-E503")}</Text>;
    case ErrorCode.E409:
    default:
      return (
        <Text>
          {bundle.formatMessage("Checklist-MainPage-I9-E409", {
            supportLink: supportLink,
          })}
        </Text>
      );
  }
};
