/* eslint-disable import/no-unused-modules */
import { useEffect } from "react";
import { useLazyGetAppointmentV2Query } from "../../reduxStore/api/apiSlice";
import { useGetApplicationId } from "./useGetApplicationId";
import { useAppSelector } from "../../reduxStore/reduxHooks";
import { getConfigData, setConfigData, setFeatureFlag } from "../../reduxStore/slices/configSlice";
import { CONFIG_DATA_KEY, CONFIG_FEATURE_FLAG } from "../../utility/enums/common";
import { useDispatch } from "react-redux";
import { getLocale } from "../../utility/locale-helper";
import { NHEAppointmentApplicationTypeEnum } from "@amzn/hvh-simple-hire-checklist-internal-api-common";
import { isSalesforceApplication } from "../../helpers/url-params";
import { useGetApplicationManageData } from "./useGetApplicationManageData";
import { isEligibleForNHEAppointmentRescheduling } from "../../helpers/configHelper";
import { useUrlBuilder } from "../useUrlBuilder.hook";

export const useGetNHEAppointment = () => {
  const { nheAppointmentDetails } = useAppSelector(getConfigData);
  const dispatch = useDispatch();
  const applicationId = useGetApplicationId();
  const { connectVirtualNhe } = useUrlBuilder();
  const applicationManageData = useGetApplicationManageData();
  const [getNHEAppointment] = useLazyGetAppointmentV2Query();

  useEffect(() => {
    if (nheAppointmentDetails) return;

    if (applicationId && !isSalesforceApplication(applicationId) && applicationManageData) {
      getNHEAppointment(
        { applicationId, locale: getLocale(), applicationType: NHEAppointmentApplicationTypeEnum.NEW_HIRE_EVENT },
        true
      )
        .unwrap()
        .then((data) => {
          dispatch(
            setConfigData({
              key: CONFIG_DATA_KEY.NHE_APPOINTMENT_DETAILS,
              value: data,
            })
          );

          dispatch(
            setFeatureFlag({
              key: CONFIG_FEATURE_FLAG.IS_ELIGIBLE_FOR_NHE_APPOINTMENT_RESCHEDULING,
              value: isEligibleForNHEAppointmentRescheduling(
                data,
                applicationManageData,
                isSalesforceApplication(applicationId)
              ),
            })
          );
        });
    }
  }, [applicationId, dispatch, getNHEAppointment, nheAppointmentDetails, applicationManageData]);

  // Patch the link for Connect virtual appointment page
  const patchedAppointmentDetails = nheAppointmentDetails ? { ...nheAppointmentDetails } : undefined;
  if (patchedAppointmentDetails?.locationType === "VIRTUAL_CONNECT") {
    patchedAppointmentDetails.displayReadyLocation = connectVirtualNhe;
    patchedAppointmentDetails.virtualMeetingLink = connectVirtualNhe;
  }

  return patchedAppointmentDetails;
};
