import { Select } from "@amzn/stencil-react-components/form";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { Label } from "@amzn/stencil-react-components/text";
import React from "react";
import { Text } from "../../../../components/typography";
import { useChecklistArb } from "../../../../hooks/use-arb";
import { useAppDispatch } from "../../../../reduxStore/reduxHooks";
import { openOldUiFlyoutContentUpload } from "../../../../reduxStore/slices/docUploadSlice";
import {
  AttachmentType,
  TEMPORARY_CONSULAR_RESIDENCE_VISA_OFFICIAL_APPROVAL_INM_FMM,
  TEMPORARY_RESIDENCE_STUDENT_CARD_INM_WORK_AUTHORIZATION,
} from "../models";
import { useSendAnalyticEvent } from "../../../../hooks/jobDetailsCardAnalyticEventHelper.hook";

export const DocumentUploadDropdown = () => {
  const dispatch = useAppDispatch();
  const bundle = useChecklistArb();

  const options = [
    {
      name: bundle.getMessage("Checklist-Document-Mexican-Passport"),
      type: AttachmentType.MEXICAN_PASSPORT,
    },
    {
      name: bundle.getMessage("Checklist-Document-Consular-Identification-Card"),
      type: AttachmentType.CONSULAR_IDENTIFICATION_CARD,
    },
    {
      name: bundle.getMessage("Checklist-Document-Voter-Card"),
      type: AttachmentType.VOTER_CARD,
    },
    {
      name: bundle.getMessage("Checklist-Document-Military-Service-Card"),
      type: AttachmentType.MILITARY_SERVICE_CARD,
    },
    {
      name: bundle.getMessage("Checklist-Document-Mexican-Birth-Certificate"),
      type: AttachmentType.MEXICAN_BIRTH_CERTIFICATE,
    },
    {
      name: bundle.getMessage("Checklist-Document-Temporary-Residence-Card"),
      type: AttachmentType.TEMPORARY_RESIDENCE_CARD,
    },
    {
      name: bundle.getMessage("Checklist-Document-Temporary-Resident-Student-Card-INM-Work-Authorization"),
      type: TEMPORARY_RESIDENCE_STUDENT_CARD_INM_WORK_AUTHORIZATION,
    },
    {
      name: bundle.getMessage("Checklist-Document-Permanent-Residence-Card"),
      type: AttachmentType.PERMANENT_RESIDENCE_CARD,
    },
    {
      name: bundle.getMessage("Checklist-Document-Naturalization-Certificate"),
      type: AttachmentType.NATURALIZATION_CERTIFICATE,
    },
    {
      name: bundle.getMessage("Checklist-Document-Temporary-Consular-Residence-Visa-INM-FMM"),
      type: TEMPORARY_CONSULAR_RESIDENCE_VISA_OFFICIAL_APPROVAL_INM_FMM,
    },
    {
      name: bundle.getMessage("Checklist-Document-Other-Option"),
      type: AttachmentType.OTHER,
    },
  ];

  const chooseAnOption = bundle.getMessage("Checklist-Document-Choose-An-Option");
  const otherDocumentNotice = bundle.getMessage("Checklist-Document-Notice-Other");

  const renderOption = (option: any) => (
    <Row gridGap="S200" alignItems="center">
      {option.name}
    </Row>
  );

  const renderNativeOption = (option: any) => `${option.name}`;

  const renderSelectedValue = (option: any) => `${option.name}`;

  const valueAccessor = (option: any) => option.name;

  const { sendDocumentsAnalyticsEvent } = useSendAnalyticEvent();

  const onChange = (option: any) => {
    sendDocumentsAnalyticsEvent("dropdown click", option?.type);

    dispatch(
      openOldUiFlyoutContentUpload({
        oldUiFlyoutContent: {
          documentType: option.type,
          isEdit: false,
          buttonDelegationEnabled: true,
        },
      })
    );
  };

  return (
    <Col gridGap={16}>
      <Label htmlFor="doc-upload-selector-1" id="doc-upload-selector-label-1"></Label>
      <Select
        aria-required="true"
        id="doc-upload-selector-1"
        labelId="doc-upload-selector-label-1"
        placeholder={chooseAnOption}
        options={options}
        renderOption={renderOption}
        renderNativeOption={renderNativeOption}
        renderSelectedValue={renderSelectedValue}
        valueAccessor={valueAccessor}
        onChange={onChange}
      />
      <Text fontSize="T100">{otherDocumentNotice}</Text>
    </Col>
  );
};
