import React, { useEffect, useState } from "react";
import { Col } from "@amzn/stencil-react-components/layout";
import { Input, InputFooter } from "@amzn/stencil-react-components/form";
import { MessageBanner, MessageBannerType } from "@amzn/stencil-react-components/message-banner";
import { useChecklistArb } from "../../../../hooks/use-arb";
import { H2, Text } from "../../../../components/typography";
import { Button } from "@amzn/stencil-react-components/button";
import { AttachmentType } from "../models";
import { useParams } from "react-router-dom";
import { deleteCachedRequest } from "../../../../utility/candidate-data";
import {
  useGetCandidateDataQuery,
  useUpdateCandidateDocumentIdToCDSMutation,
} from "../../../../reduxStore/api/apiSlice";
import { dispatchOnDev, isDevEnv } from "../../../../utility/dev-env-helpers";
import { useBackToMainPage } from "../helpers/useBackToMainPage.hook";
import { useTemporarySubmitDisabledSolution } from "../helpers/useTemporarySubmitDisabledSolution.hook";
import { TEMPORARY_SUBMIT_BUTTON_DISABLED_SOLUTION_SELECTOR } from "../../../../utility/constants/common";
import { Label } from "@amzn/stencil-react-components/text";

export const EditCURPId = () => {
  const { applicationId } = useParams<{
    requisitionId: string;
    applicationId: string;
  }>();
  const { data: candidateData, refetch } = useGetCandidateDataQuery({ applicationId });
  const [updateCandidateDocumentID] = useUpdateCandidateDocumentIdToCDSMutation();

  const bundle = useChecklistArb();
  const curpIdNumberMessage = bundle.getMessage("Checklist-MainPage-CURP-ID-Number-Title");
  const curpIdNumberError = bundle.getMessage("Checklist-CURP-ID-Text-Error");

  const documentImageSubmitMessage = bundle.getMessage("Checklist-Document-Image-Submit-ButtonText");
  const documentIdUploadDependencyServiceErrorMessage = bundle.getMessage(
    "Checklist-Document-ID-General-Error-Message"
  );
  const [curpNumber, setCurpNumber] = useState<string>("");
  const alphaNumericPattern = "^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]{18})$";
  const regexCheck = new RegExp(alphaNumericPattern);
  const backToMainPage = useBackToMainPage(refetch);
  const [currentCurpId, setCurrentCurpId] = useState<string>("");
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const [idUploadDependencyServiceErrorMessage, setIdUploadDependencyServiceErrorMessage] = useState<string>();
  useTemporarySubmitDisabledSolution(false);

  useEffect(() => {
    if (candidateData) {
      if (candidateData.primaryNationalId?.idNumber) {
        setCurrentCurpId(candidateData.primaryNationalId.idNumber);
      }
    }
  }),
    [candidateData];

  useEffect(() => {
    curpNumber.length > 0 && setIsInvalid(!regexCheck.test(curpNumber));
  }, [curpNumber]);

  const stars = "***************";

  const clickEventSubmitDocument = async () => {
    if (!candidateData) {
      dispatchOnDev(() => {
        console.error("CandidateData is not ready yet");
      });
      return;
    }

    if (!regexCheck.test(curpNumber)) {
      if (isDevEnv()) console.error("invalid curp id number");
      setIsInvalid(true);
      const input = document.getElementById(`curpId`);
      input?.focus();
      return;
    }

    let returnToMainPage = true;
    try {
      if (isDevEnv()) console.log("submit document id");
      updateCandidateDocumentID({
        candidateId: candidateData?.candidateId,
        idData: {
          nationalIdType: AttachmentType.CURP,
          idNumber: curpNumber,
        },
      });
    } catch (err) {
      if (isDevEnv()) console.error("error submit document id");
      console.log(err);
      setIdUploadDependencyServiceErrorMessage(documentIdUploadDependencyServiceErrorMessage);
      returnToMainPage = false;
    }

    deleteCachedRequest(applicationId); // delete stale data so that updated Id loads on home page
    if (returnToMainPage) {
      backToMainPage();
    }
  };

  return (
    <Col gridGap={12} width={"100%"} padding={"10px 16px"}>
      <H2 fontWeight={"bold"}>{curpIdNumberMessage}</H2>
      <Label htmlFor="curpId">
        {curpIdNumberMessage}{" "}
        <strong style={{ color: "red" }} aria-hidden="true">
          *
        </strong>{" "}
      </Label>
      <Input
        id="curpId"
        aria-required="true"
        placeholder={currentCurpId ? stars + currentCurpId.slice(-4) : curpIdNumberMessage}
        maxLength={18}
        onChange={(e) => setCurpNumber(e.target.value)}
        aria-invalid={isInvalid ? "true" : "false"}
        error={isInvalid}
        aria-describedby="curpIdError"
      ></Input>
      {isInvalid ? (
        <InputFooter id="curpIdError" error>
          {curpIdNumberError}
        </InputFooter>
      ) : null}
      {idUploadDependencyServiceErrorMessage ? (
        <MessageBanner type={MessageBannerType.Error}>{idUploadDependencyServiceErrorMessage}</MessageBanner>
      ) : null}
      <Button
        data-testid="curp-id-edit-submit"
        onClick={clickEventSubmitDocument}
        id={TEMPORARY_SUBMIT_BUTTON_DISABLED_SOLUTION_SELECTOR}
        style={{ visibility: "hidden" }}
      >
        {documentImageSubmitMessage}
      </Button>
    </Col>
  );
};
