import {
  Brightness,
  Camera,
  CameraMode,
  FaceDetection,
  ImageCropper,
  ImageMinSize,
  ImageProcessingData,
  ImageProcessor,
  ImageResizer,
} from "@amzn/hvh-camera-ui";
import { Col } from "@amzn/stencil-react-components/layout";
import { Spinner, SpinnerSize } from "@amzn/stencil-react-components/spinner";
import { Circle } from "rc-progress";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { SkipBadgeModal } from "../../../../components/skip-badge/modal";
import { CHECKLIST_URL, TAKE_BADGE_PHOTO_CAMERA_GALLERY_URL } from "../../../../config/urls";
import { incrementFailedAttempts, reachedAttemptsLimit } from "../../../../helpers/badge-failed-attempts";
import { useChecklistArb } from "../../../../hooks/use-arb";
import { useMetrics } from "../../../../hooks/use-metrics";
import { useNavigator } from "../../../../hooks/use-navigator";
import { ActionType } from "../../../../reactContextStore/state";
import { Context } from "../../../../reactContextStore/store";
import { useAppSelector } from "../../../../reduxStore/reduxHooks";
import { getConfigData } from "../../../../reduxStore/slices/configSlice";
import * as adobeAnalytics from "../../../../utility/adobe-analytics";
import { extractMetricData } from "../../../../utility/badge-metric";
import { dispatchOnDev } from "../../../../utility/dev-env-helpers";
import {
  IndicatorBrightness,
  IndicatorManyFaces,
  IndicatorNoFace,
  IndicatorPhotoTooSmall,
  IndicatorPosition,
} from "./indicator";
import { useSendAnalyticEvent } from "../../../../hooks/jobDetailsCardAnalyticEventHelper.hook";

const MAX_PHOTOS = 10;

const modules = [
  new FaceDetection({
    noFaceIndicator: <IndicatorNoFace />,
    manyFacesIndicator: <IndicatorManyFaces />,
    modelsPath: "/checklist/models",
  }),
  new ImageCropper({ indicator: <IndicatorPosition /> }),
  new ImageResizer({
    targetSize: { width: 600, height: 800 },
    indicator: <div></div>,
  }),
  new Brightness({
    threshold: { min: 5, max: 150 },
    indicator: <IndicatorBrightness />,
  }),
  new ImageMinSize({
    minSize: { width: 240, height: 320 },
    indicator: <IndicatorPhotoTooSmall />,
  }),
];

const imageProcessor = new ImageProcessor(modules);

const MAX_NUM_OF_WRONG_SHOTS = 30;

export const BadgeCameraRoute = () => {
  const [goToGallery, goToChecklist] = useNavigator(TAKE_BADGE_PHOTO_CAMERA_GALLERY_URL, CHECKLIST_URL);
  const shots = useRef<ImageProcessingData[]>([]);
  const [index, setIndex] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const [indicator, setIndicator] = useState<React.ReactNode>(null);
  const [isSkipBadgeModalOpen, setIsSkipBadgeModalOpen] = useState(false);
  const wrongShots = useRef(0);
  const { dispatch } = useContext(Context);

  const metrics = useMetrics();

  const { sendBadgePhotoPageAnalyticsEvent } = useSendAnalyticEvent();

  const closeSkipBadgeModal = useCallback(() => {
    setIsSkipBadgeModalOpen(false);
  }, []);

  useEffect(() => {
    if (imageProcessor.init) {
      imageProcessor.init().then(() => setLoading(false));
    }
    if (metrics?.sendMetric) {
      metrics?.sendMetric({ pageView: "badge/camera" });
    }
  }, [metrics?.sendMetric]);

  useEffect(() => {
    sendBadgePhotoPageAnalyticsEvent("badge/camera");
  }, []);

  const onShot = useCallback(
    async (imageData: string) => {
      try {
        const imageProcessingResult = await imageProcessor.process(imageData);

        const metricData = extractMetricData(imageProcessingResult);

        if (metrics?.sendMetric) {
          metrics.sendMetric({ event: "camera-shot", details: metricData });
        }

        if (!imageProcessingResult.success) {
          const { failedModule } = imageProcessingResult;
          setIndicator(failedModule?.module.getIndicator(failedModule.results));
          wrongShots.current++;
          if (wrongShots.current > MAX_NUM_OF_WRONG_SHOTS) {
            incrementFailedAttempts();
            wrongShots.current = 0;
            if (reachedAttemptsLimit()) {
              setIsSkipBadgeModalOpen(true);
            }
          }
        } else {
          setIndicator(null);
          if (shots.current.length < MAX_PHOTOS) {
            shots.current.push(imageProcessingResult);
            setIndex(shots.current.length);
            if (shots.current.length === MAX_PHOTOS) {
              dispatch({
                type: ActionType.UpdateSampleShots,
                payload: shots.current.sort((a, b) => b.score - a.score),
              });
              goToGallery();
            }
          }
        }
      } catch (e) {
        dispatchOnDev(() => {
          console.error(e);
        });
      }
    },
    [metrics?.sendMetric]
  );
  const bundle = useChecklistArb();
  if (loading === true) {
    return (
      <Col justifyContent="center" alignItems="center" height="100vh">
        <Spinner size={SpinnerSize.Large}></Spinner>
      </Col>
    );
  }
  const percent = (100 / MAX_PHOTOS) * index;
  const start = bundle.getMessage("Checklist-BPStart-Start");

  return (
    <>
      <Camera mode={"auto" as CameraMode} onShot={onShot} autoFramesInterval={500} startButtonText={start} />
      <div className={`error-indicator ${!indicator ? "hidden" : ""}`}>{indicator}</div>
      <div className={`progress-container ${indicator || index === 0 ? "hidden" : ""}`}>
        <div className="progress-wrapper">
          <Circle percent={percent} trailColor="#eeeeee" trailWidth={3} strokeWidth={8} strokeColor="#27acce" />
        </div>
      </div>
      <SkipBadgeModal isOpen={isSkipBadgeModalOpen} close={closeSkipBadgeModal} goToChecklist={goToChecklist} />
    </>
  );
};
