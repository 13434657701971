/* eslint-disable import/no-unused-modules */
import { useEffect } from "react";
import { useLazyGetMcStatusQuery } from "../../reduxStore/api/apiSlice";
import { useGetApplicationId } from "./useGetApplicationId";
import { isSalesforceApplication } from "../../helpers/url-params";
import { getCountryForDomain } from "../../utility/locale-helper";
import { useAppSelector } from "../../reduxStore/reduxHooks";
import { getConfigData, setConfigData } from "../../reduxStore/slices/configSlice";
import { useDispatch } from "react-redux";
import { CONFIG_DATA_KEY } from "../../utility/enums/common";
import { useGetCandidateData } from "./useGetCandidateData";
import { FeatFlag, MEDICAL_CHECK_ALLOWED_COUNTRY } from "../../config/appConfigConstants";
import { isDevEnv } from "../../utility/dev-env-helpers";
import { useGetAllContigenciesData } from "./useGetAllContigenciesData";
import { isTaskEnabledByAppConfig } from "../../utility/app-config-helper";

export const useGetMedicalCheckStatus = () => {
  const { medicalCheckStatus } = useAppSelector(getConfigData);
  const dispatch = useDispatch();
  const allContigencies = useGetAllContigenciesData();
  const applicationId = useGetApplicationId();
  const candidateData = useGetCandidateData();
  const [getMedicalCheckStatus] = useLazyGetMcStatusQuery();

  useEffect(() => {
    const country = getCountryForDomain();
    const skipCallForGetMedicalCheck =
      !(country in MEDICAL_CHECK_ALLOWED_COUNTRY) || isSalesforceApplication(applicationId);

    if (candidateData?.candidateId) {
      const flagEnabled = isTaskEnabledByAppConfig(FeatFlag.ENABLE_ALL_CONTINGENCIES_MEDICAL_CHECK, applicationId);
      if (flagEnabled && allContigencies !== "SKIPPED") {
        if (
          allContigencies?.MedicalCheck &&
          country in MEDICAL_CHECK_ALLOWED_COUNTRY &&
          !isSalesforceApplication(applicationId)
        ) {
          dispatch(
            setConfigData({
              key: CONFIG_DATA_KEY.MEDICAL_CHECK_STATUS,
              value: allContigencies.MedicalCheck,
            })
          );
        } else {
          dispatch(
            setConfigData({
              key: CONFIG_DATA_KEY.MEDICAL_CHECK_STATUS,
              value: "SKIPPED",
            })
          );
        }
      } else if (!flagEnabled && !skipCallForGetMedicalCheck) {
        getMedicalCheckStatus(
          {
            candidateId: candidateData?.candidateId,
          },
          true
        )
          .unwrap()
          .then((data) => {
            dispatch(
              setConfigData({
                key: CONFIG_DATA_KEY.MEDICAL_CHECK_STATUS,
                value: data,
              })
            );
          });
      } else {
        if (isDevEnv()) console.warn("Skipping useGetMedicalCheckStatus hook");
        dispatch(
          setConfigData({
            key: CONFIG_DATA_KEY.MEDICAL_CHECK_STATUS,
            value: "SKIPPED",
          })
        );
      }
    }
  }, [medicalCheckStatus, allContigencies, applicationId, dispatch, getMedicalCheckStatus, candidateData?.candidateId]);

  return medicalCheckStatus;
};
