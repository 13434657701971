import { Col, Row } from "@amzn/stencil-react-components/layout";
import { Spinner, SpinnerSize } from "@amzn/stencil-react-components/spinner";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SUPPORTED_COUNTRY } from "../../config/appConfigConstants";
import { HVH_COLORS } from "../../config/palette";
import { Stage } from "../../helpers/get-settings";
import { isDragonstone, isSalesforceApplication } from "../../helpers/url-params";
import { getPayRate, isTentativeJobCardStartDate } from "../../helpers/utils";
import { useChecklistArb } from "../../hooks/use-arb";
import { useAppSelector } from "../../reduxStore/reduxHooks";
import { getFeatureFlag } from "../../reduxStore/slices/configSlice";
import { isCustomerService } from "../../utility/app-config-helper";
import { JobDetailsModel } from "../../utility/application-data";
import { getProcessedJCAddress, isVirtualJob } from "../../utility/jc-response-helper";
import { getDefaultLocaleAndCountryForCurrentDomain, getSupportedLanguage } from "../../utility/locale-helper";
import { H2 } from "../typography";
import { CardDetail, ManageApplicationCardHeader } from "./jobDetailsCardHeader";
import { useGetJobDetails } from "../../hooks/apis/useGetJobDetails";

interface ManageApplicationCardProps {
  stage?: Stage;
  collapsed: boolean;
}

export const ManageApplicationCard = ({ stage, collapsed }: ManageApplicationCardProps) => {
  const { requisitionId, applicationId } = useParams<{
    requisitionId: string;
    applicationId: string;
  }>();

  const [error, setError] = useState<boolean>(false);
  const [scheduleIdFound, setScheduleIdFound] = useState<boolean>(true);

  const bundle = useChecklistArb();
  const jobDetails = bundle.getMessage("Checklist-JobCard-JobDetails");
  const loadingText = bundle.getMessage("checklist-General-TaskStatus-Loading");

  const { isTentativeStartDate, isCustomerServiceJob } = useAppSelector(getFeatureFlag);

  const scheduleDetails = useGetJobDetails();

  const address = scheduleDetails && getProcessedJCAddress(scheduleDetails);

  const country = getDefaultLocaleAndCountryForCurrentDomain().country as SUPPORTED_COUNTRY;

  // TODO: We should manage all of the flags logic on backend, and frontend should do a mapping as a dumb frontend, which only responsible for managing the UI state not maintain the logic for overriding the state on UI.
  const { showCurrencyCode } = getPayRate(country);

  const details: CardDetail = {
    jobTitle: scheduleDetails?.externalJobTitle as string,
    hours: scheduleDetails?.scheduleText as string,
    startDateUntranslated: scheduleDetails?.firstDayOnSite as string,
    startTimeUntranslated: scheduleDetails?.startTime as string,
    startDate: scheduleDetails?.firstDayOnSiteL10N as string,
    startTime: scheduleDetails?.startTimeL10N as string,
    description: scheduleDetails?.briefJobDescription as string,
    address: address as string,
    image: scheduleDetails?.image as string,
    isTentativeStartDate: isTentativeJobCardStartDate(!!isTentativeStartDate, !!isCustomerServiceJob),
    signOnBonus: scheduleDetails?.signOnBonus,
    signOnBonusL10N: scheduleDetails?.signOnBonusL10N,
    scheduleBannerText: scheduleDetails?.scheduleBannerText as string,
    hoursPerWeek: scheduleDetails?.hoursPerWeekL10N as string,
    employmentType: scheduleDetails?.employmentType as string,
    employmentTypeL10N: scheduleDetails?.employmentTypeL10N as string,
    supportedLanguage: isSalesforceApplication(applicationId)
      ? (getSupportedLanguage(scheduleDetails?.jobDescriptionRecordLanguages, bundle, true) as string)
      : (getSupportedLanguage(scheduleDetails?.requiredLanguage, bundle) as string),
    parsedTrainingDate: scheduleDetails?.parsedTrainingDate as string,
    currencyCode: scheduleDetails?.currencyCode as string,
    isVirtual: isVirtualJob(scheduleDetails) as boolean,
    showCurrencyCode: showCurrencyCode,
    hourlyBasePayL10N: scheduleDetails?.totalPayRateL10N as string,
    biweeklyBasePayL10N: scheduleDetails?.biweeklyBasePayL10N as string,
    monthlyBasePayL10N: scheduleDetails?.monthlyBasePayL10N as string,
    yearlyBasePayL10N: scheduleDetails?.yearlyBasePayL10N as string,
    scheduleText: scheduleDetails?.scheduleText,
    monthlyPayRateL10N: scheduleDetails?.monthlyPayRateL10N as string,
    languagePremiumL10N: scheduleDetails?.monthlyLanguagePayL10N as string,
  };

  const checkScheduleDetailsError = (scheduleDetails: JobDetailsModel | undefined | null) => {
    if (scheduleDetails?.error) {
      setError(true);
    } else {
      if (scheduleDetails?.isScheduleReleased) {
        setScheduleIdFound(false);
        setError(true);
      }
    }
  };

  useEffect(() => {
    checkScheduleDetailsError(scheduleDetails);
  }, [scheduleDetails]);

  if (!isDragonstone(requisitionId) && !isCustomerService(applicationId)) {
    return null;
  }

  if (!scheduleDetails) {
    return (
      <Col width={"100%"}>
        {!collapsed && (
          <Row padding={"1rem"} alignItems={"center"} justifyContent={"space-between"}>
            <H2>{jobDetails}</H2>
          </Row>
        )}

        <Col alignItems="center" padding={"2rem"} backgroundColor={HVH_COLORS.NEUTRAL_0}>
          <Spinner size={SpinnerSize.Small} loadingText={loadingText} showText={true} />
        </Col>
      </Col>
    );
  }

  if (error || !details || !scheduleIdFound) {
    return null;
  }

  return (
    <Col width="100%">
      {!collapsed && (
        <Col padding={"1rem"}>
          <H2>{jobDetails}</H2>
        </Col>
      )}
      <ManageApplicationCardHeader jobDetails={details} collapsed={collapsed} />
    </Col>
  );
};
