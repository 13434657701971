import { addEventMetric } from "../utility/adobe-analytics";
import { ApplicationManageData, AtoZStatus, JobDetailsModel } from "../utility/application-data";
import { CandidateData } from "../utility/candidate-data";

const ATOZ_ERROR = "AtoZ Event Error";

interface sendAnalyticEvent {
  eventName: string;
  response: AtoZStatus;
  applicationId: string;
  candidateData: CandidateData | undefined | null;
  applicationManageData: ApplicationManageData | undefined | null;
  jobDetails: JobDetailsModel | undefined | null;
  errorInButtonComp?: boolean;
}

export const sendAnalyticsEvent = async ({
  eventName,
  response,
  applicationId,
  candidateData,
  applicationManageData,
  jobDetails,
  errorInButtonComp,
}: sendAnalyticEvent) => {
  if (eventName === ATOZ_ERROR || errorInButtonComp) {
    await addEventMetric(window, applicationId, eventName, candidateData, applicationManageData, jobDetails, {
      error: { errorCode: response.errorCode, errorMessage: response.errorMessage },
    });
  } else {
    await addEventMetric(window, applicationId, eventName, candidateData, applicationManageData, jobDetails);
  }
};
