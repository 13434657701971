import { I_9_DOCUMENT, ORDER_YOUR_SAFETY_SHOE } from "../../config/taskConfigConstants";
import { StatusEnum } from "../../utility/enums/common";
import { useChecklistArb } from "../../hooks/use-arb";
import { CAAS_STATUS } from "../i9-task/i9-data";

type UseStatusTextType = (text: string | undefined, taskName: string | undefined) => string;

export const useStatusText: UseStatusTextType = (text, taskName) => {
  const safeString = (text?.toString() as keyof typeof cases) || "";
  const { getMessage } = useChecklistArb();

  const cases = {
    [StatusEnum.ACTION_REQUIRED]: () => getMessage("checklist-General-TaskStatus-ActionRequired"),
    [StatusEnum.NOT_STARTED]: () => getMessage("checklist-General-TaskStatus-NotStarted"),
    [StatusEnum.ERROR]: () => getMessage("checklist-General-TaskStatus-Error"),
    [StatusEnum.NOT_AVAILABLE_YET]: () =>
      taskName === I_9_DOCUMENT
        ? getMessage("Checklist-MainPage-I9-Unavailable")
        : getMessage("checklist-General-TaskStatus-NotAvailableYet"),
    [StatusEnum.IN_PROGRESS]: () => getMessage("checklist-General-TaskStatus-InProgress"),
    // can't show Rejected on the screen for legal reason. Instead, we should show Completed in the flyout with green banner
    // https://issues.amazon.com/issues/Kondo_QA_Issue-653
    [StatusEnum.REJECTED]: () => getMessage("checklist-General-TaskStatus-Completed"),
    [StatusEnum.COMPLETED]: () => getMessage("checklist-General-TaskStatus-Completed"),
    [StatusEnum.INELIGIBLE]: () =>
      taskName === ORDER_YOUR_SAFETY_SHOE ? getMessage("Checklist-MainPage-MindYourStep-IneligibleStatus") : undefined,
    [CAAS_STATUS.PendingF2FCheck]: () => getMessage("checklist-General-TaskStatus-InProgress"),
  };

  const statusText = typeof cases[safeString] === "function" && cases[safeString]();

  return statusText || safeString;
};
