import React from "react";
import { Button } from "@amzn/stencil-react-components/button";
import { Col, Hr } from "@amzn/stencil-react-components/layout";
import { Link } from "@amzn/stencil-react-components/link";
import { useParams } from "react-router-dom";
import { eventHandler } from "../helpers/eventHandler";
import { AttachmentType } from "../models";
import { useAppDispatch } from "../../../../reduxStore/reduxHooks";
import { useSendAnalyticEvent } from "../../../../hooks/jobDetailsCardAnalyticEventHelper.hook";

interface Props {
  buttonMessage: string;
  documentType: string;
}

export const DocumentUploadButton = ({ buttonMessage, documentType }: Props) => {
  const { requisitionId, applicationId } = useParams<{
    applicationId: string;
    requisitionId: string;
  }>();

  const dispatch = useAppDispatch();

  const { sendDocumentsAnalyticsEvent } = useSendAnalyticEvent();

  return (
    <Col gridGap={16} display={"flex"} height={"60px"} alignItems={"center"} justifyContent={"center"}>
      <Link
        style={{ width: "100%", display: "flex" }}
        href={`/checklist/tasks/document/${requisitionId}/${applicationId}/${documentType}/false`}
        textDecoration={"none"}
        onClick={(e) => {
          sendDocumentsAnalyticsEvent("button click", documentType);
          eventHandler(
            e,
            {
              documentType: documentType as AttachmentType,
              isEdit: false,
              buttonDelegationEnabled: true,
            },
            dispatch
          );
        }}
      >
        <Button style={{ width: "100%" }}> {buttonMessage}</Button>
      </Link>
      <Hr />
    </Col>
  );
};
