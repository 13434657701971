import { Button, ButtonSize, ButtonVariant } from "@amzn/stencil-react-components/button";
import { Radio } from "@amzn/stencil-react-components/form";
import { IconMoreHorizontal } from "@amzn/stencil-react-components/icons";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { Modal, ModalContent } from "@amzn/stencil-react-components/modal";
import { PopoverController } from "@amzn/stencil-react-components/popover";
import { Spinner, SpinnerSize } from "@amzn/stencil-react-components/spinner";
import { Text } from "@amzn/stencil-react-components/text";
import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { SUPPORTED_COUNTRY } from "../../config/appConfigConstants";
import { HVH_COLORS } from "../../config/palette";
import { useSendAnalyticEvent } from "../../hooks/jobDetailsCardAnalyticEventHelper.hook";
import { useChecklistArb } from "../../hooks/use-arb";
import { useNavigatorWithURLReady } from "../../hooks/use-navigator";
import { useUrlBuilder } from "../../hooks/useUrlBuilder.hook";
import { useWithDrawApplicationMutation } from "../../reduxStore/api/apiSlice";
import { useAppSelector } from "../../reduxStore/reduxHooks";
import { getFeatureFlag } from "../../reduxStore/slices/configSlice";
import { isCustomerService } from "../../utility/app-config-helper";
import { getCountryForDomain } from "../../utility/locale-helper";
import "../sda/styles.scss";
import { Label } from "../typography";
import { IReasonsConfig, getReasonsCodeConfig, getReasonsCodeConfigBasedOnCountry } from "./config";
import "./styles.scss";

interface ManageApplicationCardModalProps {
  eligibleForCSS: boolean;
  eligibleForWithdraw: boolean;
}

export const ManageApplicationCardModal = ({
  eligibleForCSS,
  eligibleForWithdraw,
}: ManageApplicationCardModalProps) => {
  const { isEligibleForNheAppointmentRescheduling, isEligibleForRtwAppointmentRescheduling } =
    useAppSelector(getFeatureFlag);

  const [withdrawApplication] = useWithDrawApplicationMutation();

  const bundle = useChecklistArb();

  const { applicationId } = useParams<{ applicationId: string }>();

  const { cssURLSchedule, appointmentURLService, rtwAppointmentSelfServiceURL, dashboardURL } = useUrlBuilder();

  const { sendFirstDayChangeAnalyticsEvent, sendWithdrawApplicationAnalyticsEvent } = useSendAnalyticEvent();

  const navigateToCSS = useNavigatorWithURLReady(cssURLSchedule);

  const navigateToCSSUrlWithAnalytics = async () => {
    await sendFirstDayChangeAnalyticsEvent("first day change link click");
    navigateToCSS();
  };

  const navigateToNHE = useNavigatorWithURLReady(appointmentURLService);

  const navigateToRTW = useNavigatorWithURLReady(rtwAppointmentSelfServiceURL);

  // For popover button
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const target = useRef(null);
  const togglePopover = () => setIsPopoverOpen(!isPopoverOpen);
  const closePopover = () => setIsPopoverOpen(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hideWarningMessage, setHideWarningMessage] = useState(true);
  const [isWithdrawConfirmationOpen, setIsWithdrawConfirmationOpen] = useState(false);
  const [isLoadingScreenOpen, setIsLoadingScreenOpen] = useState(false);
  const [isProgressCompletedViewOpen, setIsProgressCompletedViewOpen] = useState(false);
  const [isProgressFailedViewOpen, setIsProgressFailedViewOpen] = useState(false);
  const [withdrawReason, setWithdrawReason] = useState<IReasonsConfig>();
  const country = getCountryForDomain();

  const openModal = () => {
    setIsModalOpen(true);
    setIsWithdrawConfirmationOpen(true);
    sendWithdrawApplicationAnalyticsEvent("click withdraw", "");
  };

  const closeModal = () => {
    closeAllContents();
    setIsModalOpen(false);
    setWithdrawReason(undefined);
    setHideWarningMessage(true);
  };

  const closeAllContents = () => {
    setIsWithdrawConfirmationOpen(false);
    setIsLoadingScreenOpen(false);
    setIsProgressCompletedViewOpen(false);
    setIsProgressFailedViewOpen(false);
  };

  const displayLoadingScreen = () => {
    closeAllContents();
    setIsLoadingScreenOpen(true);
  };

  const displayProgressCompletedView = () => {
    closeAllContents();
    setIsProgressCompletedViewOpen(true);
  };

  const displayProgressFailedView = () => {
    closeAllContents();
    setIsProgressFailedViewOpen(true);
  };

  const onClickWithdrawApplication = async () => {
    if (withdrawReason === undefined) {
      setHideWarningMessage(false);
    } else {
      displayLoadingScreen();
      try {
        withdrawApplication({ applicationId, withdrawReason: withdrawReason.key })
          .unwrap()
          .then((result) => {
            if (result.statusCode === 200) {
              sendWithdrawApplicationAnalyticsEvent("select withdraw reason", withdrawReason.translation);
              displayProgressCompletedView();
            } else {
              displayProgressFailedView();
            }
          });
      } catch (err) {
        displayProgressFailedView();
      }
    }
  };

  const onChangeWithdrawReason = (selected: IReasonsConfig) => {
    setWithdrawReason(selected);
    setHideWarningMessage(true);
  };

  const reasonCodeConfig = getReasonsCodeConfig(bundle.getMessage);
  const relatedToLocationReasons = getReasonsCodeConfigBasedOnCountry(reasonCodeConfig, country);

  const reasonContent = (
    <>
      <Col gridGap="1rem">
        {relatedToLocationReasons.map((item) => {
          return (
            <Row
              gridGap="S200"
              alignItems="center"
              key={item.key}
              data-testid="job-details-card-modal-withdraw-reasons"
            >
              <Radio id={item.key} name={"radio"} value={item.key} onChange={() => onChangeWithdrawReason(item)} />
              <Label htmlFor={item.key}>{item.translation}</Label>
            </Row>
          );
        })}
      </Col>
    </>
  );

  const withdrawApplicationConfirmation = (
    <ModalContent
      titleText={bundle.getMessage("Checklist-CardModal-Withdraw")}
      buttons={[
        <>
          <Col width="100%">
            <Row width="100%" gridGap="1rem">
              <Col width="100%">
                <Button
                  onClick={closeModal}
                  size={ButtonSize.Small}
                  data-testid="job-details-card-modal-close-modal-button"
                >
                  {bundle.getMessage("Checklist-CardModal-Cancel")}
                </Button>
              </Col>
              <Col width="100%">
                <Button
                  onClick={onClickWithdrawApplication}
                  variant={ButtonVariant.Primary}
                  size={ButtonSize.Small}
                  data-testid="job-details-card-modal-on-click-withdraw-application-button"
                >
                  {bundle.getMessage("Checklist-CardModal-Confirm")}
                </Button>
              </Col>
            </Row>
          </Col>
        </>,
      ]}
    >
      <Col gridGap="1rem" data-testid="job-details-card-modal-reason-content">
        <Col gridGap="0.5rem">
          <Text fontSize="T300">{bundle.getMessage("Checklist-CardModal-WithdrawQuestion")}</Text>
          <Text fontSize="T200">{bundle.getMessage("Checklist-CardModal-TellReason")}</Text>
        </Col>
        {reasonContent}
        <Text fontSize="T200" color="red" hidden={hideWarningMessage}>
          {bundle.getMessage("Checklist-CardModal-SelectReason")}
        </Text>
      </Col>
    </ModalContent>
  );

  const loadingScreen = (
    <ModalContent
      titleText={bundle.getMessage("Checklist-CardModal-Processing")}
      data-testid="job-details-card-modal-loading-screen"
    >
      <Col padding="1rem" alignItems="center">
        <Spinner size={SpinnerSize.Small} showText={false} />
      </Col>
    </ModalContent>
  );

  const progressCompletedView = (
    <ModalContent titleText={bundle.getMessage("Checklist-CardModal-Success")}>
      <Col alignItems="center">
        <Button
          variant={ButtonVariant.Primary}
          onClick={useNavigatorWithURLReady(dashboardURL)}
          data-testid="job-details-card-modal-progress-completed-view"
        >
          {bundle.getMessage("Checklist-CardModal-Dashboard")}
        </Button>
      </Col>
    </ModalContent>
  );

  const progressFailedView = (
    <ModalContent titleText={bundle.getMessage("Checklist-CardModal-Error")}>
      <Col alignItems="center">
        <Button
          variant={ButtonVariant.Primary}
          onClick={closeModal}
          data-testid="job-details-card-modal-progress-failed-view"
        >
          {bundle.getMessage("Checklist-General-CloseButtonText")}
        </Button>
      </Col>
    </ModalContent>
  );

  const popoverController = (
    <PopoverController
      target={target}
      isOpen={isPopoverOpen}
      close={closePopover}
      data-testid="job-details-card-modal-popover-controller"
    >
      <Col padding="1rem" gridGap="1rem" alignItems="center" data-testid="job-options-dropdown">
        {eligibleForWithdraw && (
          <Button
            className="text-button"
            color={HVH_COLORS.PRIMARY_70}
            onClick={openModal}
            variant={ButtonVariant.Tertiary}
            data-testid="job-details-card-modal-open-modal-button"
          >
            {bundle.getMessage("Checklist-CardModal-Withdraw")}
          </Button>
        )}
        {isEligibleForNheAppointmentRescheduling && (
          <Button
            className="text-button"
            color={HVH_COLORS.PRIMARY_70}
            onClick={navigateToNHE}
            variant={ButtonVariant.Tertiary}
            data-testid="job-details-card-modal-appointment-url-service-button"
          >
            {bundle.getMessage("Checklist-CardModal-AppointmentCard-Reschedule")}
          </Button>
        )}
        {isEligibleForRtwAppointmentRescheduling && (
          <Button
            className="text-button"
            color={HVH_COLORS.PRIMARY_70}
            onClick={navigateToRTW}
            variant={ButtonVariant.Tertiary}
            data-testid="job-details-card-modal-rtw-appointment-self-service-url-button"
          >
            {bundle.getMessage("Checklist-CardModal-RTW-AppointmentCard-Reschedule")}
          </Button>
        )}
        {eligibleForCSS && (
          <Button
            className="text-button"
            color={HVH_COLORS.PRIMARY_70}
            onClick={navigateToCSSUrlWithAnalytics}
            variant={ButtonVariant.Tertiary}
            data-testid="job-details-card-modal-css-url-schedule-button"
          >
            {bundle.getMessage("Checklist-JobCard-ChangeSchedule")}
          </Button>
        )}
      </Col>
    </PopoverController>
  );

  return (
    <>
      <Modal isOpen={isModalOpen} close={closeModal} shouldCloseOnClickOutside={false}>
        {isModalOpen && isWithdrawConfirmationOpen && withdrawApplicationConfirmation}
        {isLoadingScreenOpen && loadingScreen}
        {isProgressFailedViewOpen && progressFailedView}
        {isProgressCompletedViewOpen && progressCompletedView}
      </Modal>

      <Col padding="1rem 1rem 1rem 0">
        {!isCustomerService(applicationId) && (
          <Button
            icon={<IconMoreHorizontal />}
            onClick={togglePopover}
            ref={target}
            size={ButtonSize.Small}
            aria-label="more job options"
            data-testid="job-details-card-modal-toggle-popover-button"
          />
        )}
        {popoverController}
      </Col>
    </>
  );
};
