import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

export const isValidAppId = (appId: string) => {
  // Non SF appId is hexadecimal string, hence the regex will be try to check if the string is valid hexadecimal ([0-9a-fA-F]) followed by length.
  const validNonSFAppId = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(appId);
  const validSFAppId = appId.length != 36 && appId.startsWith("a");

  return validNonSFAppId || validSFAppId;
};

export const useGetApplicationId = () => {
  const { applicationId } = useParams<{ applicationId: string }>();

  useEffect(() => {
    if (applicationId && applicationId.length) {
      if (!isValidAppId(applicationId) && process.env.NODE_ENV === "production") {
        console.error("Invalid application ID");
        throw new Error("Invalid application ID");
      }
    }
  }, [applicationId]);

  return applicationId;
};
