import { I9Type } from "../components/i9-task/i9-data";
import { I9Response } from "./types/common";
import { ChecklistPantherKey } from "./types/translations";

export enum ScreenResult {
  COMPLETE = "Complete",
  PENDING = "Pending",
  ERROR = "Error",
  COMPLETED = "Completed",
  UNDEFINED = "Undefined",
  NOT_STARTED = "Not Started",
  CANCELLED = "CANCELLED",
  IN_PROGRESS = "InProgress",
  INITIATED = "Initiated",
  NO_ACTION_REQUIRED = "No Action Required",
}

export const TRANSLATED_SCREEN_RESULT: Record<string, ChecklistPantherKey> = {
  Complete: "checklist-General-ASH-Status-Complete",
  Pending: "checklist-General-TaskStatus-Pending", //not added
  Error: "checklist-General-TaskStatus-Error",
  Initiated: "checklist-General-TaskStatus-Initiated",
  InProgress: "checklist-General-TaskStatus-InProgress",
  "Not Started": "checklist-General-TaskStatus-NotStarted",
  "No Action Required": "checklist-General-TaskStatus-NoActionRequired",
};

const CAAS_STATUS_TO_I9_STATUS: Record<string, ScreenResult> = {
  Initiated: ScreenResult.PENDING,
  WaitingForASHToCreateOrder: ScreenResult.PENDING,
  PendingSection1: ScreenResult.PENDING,
  Section1Complete: ScreenResult.PENDING,
  Section2Complete: ScreenResult.COMPLETE,
  PendingSection3: ScreenResult.PENDING,
  Section3Complete: ScreenResult.COMPLETE,
  "Remote+PendingSection1": ScreenResult.PENDING,
  "Remote+Section1Complete": ScreenResult.PENDING,
  "Remote+PendingSection3": ScreenResult.COMPLETE,
  Archived: ScreenResult.PENDING,
  Error: ScreenResult.ERROR,
  AwaitingCandidateInfo: ScreenResult.PENDING,
  Completed: ScreenResult.COMPLETE,
  PendingRejectionReview: ScreenResult.PENDING,
};

export const getTaskScreeningResultStatus = (status: string): ScreenResult => {
  switch (status) {
    case ScreenResult.INITIATED:
      return ScreenResult.INITIATED;
    case ScreenResult.IN_PROGRESS:
      return ScreenResult.IN_PROGRESS;
    case ScreenResult.COMPLETED:
      return ScreenResult.COMPLETE;
    case ScreenResult.NOT_STARTED:
      return ScreenResult.NOT_STARTED;
    case ScreenResult.CANCELLED:
    case ScreenResult.ERROR:
      return ScreenResult.ERROR;
    case ScreenResult.NO_ACTION_REQUIRED:
      return ScreenResult.NO_ACTION_REQUIRED;
    default:
      return ScreenResult.PENDING;
  }
};

export const getI9Status = (i9Response: I9Response): ScreenResult => {
  const i9Type = i9Response.isRemote === true ? I9Type.Remote : I9Type.Normal;
  if (i9Response.errorCode == "404") {
    return ScreenResult.NOT_STARTED;
  }
  if (!i9Response.status) {
    return ScreenResult.ERROR;
  }
  const specificKey = `${i9Type}+${i9Response.status}`;
  const commonKey = i9Response.status;
  return CAAS_STATUS_TO_I9_STATUS[specificKey] || CAAS_STATUS_TO_I9_STATUS[commonKey];
};
