import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { Col, Hr, Row } from "@amzn/stencil-react-components/layout";
import { PageContainer } from "@amzn/stencil-react-components/page";
import React from "react";
import acceptableImgSrc from "../../../../assets/images/acceptable-photo.png";
import unacceptableNoHatsAllowedImgSrc from "../../../../assets/images/unacceptable-photo-no-hats-allowed.png";
import unacceptableTooFarFromCameraImgSrc from "../../../../assets/images/unacceptable-photo-too-far-from-camera.png";
import unacceptableTooMuchFunImgSrc from "../../../../assets/images/unacceptable-photo-too-much-fun.png";
import unacceptableWrongBackgroundImgSrc from "../../../../assets/images/unacceptable-photo-wrong-background.png";
import { H1, H2, Text } from "../../../../components/typography";
import { HVH_COLORS } from "../../../../config/palette";
import { TAKE_BADGE_PHOTO_CAMERA_URL } from "../../../../config/urls";
import { useSendAnalyticEvent } from "../../../../hooks/jobDetailsCardAnalyticEventHelper.hook";
import { useChecklistArb } from "../../../../hooks/use-arb";
import { useNavigator } from "../../../../hooks/use-navigator";
import "./styles.scss";

export const BadgeCameraRequirementsRoute = () => {
  const [goToCamera] = useNavigator(TAKE_BADGE_PHOTO_CAMERA_URL);
  const { sendBadgePhotoEventAnalyticsEvent } = useSendAnalyticEvent();

  const enableCamera = () => {
    sendBadgePhotoEventAnalyticsEvent("camera enabled");

    goToCamera();
  };

  const bundle = useChecklistArb();
  const title = bundle.getMessage("Checklist-BPUploadRequirements-H1");
  const doRule = bundle.getMessage("Checklist-BPUploadRequirements-Use");
  const rule1 = bundle.getMessage("Checklist-BPUploadRequirements-Rule1");
  const rule2 = bundle.getMessage("Checklist-BPUploadRequirements-Rule2");
  const rule3 = bundle.getMessage("Checklist-BPUploadRequirements-Rule3");
  const acceptablePhoto = bundle.getMessage("Checklist-BPUploadRequirements-Acceptable");
  const unacceptablePhoto = bundle.getMessage("Checklist-BPUploadRequirements-Unacceptable");
  const doNot = bundle.getMessage("Checklist-BPUploadRequirements-DontUse");
  const doNotRule1 = bundle.getMessage("Checklist-BPUploadRequirements-DontRule1");
  const doNotRule2 = bundle.getMessage("Checklist-BPUploadRequirements-DontRule2");
  const doNotRule3 = bundle.getMessage("Checklist-BPUploadRequirements-DontRule3");
  const buttonText = bundle.getMessage("Checklist-BPCameraRequirements-ButtonText");
  const unacceptablePhotoWrongBackground = bundle.getMessage("Checklist-BPUploadRequirements-WrongBackGround");
  const unacceptablePhotoTooMuchFun = bundle.getMessage("Checklist-BPUploadRequirements-TooMuchFun");
  const unacceptablePhotoNoHatsAllowed = bundle.getMessage("Checklist-BPUploadRequirements-NoHatsAllowed");
  const unacceptablePhotoTooFarFromCamera = bundle.getMessage("Checklist-BPUploadRequirements-TooFar");

  return (
    <>
      <PageContainer color={HVH_COLORS.NEUTRAL_90}>
        <Col gridGap="1rem">
          <H1 textAlign="center">{title}</H1>
          <H2 className="do">{doRule}</H2>
          <ul className="rules-list">
            <li>
              <Text>{rule1}</Text>
            </li>
            <li>
              <Text>{rule2}</Text>
            </li>
            <li>
              <Text>{rule3}</Text>
            </li>
          </ul>
          <H2 className="do">{acceptablePhoto}</H2>
          <Row flex="row">
            <Col gridGap="1rem">
              <img src={acceptableImgSrc} className="preview-image" alt="Acceptable Photos" />
            </Col>
          </Row>
          <H2 className="dont">{doNot}</H2>
          <ul className="rules-list">
            <li>
              <Text>{doNotRule1}</Text>
            </li>
            <li>
              <Text>{doNotRule2}</Text>
            </li>
            <li>
              <Text>{doNotRule3}</Text>
            </li>
          </ul>
          <H2 className="dont">{unacceptablePhoto}</H2>
          <Row width="100%" gridGap="0.5rem">
            <Col gridGap="0.5rem">
              <Row>
                <img
                  src={unacceptableWrongBackgroundImgSrc}
                  className="preview-image"
                  alt="Unacceptable Photo Wrong Background"
                />
              </Row>
              <Row justifyContent="center">
                <Text textAlign="center">{unacceptablePhotoWrongBackground}</Text>
              </Row>
            </Col>
            <Col gridGap="0.5rem">
              <Row>
                <img
                  src={unacceptableTooMuchFunImgSrc}
                  className="preview-image"
                  alt="Unacceptable Photo Too Much Fun"
                />
              </Row>
              <Row justifyContent="center">
                <Text textAlign="center">{unacceptablePhotoTooMuchFun}</Text>
              </Row>
            </Col>
            <Col gridGap="0.5rem">
              <Row>
                <img
                  src={unacceptableNoHatsAllowedImgSrc}
                  className="preview-image"
                  alt="Unacceptable Photo No Hats Allowed"
                />
              </Row>
              <Row justifyContent="center">
                <Text textAlign="center">{unacceptablePhotoNoHatsAllowed}</Text>
              </Row>
            </Col>
            <Col gridGap="0.5rem">
              <Row>
                <img
                  src={unacceptableTooFarFromCameraImgSrc}
                  className="preview-image"
                  alt="Unacceptable Photo Too Far from Camera"
                />
              </Row>
              <Row justifyContent="center">
                <Text textAlign="center">{unacceptablePhotoTooFarFromCamera}</Text>
              </Row>
            </Col>
          </Row>
          <Hr />
          <Row justifyContent="center">
            <Button
              data-testid="badge-photo-enable-camera-button"
              onClick={enableCamera}
              variant={ButtonVariant.Primary}
            >
              {buttonText}
            </Button>
          </Row>
        </Col>
      </PageContainer>
    </>
  );
};
