import React from "react";
import "./styles.scss";
import { H1 } from "@amzn/stencil-react-components/text";
import { Row } from "@amzn/stencil-react-components/layout";
import { useChecklistArb } from "../../hooks/use-arb";
import { StatusContainer } from "./status-container";

import { Button, ButtonSize, ButtonVariant } from "@amzn/stencil-react-components/button";

export const ScreeningResultCompletedHeader = () => {
  const bundle = useChecklistArb();

  const formattedcompletemsg = bundle.getMessage("Checklist-AppointmentCard-Complete-Msg");

  const viewresult = bundle.formatMessage("Checklist-AppointmentCard-ButtonText7");
  const nextstep = bundle.formatMessage("Checklist-AppointmentCard-ButtonText8");
  return (
    <StatusContainer
      data-testid={"page-header-screening-result-complete"}
      largeViewPortImage={require("../../assets/svg/complete.svg").default}
      smallViewPortImage={require("../../assets/svg/complete.svg").default}
    >
      <H1 fontSize={"T400"} fontWeight={"regular"} textAlign="center" id="main-content">
        {formattedcompletemsg}
      </H1>

      <Row gridGap={"1rem"}>
        <Button
          data-testid="scroll-screening-result"
          size={ButtonSize.Small}
          onClick={() => document.getElementById("screeningresult")?.scrollIntoView()}
        >
          {viewresult}
        </Button>

        <Button
          data-testid="scroll-activities"
          variant={ButtonVariant.Primary}
          size={ButtonSize.Small}
          onClick={() => document.getElementById("activities")?.scrollIntoView()}
        >
          {nextstep}
        </Button>
      </Row>
    </StatusContainer>
  );
};
