import { CUSTOMER_SERVICE, NON_CUSTOMER_SERVICE } from "../../../config/appConfigConstants";
import { GB_CAAS_STATUS } from "../i9-data";
import { InstructionMapping } from "../i9-task-card";

export const getGBInstructionMapping = () => {
  const gbDefaultInstructionMapping: InstructionMapping = {
    [NON_CUSTOMER_SERVICE]: {
      [GB_CAAS_STATUS.Completed]: null,
    },
    [CUSTOMER_SERVICE]: {},
  };

  return gbDefaultInstructionMapping;
};
