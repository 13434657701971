import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { AttachmentType } from "../../routes/tasks/document/models";

export type OldUiFlyoutContentType = DocUploadState["oldUiFlyoutContent"];
interface DocUploadState {
  oldUiFlyoutContent: {
    documentType: AttachmentType | "FORM";
    isEdit: boolean;
    buttonDelegationEnabled: boolean | null;
  };
}

export const initialState: DocUploadState = {
  oldUiFlyoutContent: {
    // documentType: AttachmentType.MEXICAN_PASSPORT,
    documentType: "FORM",
    isEdit: false,
    buttonDelegationEnabled: true,
  },
};

const docUploadSlice = createSlice({
  name: "docUpload",
  initialState,
  reducers: {
    openOldUiFlyoutContentUpload: (state, action: PayloadAction<{ oldUiFlyoutContent: OldUiFlyoutContentType }>) => {
      state.oldUiFlyoutContent = action.payload.oldUiFlyoutContent;
    },
    updateButtonDelegationEnabled: (
      state,
      action: PayloadAction<OldUiFlyoutContentType["buttonDelegationEnabled"]>
    ) => {
      state.oldUiFlyoutContent.buttonDelegationEnabled = action.payload;
    },
  },
});

export const { openOldUiFlyoutContentUpload, updateButtonDelegationEnabled } = docUploadSlice.actions;

export const getOldUiFlyoutContent = (state: RootState) => state.docUpload.oldUiFlyoutContent;

export default docUploadSlice.reducer;
