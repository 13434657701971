import {
  getScreeningResultStatusFunc,
  i9GetScreeningResultStatusFunc,
} from "../components/screening-results/screeningResultsHelper";
import {
  I9_TASK_ID,
  RIGHT_TO_WORK_TASK_DEFINITION_ID,
  RIGHT_TO_WORK_TASK_TITLE,
  SUPPORTED_COUNTRY,
} from "../config/appConfigConstants";
import { isSalesforceApplication } from "../helpers/url-params";
import { getValidStatesForShowScreeningResult } from "../helpers/utils";
import { useAppSelector } from "../reduxStore/reduxHooks";
import { getConfigData, getFeatureFlag } from "../reduxStore/slices/configSlice";
import {
  getRightToWorkOrI9TaskType,
  getTaskDefinitionsFromConfig,
  isTaskEnabledByAppConfig,
} from "../utility/app-config-helper";
import { getCountryForDomain } from "../utility/locale-helper";
import { getI9Status, getTaskScreeningResultStatus, ScreenResult } from "../utility/screening-results-helper";
import { I9Response } from "../utility/types/common";
import { useGetApplicationId } from "./apis/useGetApplicationId";
import { useGetApplicationManageData } from "./apis/useGetApplicationManageData";

const useWorkAuth = (): I9Response | undefined => {
  const { isAlumni } = useAppSelector(getFeatureFlag);
  const applicationId = useGetApplicationId();
  const applicationManageData = useGetApplicationManageData();
  const { caasI9Status, caasRTWStatus } = useAppSelector(getConfigData);
  const { taskTitle, isIntegratedWithCaaS } = getTaskDefinitionsFromConfig(
    RIGHT_TO_WORK_TASK_DEFINITION_ID,
    applicationId
  );

  const country = getCountryForDomain();

  if (!applicationManageData) {
    return undefined;
  }

  // Do valid state check for HBA apps
  if (!isSalesforceApplication(applicationId)) {
    const { applicationState } = applicationManageData;

    if (!applicationState) {
      return undefined;
    }
    const validStates = getValidStatesForShowScreeningResult(country, !!isAlumni);
    if (!validStates.includes(applicationState)) {
      return undefined;
    }
  }

  // Deal with RTW vs I9 task type to get correct status
  if (isTaskEnabledByAppConfig(I9_TASK_ID, applicationId) && isIntegratedWithCaaS && country !== SUPPORTED_COUNTRY.MX) {
    if (taskTitle === RIGHT_TO_WORK_TASK_TITLE) {
      return caasRTWStatus !== "SKIPPED" ? caasRTWStatus : undefined;
    } else {
      return caasI9Status !== "SKIPPED" ? caasI9Status : undefined;
    }
  }

  return undefined;
};

/**
 * Get all screening statuses with results converted to be ready for display
 *
 * @returns Screening statuses for all screening types
 */
export const useScreeningInfo = (): {
  airBgcStatus: ScreenResult;
  bgcStatus: ScreenResult;
  dtStatus: ScreenResult;
  mcStatus: ScreenResult;
  workAuthStatus: ScreenResult;
  loaded: boolean;
} => {
  const { isEligibleToSkipAppt1, isAshConfigLoaded } = useAppSelector(getFeatureFlag);
  const { drugtestStatus, bgcStatus, medicalCheckStatus, airBgcStatus, caasI9Status } = useAppSelector(getConfigData);
  const workAuthStatus = useWorkAuth();
  const applicationId = useGetApplicationId();
  const country = getCountryForDomain();
  const isGenericRightToWorkTask = getRightToWorkOrI9TaskType(RIGHT_TO_WORK_TASK_DEFINITION_ID, applicationId);

  const dtStatusWithSkip = drugtestStatus === "SKIPPED" ? undefined : drugtestStatus;

  const convertedAirBgc = getScreeningResultStatusFunc(airBgcStatus, getTaskScreeningResultStatus);
  const convertedBgc = getScreeningResultStatusFunc(bgcStatus, getTaskScreeningResultStatus);
  const convertedDrugtest = getScreeningResultStatusFunc(dtStatusWithSkip, getTaskScreeningResultStatus);
  const convertedMedicalCheck = getScreeningResultStatusFunc(
    medicalCheckStatus === "SKIPPED" ? undefined : medicalCheckStatus,
    getTaskScreeningResultStatus
  );
  const convertedRtwOrI9 =
    isGenericRightToWorkTask || country === SUPPORTED_COUNTRY.CA
      ? getScreeningResultStatusFunc(workAuthStatus, getTaskScreeningResultStatus)
      : i9GetScreeningResultStatusFunc(
          workAuthStatus,
          getI9Status,
          caasI9Status !== "SKIPPED" && caasI9Status?.status ? caasI9Status?.status : undefined,
          !!isEligibleToSkipAppt1
        );

  return {
    airBgcStatus: convertedAirBgc,
    bgcStatus: convertedBgc,
    dtStatus: convertedDrugtest,
    mcStatus: convertedMedicalCheck,
    workAuthStatus: convertedRtwOrI9,
    loaded: !!isAshConfigLoaded,
  };
};
