import { useEffect, useState } from "react";
import { GratitudeComponentApplicationStateCriteriaEnum } from "../utility/constants/application-status";
import { isSalesforceApplication } from "../helpers/url-params";
import { useGetApplicationId } from "./apis/useGetApplicationId";
import { useGetApplicationManageData } from "./apis/useGetApplicationManageData";

export type GratitudeComponentStatusCriteriaType = keyof typeof GratitudeComponentApplicationStateCriteriaEnum;

export const useApplicationState = () => {
  const applicationId = useGetApplicationId();
  const applicationManageData = useGetApplicationManageData();

  const [applicationState, setApplicationState] = useState<string>("");

  useEffect(() => {
    if (applicationId && applicationManageData) {
      if (!isSalesforceApplication(applicationId) && applicationManageData) {
        setApplicationState(applicationManageData?.applicationState as string);
      }
    }
  }, [applicationId, applicationManageData]);

  return applicationState as GratitudeComponentStatusCriteriaType;
};
