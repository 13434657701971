import * as Cookie from "js-cookie";
import { ChecklistBundle } from "../hooks/use-arb";
import { SUPPORTED_COUNTRY } from "../config/appConfigConstants";
import { ChecklistPantherKey } from "./types/translations";

const SPANISH = "es";
const ENGLISH = "en";
const FRENCH = "fr";

export function getLanguage() {
  const locale = getLocale();
  const language = locale.substr(0, 2);
  return language;
}

export function getDefaultLocaleAndCountryForCurrentDomain(): {
  locale: string;
  country: SUPPORTED_COUNTRY;
} {
  if (
    window.location.hostname.endsWith("checklist.associate.amazondelivers.jobs") ||
    window.location.hostname === "localhost"
  ) {
    // return { locale: "en-GB", country: SUPPORTED_COUNTRY.GB };
    // We are on a ASH cloudfront distribution instead of career site, we must be in a hydra test run
    return {
      locale: "en-US", // Test logic usually checks for us-english content
      country: (Cookie.get("ash-hydra-country") ?? "US") as SUPPORTED_COUNTRY,
    };
  }

  switch (window.location.hostname) {
    // UK
    case "gamma-uk.devo.jobsatamazon.hvh.a2z.com":
    case "uk.preprod.jobsatamazon.hvh.a2z.com":
    case "gamma.devo.jobsatamazon.hvh.a2z.com":
    case "www.jobsatamazon.co.uk":
      return { locale: "en-GB", country: SUPPORTED_COUNTRY.GB };
    // US
    case "beta-us.devo.jobsatamazon.hvh.a2z.com":
    case "gamma-us.devo.jobsatamazon.hvh.a2z.com":
    case "us.preprod.jobsatamazon.hvh.a2z.com":
    case "hiring.amazon.com":
      return { locale: "en-US", country: SUPPORTED_COUNTRY.US };
    // BR
    case "gamma-br.devo.jobsatamazon.hvh.a2z.com":
    case "br.preprod.jobsatamazon.hvh.a2z.com":
    case "trabalho.amazon.com.br":
      return { locale: "pt-BR", country: SUPPORTED_COUNTRY.BR };
    // CO
    case "gamma-co.devo.jobsatamazon.hvh.a2z.com":
    case "co.preprod.jobsatamazon.hvh.a2z.com":
    case "trabaja.amazon.co":
      return { locale: "es-CO", country: SUPPORTED_COUNTRY.CO };
    // CR
    case "gamma-cr.devo.jobsatamazon.hvh.a2z.com":
    case "cr.preprod.jobsatamazon.hvh.a2z.com":
    case "trabaja.amazon.cr":
      return { locale: "es-CR", country: SUPPORTED_COUNTRY.CR };
    // MX
    case "gamma-mx.devo.jobsatamazon.hvh.a2z.com":
    case "mx.preprod.jobsatamazon.hvh.a2z.com":
    case "trabaja.amazon.com.mx":
      return { locale: "es-MX", country: SUPPORTED_COUNTRY.MX };
    // CA
    case "gamma-ca.devo.jobsatamazon.hvh.a2z.com":
    case "ca.preprod.jobsatamazon.hvh.a2z.com":
    case "hiring.amazon.ca":
      return { locale: "en-CA", country: SUPPORTED_COUNTRY.CA };
    // IN Swayam
    case "gamma-in.devo.jobsatamazon.hvh.a2z.com":
    case "in.preprod.jobsatamazon.hvh.a2z.com":
    case "jobs.hvhindia.in":
      return { locale: "en-IN", country: SUPPORTED_COUNTRY.IN };
    // IN CS
    case "gamma-in-cs.devo.jobsatamazon.hvh.a2z.com":
    case "in-cs.preprod.jobsatamazon.hvh.a2z.com":
    case "jobs.amazon.in":
      return { locale: "en-IN", country: SUPPORTED_COUNTRY.IN };
    // JP
    case "gamma-jp.devo.jobsatamazon.hvh.a2z.com":
    case "jp.preprod.jobsatamazon.hvh.a2z.com":
    case "shigoto.amazon.co.jp":
      return { locale: "ja-JP", country: SUPPORTED_COUNTRY.JP };
    // ZA
    case "gamma-za.devo.jobsatamazon.hvh.a2z.com":
    case "za.preprod.jobsatamazon.hvh.a2z.com":
    case "jobs.amazon.co.za":
      return { locale: "en-ZA", country: SUPPORTED_COUNTRY.ZA };
    // IE
    case "gamma-ie.devo.jobsatamazon.hvh.a2z.com":
    case "ie.preprod.jobsatamazon.hvh.a2z.com":
    case "jobs.amazon.ie":
      return { locale: "en-GB", country: SUPPORTED_COUNTRY.IE };
    // PH
    case "gamma-ph.devo.jobsatamazon.hvh.a2z.com":
    case "ph.preprod.jobsatamazon.hvh.a2z.com":
    case "jobs.amazon.ph":
      return { locale: "en-US", country: SUPPORTED_COUNTRY.PH };
    // JO
    case "gamma-jo.devo.jobsatamazon.hvh.a2z.com":
    case "jo.preprod.jobsatamazon.hvh.a2z.com":
    case "jobs.amazon.jo":
      return { locale: "en-JO", country: SUPPORTED_COUNTRY.JO };
    // EG
    case "gamma-eg-cs.devo.jobsatamazon.hvh.a2z.com":
    case "eg.preprod.jobsatamazon.hvh.a2z.com":
    case "jobs.amazon.eg":
      return { locale: "en-EG", country: SUPPORTED_COUNTRY.EG };
    default:
      return { locale: "en-US", country: SUPPORTED_COUNTRY.US };
  }
}

export const changeHtmlLangAttribute = () => {
  const html = document.querySelector("html");

  if (html?.lang) {
    html.lang = getDefaultLocaleAndCountryForCurrentDomain()?.locale?.substring(0, 2);
  }
};

export const getLocale = () => {
  const userDefinedLocale = Cookie.get("hvh-locale");

  const DEFAULT_LOCALE = getDefaultLocaleAndCountryForCurrentDomain().locale;
  return userDefinedLocale && userDefinedLocale.length > 0 ? userDefinedLocale : DEFAULT_LOCALE;
};

export const localeToLanguageMap: Map<string, ChecklistPantherKey> = new Map([
  ["en-US", "Checklist-General-English"],
  ["es-US", "Checklist-General-Spanish"],
  ["es-MX", "Checklist-General-Spanish"],
  ["fr-CA", "Checklist-General-French"],
  ["en-CA", "Checklist-General-English"],
]);

export const languageToTranslatedLanguageMap: Map<string, ChecklistPantherKey> = new Map([
  ["English", "Checklist-General-English"],
  ["English (UK)", "Checklist-General-English-UK"],
  ["Portuguese (Brazil)", "Checklist-General-Portuguese"],
  ["Japanese", "Checklist-General-Japan"],
  ["Spanish", "Checklist-General-Spanish"],
]);

export function isSpanishLocale(): boolean {
  return getLanguage() == SPANISH;
}

export function isEnglishLocale(): boolean {
  return getLanguage() == ENGLISH;
}

export function isFrenchLocale(): boolean {
  return getLanguage() == FRENCH;
}

export function getCountryForDomain(): SUPPORTED_COUNTRY {
  const region = getDefaultLocaleAndCountryForCurrentDomain().country;
  return region;
}

export function getSupportedLanguage(
  localeList: Array<string> | undefined,
  bundle: ChecklistBundle,
  isLanguageList?: boolean
): string {
  if (!localeList) {
    return "";
  }

  let languages = "";
  for (let i = 0; i < localeList.length; i++) {
    const languageKey = isLanguageList
      ? languageToTranslatedLanguageMap.get(localeList[i].trim())
      : localeToLanguageMap.get(localeList[i].trim());

    if (languageKey) {
      isFrenchLocale()
        ? (languages += bundle.getMessage(languageKey).toLowerCase() + ", ")
        : (languages += bundle.getMessage(languageKey) + ", ");
    }
  }
  return languages.length === 0 ? languages : languages.substr(0, languages.length - 2);
}
