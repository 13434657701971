import { useAppDispatch } from "../../../../reduxStore/reduxHooks";
import { openOldUiFlyoutContentUpload } from "../../../../reduxStore/slices/docUploadSlice";

export const useBackToMainPage = (refetch?: () => void) => {
  const dispatch = useAppDispatch();

  return () => {
    refetch?.();
    dispatch(
      openOldUiFlyoutContentUpload({
        oldUiFlyoutContent: { documentType: "FORM", isEdit: false, buttonDelegationEnabled: true },
      })
    );
    return;
  };
};
