import { AppointmentInfo, NHEAppointmentStatusEnum } from "@amzn/hvh-simple-hire-checklist-internal-api-common";
import { Button, ButtonSize, ButtonVariant } from "@amzn/stencil-react-components/button";
import { IconCalendarFill, IconDesktop, IconExternalLink, IconSize } from "@amzn/stencil-react-components/icons";
import { Flex, Row } from "@amzn/stencil-react-components/layout";
import { Link } from "@amzn/stencil-react-components/link";
import { H1, P, Text } from "@amzn/stencil-react-components/text";
import moment from "moment-timezone";
import React from "react";
import ICalendarLink from "react-icalendar-link";
import { NHE_EVENT_TYPE } from "../../config/appConfigConstants";
import { MLS_REQUIRED_LANGUAGE } from "../../config/feature-control";
import { Stage } from "../../helpers/get-settings";
import { useGetNHEAppointment } from "../../hooks/apis/useGetNHEAppointment";
import { useGetRTWAppointment } from "../../hooks/apis/useGetRTWAppointment";
import { useChecklistArb } from "../../hooks/use-arb";
import { useUrlBuilder } from "../../hooks/useUrlBuilder.hook";
import { useAppSelector } from "../../reduxStore/reduxHooks";
import { getFeatureFlag } from "../../reduxStore/slices/configSlice";
import { getSupportedLanguage } from "../../utility/locale-helper";
import { displayVenueTime } from "../../utility/time-processor";
import AppLoader from "../common/AppLoader";
import LinkWithRedirect from "../common/LinkWithRedirect";
import { StatusContainer } from "./status-container";
import { addToCalendarKeyDown, getCalendarEventV2Appointment } from "./utils";

interface AppointmentDetailsV2HeaderProps {
  sfRequiredLanguage: string | undefined;
  onViewDetailsClick: (event: string) => void;
  stage?: Stage;
}

interface AppointmentHeaderCardProps {
  appointmentType: NHE_EVENT_TYPE;
  sfRequiredLanguage: string | undefined;
  calendarFileName: string;
  appointmentV2Details: AppointmentInfo;
  stage?: Stage;
  onlyOneAppointment: boolean;
  onViewDetailsClick: (event: string) => void;
}

const AppointmentHeaderCard = ({
  appointmentType,
  sfRequiredLanguage,
  calendarFileName,
  appointmentV2Details,
  stage,
  onlyOneAppointment,
  onViewDetailsClick,
}: AppointmentHeaderCardProps) => {
  const bundle = useChecklistArb();
  const cannotAttend = bundle.formatMessage("Checklist-PreHireDetails-CannotAttend");
  const { startTime, timezone } = getCalendarEventV2Appointment(appointmentV2Details);
  const { appointmentURLService, rtwAppointmentSelfServiceURL } = useUrlBuilder();
  const rescheduleAppointmentURL = appointmentType === "RTW" ? rtwAppointmentSelfServiceURL : appointmentURLService;

  function appointmentHeaderFormatedStartTime() {
    if (!startTime) return;

    const displayTimeInVenueTime = displayVenueTime(appointmentV2Details);

    const startTimeMoment = moment.utc(startTime).tz(timezone);

    const currDate = moment.tz(timezone).startOf("day");
    const currentDateTime = moment.tz(currDate, timezone).tz(timezone); // Display in the NHE timezone, ensure the Panther format string includes the timezone!

    const dateTimeTranslated = bundle.formatMessage("Checklist-AppointmentCard-Month-Day-Year", {
      dateParam: displayTimeInVenueTime,
    });

    const dayDiff = startTimeMoment.diff(currentDateTime, "day");
    const countdownMsg =
      appointmentType !== "RTW"
        ? dayDiff > 0
          ? bundle.formatMessage("Checklist-AppointmentCard-Countdown1", {
              days: dayDiff,
            })
          : bundle.formatMessage("Checklist-AppointmentCard-Countdown2")
        : dayDiff > 0
        ? bundle.formatMessage("Checklist-RTW-AppointmentCard-Countdown1", {
            days: dayDiff,
          })
        : bundle.formatMessage("Checklist-RTW-AppointmentCard-Countdown2");

    return { countdownMsg, dateTimeTranslated };
  }
  const isVirtual = appointmentV2Details?.locationType === "PHYSICAL" ? false : true;
  const displayReadyLocation = appointmentV2Details ? appointmentV2Details.displayReadyLocation : null;

  const physicalLocationGoogleMapLink = (
    <Link
      fontSize={"T200"}
      href={"http://maps.google.com/?q=" + displayReadyLocation}
      target={"_blank"}
      data-testid={`appointment-location-${displayReadyLocation}`}
    >
      <Text>{displayReadyLocation}</Text>
    </Link>
  );

  const virtualDisplayLink = displayReadyLocation
    ? displayReadyLocation
    : (bundle.getMessage("Checklist-Common-Virtual") as string);

  const virtualLinkCompleteMessage = (
    <Link
      icon={<IconExternalLink size={IconSize.ExtraSmall} title={bundle.getMessage("Checklist-external-link")} />}
      tabIndex={0}
      fontSize={"T200"}
      href={virtualDisplayLink}
      target={"_blank"}
      margin={{ left: 5 }}
    >
      {appointmentV2Details?.locationType === "VIRTUAL_CONNECT"
        ? bundle.formatMessage("Checklist-Appointment-JoinHere")
        : virtualDisplayLink}{" "}
    </Link>
  );

  const displayReadyVirtualLocation = bundle.formatMessage("Checklist-PreHireDetails-Location-Virtual-NHE", {
    address: virtualLinkCompleteMessage,
  });

  const genericAddressLink =
    // Don't show virtualNHE if it is a RTWEvent
    isVirtual ? (
      <Flex flexDirection="row" alignItems="center" data-testid={`appointment-location-${displayReadyLocation}`}>
        <IconDesktop margin={{ right: 5 }} /> <Text>{displayReadyVirtualLocation}</Text>
      </Flex>
    ) : (
      physicalLocationGoogleMapLink
    );

  const timeDetails = appointmentHeaderFormatedStartTime();

  const timeMessage = appointmentV2Details?.displayReadyStartDate
    ? `${appointmentV2Details?.displayReadyStartDate} ${appointmentV2Details.displayReadyTimeSlot}`
    : `${timeDetails?.dateTimeTranslated} ${appointmentV2Details.displayReadyTimeSlot}`;

  const supportedLanguage = sfRequiredLanguage
    ? sfRequiredLanguage
    : getSupportedLanguage(appointmentV2Details?.supportedLanguage, bundle);

  const addToCalendar = bundle.formatMessage("Checklist-AppointmentCard-ButtonText3");
  const formattedAppointmentSupportedLanguageMsg =
    bundle.getMessage("Checklist-AppointmentCard-Language") + ": " + supportedLanguage;
  const { isEligibleForNheAppointmentRescheduling, isEligibleForRtwAppointmentRescheduling } =
    useAppSelector(getFeatureFlag);
  const reschedule = "Checklist-AppointmentCard-ButtonText2";
  const viewDetails = bundle.formatMessage("Checklist-AppointmentCard-ButtonText1");

  const nheAppointmentTitle = bundle.formatMessage("Checklist-CardControlled-PreHireTitle");
  const rtwAppointmentTitle = bundle.formatMessage("Checklist-Header-RTW-Appointment-Title");

  const nheCalendarEvent = {
    startTime: appointmentV2Details?.startTimestamp || "",
    endTime: appointmentV2Details?.endTimestamp || "",
    title: nheAppointmentTitle,
    location: appointmentV2Details?.displayReadyLocation || "",
  };

  const rtwCalendarEvent = {
    startTime: appointmentV2Details?.startTimestamp || "",
    endTime: appointmentV2Details?.endTimestamp || "",
    title: rtwAppointmentTitle,
    location: appointmentV2Details?.displayReadyLocation || "",
  };

  const calendarEvent = appointmentType === NHE_EVENT_TYPE.RTW ? rtwCalendarEvent : nheCalendarEvent;
  const rescheduleEligibilityType =
    appointmentType == NHE_EVENT_TYPE.NHE
      ? isEligibleForNheAppointmentRescheduling
      : isEligibleForRtwAppointmentRescheduling;

  return (
    <>
      {onlyOneAppointment ? (
        <H1 fontSize={"T400"} fontWeight={"regular"} id="main-content">
          {timeDetails?.countdownMsg}
        </H1>
      ) : (
        <Row>
          <Text fontSize={"T200"}>{timeDetails?.countdownMsg}</Text>
        </Row>
      )}

      <Row gridGap={"1rem"}>
        <Row gridGap={"0.3rem"} alignItems={"center"}>
          <IconCalendarFill />
          <Text data-testid={`appointment-starttime-${startTime}`} fontSize={"T200"}>
            {timeMessage}
          </Text>
        </Row>
      </Row>
      {onlyOneAppointment && (
        <>
          {stage && MLS_REQUIRED_LANGUAGE[stage] && (
            <Text data-testid="supported-language" fontSize={"T200"}>
              {formattedAppointmentSupportedLanguageMsg}
            </Text>
          )}
          {genericAddressLink}
          <Row gridGap={"1rem"} alignItems="center">
            {rescheduleEligibilityType && (
              <>
                <Text style={{ fontStyle: "italic" }} fontSize={"T200"}>
                  {cannotAttend}
                </Text>
                <LinkWithRedirect
                  data-testid="reschedule-button"
                  link={rescheduleAppointmentURL}
                  messageKey={reschedule}
                  messagePlaceholder={"Reschedule"}
                />
              </>
            )}
          </Row>
        </>
      )}
      <Row gridGap={"1rem"}>
        <Button
          as={"Col"}
          size={ButtonSize.Small}
          onKeyDown={(event) => addToCalendarKeyDown(event, ".add-to-calendar")}
          tabIndex={-1}
        >
          <ICalendarLink
            data-testid="calendar-button"
            key="calendar-button"
            filename={calendarFileName}
            event={calendarEvent}
            className={"add-to-calendar"}
          >
            {addToCalendar}
          </ICalendarLink>
        </Button>
        <Button
          data-testid="action-select-appointment-type"
          aria-label="view-details"
          variant={ButtonVariant.Primary}
          size={ButtonSize.Small}
          onClick={() => onViewDetailsClick(appointmentType)}
        >
          {viewDetails}
        </Button>
      </Row>
    </>
  );
};

export const AppointmentDetailsV2Header = ({
  sfRequiredLanguage,
  onViewDetailsClick,
  stage,
}: AppointmentDetailsV2HeaderProps) => {
  const bundle = useChecklistArb();
  const nheInfo = useGetNHEAppointment();
  const rtwInfo = useGetRTWAppointment();

  const appointmentTitle = bundle.formatMessage("Checklist-CardControlled-PreHireTitle");
  const mainHeaderTitle = bundle.formatMessage("Checklist-Main-Header-Appointment-Title");
  const nheAppointmentTitle = bundle.formatMessage("Checklist-Header-NHE-Appointment-Title");
  const rtwAppointmentTitle = bundle.formatMessage("Checklist-Header-RTW-Appointment-Title");
  const nheOnholdAppointmentMainText = bundle.getMessage("Checklist-AppointmentCard-First-Day-On-Hold-Text1");
  const nheOnholdAppointmentSubText = bundle.getMessage("Checklist-AppointmentCard-First-Day-On-Hold-Text2");

  // Spinner until these are ready
  if (!(rtwInfo && nheInfo)) {
    return <AppLoader />;
  }

  const nheError = nheInfo && "errorStatusCode" in nheInfo && nheInfo.errorStatusCode;
  const rtwError = rtwInfo && "errorStatusCode" in rtwInfo && rtwInfo.errorStatusCode;

  // Both NHE and RTW available. Applicable for UK.
  if (!nheError && !rtwError) {
    return (
      <StatusContainer
        data-testid="page-header-prehire-event-nhe-and-rtw-available"
        largeViewPortImage={require("../../assets/images/calendar-large-viewport.png").default}
        smallViewPortImage={require("../../assets/images/calendar-small-viewport.png").default}
      >
        <H1 fontSize={"T400"} fontWeight={"regular"} id="main-content">
          {mainHeaderTitle}
        </H1>

        {
          <div className="white-box">
            <AppointmentHeaderCard
              appointmentType={NHE_EVENT_TYPE.NHE}
              sfRequiredLanguage={sfRequiredLanguage}
              calendarFileName={nheAppointmentTitle}
              appointmentV2Details={nheInfo as AppointmentInfo}
              stage={stage}
              onlyOneAppointment={false}
              onViewDetailsClick={onViewDetailsClick}
            />
          </div>
        }

        {
          <div className="white-box">
            <AppointmentHeaderCard
              appointmentType={NHE_EVENT_TYPE.RTW}
              sfRequiredLanguage={sfRequiredLanguage}
              calendarFileName={rtwAppointmentTitle}
              appointmentV2Details={rtwInfo as AppointmentInfo}
              stage={stage}
              onlyOneAppointment={false}
              onViewDetailsClick={onViewDetailsClick}
            />
          </div>
        }
      </StatusContainer>
    );
  }
  // Only NHE available.
  if (!nheError) {
    const castNheInfo = nheInfo as AppointmentInfo;
    if (castNheInfo.status == NHEAppointmentStatusEnum.ON_HOLD) {
      return (
        <StatusContainer
          id={"pageHeader-prehireEvent-NheOnhold"}
          data-testid="page-header-prehire-event-nhe-on-hold"
          largeViewPortImage={require("../../assets/images/calendar-large-viewport.png").default}
          smallViewPortImage={require("../../assets/images/calendar-small-viewport.png").default}
        >
          <H1 fontSize={"T500"} fontWeight={"regular"} style={{ maxWidth: "400px" }} id="main-content">
            {nheOnholdAppointmentMainText}
          </H1>
          <P fontSize={"T300"} fontWeight={"regular"} style={{ maxWidth: "400px" }} textAlign="center">
            {nheOnholdAppointmentSubText}
          </P>
        </StatusContainer>
      );
    }
    return (
      <StatusContainer
        data-testid="page-header-prehire-event-nhe-or-rtw-available"
        largeViewPortImage={require("../../assets/images/calendar-large-viewport.png").default}
        smallViewPortImage={require("../../assets/images/calendar-small-viewport.png").default}
      >
        <AppointmentHeaderCard
          appointmentType={NHE_EVENT_TYPE.NHE}
          sfRequiredLanguage={sfRequiredLanguage}
          calendarFileName={appointmentTitle}
          appointmentV2Details={castNheInfo}
          stage={stage}
          onlyOneAppointment={true}
          onViewDetailsClick={onViewDetailsClick}
        />
      </StatusContainer>
    );
  }

  // Only RTW available.
  if (!rtwError) {
    return (
      <StatusContainer
        data-testid="page-header-prehire-event-nhe-or-rtw-available"
        largeViewPortImage={require("../../assets/images/calendar-large-viewport.png").default}
        smallViewPortImage={require("../../assets/images/calendar-small-viewport.png").default}
      >
        <AppointmentHeaderCard
          appointmentType={NHE_EVENT_TYPE.RTW}
          sfRequiredLanguage={sfRequiredLanguage}
          calendarFileName={appointmentTitle}
          appointmentV2Details={rtwInfo as AppointmentInfo}
          stage={stage}
          onlyOneAppointment={true}
          onViewDetailsClick={onViewDetailsClick}
        />
      </StatusContainer>
    );
  }

  return <></>;
};
