import { CUSTOMER_SERVICE, NON_CUSTOMER_SERVICE } from "../../../config/appConfigConstants";
import { InstructionMapping } from "../i9-task-card";

export const getPHInstructionMapping = () => {
  const phDefaultInstructionMapping: InstructionMapping = {
    [NON_CUSTOMER_SERVICE]: {},
    [CUSTOMER_SERVICE]: {},
  };

  return phDefaultInstructionMapping;
};
