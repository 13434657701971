import { Button } from "@amzn/stencil-react-components/button";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import React, { useCallback } from "react";
import { getNumberOfFailedAttempts } from "../../helpers/badge-failed-attempts";
import { useSendAnalyticEvent } from "../../hooks/jobDetailsCardAnalyticEventHelper.hook";
import { useChecklistArb } from "../../hooks/use-arb";
import { useMetrics } from "../../hooks/use-metrics";
import { H2, Text } from "../typography";

interface SkipBadgeProps {
  goToChecklist: () => void;
}

export const SkipBadge = ({ goToChecklist }: SkipBadgeProps) => {
  const metrics = useMetrics();

  const { sendBadgePhotoEventAnalyticsEvent } = useSendAnalyticEvent();

  const handleSkipClick = useCallback(() => {
    sendBadgePhotoEventAnalyticsEvent("skip badge photo task");

    if (metrics?.sendMetric) {
      metrics.sendMetric({
        event: "skip-badge-photo-task",
        details: {
          numberOfFailedAttempts: getNumberOfFailedAttempts(),
        },
      });
    }
    goToChecklist();
  }, [goToChecklist, metrics?.sendMetric]);

  const bundle = useChecklistArb();
  const skipDetails = bundle.getMessage("Checklist-BPSkipBadgeModal-Details");
  const skipTile = bundle.getMessage("Checklist-BPSkipBadgeModal-Title");
  const buttonText = bundle.getMessage("Checklist-BPSkipBadgeModal-ButtonText");

  return (
    <Col gridGap="S300" alignItems="center">
      <H2>{skipTile}</H2>
      <Row>
        <Text textAlign="center">{skipDetails}</Text>
      </Row>
      <Button data-testid="skip-badge-photo-task-button" onClick={handleSkipClick}>
        {buttonText}
      </Button>
    </Col>
  );
};
