import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Stage } from "../../helpers/get-settings";
import { CONTACT_US_LINK, FR_CONTACT_US_LINK } from "../../utility/constants";
import { isFrenchLocale } from "../../utility/locale-helper";

export const useNavigateContactUrl = (stage: Stage | undefined) => {
  const history = useHistory();

  return useCallback(() => {
    window.open(isFrenchLocale() ? FR_CONTACT_US_LINK : CONTACT_US_LINK, "_blank");
  }, [history, stage]);
};
