export enum GratitudeComponentApplicationStateCriteriaEnum {
  "POOLING" = "POOLING",
  "PENDING_APPT_1_SCHEDULING" = "PENDING_APPT_1_SCHEDULING",
  "SCHEDULE_SELECTION_PENDING" = "SCHEDULE_SELECTION_PENDING",
  "EVALUATION_PENDING" = "EVALUATION_PENDING",
}

// APPLICATION_STATE is based from https://code.amazon.com/packages/HVHApplicationDomainInterface/blobs/mainline/--/src/com/amazon/hvhapplicationdomain/model/enums/ApplicationState.java
export enum APPLICATION_STATE {
  ASSESSMENT_PENDING = "ASSESSMENT_PENDING",
  SHIFT_SELECTION_PENDING = "SHIFT_SELECTION_PENDING",
  CONDITIONAL_INTENT_PENDING = "CONDITIONAL_INTENT_PENDING",
  BGC_CONSENT_AND_INFO_PENDING = "BGC_CONSENT_AND_INFO_PENDING",
  PERSONAL_DETAILS_PENDING = "PERSONAL_DETAILS_PENDING",
  LOCAL_BGC_CONSENT_PENDING = "LOCAL_BGC_CONSENT_PENDING",
  APPT_1_SCHEDULING = "APPT_1_SCHEDULING",
  QUESTIONS_PENDING = "QUESTIONS_PENDING",
  DIVERSITY_PENDING = "DIVERSITY_PENDING",
  REVIEW_AND_SUBMIT_PENDING = "REVIEW_AND_SUBMIT_PENDING",
  EVALUATION_PENDING = "EVALUATION_PENDING",
  APPT_1_PENDING = "APPT_1_PENDING",
  APPT_2_PENDING = "APPT_2_PENDING",
  APPT_3_PENDING = "APPT_3_PENDING",
  CONTINGENCIES_PENDING = "CONTINGENCIES_PENDING",
  SCHEDULE_SELECTION_PENDING = "SCHEDULE_SELECTION_PENDING",
  START_DATE_CONFIRMATION_PENDING = "START_DATE_CONFIRMATION_PENDING",
  NEW_HIRE_MATERIALS_PENDING = "NEW_HIRE_MATERIALS_PENDING",
  READY_TO_HIRE = "READY_TO_HIRE",
  HIRE_COMPLETE = "HIRE_COMPLETE",
  WITHDRAWN = "WITHDRAWN",
  REJECTED_BY_AMAZON = "REJECTED_BY_AMAZON",
  POOLING = "POOLING",
  PENDING_APPT_1_SCHEDULING = "PENDING_APPT_1_SCHEDULING",
  REJECTION_REVIEW_PENDING = "REJECTION_REVIEW_PENDING",
  APPT_3_SCHEDULING = "APPT_3_SCHEDULING",
}

// SF_APP_STATE_IS_READY_TO_HIRE_AND_HIRE_COMPLETE is based from https://quip-amazon.com/8eLCA2GkjHcx/Dragonspine-State-Transition-Matrix#OPF9CAhlJjC
export enum SF_APP_STATE {
  HIRED_DIRECT = "Hire Confirmed:Hired Direct",
  HIRED_CONTINGENT = "Hire Confirmed:Hired Contingent",
  HIRED_ADMIN_TERM = "Hire Confirmed:Hired - Admin Term",
  PRE_HIRE_PEOPLE_SOFT = "Pre-Hire:PeopleSoft: Manage Hire",
  PRE_HIRE_INITIATE_ONBOARDING = "Pre-Hire:Initiate Onboarding",
  PRE_HIRE_ERROR_INITIATE_ONBOARDING = "Pre-Hire:Error: Initiate Onboarding",
  PRE_HIRE_ERROR_NO_SHOW_REHIRE = "Pre-Hire:No Show - Rehire",
}
