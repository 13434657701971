import { I9Response } from "@amzn/hvh-simple-hire-checklist-internal-api-common";
import badge from "../assets/images/badge.png";
import computer from "../assets/images/computer.png";
import form from "../assets/images/form.png";
import multipleTask from "../assets/images/multipleTask.png";
import shoes from "../assets/images/shoes.png";
import video from "../assets/images/video.png";
import { getI9ActivityStatus } from "../components/i9-task/i9-data";
import { getWorkAuthorizationCardTaskTitle, isInfoOnlyTask } from "../helpers/utils";
import { useGetJobPreviewVideoDetails } from "../hooks/apis/useGetJobPreviewVideoData";
import { ChecklistBundle } from "../hooks/use-arb";
import { rtwAppointmentProps } from "../routes/checklist";
import {
  getTaskDefinitionsFromConfig,
  isCustomerService,
  isDisableCaasI9GETCallEnabledByAppConfig,
  isTaskEnabledByAppConfig,
} from "../utility/app-config-helper";
import {
  ChecklistTasks,
  ChecklistTasksAnalytic,
  ChecklistTasksKey,
  ChecklistTasksStatusInfo,
  StatusEnum,
} from "../utility/enums/common";
import { getCountryForDomain } from "../utility/locale-helper";
import * as APP_CONFIG from "./appConfigConstants";
import * as TASK_CONFIG from "./taskConfigConstants";
import * as URL from "./urls";

/**
 * Tasks interface is the tasks card configuration that will be shown to the candidate on /checklist.
 */
export interface Task {
  titleText: string; // Responsible for showing the Card title
  analyticsOnExpand?: string; // Responsible to send the analytic data when the flyout is showed
  titleStatusPath?: string; // Responsible for the endpoint url to get the current status of tasks.
  hideWhenError?: boolean; // Responsible to hide the tasks from UI when there is an error
  shouldOverrideStatus?: Record<string, StatusEnum>; // Responsible to override the status of tasks from BE
  defaultStatus?: StatusEnum; // Responsible to set the default status of the task
  hideOnConfig?: boolean;
  taskStatusName?: string; // TODO: Confirm what taskStatusName used for
  getTaskStatusByBBId?: boolean; // Responsible as a flag to get status endpoint using BB ID
  checkBusinessLine?: boolean; // Responsible to disable the component using the BusinessLine checking
  taskName?: string; // Task name that used for internally for check task.
  icon?: string; // Responsible to show the icon if exists on the card
  duration?: number; // Responsible to showing how long the tasks estimate will be. The unit is on minutes
}

export interface TaskGroup {
  name: string;
  tasks: Array<Task>;
  id?: string;
}

export const hideTaskOnConfig = (task: Task) => !task.hideOnConfig;

interface GetTargetTaskGroupProps {
  isDragonstone: boolean;
  isCustomerService: boolean;
  skipAppointmentOne: boolean;
  taskGroups: {
    taskGroupForNewUI: Array<TaskGroup>;
    taskGroupForCustomerService: Array<TaskGroup>;
    taskGroupForSkipAppt1Eligible: Array<TaskGroup>;
  };
}

export const getTargetTaskGroup = ({ isCustomerService, taskGroups, skipAppointmentOne }: GetTargetTaskGroupProps) => {
  const { taskGroupForCustomerService, taskGroupForNewUI, taskGroupForSkipAppt1Eligible } = taskGroups;

  let targetTaskGroup: Array<TaskGroup> = [];

  targetTaskGroup = skipAppointmentOne ? taskGroupForSkipAppt1Eligible : taskGroupForNewUI;

  if (isCustomerService) {
    targetTaskGroup = taskGroupForCustomerService;
  }

  return targetTaskGroup;
};

interface GetChecklistTasksProps {
  bundle: ChecklistBundle;
  applicationId: string;
  appConfigLoaded: boolean;
  country: APP_CONFIG.SUPPORTED_COUNTRY;
  rightToWorkAppointmentProps?: rtwAppointmentProps;
  workAuthStatus?: I9Response | "SKIPPED";
  enableBadgePhotoForJapan?: boolean;
  skipAppt1?: boolean;
}

export const GetChecklistTasks = ({
  bundle,
  applicationId,
  appConfigLoaded,
  rightToWorkAppointmentProps,
  country,
  workAuthStatus,
  enableBadgePhotoForJapan,
  skipAppt1,
}: GetChecklistTasksProps) => {
  const jobPreviewVideoDetails = useGetJobPreviewVideoDetails();

  const resolveWorkAuthorizationTitleStatusPath = () => {
    const taskDefinitionConfig = getTaskDefinitionsFromConfig(
      APP_CONFIG.RIGHT_TO_WORK_TASK_DEFINITION_ID,
      applicationId
    );

    if (!appConfigLoaded || !taskDefinitionConfig) {
      return "";
    }

    if (!taskDefinitionConfig.isIntegratedWithCaaS) {
      return "";
    }

    if (country === APP_CONFIG.SUPPORTED_COUNTRY.MX) {
      return URL.WORK_AUTH_DOC_UPLOAD_STATUS_ENDPOINT;
    }

    if (
      taskDefinitionConfig.taskTitle == APP_CONFIG.RIGHT_TO_WORK_TASK_TITLE &&
      taskDefinitionConfig.isIntegratedWithCaaS
    ) {
      return URL.RIGHT_TO_WORK_STATUS_ENDPOINT;
    }

    return isDisableCaasI9GETCallEnabledByAppConfig() ? URL.CAAS_API_URL : URL.I9_STATUS_ENDPOINT;
  };

  const ChecklistTasksAnalyticOnAction: ChecklistTasksAnalytic = {
    [ChecklistTasksKey.START_TAKE_BADGE_PHOTO]: "go to take badge photo",
    [ChecklistTasksKey.NEW_UI_DOCUMENT_UPLOAD]: "Upload mandatory documents",
  };

  const ChecklistTasksAnalyticOnExpand: ChecklistTasksAnalytic = {
    [ChecklistTasksKey.COMPLETE_EMPLOYMENT_FORM]: "complete employment form",
    [ChecklistTasksKey.START_TAKE_BADGE_PHOTO]: "badge photo",
    [ChecklistTasksKey.ORDER_YOUR_SAFETY_SHOES]: "ash expand shoe purchase",
    [ChecklistTasksKey.COMPLETE_EMPLOYMENT_PAPERWORK]: "new hire docs",
    [ChecklistTasksKey.CREATE_EMPLOYEE_A_TO_Z_ACCOUNT]: "atoz account",
    [ChecklistTasksKey.PROVIDE_BACKGROUND_CHECK_INFORMATION]: "bgc information",
    [ChecklistTasksKey.SCHEDULE_RIGHT_TO_WORK]: "schedule rtw appointment",
    [ChecklistTasksKey.NEW_UI_DOCUMENT_UPLOAD]: "upload mandatory docs",
    [ChecklistTasksKey.JOB_PREVIEW_VIDEO]: "watch job preview video",
  };

  const ChecklistTasksStatusName: ChecklistTasksStatusInfo = {
    [ChecklistTasksKey.NEW_HIRE_VIDEO]: "NEW_HIRE_VIDEO",
    [ChecklistTasksKey.ORDER_YOUR_SAFETY_SHOES]: "ZAPPOS_SHOE_PROMOTION",
  };

  const allTasksDefinition: ChecklistTasks = {
    [ChecklistTasksKey.NEW_HIRE_VIDEO]: {
      titleText: bundle.getMessage("Checklist-MainPage-Video-Title"),
      taskName: TASK_CONFIG.NEW_HIRE_VIDEO,
      titleStatusPath: URL.NEW_HIRE_VIDEO_STATUS_ENDPOINT,
      taskStatusName: ChecklistTasksStatusName.NEW_HIRE_VIDEO,
      getTaskStatusByBBId: true,
      icon: video,
      hideOnConfig:
        !appConfigLoaded || !isTaskEnabledByAppConfig(APP_CONFIG.NHE_VIDEO_TASK_ID, applicationId) || skipAppt1,
      duration: 5,
    },
    [ChecklistTasksKey.COMPLETE_EMPLOYMENT_FORM]: {
      titleText: bundle.getMessage(
        getWorkAuthorizationCardTaskTitle(
          getTaskDefinitionsFromConfig(APP_CONFIG.RIGHT_TO_WORK_TASK_DEFINITION_ID, applicationId)?.taskTitle,
          getCountryForDomain() as APP_CONFIG.SUPPORTED_COUNTRY
        )
      ),
      taskName: TASK_CONFIG.I_9_DOCUMENT,
      analyticsOnExpand: ChecklistTasksAnalyticOnExpand.COMPLETE_EMPLOYMENT_FORM,
      titleStatusPath: resolveWorkAuthorizationTitleStatusPath(),
      shouldOverrideStatus: getI9ActivityStatus(applicationId),
      defaultStatus: isInfoOnlyTask({
        country,
        isCustomerService: isCustomerService(applicationId),
        isI9Task: true,
      })
        ? StatusEnum.SKIPPED
        : StatusEnum.NOT_STARTED,
      icon: form,
      hideOnConfig:
        !appConfigLoaded ||
        !isTaskEnabledByAppConfig(APP_CONFIG.I9_TASK_ID, applicationId) ||
        // The default behavior for work Auth is we will use the value we get from appConfig as last resort
        (workAuthStatus === "SKIPPED" && !isTaskEnabledByAppConfig(APP_CONFIG.I9_TASK_ID, applicationId)),
      duration: 5,
    },
    [ChecklistTasksKey.START_TAKE_BADGE_PHOTO]: {
      titleText: bundle.getMessage("Checklist-MainPage-BadgePhoto-Title"),
      taskName: TASK_CONFIG.BADGE_PHOTO,
      analyticsOnExpand: ChecklistTasksAnalyticOnExpand.START_TAKE_BADGE_PHOTO,
      titleStatusPath: URL.BADGE_PHOTO_STATUS_ENDPOINT,
      hideWhenError: true,
      shouldOverrideStatus: {
        [StatusEnum.IN_PROGRESS]: StatusEnum.COMPLETED,
        [StatusEnum.API_ERROR]: StatusEnum.ACTION_REQUIRED,
        [StatusEnum.NOT_STARTED]: StatusEnum.ACTION_REQUIRED,
        [StatusEnum.COMPLETED]: StatusEnum.COMPLETED,
      },
      icon: badge,
      hideOnConfig:
        !appConfigLoaded ||
        !isTaskEnabledByAppConfig(APP_CONFIG.BADGE_PHOTO_TASK_ID, applicationId) ||
        skipAppt1 ||
        ((getCountryForDomain() as APP_CONFIG.SUPPORTED_COUNTRY) === APP_CONFIG.SUPPORTED_COUNTRY.JP &&
          !enableBadgePhotoForJapan),
      duration: 5,
    },
    [ChecklistTasksKey.ORDER_YOUR_SAFETY_SHOES]: {
      titleText:
        country === APP_CONFIG.SUPPORTED_COUNTRY.CA
          ? bundle.getMessage("Checklist-MainPage-MindYourStep-Title-CA")
          : bundle.getMessage("Checklist-MainPage-MindYourStep-Title"),
      taskName: TASK_CONFIG.ORDER_YOUR_SAFETY_SHOE,
      taskStatusName: ChecklistTasksStatusName.ORDER_YOUR_SAFETY_SHOES,
      hideWhenError: true,
      getTaskStatusByBBId: true,
      analyticsOnExpand: ChecklistTasksAnalyticOnExpand.ORDER_YOUR_SAFETY_SHOES,
      checkBusinessLine: true,
      shouldOverrideStatus: {
        [StatusEnum.ELIGIBLE]: StatusEnum.COMPLETED,
        [StatusEnum.API_ERROR]: StatusEnum.INELIGIBLE,
      },
      icon: shoes,
      hideOnConfig: !appConfigLoaded || !isTaskEnabledByAppConfig(APP_CONFIG.MYSP_TASK_ID, applicationId),
      duration: 15,
    },
    [ChecklistTasksKey.COMPLETE_EMPLOYMENT_PAPERWORK]: {
      titleText: bundle.getMessage("Checklist-Complete-Employment-Paperwork"),
      taskName: TASK_CONFIG.EMPLOYMENT_PAPERWORK,
      titleStatusPath: URL.ONBASE_DOCUMENT_STATUS_ENDPOINT,
      analyticsOnExpand: ChecklistTasksAnalyticOnExpand.COMPLETE_EMPLOYMENT_PAPERWORK,
      hideWhenError: true,
      shouldOverrideStatus: {
        [StatusEnum.ERROR]: StatusEnum.NOT_AVAILABLE_YET,
      },
      icon: computer,
      hideOnConfig:
        !appConfigLoaded || !isTaskEnabledByAppConfig(APP_CONFIG.EMPLOYMENT_PAPERWORK_TASK_ID, applicationId),
      duration: 30,
    },
    [ChecklistTasksKey.CREATE_EMPLOYEE_A_TO_Z_ACCOUNT]: {
      titleText: bundle.getMessage("Checklist-AtoZ-AtoZStatus-Title"),
      taskName: TASK_CONFIG.CREATE_EMPLOYEE_A_TO_Z_ACCOUNT,
      titleStatusPath: URL.ATOZ_STATUS_ENDPOINT,
      hideWhenError: true,
      hideOnConfig: !appConfigLoaded || !isTaskEnabledByAppConfig(APP_CONFIG.ATOZ_TASK_ID, applicationId),
      analyticsOnExpand: ChecklistTasksAnalyticOnExpand.CREATE_EMPLOYEE_A_TO_Z_ACCOUNT,
      icon: badge,
      duration: 15,
    },
    [ChecklistTasksKey.PROVIDE_BACKGROUND_CHECK_INFORMATION]: {
      titleText: bundle.getMessage("Checklist-MainPage-bgcInformation-Title"),
      taskName: TASK_CONFIG.BACKGROUNDCHECK_INFORMATION,
      titleStatusPath: URL.BACKGROUND_CHECK_STATUS_ENDPOINT,
      icon: form,
      analyticsOnExpand: ChecklistTasksAnalyticOnExpand.PROVIDE_BACKGROUND_CHECK_INFORMATION,
      hideOnConfig:
        !appConfigLoaded || !isTaskEnabledByAppConfig(APP_CONFIG.BACKGROUND_CHECK_INFORMATION_ID, applicationId),
      duration: 10,
    },
    [ChecklistTasksKey.NEW_UI_DOCUMENT_UPLOAD]: {
      titleText: bundle.getMessage("Checklist-docUpload-header"),
      taskName: TASK_CONFIG.DOC_UPLOADS,
      hideWhenError: true,
      shouldOverrideStatus: {
        [StatusEnum.NOT_STARTED]: StatusEnum.ACTION_REQUIRED,
      },
      taskStatusName: TASK_CONFIG.DOC_UPLOADS,
      defaultStatus: StatusEnum.NOT_STARTED,
      icon: multipleTask,
      analyticsOnExpand: ChecklistTasksAnalyticOnExpand.NEW_UI_DOCUMENT_UPLOAD,
      titleStatusPath: URL.MANDATORY_DOC_UPLOAD_STATUS_ENDPOINT,
      hideOnConfig: !appConfigLoaded || !isTaskEnabledByAppConfig(APP_CONFIG.DOCUMENT_TASK_ID, applicationId),
      duration: 25,
    },
    [ChecklistTasksKey.SCHEDULE_RIGHT_TO_WORK]: {
      titleText: bundle.getMessage("Checklist-MainPage-RTW-Appointment-Title"),
      taskName: TASK_CONFIG.SCHEDULE_RIGHT_TO_WORK_APPOINTMENT,
      titleStatusPath: URL.RTW_APPOINTMENT_STATUS_ENDPOINT,
      icon: multipleTask,
      shouldOverrideStatus: {
        [StatusEnum.NHE_COMPLETED]: rightToWorkAppointmentProps?.PreviousrightToWorkExpired
          ? StatusEnum.NHE_UNKNOWN
          : StatusEnum.NHE_COMPLETED,
        [StatusEnum.NHE_RESERVED]: StatusEnum.NHE_RESERVED,
        [StatusEnum.NHE_UNKNOWN]: StatusEnum.NHE_UNKNOWN,
      },
      defaultStatus: StatusEnum.NHE_UNKNOWN,
      analyticsOnExpand: ChecklistTasksAnalyticOnExpand.SCHEDULE_RIGHT_TO_WORK,
      hideOnConfig:
        !appConfigLoaded ||
        !isTaskEnabledByAppConfig(APP_CONFIG.SCHEDULE_RIGHT_TO_WORK_APPOINTMENT_ID, applicationId) ||
        !rightToWorkAppointmentProps?.isRtwAppointmentEnabled,
      duration: 5,
    },
    [ChecklistTasksKey.JOB_PREVIEW_VIDEO]: {
      titleText: bundle.getMessage("Checklist-MainPage-Job-Preview-Video-Title"),
      taskName: TASK_CONFIG.JOB_PREVIEW_VIDEO,
      titleStatusPath: jobPreviewVideoDetails.jobPreviewVideoURL,
      icon: video,
      shouldOverrideStatus: {},
      defaultStatus: StatusEnum.ACTION_REQUIRED,
      analyticsOnExpand: ChecklistTasksAnalyticOnExpand.JOB_PREVIEW_VIDEO,
      hideOnConfig: !appConfigLoaded || !isTaskEnabledByAppConfig(APP_CONFIG.JOB_PREVIEW_VIDEO_TASK_ID, applicationId),
      duration: 5,
    },
  };

  const taskGroupForNewUI: Array<TaskGroup> = [
    {
      id: TASK_CONFIG.PRE_HIRE_APPOINTMENT_GROUP,
      name: bundle.getMessage("Checklist-MainPage-NewSubTitle1"),
      tasks:
        country === APP_CONFIG.SUPPORTED_COUNTRY.GB
          ? [
              allTasksDefinition.NEW_HIRE_VIDEO,
              allTasksDefinition.START_TAKE_BADGE_PHOTO,
              allTasksDefinition.COMPLETE_EMPLOYMENT_FORM,
              allTasksDefinition.PROVIDE_BACKGROUND_CHECK_INFORMATION,
              allTasksDefinition.SCHEDULE_RIGHT_TO_WORK,
            ]
          : [
              allTasksDefinition.COMPLETE_EMPLOYMENT_FORM,
              allTasksDefinition.NEW_UI_DOCUMENT_UPLOAD,
              allTasksDefinition.PROVIDE_BACKGROUND_CHECK_INFORMATION,
              allTasksDefinition.START_TAKE_BADGE_PHOTO,
              allTasksDefinition.NEW_HIRE_VIDEO,
              allTasksDefinition.JOB_PREVIEW_VIDEO,
            ],
    },
    {
      id: TASK_CONFIG.FIRST_DAY_GROUP,
      name: bundle.getMessage("Checklist-MainPage-NewSubTitle3"),
      tasks:
        country === APP_CONFIG.SUPPORTED_COUNTRY.CA
          ? [
              allTasksDefinition.COMPLETE_EMPLOYMENT_PAPERWORK,
              allTasksDefinition.ORDER_YOUR_SAFETY_SHOES,
              allTasksDefinition.CREATE_EMPLOYEE_A_TO_Z_ACCOUNT,
            ]
          : [
              allTasksDefinition.ORDER_YOUR_SAFETY_SHOES,
              allTasksDefinition.COMPLETE_EMPLOYMENT_PAPERWORK,
              allTasksDefinition.CREATE_EMPLOYEE_A_TO_Z_ACCOUNT,
            ],
    },
  ];

  const taskGroupForSkipAppt1Eligible: Array<TaskGroup> = [
    {
      id: TASK_CONFIG.FIRST_DAY_GROUP,
      name: bundle.getMessage("Checklist-MainPage-NewSubTitle3"),
      tasks: taskGroupForNewUI.reduce<Task[]>((acc, obj) => acc.concat(obj.tasks), []),
    },
  ];

  const taskGroupForCustomerService: Array<TaskGroup> = [
    {
      id: TASK_CONFIG.FIRST_DAY_GROUP,
      name: bundle.getMessage("Checklist-MainPage-NewSubTitle3"),
      tasks: [
        allTasksDefinition.COMPLETE_EMPLOYMENT_FORM,
        allTasksDefinition.START_TAKE_BADGE_PHOTO,
        allTasksDefinition.NEW_HIRE_VIDEO,
        allTasksDefinition.ORDER_YOUR_SAFETY_SHOES,
        allTasksDefinition.COMPLETE_EMPLOYMENT_PAPERWORK,
        allTasksDefinition.CREATE_EMPLOYEE_A_TO_Z_ACCOUNT,
      ],
    },
  ];

  return {
    allTasksDefinition,
    taskGroupForNewUI,
    taskGroupForCustomerService,
    taskGroupForSkipAppt1Eligible,
  };
};
