import {
  AllContingencies,
  AppointmentInfo,
  NHEAppointmentStatusEnum,
} from "@amzn/hvh-simple-hire-checklist-internal-api-common";
import moment from "moment";
import { CAAS_STATUS } from "../components/i9-task/i9-data";
import { ContingencyResponse } from "../components/screening-results/screeningResults";
import { updateStatusForAshLite } from "../components/task";
import { COMPLETE_RIGHT_TO_WORK, I_9_DOCUMENT, ORDER_YOUR_SAFETY_SHOE } from "../config/taskConfigConstants";
import { Task } from "../config/tasks";
import { ChecklistBundle } from "../hooks/use-arb";
import { GratitudeStateEnum, GratitudeStateType } from "../hooks/useGratitudeComponentState.hook";
import { WorkAuthorizationAttachmentType } from "../routes/tasks/document/models";
import { getTaskDefinitionsFromConfig, isTaskEnabledByAppConfig } from "../utility/app-config-helper";
import { APPLICATION_STATE } from "../utility/constants/application-status";
import { UnsoundEither } from "../utility/generic-types";
import { getLocale } from "../utility/locale-helper";
import {
  ADDITIONAL_SCREENING_RESULT_VALID_ALUMNI,
  ADDITIONAL_VALID_APP_STATE_TO_DISPLAY_NHE_UK,
  BADGE_PHOTO_APPLICATION_STATES_FOR_JAPAN,
  COUNTRIES_WITHOUT_I9,
  COUNTRIES_WITHOUT_TASK_STATUS_INDICATOR,
  COUNTRIES_WITH_WORK_AUTHORIZATION_TASK_TITLE,
  EMPLOYMENT_TYPE,
  JOB_DETAIL_DATE_FORMAT,
  JOB_DETAIL_TIME_FORMAT,
  RIGHT_TO_WORK_TASK_DEFINITION_ID,
  RIGHT_TO_WORK_TASK_TITLE,
  SDA_DATE_FORMAT,
  SDA_TIME_FORMAT,
  SHOW_SCREENING_RESULT_VALID_STATES,
  SHOW_SCREENING_RESULT_VALID_STATES_UK,
  SUPPORTED_COUNTRY,
  UK_EMPLOYMENT_TYPE_LABEL_OVERRIDE,
  IS_DRUG_TEST_APPOINTMENT_ENABLED,
} from "./../config/appConfigConstants";
import {
  ApplicationManageData,
  JobDetailsModel,
  SdaInfo,
  SkipAppointmentOneEligibility,
} from "./../utility/application-data";
import { ExtendAppointmentInfo, PayInterval, StatusEnum } from "./../utility/enums/common";
import { isSalesforceApplication } from "./url-params";
import { APPOINTMENT_NEED_TO_SCHEDULE } from "../utility/constants/common";
// eslint-disable-next-line import/no-unresolved

export const resolveDurationText = (unit: string, status?: string, duration?: number, country?: string) => {
  if (status && isCaasStatus(status)) {
    if (country == SUPPORTED_COUNTRY.CA) {
      if (status == CAAS_STATUS.Pending || status == CAAS_STATUS.Initiated) {
        return duration && unit;
      }
    }

    if (status == CAAS_STATUS.AwaitingCandidateInfo) {
      return duration && unit;
    }

    return "";
  }

  return duration && unit;
};

const isCaasStatus = (status: string): boolean => {
  return Object.values(CAAS_STATUS).includes(status as CAAS_STATUS);
};

export const isValidCaasDTResponse = (caasDTResponse: ContingencyResponse | undefined, isAlumni: boolean): boolean => {
  const badCaasResponse = !caasDTResponse || caasDTResponse?.status === StatusEnum.API_ERROR;
  return isAlumni && !badCaasResponse;
};

export const isRtwTaskDefinitionConfigEnabled = (applicationId: string): boolean => {
  const taskDefinitionConfig = getTaskDefinitionsFromConfig(RIGHT_TO_WORK_TASK_DEFINITION_ID, applicationId);

  return taskDefinitionConfig?.taskTitle === RIGHT_TO_WORK_TASK_TITLE;
};

export const setupSdaPayloadFromJobDetail = (jobDetail?: JobDetailsModel | null): SdaInfo => {
  if (!jobDetail) {
    return {} as SdaInfo;
  }
  return {
    startDate: convertJobDetailDateToSdaDate(jobDetail.firstDayOnSite),
    startTime: convertJobDetailTimeToSdaTime(jobDetail.startTime),
    publicText: jobDetail.publicText || "",
    address: jobDetail.address,
    startDateStatus: jobDetail.startDateStatus || "",
    timezone: jobDetail.timezone || "",
  };
};

export const convertJobDetailDateToSdaDate = (jobDetailDate?: string): string => {
  if (!jobDetailDate) {
    return "";
  }

  return moment(jobDetailDate, JOB_DETAIL_DATE_FORMAT).format(SDA_DATE_FORMAT);
};

export const convertJobDetailTimeToSdaTime = (jobDetailTime?: string): string => {
  if (!jobDetailTime) {
    return "";
  }

  return moment(jobDetailTime, JOB_DETAIL_TIME_FORMAT).format(SDA_TIME_FORMAT);
};

export const isCurrentLocale = (locale: string) => getLocale() === locale;

export const isCountryWithoutI9 = (country: SUPPORTED_COUNTRY) => {
  return country in COUNTRIES_WITHOUT_I9;
};

export const isCountryWithoutWorkAuthStatusIndicator = (country: SUPPORTED_COUNTRY) => {
  return country in COUNTRIES_WITHOUT_TASK_STATUS_INDICATOR;
};

interface IsInfoOnlyTaskProps {
  country: SUPPORTED_COUNTRY;
  isCustomerService: boolean;
  task?: Task;
  isI9Task?: boolean;
}

export const isInfoOnlyTask = ({ country, isCustomerService, task, isI9Task }: IsInfoOnlyTaskProps) => {
  if (isCustomerService && isCountryWithoutWorkAuthStatusIndicator(country)) {
    if (task) {
      if (task.titleText === COMPLETE_RIGHT_TO_WORK || task.taskName === I_9_DOCUMENT) {
        return true;
      }
    }

    if (isI9Task) return true;
  } else if (country == SUPPORTED_COUNTRY.JP && (task?.taskName === I_9_DOCUMENT || isI9Task)) {
    return true;
  } else if (country == SUPPORTED_COUNTRY.CA && task?.taskName === ORDER_YOUR_SAFETY_SHOE) {
    return true;
  }

  return false;
};

type Linker = (href: string) => JSX.Element;

// Will receive a string separated by comma, with the assumption the first index as the chime link
export const getChimeLinkForVirtualNHE = (input: string, placeholder: string, linker?: Linker) => {
  const _input = input.split(",");
  const prepLink = linker || ((href) => href);

  return _input[0] && _input[0].includes("https://chime.aws") ? prepLink(_input[0]) : placeholder;
};

// Will receive a string separated by comma, japan is not using chime so we have relaxed validation
export const getLinkForJapanVirtualNHE = (input: string, placeholder: string, linker?: Linker) => {
  const _input = input.split(",");
  const prepLink = linker || ((href) => href);

  // Only show placeholder if blank/empty since Japan will have non-chime virtual appointments
  return _input[0] && _input[0].trim() !== "" ? prepLink(_input[0]) : placeholder;
};

export const showStartDateTooltip = (bundle: ChecklistBundle, country: SUPPORTED_COUNTRY) => {
  switch (country) {
    case SUPPORTED_COUNTRY.GB:
      return bundle.getMessage("Checklist-PossibleStartDate-ToolTip-UK");
    case SUPPORTED_COUNTRY.IE:
    case SUPPORTED_COUNTRY.IN:
    case SUPPORTED_COUNTRY.PH:
    case SUPPORTED_COUNTRY.ZA:
    case SUPPORTED_COUNTRY.CA:
    case SUPPORTED_COUNTRY.JP: // https://quip-amazon.com/jaIBAaHvBezr/Japan-Full-PRD-Amazon-Hiring-Solution No drug test
      ///Your first day is contingent on the results of your background check.
      return bundle.getMessage("Checklist-StartDate-BackgroundCheckOnly-ToolTip");
    case SUPPORTED_COUNTRY.BR:
    case SUPPORTED_COUNTRY.CO:
    case SUPPORTED_COUNTRY.CR:
    case SUPPORTED_COUNTRY.EG:
    case SUPPORTED_COUNTRY.JO:
    case SUPPORTED_COUNTRY.MX:
    case SUPPORTED_COUNTRY.US:
      ///Your first day is contingent on the results of your background check and drug test.
      return bundle.getMessage("Checklist-StartDate-ToolTip");
    default:
      // eslint-disable-next-line no-case-declarations
      const _exhaustiveCase: never = country;
  }
};

export const overrideEmploymentType = (country: SUPPORTED_COUNTRY, value: EMPLOYMENT_TYPE): EMPLOYMENT_TYPE => {
  switch (country) {
    case SUPPORTED_COUNTRY.GB:
      return UK_EMPLOYMENT_TYPE_LABEL_OVERRIDE[value] ?? value;
    default:
      return value;
  }
};

type showEmploymentTypeProps = {
  bundle: ChecklistBundle;
  country: SUPPORTED_COUNTRY;
  employmentType: EMPLOYMENT_TYPE;
  employmentTypeL10N: string;
};

export const showEmploymentType = ({
  bundle,
  country,
  employmentType,
  employmentTypeL10N,
}: showEmploymentTypeProps) => {
  if (country === SUPPORTED_COUNTRY.GB) {
    const translatedEmploymentType = bundle.getMessage(
      `Checklist-Common-${overrideEmploymentType(country, employmentType)}`
    );
    return translatedEmploymentType != "N/A" ? translatedEmploymentType : employmentTypeL10N;
  }

  return employmentTypeL10N;
};

export const getValidStatesForShowScreeningResult = (country: SUPPORTED_COUNTRY, isAlumni: boolean) => {
  switch (country) {
    case SUPPORTED_COUNTRY.GB:
      return [...SHOW_SCREENING_RESULT_VALID_STATES, ...SHOW_SCREENING_RESULT_VALID_STATES_UK] as string[];
    case SUPPORTED_COUNTRY.US:
      return [...SHOW_SCREENING_RESULT_VALID_STATES, ...ADDITIONAL_SCREENING_RESULT_VALID_ALUMNI] as string[];
    default:
      return SHOW_SCREENING_RESULT_VALID_STATES as string[];
  }
};

type isEligibleToDisplayNheProps = {
  country: SUPPORTED_COUNTRY;
  nheDetails?: UnsoundEither<AppointmentInfo, { errorStatusCode: number }>;
  rtwDetails?: UnsoundEither<AppointmentInfo, { errorStatusCode: number }>;
  applicationManageDetails: ApplicationManageData | undefined;
};

type isEligibleToDisplayDTProps = {
  nheDetails?: UnsoundEither<AppointmentInfo, { errorStatusCode: number }>;
  dtDetails?: ExtendAppointmentInfo;
  applicationManageDetails: ApplicationManageData | undefined;
  applicationId: string;
};

const validNheSkipStatus: SkipAppointmentOneEligibility[] = [
  SkipAppointmentOneEligibility.SKIP_BY_ELIGIBILITY_CHECK,
  SkipAppointmentOneEligibility.SKIP_BY_USER_SELECTION,
];

export const isAlumni = (applicationManageDetails: ApplicationManageData | undefined | null): boolean => {
  return !!applicationManageDetails?.isAlumniApplication;
};

export const hasSkipAppointmentOneEligibility = (
  applicationManageDetails: ApplicationManageData | undefined | null
): boolean => {
  if (!applicationManageDetails) return false;
  const { skipAppointmentOne } = applicationManageDetails;
  return validNheSkipStatus.some((status) => status === skipAppointmentOne);
};

export const badgePhotoAllowedStateForJapan = (
  applicationManageDetails: ApplicationManageData | undefined | null
): boolean => {
  if (applicationManageDetails?.applicationState) {
    return BADGE_PHOTO_APPLICATION_STATES_FOR_JAPAN.includes(
      applicationManageDetails?.applicationState as APPLICATION_STATE
    ) as boolean;
  }
  return false;
};

export const isEligibleForDrugTestAppointment = ({
  dtDetails,
  applicationManageDetails,
  applicationId,
}: isEligibleToDisplayDTProps) => {
  const now = moment();
  const noAppointmentOrNotInPast = !dtDetails?.startTimestamp || !moment(dtDetails.startTimestamp).isBefore(now, "day");

  return (
    isTaskEnabledByAppConfig(IS_DRUG_TEST_APPOINTMENT_ENABLED, applicationId) &&
    applicationManageDetails?.applicationState === APPLICATION_STATE.APPT_1_PENDING &&
    !hasSkipAppointmentOneEligibility(applicationManageDetails) &&
    (!dtDetails?.errorStatusCode ||
      (!!dtDetails?.errorStatusCode && dtDetails?.errorCode === APPOINTMENT_NEED_TO_SCHEDULE)) &&
    noAppointmentOrNotInPast
  );
};

export const isEligibleToDisplayNhe = ({
  nheDetails,
  rtwDetails,
  applicationManageDetails,
  country,
}: isEligibleToDisplayNheProps) => {
  const now = moment();

  const nheInPast = nheDetails?.startTimestamp && moment(nheDetails.startTimestamp).isBefore(now, "day");
  const rtwInPast = rtwDetails?.startTimestamp && moment(rtwDetails.startTimestamp).isBefore(now, "day");

  const isValid = !(
    hasSkipAppointmentOneEligibility(applicationManageDetails) ||
    nheInPast ||
    (applicationManageDetails?.applicationState && nheDetails?.status !== NHEAppointmentStatusEnum.RESERVED) ||
    nheDetails?.status === NHEAppointmentStatusEnum.COMPLETED
  );

  switch (country) {
    case SUPPORTED_COUNTRY.GB:
      if (applicationManageDetails?.applicationState) {
        const isRtwValid =
          rtwInPast ||
          (applicationManageDetails &&
            applicationManageDetails.applicationState &&
            rtwDetails &&
            rtwDetails.status !== NHEAppointmentStatusEnum.RESERVED) ||
          (rtwDetails && rtwDetails?.status === NHEAppointmentStatusEnum.COMPLETED);

        return (
          isValid ||
          isRtwValid ||
          ADDITIONAL_VALID_APP_STATE_TO_DISPLAY_NHE_UK.includes(applicationManageDetails?.applicationState) ||
          !rtwDetails?.errorStatusCode
        );
      }

      return isValid;

    case SUPPORTED_COUNTRY.JP:
      if (nheDetails?.status == NHEAppointmentStatusEnum.ON_HOLD) {
        return true;
      }

      return isValid;

    default:
      return isValid;
  }
};

export const getStatusOverride = <T extends StatusEnum | CAAS_STATUS | string>(
  shouldOverrideStatus: Record<string, T> | undefined,
  status: T | undefined
) => {
  return status && shouldOverrideStatus && shouldOverrideStatus[status] ? shouldOverrideStatus[status] : status;
};

type isEligibleToShowLanguageSupportedOnJobDetailsProps = {
  country: SUPPORTED_COUNTRY;
  isCustomerService: boolean;
  supportedLanguage: string | undefined;
};

export const isEligibleToShowLanguageSupportedOnJobDetails = ({
  country,
  isCustomerService,
  supportedLanguage,
}: isEligibleToShowLanguageSupportedOnJobDetailsProps) => {
  const isHasSupportedLanguage = supportedLanguage && supportedLanguage.length > 0;

  switch (country) {
    case SUPPORTED_COUNTRY.MX:
      if (isCustomerService) return true;
      return false;
    case SUPPORTED_COUNTRY.GB:
      if (isCustomerService) return false;
      return isHasSupportedLanguage;
    default:
      return isHasSupportedLanguage;
  }
};

interface isValidToShowGratitudeComponentProps {
  gratitudeComponentState: GratitudeStateType;
  country: SUPPORTED_COUNTRY;
  isCustomerService: boolean;
}

export const isValidToShowGratitudeComponent = ({
  gratitudeComponentState,
  country,
  isCustomerService,
}: isValidToShowGratitudeComponentProps) => {
  return (
    gratitudeComponentState !== GratitudeStateEnum.HideGratitudeComponent &&
    country === SUPPORTED_COUNTRY.GB &&
    !isCustomerService
  );
};

interface GetVirtualLinkProps {
  address: string;
  forJapan: boolean;
  bundle: ChecklistBundle;
  linker?: Linker;
}

export function getVirtualLinkAddress({ address, forJapan, bundle, linker }: GetVirtualLinkProps) {
  return forJapan
    ? getLinkForJapanVirtualNHE(address, bundle.getMessage("Checklist-Common-Virtual"), linker)
    : getChimeLinkForVirtualNHE(address, bundle.getMessage("Checklist-Common-Virtual"), linker);
}

export const getWorkAuthorizationCardTaskTitle = (titleFromTaskDefinition: string, country: SUPPORTED_COUNTRY) => {
  if (titleFromTaskDefinition !== RIGHT_TO_WORK_TASK_TITLE) {
    if (country == SUPPORTED_COUNTRY.US) {
      return "Checklist-MainPage-CompleteI9-Title";
    }

    if (country == SUPPORTED_COUNTRY.CA) {
      return "Checklist-MainPage-WorkAuthorization-TaskTitle";
    }
  }

  return country in COUNTRIES_WITH_WORK_AUTHORIZATION_TASK_TITLE
    ? "Checklist-MainPage-WorkAuthorization-TaskTitle"
    : "Checklist-MainPage-RightToWork-TaskTitle";
};

export const uuid = () =>
  "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (match, r) =>
    ("x" == match ? (r = (Math.random() * 16) | 0) : (r & 0x3) | 0x8).toString(16)
  );

export function isAirJob(businessLine: string | undefined) {
  return businessLine === "AIR";
}

export const getPayRate = (country: SUPPORTED_COUNTRY) => {
  switch (country) {
    case SUPPORTED_COUNTRY.MX:
    case SUPPORTED_COUNTRY.BR:
    case SUPPORTED_COUNTRY.CO:
    case SUPPORTED_COUNTRY.CR:
      return {
        showCurrencyCode: true,
        payInterval: PayInterval.MONTHLY,
      };
    case SUPPORTED_COUNTRY.IN:
      return {
        showCurrencyCode: false,
        payInterval: PayInterval.YEARLY,
      };
    case SUPPORTED_COUNTRY.PH:
    case SUPPORTED_COUNTRY.EG:
    case SUPPORTED_COUNTRY.JO:
      return {
        showCurrencyCode: false,
        payInterval: PayInterval.MONTHLY,
      };
    case SUPPORTED_COUNTRY.ZA:
    case SUPPORTED_COUNTRY.IE:
      return {
        showCurrencyCode: false,
        payInterval: PayInterval.HOURLY,
      };
    case SUPPORTED_COUNTRY.JP:
      return {
        showCurrencyCode: false,
        payInterval: PayInterval.HOURLY,
      };
    default:
      return {
        showCurrencyCode: false,
        payInterval: PayInterval.HOURLY,
      };
  }
};

export const applicationIdWhenErr = (params: string[]): string | null => {
  function findUUID(params: string[]): string | null {
    const regex = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;

    for (const item of params) {
      const dspApplicationMatch = item.match(regex);
      if (dspApplicationMatch) {
        return dspApplicationMatch[0];
      }

      const sfApplicationMatch = item.length != 36 && item.startsWith("a");
      if (sfApplicationMatch) {
        return item;
      }
    }
    return null;
  }

  return findUUID(params);
};

export const getI9CaaSResponse = (applicationId: string, status: StatusEnum | undefined) => {
  const taskDefinition = getTaskDefinitionsFromConfig(RIGHT_TO_WORK_TASK_DEFINITION_ID, applicationId);

  const isIntegratedWithCaaS: boolean = !taskDefinition || taskDefinition.isIntegratedWithCaaS;

  return {
    isIntegratedWithCaaS,
    status: updateStatusForAshLite(isIntegratedWithCaaS, applicationId, status, taskDefinition),
  };
};

export const isTentativeJobCardStartDate = (isTentativeStartDate: boolean, isCustomerService: boolean) => {
  // For CS job it can't be tentative
  // https://issues.amazon.com/issues/37d72262-1885-404c-938e-b198684e2c2d?selectedConversation=beae9e4f-be1c-4dc2-b88a-8edb0efc9b2c
  if (isCustomerService) return false;

  if (isTentativeStartDate) return true;

  return false;
};

export const getAdobeEventTextName = (eventName: string, option: any) => {
  const baseEventName = WorkAuthorizationAttachmentType.has(option)
    ? "complete employment form"
    : "upload mandatory docs";

  switch (eventName) {
    case "submit button click":
      return `${baseEventName} submit button click`;
    case "cancel button click":
      return `${baseEventName} cancel button click`;
    default:
      return `${baseEventName} option`;
  }
};

export const mapAllContigenciesStatuses = (url: string, allContingenciesResponse: AllContingencies) => {
  if (url.startsWith("/api/status/right-to-work/candidate/")) {
    return allContingenciesResponse.RightToWork;
  }
  if (url.startsWith("/api/status/background-check/candidate/")) {
    return allContingenciesResponse.BGC;
  }
  if (url.startsWith("/api/caas/")) {
    return allContingenciesResponse.WorkAuthorization;
  }
  if (url.startsWith("/api/status/i9/candidate/")) {
    return allContingenciesResponse.WorkAuthorization;
  }
};

export const validStatesToShowScreeningResultForHBAApplications = (
  applicationId: string,
  country: SUPPORTED_COUNTRY,
  isAlumniCandidate: boolean,
  applicationState: string | null
) => {
  if (isSalesforceApplication(applicationId)) {
    return true;
  }

  const validStates = getValidStatesForShowScreeningResult(country, !!isAlumniCandidate);
  const enableScreeningResultsComponent = applicationState && validStates.includes(applicationState);

  return enableScreeningResultsComponent;
};
