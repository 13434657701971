import React from "react";
import { Text } from "@amzn/stencil-react-components/text";
import { CUSTOMER_SERVICE, JobType, NON_CUSTOMER_SERVICE } from "../../../config/appConfigConstants";
import { US_CAAS_STATUS } from "../i9-data";
import { Row } from "@amzn/stencil-react-components/layout";
import { ChecklistBundle } from "../../../hooks/use-arb";
import { ErrorCode, getErrorMessage } from "../i9-error-message";
import { USCIS_URL, USCIS_URL_ES } from "../../../config/urls";
import { Link } from "@amzn/stencil-react-components/link";
import { isSpanishLocale } from "../../../utility/locale-helper";
import { CONTACT_US_LINK } from "../../../utility/constants";
import { InstructionMapping } from "../i9-task-card";

interface USInstructionMappingProps {
  i9ErrorCode: ErrorCode | undefined;
  bundle: ChecklistBundle;
  isAlumni: boolean;
}

export const getUSInstructionMapping = ({
  i9ErrorCode,
  bundle,
  isAlumni,
}: USInstructionMappingProps): InstructionMapping => {
  const here = bundle.getMessage("Checklist-MainPage-Link");
  const acceptableDocument = bundle.getMessage(
    "Checklist-MainPage-Common-AcceptableDocumentation",
    "acceptable documentation"
  );
  const candidateSupport = bundle.getMessage("Checklist-MainPage-Common-CandidateSupport", "candidate support");
  const errorMessage = getErrorMessage(i9ErrorCode, bundle);
  const uscisLink = isSpanishLocale() ? USCIS_URL_ES : USCIS_URL;
  const uscisSupportLink = <a href={uscisLink}>list</a>;
  const supportLink = (
    <Link target="_blank" href={CONTACT_US_LINK}>
      {isAlumni ? candidateSupport : here}
    </Link>
  );
  const i9Link = (
    <Link target="_blank" href={uscisLink}>
      {isAlumni ? acceptableDocument : here}
    </Link>
  );

  const section1CompleteMessage = bundle.formatMessage(
    isAlumni ? "Checklist-MainPage-Alumni-CompletedSection1Message" : "Checklist-MainPage-I9-CompletedSection1Message",
    {
      i9Link: i9Link,
      supportLink: supportLink,
    }
  );

  const translatedI9Form = isAlumni
    ? bundle.getMessage(
        "Checklist-MainPage-Alumni-Form",
        "The Form I-9 is used to verify the identity and employment authorization of individuals hired in the United States. Click the button below to get started."
      )
    : bundle.getMessage("Checklist-MainPage-I9-Form");

  const pendingSection1GeneralMessage = bundle.getMessage(
    "Checklist-CustomerService-US-I9-PendingSection1-Detail-GeneralMessage"
  );
  const pendingSection1DetailMessage1 = bundle.getMessage(
    "Checklist-CustomerService-US-I9-PendingSection1-Detail-Message1"
  );
  const pendingSection1DetailMessage2 = bundle.getMessage(
    "Checklist-CustomerService-US-I9-PendingSection1-Detail-Message2"
  );
  const pendingSection1DetailMessage2FirstSplitWord = bundle.getMessage(
    "Checklist-CustomerService-US-I9-PendingSection1-Detail-Message2FirstSplitWord"
  );
  const pendingSection1DetailMessage2SecondSplitWord = bundle.getMessage(
    "Checklist-CustomerService-US-I9-PendingSection1-Detail-Message2SecondSplitWord"
  );
  const pendingSection1DetailMessage3 = bundle.getMessage(
    "Checklist-CustomerService-US-I9-PendingSection1-Detail-Message3"
  );
  const pendingSection1DetailMessage4 = bundle.getMessage(
    "Checklist-CustomerService-US-I9-PendingSection1-Detail-Message4"
  );
  const pendingSection1DetailMessage5 = bundle.getMessage(
    "Checklist-CustomerService-US-I9-PendingSection1-Detail-Message5"
  );
  const pendingSection2GeneralMessage = bundle.getMessage(
    "Checklist-CustomerService-US-I9-PendingSection2-Detail-GeneralMessage"
  );
  const pendingSection2USI9NoteMessage = bundle.getMessage("Checklist-US-Customer-Service-I9-Note-Section1Complete");
  const pendingSection2USI9NoteKeyword = bundle.getMessage("Checklist-US-Customer-Service-I9-Note-Keyword");
  const pendingSection2DetailMessage1 = bundle.formatMessage(
    "Checklist-CustomerService-US-I9-PendingSection2-Detail-Message1",
    { list: uscisSupportLink }
  );
  const pendingSection3GeneralMessage = bundle.getMessage(
    "Checklist-CustomerService-US-I9-PendingSection3-Detail-GeneralMessage"
  );
  const MessageThanksForCompletingI9 = bundle.getMessage(
    isAlumni ? "Checklist-MainPage-Alumni-ThankYouMessage" : "Checklist-MainPage-I9-ThankYouMessage"
  );

  const pendingSection3Message = bundle.formatMessage(
    isAlumni
      ? "Checklist-MainPage-Alumni-AlreadyCompletedDetailedMessage"
      : "Checklist-MainPage-I9-AlreadyCompletedDetailedMessage",
    {
      i9Link: i9Link,
      supportLink: supportLink,
    }
  );

  const pendingSection3MessageNoAppointmentScheduled = isAlumni
    ? bundle.formatMessage("Checklist-MainPage-Alumni-PendingSection3NoAppointment", {
        i9Link: i9Link,
        supportLink: supportLink,
      })
    : null;

  const pendingSection3NoActionRequired = isAlumni
    ? bundle.formatMessage("Checklist-MainPage-Alumni-SkipNHE", {
        i9Link: i9Link,
        supportLink: supportLink,
      })
    : null;

  const usCaasStatusToInstructionsMapping: {
    [key in JobType]: { [key in string]: JSX.Element | null };
  } = {
    [NON_CUSTOMER_SERVICE]: {
      [US_CAAS_STATUS.Section1Complete]: (
        <Text data-testid="i9-section-1-thanks-message">{section1CompleteMessage}</Text>
      ),
      [US_CAAS_STATUS.Section2Complete]: <Text data-testid="i9-thanks-message">{MessageThanksForCompletingI9}</Text>,
      [US_CAAS_STATUS.PendingSection3]: (
        <Text data-testid="i9-pending-section-3-message">{pendingSection3Message}</Text>
      ),
      [US_CAAS_STATUS.PendingSection3NoAppointmentScheduled]: (
        <Text>{pendingSection3MessageNoAppointmentScheduled}</Text>
      ),
      [US_CAAS_STATUS.PendingSection3NoActionRequired]: <Text>{pendingSection3NoActionRequired}</Text>,
      [US_CAAS_STATUS.Archived]: <Text data-testid="i9-pending-section-3-message">{pendingSection3Message}</Text>,
      [US_CAAS_STATUS.Section3Complete]: <Text data-testid="i9-thanks-message">{MessageThanksForCompletingI9}</Text>,
      [US_CAAS_STATUS.Error]: <Text>{errorMessage}</Text>,
      [US_CAAS_STATUS.Pending]: null,
      [US_CAAS_STATUS.Completed]: null,
      [US_CAAS_STATUS.WaitingForASHToCreateOrder]: <Text data-testid="i9-form-message">{translatedI9Form}</Text>,
      [US_CAAS_STATUS.PendingSection1]: <Text data-testid="i9-form-message">{translatedI9Form}</Text>,
      [US_CAAS_STATUS.Initiated]: <Text data-testid="i9-form-message">{translatedI9Form}</Text>,
    },
    [CUSTOMER_SERVICE]: {
      [US_CAAS_STATUS.Section2Complete]: <Text data-testid="i9-thanks-message">{MessageThanksForCompletingI9}</Text>,
      [US_CAAS_STATUS.PendingSection1]: (
        <>
          <Row>
            <Text fontSize={"T200"} textAlign="justify">
              {pendingSection1GeneralMessage}
            </Text>
          </Row>
          <Row>
            <Text fontSize={"T200"} textAlign="justify">
              {pendingSection1DetailMessage1}
            </Text>
          </Row>
          <Row>
            <Text fontSize={"T200"} textAlign="justify">
              {pendingSection1DetailMessage2} <strong>{pendingSection1DetailMessage2FirstSplitWord}</strong>{" "}
              {pendingSection1DetailMessage2SecondSplitWord}
            </Text>
          </Row>
          <Row margin="0 0 0 2.5rem">
            <Text fontSize={"T200"}>
              <ul className="instructions-list">
                <li> {pendingSection1DetailMessage3}</li>
                <li> {pendingSection1DetailMessage4}</li>
                <li>
                  {" "}
                  <strong>{pendingSection1DetailMessage5}</strong>
                </li>
              </ul>
            </Text>
          </Row>
        </>
      ),
      [US_CAAS_STATUS.Section1Complete]: (
        <>
          <Row>
            <Text fontSize={"T200"} textAlign="justify">
              <strong>{pendingSection2GeneralMessage}</strong>
            </Text>
          </Row>
          <Row>
            <Text fontSize={"T200"} textAlign="justify">
              {pendingSection2DetailMessage1}
            </Text>
          </Row>
          <Row>
            <Text fontSize={"T200"} textAlign="justify">
              <strong>{pendingSection2USI9NoteKeyword}</strong>: {pendingSection2USI9NoteMessage}
            </Text>
          </Row>
        </>
      ),
      [US_CAAS_STATUS.Archived]: (
        <>
          <Row>
            <Text fontSize={"T200"}>{pendingSection3GeneralMessage}</Text>
          </Row>
        </>
      ),
      [US_CAAS_STATUS.PendingSection3]: (
        <>
          <Row>
            <Text fontSize={"T200"}>{pendingSection3GeneralMessage}</Text>
          </Row>
        </>
      ),
      [US_CAAS_STATUS.Section3Complete]: <Text>{MessageThanksForCompletingI9}</Text>,
      [US_CAAS_STATUS.Error]: <Text>{errorMessage}</Text>,
      [US_CAAS_STATUS.Pending]: null,
      [US_CAAS_STATUS.Completed]: null,
      [US_CAAS_STATUS.Initiated]: <Text>{translatedI9Form}</Text>,
      [US_CAAS_STATUS.WaitingForASHToCreateOrder]: <Text>{translatedI9Form}</Text>,
    },
  };

  return usCaasStatusToInstructionsMapping;
};
