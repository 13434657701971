import React from "react";
import { Col } from "@amzn/stencil-react-components/layout";
import DOMPurify from "dompurify";
import { MessageBanner } from "@amzn/stencil-react-components/message-banner";
import { Banner } from "../../utility/types/common";
import { Text } from "@amzn/stencil-react-components/text";

interface BannerMessageProps {
  banner: Banner;
  onDismissed?: () => void;
}

const BannerMessage = ({ banner, onDismissed }: BannerMessageProps) => {
  const purifiedHtml = DOMPurify.sanitize(banner.text);

  return (
    <Col>
      <MessageBanner
        type={banner.type}
        isDismissible={banner.isDismissible || true}
        aria-live="assertive"
        {...(onDismissed && { onDismissed: onDismissed })}
        {...(banner.dismissTime && { autoDismissAfter: banner.dismissTime })}
      >
        <Text fontSize="T200" dangerouslySetInnerHTML={{ __html: purifiedHtml }}></Text>
      </MessageBanner>
    </Col>
  );
};

export default BannerMessage;
