import { CUSTOMER_SERVICE, NON_CUSTOMER_SERVICE } from "../../../config/appConfigConstants";
import { InstructionMapping } from "../i9-task-card";

export const getINInstructionMapping = () => {
  const inDefaultInstructionMapping: InstructionMapping = {
    [NON_CUSTOMER_SERVICE]: {},
    [CUSTOMER_SERVICE]: {},
  };

  return inDefaultInstructionMapping;
};
