import React, { createContext, Dispatch, useReducer } from "react";
import Reducer from "./reducer";
import { Action, State } from "./state";

const initialState: State = {};

const Store = ({ children }: any) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  return <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>;
};

export const Context = createContext({
  state: initialState,
  dispatch: (() => {}) as Dispatch<Action>,
});
export default Store;
