import React, { ReactNode } from "react";
import { SUPPORTED_COUNTRY } from "../../config/appConfigConstants";
import { Row } from "@amzn/stencil-react-components/layout";
import { Text } from "@amzn/stencil-react-components/text";
import { ChecklistBundle } from "../../hooks/use-arb";

type TextBuilderConfigItem = typeof Text.defaultProps & {
  text: ReactNode;
  key: string;
  prefixText?: string;
};

export type TextBuilderConfigArray = Array<TextBuilderConfigItem>;
export type TextBuilderConfigType = (arb: ChecklistBundle) => Record<SUPPORTED_COUNTRY, TextBuilderConfigArray>;
type DefaultConfigType = (arb: ChecklistBundle) => TextBuilderConfigArray;

interface TextBuilderProps {
  config: Record<SUPPORTED_COUNTRY, TextBuilderConfigArray>;
  country: SUPPORTED_COUNTRY;
}

export const getDefaultValues = (
  defaultVal: TextBuilderConfigArray
): Record<SUPPORTED_COUNTRY, TextBuilderConfigArray> =>
  Object.keys(SUPPORTED_COUNTRY).reduce(
    (acc, key) => ({ ...acc, [key]: defaultVal }),
    {} as Record<SUPPORTED_COUNTRY, TextBuilderConfigArray>
  );

/**
 * A React component that renders a text with Note items, numeral lists and bold texts.
 *
 * @example
 * Some very long main text here;
 * 1. First item;
 * 2. Second item;
 * NOTE: some note text here;
 */

export const TextBuilder = ({ config, country }: TextBuilderProps) => {
  return (
    <>
      {config[country].map((textProps) => {
        const { text, prefixText, key } = textProps;

        return (
          <Row key={key}>
            <Text fontSize={"T200"} {...textProps} className="text-builder">
              {prefixText}
              {text}
            </Text>
          </Row>
        );
      })}
    </>
  );
};
