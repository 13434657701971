import { ContingencyResponse } from "../../components/screening-results/screeningResults";
import { Task } from "../../config/tasks";
import { rtwAppointmentProps } from "../../routes/checklist";
import { GratitudeStateEnum } from "../../hooks/useGratitudeComponentState.hook";
import {
  ApplicationManageData,
  DocumentStatus,
  AtoZStatus,
  SdaInfo,
  JobDetailsModel,
  RightToWorkResponse,
} from "../application-data";
import { CandidateData } from "../candidate-data";
import { ErrorInfo, I9Response } from "../types/common";
import { AllContingencies, AppointmentInfo } from "@amzn/hvh-simple-hire-checklist-internal-api-common";
import { UnsoundEither } from "../generic-types";
import { JobPreviewVideoDetailsResponse } from "../../hooks/apis/useGetJobPreviewVideoData";

// === configSlice enums ===
export enum CONFIG_FEATURE_FLAG {
  IS_ASH_CONFIG_LOADED = "isAshConfigLoaded",
  IS_ELIGIBLE_TO_SHOW_GRATITUDE_COMPONENT = "isEligibleToShowGratitudeComponent",
  IS_CUSTOMER_SERVICE_JOB = "isCustomerServiceJob",
  IS_UK_RTW_NO_CAAS_INTEGRATION = "isUkRtwNoCaasIntegration",
  IS_ELIGIBLE_FOR_CSS = "isEligibleForCss",
  IS_ELIGIBLE_TO_SKIP_APPT_1 = "isEligibleToSkipAppt1",
  IS_ALUMNI = "isAlumni",
  IS_ELIGIBLE_FOR_WITHDRAW = "isEligibleForWithdraw",
  IS_ELIGIBLE_FOR_NHE_APPOINTMENT_RESCHEDULING = "isEligibleForNheAppointmentRescheduling",
  IS_ELIGIBLE_FOR_RTW_APPOINTMENT_RESCHEDULING = "isEligibleForRtwAppointmentRescheduling",
  IS_ELIGIBLE_FOR_DT_APPOINTMENT_RESCHEDULING = "isEligibleForDTAppointmentRescheduling",
  IS_TENTATIVE_START_DATE = "isTentativeStartDate",
  IS_SALESFORCE_APPLICATION = "isSalesforceApplication",
  IS_EDM_LOGIN_ENABLED = "isEdmLoginEnabled",
}

export enum CONFIG_DATA_KEY {
  GRATITUDE_COMPONENT_STATE = "gratitudeComponentState",
  RTW_TASK_DEFINITION = "rtwTaskDefinition",
  CANDIDATE_DATA = "candidateData",
  RTW_APPOINTMENT_INFO = "rtwAppointmentInfo",
  BADGE_PHOTO_URL = "badgePhotoUrl",
  SDA_DATA = "sdaData",
  DRUG_TEST_STATUS = "drugtestStatus",
  CAAS_DRUG_TEST_STATUS = "caasDrugtestStatus",
  CAAS_I9_STATUS = "caasI9Status",
  ONBASE_DATA = "onbaseData",
  CAAS_RTW_STATUS = "caasRTWStatus",
  ALL_CONTINGENCIES_STATUS = "allContingenciesStatus",
  MEDICAL_CHECK_STATUS = "medicalCheckStatus",
  BACKGROUND_CHECK_STATUS = "bgcStatus",
  AIR_BACKGROUND_CHECK_STATUS = "airBgcStatus",
  APPLICATION_MANAGE_DATA = "applicationManageData",
  JOB_DETAILS = "jobDetails",
  JOB_PREVIEW_VIDEO_DETAILS = "jobPreviewVideoDetails",
  RTW_DETAILS = "rtwResponseDetails",
  RTW_APPOINTMENT_DETAILS = "rtwAppointmentDetails",
  NHE_APPOINTMENT_DETAILS = "nheAppointmentDetails",
  DT_APPOINTMENT_DETAILS = "dtAppointmentDetails",
  ATOZ_CANDIDATE_DATA = "candidateAtoZ",
  ATOZ_CANDIDATE_DATA_WITH_PASSWORD = "candidateAtoZWithPassword",
  CSRF_TOKEN = "csrfToken",
  APP_ERROR = "appError",
}

export type ExtendAppointmentInfo =
  | UnsoundEither<AppointmentInfo, { errorStatusCode: number; errorCode: string }>
  | undefined;

export type CONFIG_DATA_TYPES = {
  [CONFIG_DATA_KEY.GRATITUDE_COMPONENT_STATE]: GratitudeStateEnum;
  [CONFIG_DATA_KEY.RTW_TASK_DEFINITION]: any;
  [CONFIG_DATA_KEY.CANDIDATE_DATA]: CandidateData;
  [CONFIG_DATA_KEY.JOB_DETAILS]: JobDetailsModel;
  [CONFIG_DATA_KEY.APPLICATION_MANAGE_DATA]: ApplicationManageData;
  [CONFIG_DATA_KEY.DRUG_TEST_STATUS]: ContingencyResponse | "SKIPPED";
  [CONFIG_DATA_KEY.MEDICAL_CHECK_STATUS]: ContingencyResponse | "SKIPPED";
  [CONFIG_DATA_KEY.BACKGROUND_CHECK_STATUS]: ContingencyResponse;
  [CONFIG_DATA_KEY.JOB_PREVIEW_VIDEO_DETAILS]: JobPreviewVideoDetailsResponse;
  [CONFIG_DATA_KEY.AIR_BACKGROUND_CHECK_STATUS]: ContingencyResponse;
  [CONFIG_DATA_KEY.CAAS_DRUG_TEST_STATUS]: ContingencyResponse;
  [CONFIG_DATA_KEY.ALL_CONTINGENCIES_STATUS]: AllContingencies | "SKIPPED";
  [CONFIG_DATA_KEY.CAAS_I9_STATUS]: I9Response | "SKIPPED";
  [CONFIG_DATA_KEY.CAAS_RTW_STATUS]: I9Response | "SKIPPED";
  [CONFIG_DATA_KEY.RTW_DETAILS]: RightToWorkResponse;
  [CONFIG_DATA_KEY.RTW_APPOINTMENT_DETAILS]: UnsoundEither<AppointmentInfo, { errorStatusCode: number }> | undefined;
  [CONFIG_DATA_KEY.NHE_APPOINTMENT_DETAILS]: UnsoundEither<AppointmentInfo, { errorStatusCode: number }> | undefined;
  [CONFIG_DATA_KEY.DT_APPOINTMENT_DETAILS]: ExtendAppointmentInfo;
  [CONFIG_DATA_KEY.ONBASE_DATA]: DocumentStatus;
  [CONFIG_DATA_KEY.RTW_APPOINTMENT_INFO]: rtwAppointmentProps | undefined;
  [CONFIG_DATA_KEY.SDA_DATA]: SdaInfo;
  [CONFIG_DATA_KEY.ATOZ_CANDIDATE_DATA]: AtoZStatus | "SKIPPED";
  [CONFIG_DATA_KEY.ATOZ_CANDIDATE_DATA_WITH_PASSWORD]: AtoZStatus | "SKIPPED";
  [CONFIG_DATA_KEY.BADGE_PHOTO_URL]: string;
  [CONFIG_DATA_KEY.CSRF_TOKEN]: string;
  [CONFIG_DATA_KEY.APP_ERROR]: ErrorInfo | undefined;
};

/**
 * This enum was dead code, but it seems like useful documentation for what file
 * types were supported by EDM so leaving it as a comment
 */

// export enum DOC_VALID_FORMAT {
//   JPEG = "JPEG",
//   JPG = "JPG",
//   PDF = "PDF",
//   PNG = "PNG",
// }

export enum ChecklistTasksKey {
  NEW_HIRE_VIDEO = "NEW_HIRE_VIDEO",
  COMPLETE_EMPLOYMENT_FORM = "COMPLETE_EMPLOYMENT_FORM",
  START_TAKE_BADGE_PHOTO = "START_TAKE_BADGE_PHOTO",
  ORDER_YOUR_SAFETY_SHOES = "ORDER_YOUR_SAFETY_SHOES",
  COMPLETE_EMPLOYMENT_PAPERWORK = "COMPLETE_EMPLOYMENT_PAPERWORK",
  CREATE_EMPLOYEE_A_TO_Z_ACCOUNT = "CREATE_EMPLOYEE_A_TO_Z_ACCOUNT",
  PROVIDE_BACKGROUND_CHECK_INFORMATION = "PROVIDE_BACKGROUND_CHECK_INFORMATION",
  NEW_UI_DOCUMENT_UPLOAD = "NEW_UI_DOCUMENT_UPLOAD",
  SCHEDULE_RIGHT_TO_WORK = "SCHEDULE_RIGHT_TO_WORK",
  JOB_PREVIEW_VIDEO = "JOB_PREVIEW_VIDEO",
}

export type ChecklistTasks = Record<ChecklistTasksKey, Task>;

export type ChecklistTasksAnalytic = Partial<Record<ChecklistTasksKey, string>>;

export type ChecklistTasksStatusInfo = Partial<Record<ChecklistTasksKey, string>>;

export enum PayInterval {
  HOURLY = "HOURLY",
  BIWEEKLY = "BIWEEKLY",
  MONTHLY = "MONTHLY",
  YEARLY = "YEARLY",
}

/**
 * Current statuses for default tasks
 */
export enum StatusEnum {
  HIDDEN = "Hidden",
  LOADING = "Loading",
  NOT_STARTED = "Not Started",
  IN_PROGRESS = "In Progress",
  COMPLETED = "Completed",
  API_ERROR = "N/A",
  ERROR = "Error",
  PENDING_REVIEW = "Pending Review By Amazon",
  ELIGIBLE = "Eligible",
  INELIGIBLE = "Ineligible",
  INITIATED = "Initiated",
  NO_ACTION_REQUIRED = "No Action Required",
  NOT_REQUIRED = "Not Required",
  NOT_AVAILABLE_YET = "Not Available Yet",
  ACTION_REQUIRED = "Action Required",
  PENDING = "Pending",
  REJECTED = "Rejected",
  AWAITING_CANDIDATE_INFO = "Awaiting Candidate Info",
  NHE_UNKNOWN = "UNKNOWN",
  NHE_RESERVED = "RESERVED",
  NHE_COMPLETED = "COMPLETED",
  SKIPPED = "SKIPPED",
}

/**
 * Drug test retake status
 */

export enum DrugTestRetakeStatus {
  NO_CURRENT_DT_RETAKE_APPOINTMENT = "No Drug Test Retake Appointment",
  SHOW_DT_RETAKE_APPOINTMENT = "Show Drug Test Retake Appointment",
  DT_RETAKE_APPOINTMENT_DETAILS = "Drug Test Retake Appointment Details",
}
