/* eslint-disable import/no-unused-modules */
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { isSalesforceApplication } from "../../helpers/url-params";
import { useLazyGetJobPreviewVideoDetailsQuery } from "../../reduxStore/api/apiSlice";
import { useAppSelector } from "../../reduxStore/reduxHooks";
import { getConfigData, setConfigData } from "../../reduxStore/slices/configSlice";
import { CONFIG_DATA_KEY } from "../../utility/enums/common";
import { useGetApplicationId } from "./useGetApplicationId";

export interface JobPreviewVideoDetailsResponse {
  status?: string;
}

export const useGetJobPreviewVideoDetails = () => {
  const { jobPreviewVideoDetails, jobDetails } = useAppSelector(getConfigData);
  const dispatch = useDispatch();
  const applicationId = useGetApplicationId();
  const [getJobPreviewVideoDetails] = useLazyGetJobPreviewVideoDetailsQuery();

  const jobPreviewVideoURL = `/api/taskstatus/${applicationId}/taskType/${encodeURIComponent(
    `JOB_PREVIEW_VIDEO#${jobDetails?.siteJobPreviewVideoLink}`
  )}`;

  useEffect(() => {
    if (jobPreviewVideoDetails) return;

    if (applicationId && !isSalesforceApplication(applicationId) && jobDetails) {
      getJobPreviewVideoDetails(
        { applicationId, taskType: encodeURIComponent(`JOB_PREVIEW_VIDEO#${jobDetails?.siteJobPreviewVideoLink}`) },
        true
      )
        .unwrap()
        .then((data) => {
          dispatch(
            setConfigData({
              key: CONFIG_DATA_KEY.JOB_PREVIEW_VIDEO_DETAILS,
              value: data,
            })
          );
        });
    }
  }, [applicationId, dispatch, getJobPreviewVideoDetails, jobDetails]);

  return { jobPreviewVideoDetails, jobPreviewVideoURL };
};
