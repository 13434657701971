import { Row } from "@amzn/stencil-react-components/layout";
import { Status, StatusIndicator } from "@amzn/stencil-react-components/status-indicator";
import React from "react";
import { SUPPORTED_COUNTRY } from "../../config/appConfigConstants";
import { HVH_COLORS } from "../../config/palette";
import {
  COMPLETE_RIGHT_TO_WORK,
  EMPLOYMENT_PAPERWORK,
  I_9_DOCUMENT,
  NEW_HIRE_VIDEO,
  ORDER_YOUR_SAFETY_SHOE,
} from "../../config/taskConfigConstants";
import { Task } from "../../config/tasks";
import { isCountryWithoutWorkAuthStatusIndicator } from "../../helpers/utils";
import { useChecklistArb } from "../../hooks/use-arb";
import { isCustomerService } from "../../utility/app-config-helper";
import { StatusEnum } from "../../utility/enums/common";
import { getCountryForDomain } from "../../utility/locale-helper";
import { NHEAppointmentStatusEnum } from "../appointment-card/card-controlled";

interface DueDateProps {
  applicationId: string;
  status?: StatusEnum | NHEAppointmentStatusEnum;
  dueDate?: Date;
  task?: Task;
  testIdText: string;
}

export const DueDateIndicator = ({ status, dueDate, testIdText, task, applicationId }: DueDateProps) => {
  let text: string;
  let color: string;
  let icon: Status;
  let testIdStatusText: string;
  const bundle = useChecklistArb();
  const completed = bundle.getMessage("checklist-General-TaskStatus-Completed");
  const unavailable = bundle.getMessage("checklist-General-TaskStatus-Unavailable");
  const overdue = bundle.getMessage("checklist-General-TaskStatus-Overdue");
  const dueSoon = bundle.getMessage("checklist-General-TaskStatus-DueSoon");
  const translatedDueDate = bundle.formatMessage("checklist-General-DueDate", {
    date: dueDate,
  });

  const displayOnlyDueSoonStatus: string[] = [EMPLOYMENT_PAPERWORK, I_9_DOCUMENT, NEW_HIRE_VIDEO];

  switch (status) {
    // can't show Rejected on the screen for legal reason. Instead, we should show Completed in the flyout with green banner
    // https://issues.amazon.com/issues/Kondo_QA_Issue-653
    case StatusEnum.REJECTED:
    case NHEAppointmentStatusEnum.COMPLETED:
    case StatusEnum.NO_ACTION_REQUIRED:
    case StatusEnum.COMPLETED:
      text = completed;
      testIdStatusText = text;
      color = HVH_COLORS.GREEN_10;
      icon = Status.Positive;
      break;
    case StatusEnum.INELIGIBLE:
    case StatusEnum.NOT_AVAILABLE_YET:
      text = unavailable;
      testIdStatusText = text;
      color = HVH_COLORS.NEUTRAL_10;
      icon = Status.Unavailable;
      break;
    default:
      if (dueDate) {
        text = dueSoon + " · " + translatedDueDate;
        testIdStatusText = dueSoon;
      } else {
        //https://issues.amazon.com/issues/HVH-PLAN-10568
        //https://issues.amazon.com/issues/HVH-PLAN-11549
        if (isCustomerService(applicationId) && displayOnlyDueSoonStatus.includes(task?.taskName as string)) {
          text = dueSoon;
          testIdStatusText = text;
        } else {
          text = overdue;
          testIdStatusText = text;
        }
      }
      color = HVH_COLORS.YELLOW_10;
      icon = Status.Warning;
      break;
  }

  return resolveStatusIndicator({ applicationId, icon, color, text, testIdStatusText, testIdText, status, task });
};

type resolveStatusIndicatorProps = {
  applicationId: string;
  icon: Status;
  color: string;
  text: string;
  testIdText?: string;
  testIdStatusText?: string;
  status?: StatusEnum | NHEAppointmentStatusEnum;
  task?: Task;
};

export const resolveStatusIndicator = ({
  applicationId,
  icon,
  color,
  text,
  testIdText,
  testIdStatusText,
  status,
  task,
}: resolveStatusIndicatorProps) => {
  const country = getCountryForDomain();

  if (!isCustomerService(applicationId)) {
    if (
      task &&
      task.taskName === ORDER_YOUR_SAFETY_SHOE &&
      status &&
      (status === StatusEnum.COMPLETED || status === StatusEnum.SKIPPED)
    ) {
      return <></>;
    }

    if (country == SUPPORTED_COUNTRY.JP && task && task.taskName === I_9_DOCUMENT) {
      return <></>;
    }
  } else {
    if (task && task.titleText === COMPLETE_RIGHT_TO_WORK) {
      return <></>;
    }

    if (isCountryWithoutWorkAuthStatusIndicator(country) && task && task.taskName === I_9_DOCUMENT) {
      return <></>;
    }
  }

  return (
    <Row backgroundColor={color} padding="0.25rem" data-testid={`${testIdText}-${testIdStatusText}`}>
      <StatusIndicator status={icon} messageText={text} />
    </Row>
  );
};
