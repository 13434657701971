/* eslint-disable import/no-unused-modules */
import { useEffect } from "react";
import { useLazyGetDtStatusQuery, useLazyGetDtStatusWithSfCandidateIdQuery } from "../../reduxStore/api/apiSlice";
import { useGetApplicationId } from "./useGetApplicationId";
import { isSalesforceApplication } from "../../helpers/url-params";
import { getCountryForDomain } from "../../utility/locale-helper";
import { useAppSelector } from "../../reduxStore/reduxHooks";
import { getConfigData, getFeatureFlag, setConfigData } from "../../reduxStore/slices/configSlice";
import { useDispatch } from "react-redux";
import { CONFIG_DATA_KEY } from "../../utility/enums/common";
import { useGetCandidateData } from "./useGetCandidateData";
import { DRUGTEST_ALLOWED_COUNTRY, FeatFlag } from "../../config/appConfigConstants";
import { isDevEnv } from "../../utility/dev-env-helpers";
import { useGetJobDetails } from "./useGetJobDetails";
import { isCustomerService, isTaskEnabledByAppConfig } from "../../utility/app-config-helper";
import { useGetAllContigenciesData } from "./useGetAllContigenciesData";
import { isAirJob } from "../../helpers/utils";

export const useGetDrugTestStatus = () => {
  const { drugtestStatus } = useAppSelector(getConfigData);

  // If we consuming the flag from localStorage, make sure to check it is fully loaded first, otherwise it will throw it as undefined.
  const { isAshConfigLoaded } = useAppSelector(getFeatureFlag);
  const dispatch = useDispatch();
  const allContigencies = useGetAllContigenciesData();
  const scheduleDetails = useGetJobDetails();
  const applicationId = useGetApplicationId();
  const candidateData = useGetCandidateData();
  const [getDrugTestStatus] = useLazyGetDtStatusQuery();
  const [getDrugTestStatusWithSFCandidateId] = useLazyGetDtStatusWithSfCandidateIdQuery();

  const skipCallForDrugTest =
    !(getCountryForDomain() in DRUGTEST_ALLOWED_COUNTRY) ||
    !scheduleDetails?.businessLine ||
    isCustomerService(applicationId);

  const isEligibleForGetDrugTestFromAllContigencies =
    isTaskEnabledByAppConfig(FeatFlag.ENABLE_ALL_CONTINGENCIES_DT, applicationId) &&
    !isSalesforceApplication(applicationId) &&
    !isCustomerService(applicationId);
  useEffect(() => {
    if (scheduleDetails && isAshConfigLoaded) {
      if (!skipCallForDrugTest) {
        getDrugTestStatus(
          {
            candidateGlobalId: candidateData?.candidateGlobalId,
            businessLine: scheduleDetails?.businessLine,
          },
          true
        )
          .unwrap()
          .then((data) => {
            if (
              isEligibleForGetDrugTestFromAllContigencies &&
              allContigencies &&
              allContigencies !== "SKIPPED" &&
              scheduleDetails?.businessLine &&
              getCountryForDomain() in DRUGTEST_ALLOWED_COUNTRY
            ) {
              dispatch(
                setConfigData({
                  key: CONFIG_DATA_KEY.DRUG_TEST_STATUS,
                  value: isAirJob(scheduleDetails?.businessLine)
                    ? allContigencies.AirDrugTest
                    : allContigencies.DrugTest,
                })
              );
            } else {
              if (drugtestStatus) return;
              dispatch(
                setConfigData({
                  key: CONFIG_DATA_KEY.DRUG_TEST_STATUS,
                  value: data,
                })
              );
            }
          });
      } else {
        if (isDevEnv()) console.warn("Skipping useGetDrugTestStatus hook");
        dispatch(
          setConfigData({
            key: CONFIG_DATA_KEY.DRUG_TEST_STATUS,
            value: "SKIPPED",
          })
        );
      }
    }
  }, [
    isAshConfigLoaded,
    applicationId,
    candidateData?.candidateGlobalId,
    candidateData?.sfCandidateId,
    dispatch,
    drugtestStatus,
    getDrugTestStatus,
    getDrugTestStatusWithSFCandidateId,
    isEligibleForGetDrugTestFromAllContigencies,
    scheduleDetails,
    scheduleDetails?.businessLine,
    skipCallForDrugTest,
    allContigencies,
  ]);

  return drugtestStatus;
};
