import { useParams } from "react-router-dom";
import {
  BB_BGC_INFORMATION_COLLECTION_URL,
  buildUrl,
  CSS_DT_RESCHEDULE_URL,
  CSS_DT_RETAKE_SCHEDULE_URL,
  CSS_MY_JOBS_URL,
  CSS_NHE_PAGE_URL,
  CSS_PAGE_URL,
  SCHEDULE_RIGHT_TO_WORK_APPOINTMENT_URL,
  TAKE_BADGE_PHOTO_START_URL,
  VIDEO_RECORD_API_URL,
  VIDEO_RECORD_NON_ASH_API_URL,
} from "../config/urls";
import { useUrlParams } from "./use-url-params";

type UseUrlBuilderParamsType = () => {
  cssURLSchedule: string;
  appointmentURLService: string;
  rtwAppointmentSelfServiceURL: string;
  DTAppointmentSelfServiceURL: string;
  DTRetakeAppointmentServiceURL: string;
  dashboardURL: string;
  bgcInfoCollectionURL: string;
  videoRecordQueryUrl: string;
  badgePhotoStartURL: string;
  connectVirtualNhe: string;
};

enum ReferrerMode {
  Ash = "ash",
  NonAsh = "non-ash",
}

const URLS = {
  [ReferrerMode.Ash]: VIDEO_RECORD_API_URL,
  [ReferrerMode.NonAsh]: VIDEO_RECORD_NON_ASH_API_URL,
};

export const useUrlBuilder: UseUrlBuilderParamsType = () => {
  const { applicationId, requisitionId } = useParams<{ applicationId: string; requisitionId: string }>();

  const { referrerMode, candidateId } = useUrlParams();

  const cssURLSchedule = buildUrl(CSS_PAGE_URL, {
    hostName: window.location.origin,
    requisitionId,
    applicationId,
  });

  const appointmentURLService = buildUrl(CSS_NHE_PAGE_URL, {
    hostName: window.location.origin,
    requisitionId,
    applicationId,
  });

  const rtwAppointmentSelfServiceURL = buildUrl(SCHEDULE_RIGHT_TO_WORK_APPOINTMENT_URL, {
    hostName: window.location.origin,
    applicationId,
    requisitionId,
  });

  const DTAppointmentSelfServiceURL = buildUrl(CSS_DT_RESCHEDULE_URL, {
    hostName: window.location.origin,
    applicationId,
    requisitionId,
  });

  const DTRetakeAppointmentServiceURL = buildUrl(CSS_DT_RETAKE_SCHEDULE_URL, {
    hostName: window.location.origin,
    applicationId,
    requisitionId,
  });

  const dashboardURL = buildUrl(CSS_MY_JOBS_URL, {
    hostName: window.location.origin,
  });

  const bgcInfoCollectionURL = buildUrl(BB_BGC_INFORMATION_COLLECTION_URL, {
    hostName: window.location.origin,
    applicationId,
    requisitionId,
  });

  const videoRecordQueryUrl = buildUrl(URLS[referrerMode], {
    requisitionId,
    applicationId,
    candidateId,
  });

  const badgePhotoStartURL = buildUrl(TAKE_BADGE_PHOTO_START_URL, { requisitionId, applicationId });

  const connectVirtualNhe = `${window.location.origin}/appointments/${requisitionId}/${applicationId}/NEW_HIRE_EVENT`;

  return {
    cssURLSchedule,
    appointmentURLService,
    rtwAppointmentSelfServiceURL,
    DTAppointmentSelfServiceURL,
    DTRetakeAppointmentServiceURL,
    dashboardURL,
    bgcInfoCollectionURL,
    videoRecordQueryUrl,
    badgePhotoStartURL,
    connectVirtualNhe,
  };
};
