import React from "react";
import { Col, Hr } from "@amzn/stencil-react-components/layout";
import { AttachmentType, AttachmentSubType } from "../models";
import { Text } from "../../../../components/typography";
import { useChecklistArb } from "../../../../hooks/use-arb";
import { DocumentUploadComplete } from "./document-upload-complete";
import { DocumentUploadNeeded } from "./document-upload-needed";
import { StatusEnum } from "../../../../utility/enums/common";

interface DocumentsCompletedParams {
  sides: string[];
  document: AttachmentType;
  status: StatusEnum;
}

interface DocConfig {
  documentName: string;
  documentMessage: string;
  backOptional?: boolean;
}

export const DocumentCompletedUploadFrontBack = ({ sides, document, status }: DocumentsCompletedParams) => {
  const bundle = useChecklistArb();
  const documentFrontTitle = bundle.getMessage("Checklist-Document-Front-Title");
  const documentBackTitle = bundle.getMessage("Checklist-Document-Back-Title");
  const curpIdTitleMessage = bundle.getMessage("Checklist-MainPage-CURP-ID-Title");
  const curpIdUploadMessage = bundle.getMessage("Checklist-Mainpage-CURP-ID-Upload-ButtonText");
  const voterCardTitleMessage = bundle.getMessage("Checklist-Document-Voter-Card");
  const voterCardUploadMessage = bundle.getMessage("Checklist-Document-Voter-Card-Title");
  const passportTitle = bundle.getMessage("Checklist-Document-Mexican-Passport");
  const passportUploadTitle = bundle.getMessage("Checklist-Document-Mexican-Passport-Title");
  const consularIDCardTitle = bundle.getMessage("Checklist-Document-Consular-Identification-Card");
  const consularIDCardUploadTitle = bundle.getMessage("Checklist-Document-Consular-Identification-Card-Title");
  const militaryServiceCardTitle = bundle.getMessage("Checklist-Document-Military-Service-Card");
  const militaryServiceCardUploadTitle = bundle.getMessage("Checklist-Document-Military-Service-Card-Title");
  const mexicanBirthCertificateTitle = bundle.getMessage("Checklist-Document-Mexican-Birth-Certificate");
  const mexicanBirthCertificateUploadTitle = bundle.getMessage("Checklist-Document-Mexican-Birth-Certificate-Title");
  const temporaryResidenceCardTitle = bundle.getMessage("Checklist-Document-Temporary-Residence-Card");
  const temporaryResidenceCardUploadTitle = bundle.getMessage("Checklist-Document-Temporary-Residence-Card-Title");
  const temporaryResidenceStudentCardTitle = bundle.getMessage("Checklist-Document-Temporary-Resident-Student-Card");
  const temporaryResidenceStudentCardUploadTitle = bundle.getMessage(
    "Checklist-Document-Temporary-Resident-Student-Card-Title"
  );
  const otherTitle = bundle.getMessage("Checklist-Document-Other");
  const otherUploadTitle = bundle.getMessage("Checklist-Document-Other-Title");

  const inmWorkAuthorizationTitle = bundle.getMessage("Checklist-Document-INM-Work-Authorization");
  const inmWorkAuthorizationUploadTitle = bundle.getMessage("Checklist-Document-INM-Work-Authorization-Title");
  const permanentResidenceCardTitle = bundle.getMessage("Checklist-Document-Permanent-Residence-Card");
  const permanentResidenceCardUploadTitle = bundle.getMessage("Checklist-Document-Permanent-Residence-Card-Title");
  const naturalizationCertificateTitle = bundle.getMessage("Checklist-Document-Naturalization-Certificate");
  const naturalizationCertificateUploadTitle = bundle.getMessage("Checklist-Document-Naturalization-Certificate-Title");
  const temporaryConsularResidenceVisaTitle = bundle.getMessage("Checklist-Document-Temporary-Consular-Residence-Visa");
  const temporaryConsularResidenceVisaUploadTitle = bundle.getMessage(
    "Checklist-Document-Temporary-Consular-Residence-Visa-Title"
  );
  const inmTitle = bundle.getMessage("Checklist-Document-INM");
  const inmUploadTitle = bundle.getMessage("Checklist-Document-INM-Title");
  const fmmTitle = bundle.getMessage("Checklist-Document-FMM");
  const fmmUploadTitle = bundle.getMessage("Checklist-Document-FMM-Title");

  const documentConfig: Record<string, DocConfig> = {
    [AttachmentType.CURP]: {
      documentName: curpIdTitleMessage,
      documentMessage: curpIdUploadMessage,
      backOptional: true,
    },
    [AttachmentType.VOTER_CARD]: {
      documentName: voterCardTitleMessage,
      documentMessage: voterCardUploadMessage,
    },
    [AttachmentType.MEXICAN_PASSPORT]: {
      documentName: passportTitle,
      documentMessage: passportUploadTitle,
      backOptional: true,
    },
    [AttachmentType.CONSULAR_IDENTIFICATION_CARD]: {
      documentName: consularIDCardTitle,
      documentMessage: consularIDCardUploadTitle,
    },
    [AttachmentType.MILITARY_SERVICE_CARD]: {
      documentName: militaryServiceCardTitle,
      documentMessage: militaryServiceCardUploadTitle,
      backOptional: true,
    },
    [AttachmentType.MEXICAN_BIRTH_CERTIFICATE]: {
      documentName: mexicanBirthCertificateTitle,
      documentMessage: mexicanBirthCertificateUploadTitle,
      backOptional: true,
    },
    [AttachmentType.TEMPORARY_RESIDENCE_CARD]: {
      documentName: temporaryResidenceCardTitle,
      documentMessage: temporaryResidenceCardUploadTitle,
    },
    [AttachmentType.TEMPORARY_RESIDENCE_STUDENT_CARD]: {
      documentName: temporaryResidenceStudentCardTitle,
      documentMessage: temporaryResidenceStudentCardUploadTitle,
    },
    [AttachmentType.INM_WORK_AUTHORIZATION]: {
      documentName: inmWorkAuthorizationTitle,
      documentMessage: inmWorkAuthorizationUploadTitle,
      backOptional: true,
    },
    [AttachmentType.PERMANENT_RESIDENCE_CARD]: {
      documentName: permanentResidenceCardTitle,
      documentMessage: permanentResidenceCardUploadTitle,
    },
    [AttachmentType.NATURALIZATION_CERTIFICATE]: {
      documentName: naturalizationCertificateTitle,
      documentMessage: naturalizationCertificateUploadTitle,
      backOptional: true,
    },
    [AttachmentType.TEMPORARY_CONSULAR_RESIDENCE_VISA]: {
      documentName: temporaryConsularResidenceVisaTitle,
      documentMessage: temporaryConsularResidenceVisaUploadTitle,
      backOptional: true,
    },
    [AttachmentType.INM]: {
      documentName: inmTitle,
      documentMessage: inmUploadTitle,
      backOptional: true,
    },
    [AttachmentType.FMM]: {
      documentName: fmmTitle,
      documentMessage: fmmUploadTitle,
      backOptional: true,
    },
    [AttachmentType.OTHER]: {
      documentName: otherTitle,
      documentMessage: otherUploadTitle,
      backOptional: true,
    },
  };

  const currentDocumentData = documentConfig[document];
  const front =
    sides.indexOf(AttachmentSubType.FRONT) > -1
      ? DocumentUploadComplete({ documentType: document, status: status })
      : DocumentUploadNeeded({
          documentType: document,
          documentMessage: currentDocumentData.documentMessage,
          status: status,
        });

  const back =
    sides.indexOf(AttachmentSubType.BACK) > -1
      ? DocumentUploadComplete({ documentType: document, status: status })
      : DocumentUploadNeeded({
          documentType: document,
          documentMessage: currentDocumentData.documentMessage,
          optional: currentDocumentData.backOptional,
          status: status,
        });

  return (
    <Col gridGap={16}>
      <Text>{`${currentDocumentData.documentName} - ${documentFrontTitle} `}</Text>
      {front}
      <Hr />
      <Text>{`${currentDocumentData.documentName} - ${documentBackTitle} `}</Text>
      {back}
      <Hr />
    </Col>
  );
};
