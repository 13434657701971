import React from "react";
import { Button, ButtonProps } from "@amzn/stencil-react-components/button";
import debounce from "lodash/debounce";

const DEFAULT_DEBOUNCE_TIME = 500;

interface DebounceButtonProps extends ButtonProps {
  debounceTime?: number; //time in milliseconds
}

export const DebounceButton = (props: DebounceButtonProps) => {
  const { debounceTime, onClick, ...restButtonProps } = props;

  const handleOnClick = debounce((event) => {
    if (onClick) onClick(event);
  }, debounceTime || DEFAULT_DEBOUNCE_TIME);

  return onClick ? (
    <Button data-testId="debounce-button" {...restButtonProps} onClick={(evt) => handleOnClick(evt)} />
  ) : (
    <Button data-testId="debounce-button" {...restButtonProps} />
  );
};
