import { useDispatch } from "react-redux";
import { useGetApplicationId } from "./useGetApplicationId";
import { useGetApplicationManageData } from "./useGetApplicationManageData";
import { useGetAllContigenciesData } from "./useGetAllContigenciesData";
import { useGetJobDetails } from "./useGetJobDetails";
import {
  useLazyGetAppointmentV2Query,
  useLazyGetCassValidCandidateContingenciesStatusQuery,
} from "../../reduxStore/api/apiSlice";
import { useEffect, useState } from "react";
import { isSalesforceApplication } from "../../helpers/url-params";
import { getLocale } from "../../utility/locale-helper";
import {
  AppointmentInfo,
  NHEAppointmentApplicationTypeEnum,
} from "@amzn/hvh-simple-hire-checklist-internal-api-common";
import { DrugTestRetakeStatus } from "../../utility/enums/common";
import { isEligibleForDrugTestRetakeAppointment } from "../../helpers/configHelper";
import { dispatchOnDev } from "../../utility/dev-env-helpers";
import { isAirJob } from "../../helpers/utils";
import moment from "moment";

function checkNoAppointmentOrNotInInPast(dtDetails: AppointmentInfo) {
  const now = moment();
  return !!(!dtDetails?.startTimestamp || !moment(dtDetails.startTimestamp).isBefore(now, "day"));
}

export const useGetDTRetakeAppointment = () => {
  const dispatch = useDispatch();
  const applicationId = useGetApplicationId();
  const applicationManageData = useGetApplicationManageData();
  const allContigencies = useGetAllContigenciesData();
  const scheduleDetails = useGetJobDetails();
  const [getNHEAppointment] = useLazyGetAppointmentV2Query();
  const [getValidCandidateContingencies] = useLazyGetCassValidCandidateContingenciesStatusQuery();
  const [drugTestRetakeScheduleStatus, setDrugTestRetakeScheduleStatus] = useState(
    DrugTestRetakeStatus.NO_CURRENT_DT_RETAKE_APPOINTMENT
  );
  const [dtRetakeAppointmentDetails, setDtRetakeAppointmenDetails] = useState<AppointmentInfo | null>(null);
  const [isEligibleForDTRetake, setIsEligibleForDTRetake] = useState(false);

  useEffect(() => {
    if (applicationId && !isSalesforceApplication(applicationId) && applicationManageData) {
      let drugTestContingencyStatus;
      if (allContigencies && allContigencies !== "SKIPPED" && scheduleDetails?.businessLine) {
        drugTestContingencyStatus = isAirJob(scheduleDetails?.businessLine)
          ? allContigencies.AirDrugTest
          : allContigencies.DrugTest;
      }
      if (drugTestContingencyStatus && drugTestContingencyStatus.status !== "Not Started") {
        return;
      }

      getNHEAppointment(
        { applicationId, locale: getLocale(), applicationType: NHEAppointmentApplicationTypeEnum.DRUG_TEST_RETAKE },
        true
      )
        .unwrap()
        .then(async (data) => {
          if (data.status === "RESERVED") {
            if (!checkNoAppointmentOrNotInInPast(data)) {
              return;
            }

            setDrugTestRetakeScheduleStatus(DrugTestRetakeStatus.DT_RETAKE_APPOINTMENT_DETAILS);
            setDtRetakeAppointmenDetails(data);
            setIsEligibleForDTRetake(
              isEligibleForDrugTestRetakeAppointment(
                data,
                applicationManageData,
                isSalesforceApplication(applicationId)
              )
            );
          }

          if (data.errorStatusCode === 404 && data.errorCode === "NEED_TO_SCHEDULE") {
            if (
              applicationManageData?.applicationState !== "CONTINGENCIES_PENDING" ||
              applicationManageData?.scheduleId === null
            ) {
              return;
            }
            getValidCandidateContingencies({ applicationId: applicationId }, true)
              .unwrap()
              .then((contintgencyResponse) => {
                if (contintgencyResponse == null) {
                  return;
                }

                const drugTestContingency = contintgencyResponse.contingencyStatusList.find(
                  (contingency) => contingency.contingencyType === "DrugTest"
                );
                if (drugTestContingency?.valid === false) {
                  setDrugTestRetakeScheduleStatus(DrugTestRetakeStatus.SHOW_DT_RETAKE_APPOINTMENT);
                }
              })
              .catch((error) => {
                dispatchOnDev(() => {
                  console.error("Error calling Caas Api", error);
                });
              });
          }
        });
    }
  }, [applicationId, dispatch, getNHEAppointment, applicationManageData]);

  return { drugTestRetakeScheduleStatus, dtRetakeAppointmentDetails, isEligibleForDTRetake };
};
