import React from "react";
import { Link } from "@amzn/stencil-react-components/link";
import { useChecklistArb } from "../../hooks/use-arb";
import { ChecklistPantherKey } from "../../utility/types/translations";

interface LinkWithRedirectProps {
  link: string;
  messageKey: ChecklistPantherKey;
  messagePlaceholder?: string;
}

const LinkWithRedirect = ({ link, messageKey, messagePlaceholder }: LinkWithRedirectProps) => {
  const bundle = useChecklistArb();

  return (
    <Link target="_blank" href={link} data-testid="general-first-day-header-reschedule-button">
      {bundle.getMessage(messageKey, messagePlaceholder)}
    </Link>
  );
};

export default LinkWithRedirect;
