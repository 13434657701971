import { CUSTOMER_SERVICE, JobType, NON_CUSTOMER_SERVICE } from "../../../../config/appConfigConstants";
import { ChecklistBundle } from "../../../../hooks/use-arb";

export const getJPChecklistMappingCAAS = ({ bundle }: { bundle: ChecklistBundle }) => {
  const jpCaasStatusToChecklistMapping: {
    [key in JobType]: { [key in string]: JSX.Element | null };
  } = {
    [NON_CUSTOMER_SERVICE]: {},
    [CUSTOMER_SERVICE]: {},
  };

  return jpCaasStatusToChecklistMapping;
};
