import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Text, H2 } from "../../../../../components/typography";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { UploadCard } from "../upload-card";
import { useChecklistArb } from "../../../../../hooks/use-arb";
import { AttachmentType, UploadDetails, AttachmentSubType } from "../../models";
import { submitDocument } from "../../requests/requests";
import { deleteCachedRequest } from "../../../../../utility/candidate-data";
import { MessageBanner, MessageBannerType } from "@amzn/stencil-react-components/message-banner";
import {
  useGetCandidateDataQuery,
  useGetPresignedURLToCDSMutation,
  useSubmitDocToCDSFromPresignedURLMutation,
  useUpdateCDSDocumentUploadMetadataMutation,
} from "../../../../../reduxStore/api/apiSlice";
import { dispatchOnDev, isDevEnv } from "../../../../../utility/dev-env-helpers";
import { useBackToMainPage } from "../../helpers/useBackToMainPage.hook";
import { useDispatch } from "react-redux";
import { useTemporarySubmitDisabledSolution } from "../../helpers/useTemporarySubmitDisabledSolution.hook";
import { TEMPORARY_SUBMIT_BUTTON_DISABLED_SOLUTION_SELECTOR } from "../../../../../utility/constants/common";
import { DebounceButton } from "../../../../../components/common/DebounceButton";

export function CurpUploadLayout() {
  const { applicationId } = useParams<{
    applicationId: string;
  }>();
  const dispatch = useDispatch();
  const { data: candidateData, refetch } = useGetCandidateDataQuery({ applicationId });
  const [getPresignedURL] = useGetPresignedURLToCDSMutation();
  const [submitDocToCDS] = useSubmitDocToCDSFromPresignedURLMutation();
  const [updateDocMetadataToCDS] = useUpdateCDSDocumentUploadMetadataMutation();

  const bundle = useChecklistArb();
  const [frontErrorMessage, setFrontErrorMessage] = useState<string>();
  const [backErrorMessage, setBackErrorMessage] = useState<string>();
  const [dependencyServiceErrorMessage, setDependencyServiceErrorMessage] = useState<string>();
  const backToMainPage = useBackToMainPage(refetch);

  const curpIdUploadButtonTextMessage = bundle.getMessage("Checklist-Mainpage-CURP-ID-Upload-ButtonText");
  const curpIdMessage = bundle.getMessage("Checklist-CURP-ID-Text");
  const documentImageSubmitMessage = bundle.getMessage("Checklist-Document-Image-Submit-ButtonText");

  const documentMaximumFileSizeMessage = bundle.getMessage("Checklist-Document-Maximum-File-Size-Text");
  const documentAcceptableFileMessage = bundle.getMessage("Checklist-Document-Acceptable-File-Text");
  const p0LimitFixMessage = bundle.getMessage("p0LimitFixMessage");
  const documentUploadDependencyServiceErrorMessage = bundle.getMessage("Checklist-Document-General-Error-Message");
  const frontErrorMessageBanner = bundle.getMessage("Checklist-Document-General-Error-Front-Message");
  const backErrorMessageBanner = bundle.getMessage("Checklist-Document-General-Error-Back-Message");

  const [frontDocument, setFrontDocument] = useState<UploadDetails>();
  const [backDocument, setBackDocumnet] = useState<UploadDetails>();
  const [blockSubmitButton, setBlockSubmitButton] = useState<boolean>(true);
  useTemporarySubmitDisabledSolution(false);

  useEffect(() => {
    // Back side is optional but should disable when back error message present
    setBlockSubmitButton(!frontDocument?.imageBase64 || !!backErrorMessage);
  }, [frontDocument, backErrorMessage]);

  const updateDocument = async (image: string, fileName: string, contentType: string, side: string) => {
    if (candidateData) {
      const documentData = {
        documentType: AttachmentType.CURP,
        attachmentSubType: side,
        imageBase64: image,
        fileName: fileName,
        contentType: contentType,
        candidateId: candidateData.candidateId,
      };

      if (side === AttachmentSubType.FRONT) {
        setFrontDocument(documentData);
      } else {
        setBackDocumnet(documentData);
      }
    } else {
      dispatchOnDev(() => {
        console.error("candidateData is not ready yet");
      });
    }
  };

  const clickEventSubmitDocument = async () => {
    const inputFront = document.getElementById("curp-front");
    const inputBack = document.getElementById("curp-back");

    if (isDevEnv()) console.log("start submit document");

    if (frontErrorMessage || backErrorMessage) {
      if (backErrorMessage) {
        inputBack?.focus();
      }
      if (frontErrorMessage) {
        inputFront?.focus();
      }
      return;
    }

    if (!frontDocument?.imageBase64) {
      inputFront?.focus();
      setFrontErrorMessage(frontErrorMessageBanner);
      return;
    }

    let returnToMainPage = true;
    if (frontDocument) {
      try {
        const docs = [frontDocument];
        // Back is optional currently
        if (backDocument?.imageBase64) {
          docs.push(backDocument);
        }
        await submitDocument(
          getPresignedURL,
          submitDocToCDS,
          updateDocMetadataToCDS,
          applicationId,
          docs,
          setBlockSubmitButton
        );
      } catch (err) {
        console.log(err);
        setBlockSubmitButton(false);
        setDependencyServiceErrorMessage(documentUploadDependencyServiceErrorMessage);
        returnToMainPage = false;
      }
    }

    deleteCachedRequest(applicationId); // delete stale data so that updated Id loads on home page
    if (returnToMainPage) {
      backToMainPage();
    }
  };

  const frontErrorMessageText =
    frontErrorMessage === frontErrorMessageBanner ? `${frontErrorMessageBanner}.` : frontErrorMessage;
  const backErrorMessageText =
    backErrorMessage === backErrorMessageBanner ? `${backErrorMessageBanner}.` : backErrorMessage;

  return (
    <Col display={"flex"} justifyContent={"center"} gridGap={6} width={"100%"}>
      <H2>{curpIdUploadButtonTextMessage}</H2>
      <Text>
        {curpIdMessage} <strong style={{ color: "red" }}>*</strong>{" "}
      </Text>
      <Text>{documentAcceptableFileMessage}</Text>
      <Text>{documentMaximumFileSizeMessage}</Text>
      <Row padding={{ top: "10px", bottom: "10px" }} gridGap="S400">
        <UploadCard
          side={AttachmentSubType.FRONT}
          documentDetailsFn={updateDocument}
          setErrorMessage={setFrontErrorMessage}
          setDependencyErrorMessage={setDependencyServiceErrorMessage}
          applicationId={applicationId}
          attachmentTypeName={curpIdUploadButtonTextMessage}
          customId={`curp-front`}
          hasError={!!frontErrorMessage}
        />
        <UploadCard
          side={AttachmentSubType.BACK}
          optional={true}
          documentDetailsFn={updateDocument}
          setErrorMessage={setBackErrorMessage}
          setDependencyErrorMessage={setDependencyServiceErrorMessage}
          applicationId={applicationId}
          attachmentTypeName={curpIdUploadButtonTextMessage}
          customId={`curp-back`}
          hasError={!!backErrorMessage}
        />
      </Row>
      {dependencyServiceErrorMessage && (
        <MessageBanner type={MessageBannerType.Error}>{dependencyServiceErrorMessage}</MessageBanner>
      )}
      {frontErrorMessage && (
        <MessageBanner type={MessageBannerType.Error}>
          <Text id={`curp-front-description`}>{frontErrorMessageText}</Text>
        </MessageBanner>
      )}
      {backErrorMessage && (
        <MessageBanner type={MessageBannerType.Error}>
          <Text id={`curp-back-description`}>{backErrorMessageText}</Text>
        </MessageBanner>
      )}
      <Row padding={{ top: "10px", bottom: "10px" }}>
        <MessageBanner type={MessageBannerType.Informational}>{p0LimitFixMessage}</MessageBanner>
      </Row>
      <Col padding={"10px"}>
        <DebounceButton
          onClick={clickEventSubmitDocument}
          style={{ visibility: "hidden" }}
          id={TEMPORARY_SUBMIT_BUTTON_DISABLED_SOLUTION_SELECTOR}
        >
          {documentImageSubmitMessage}
        </DebounceButton>
      </Col>
    </Col>
  );
}
