import { LoggerSender, LogType } from "@amzn/hvh_client_side_error_tracker/dist/logger-sender";
import { MutationTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  MutationDefinition,
} from "@reduxjs/toolkit/dist/query";

const createOnFlush =
  (
    url: string,
    triggerMutation: MutationTrigger<
      MutationDefinition<
        {
          metrics: any;
        },
        BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, any, FetchBaseQueryMeta>,
        string,
        any,
        "api"
      >
    >
  ) =>
  async (metrics: any) => {
    try {
      triggerMutation({ metrics });
      return true;
    } catch (e) {
      return false;
    }
  };

export class MetricsSender {
  loggerSender: LoggerSender;

  constructor(
    url: string,
    initMetricFn: MutationTrigger<
      MutationDefinition<
        {
          metrics: any;
        },
        BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, any, FetchBaseQueryMeta>,
        string,
        any,
        "api"
      >
    >
  ) {
    this.loggerSender = new LoggerSender({
      onFlush: createOnFlush(url, initMetricFn),
      urlResolver: () => Promise.resolve(url),
    });
  }

  push(metric: any) {
    if (process.env.NODE_ENV === "development") {
      console.log(metric);
    }
    this.loggerSender.push(JSON.stringify(metric), LogType.Metric);
  }
}
